import React from 'react';
import { PauseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { numberToCurrency } from '../../../../shared/function';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const columns = (onEdit, onDelete, onPrint, onUpdateStatus, isManager) => [
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code',
    sorter: {}
  },
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title',
    render: (title) => <div style={{ whiteSpace: 'pre-line' }}>{title}</div>
  },
  {
    title: 'Nội dung',
    dataIndex: 'note',
    key: 'note',
    render: (note) => <div style={{ whiteSpace: 'pre-line' }}>{note}</div>
  },
  {
    title: 'Số tiền',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Ngày lập phiếu',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: 'Kế toán duyệt',
    dataIndex: 'accountantConfirm',
    key: 'accountantConfirm',
    width: 120,
    render: (accountantConfirm) => {
      if (!accountantConfirm) {
        return (
          <Tooltip title="Đang chờ duyệt" color="red" key="red">
            <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã duyệt" color="green" key="green">
          <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
        </Tooltip>
      );
    }
  },
  {
    title: 'Xác nhận',
    dataIndex: 'confirmed',
    key: 'confirmed',
    width: 120,
    render: (confirmed) => {
      if (!confirmed) {
        return (
          <Tooltip title="Đang chờ xác nhận" color="red" key="red">
            <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã xác nhận" color="green" key="green">
          <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
        </Tooltip>
      );
    }
  }
];
