import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UserOutlined, LockOutlined, EyeTwoTone } from '@ant-design/icons';
import { Col, Row, Input, Form, Button } from 'antd';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { LeftColumn, RightColumn, RegisterLink, Footer, FormTitle, FormWrapper, MobileImage } from './styles';
import { onLogin } from './saga';
import { fieldValidate } from '@shared/function';

const { Item } = Form;
const LoginView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onSubmit = useCallback((params) => dispatch(onLogin(params)), [dispatch]);

  const onFinish = (values) => {
    const query = queryString.parse(location.search);
    values.email = values.email.toLowerCase();
    onSubmit({ user: { ...values }, redirect_to: query?.redirect_to || '' });
  };

  return (
    <Row style={{ width: '100%' }}>
      <RightColumn span={10}>
        <MobileImage src="/images/logo-white.png" alt="error" />
        <FormWrapper data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
          <FormTitle>Đăng nhập</FormTitle>
          <Form layout="vertical" form={form} name="register" scrollToFirstError onFinish={onFinish}>
            <Row>
              <Col span={24}>
                <Item name="email" rules={[fieldValidate.required, fieldValidate.email]}>
                  <Input
                    size="large"
                    placeholder="Email"
                    bordered={false}
                    suffix={<UserOutlined style={{ color: '#BDBDBD', fontSize: 20 }} />}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item name="password" rules={[fieldValidate.required]}>
                  <Input.Password
                    size="large"
                    placeholder="Mật khẩu"
                    bordered={false}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeTwoTone style={{ color: '#BDBDBD', fontSize: 20 }} />
                      ) : (
                        <LockOutlined style={{ color: '#BDBDBD', fontSize: 20 }} />
                      )
                    }
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Button htmlType="submit" type="primary" size="large" style={{ marginTop: 20, width: 160 }}>
                ĐĂNG NHẬP
              </Button>
            </Row>
          </Form>
          <Footer>
            <Link to="/forgot-password">
              <RegisterLink href="/forgot-password">Quên mật khẩu</RegisterLink>
            </Link>
            <RegisterLink href={`${process.env.WEBSITE_URL}/register`} target="_blank">
              Đăng kí
            </RegisterLink>
          </Footer>
        </FormWrapper>
      </RightColumn>
      <LeftColumn span={14} />
    </Row>
  );
};

export default LoginView;
