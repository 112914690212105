import React from 'react';
import {
  EditOutlined,
  CheckCircleOutlined,
  MenuOutlined,
  LoginOutlined,
  StopOutlined,
  TagsOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { Menu, Dropdown, Tag, Modal } from 'antd';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (
  onEdit,
  onLogin,
  onLock,
  onUnlock,
  onAddHashtag,
  onActive,
  onAddForumTopic,
  isAdmin,
  removeStudent,
  onChangePermission
) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {}
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name',
    sorter: {},
    render: (name, item) => (
      <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'wrap row' }}>
        {name}
        {item.forumMemberText !== 'Thành viên' && (
          <Tag color="orange" style={{ marginRight: 5, marginTop: 5 }}>
            {item.forumMemberText}
          </Tag>
        )}
      </div>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: {}
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    key: 'phone',
    sorter: {}
  },
  {
    title: 'Giới tính / Tuổi',
    render: ({ age, gender }) =>
      age ? `${gender === 'male' ? 'Nam' : 'Nữ'} / ${age} tuổi` : gender === 'male' ? 'Nam' : 'Nữ'
  },
  {
    title: 'Nghề nghiệp',
    dataIndex: 'job'
  },
  {
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
    sorter: {},

    render: (status) => {
      switch (status) {
        case 'locked':
          return <Tag color="red">Cấm truy cập</Tag>;
        case 'not_confirm':
          return <Tag color="orange">Đang chờ xác nhận</Tag>;
        default:
          return <Tag color="green">Đang hoạt động</Tag>;
      }
    }
  },
  {
    width: 250,
    title: 'Hashtag',
    render: (item) =>
      item.hashtags.map((hashtag) => (
        <Tag color="gray" style={{ margin: 5 }}>
          {hashtag.name}
        </Tag>
      ))
  },
  {
    width: 250,
    title: 'Chủ đề Forum',
    render: (item) =>
      item.forumTopics.map((topic) => (
        <Tag color="gray" style={{ margin: 5 }}>
          {topic.name}
        </Tag>
      ))
  },
  {
    title: 'Ngày đăng kí',
    key: 'created_at',
    dataIndex: 'createdAt',
    sorter: {}
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onChangePermission(item)}>
                <TagsOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Thay đổi chức vụ
              </Menu.Item>
              <Menu.Item onClick={() => onAddForumTopic(item)}>
                <TagsOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Thêm chủ đề diễn đàn
              </Menu.Item>
              <Menu.Item onClick={() => onAddHashtag(item)}>
                <TagsOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Thêm hashtag trả phí
              </Menu.Item>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              {isAdmin && (
                <Menu.Item onClick={() => onLogin(item)}>
                  <LoginOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Đăng nhập
                </Menu.Item>
              )}
              {item.status === 'not_confirm' && (
                <Menu.Item onClick={() => onActive(item)} style={{ color: 'green' }}>
                  <CheckCircleOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Kich hoạt tài khoản
                </Menu.Item>
              )}
              {(item.status === 'active' || item.status === 'not_confirm') && (
                <Menu.Item onClick={() => onLock(item)} style={{ color: 'red' }}>
                  <StopOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Cấm truy cập
                </Menu.Item>
              )}
              {item.status === 'locked' && (
                <Menu.Item onClick={() => onUnlock(item)} style={{ color: 'green' }}>
                  <CheckCircleOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Cho phép truy cập
                </Menu.Item>
              )}
              {isAdmin && (
                <Menu.Item
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <div>
                          Bạn có muốn xóa tài khoản thành viên: <b>{item.name}</b> này không?
                        </div>
                      ),
                      icon: <ExclamationCircleOutlined />,
                      okText: 'Có',
                      cancelText: 'Không',
                      onOk: () => {
                        removeStudent(item);
                      }
                    });
                  }}
                >
                  <LoginOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa tài khoản
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    )
  }
];
