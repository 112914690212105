import { get } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';

const ON_GET_WATCHER = 'ON_GET_WATCHER';

function* getData({ params, setData }) {
  try {
    const response = yield call(get, '/statistics', { params });
    setData({
      loading: false,
      studentDebts: response.studentDebts,
      empployeeDebts: response.empployeeDebts,
      totalExpenses: response.totalExpenses,
      totalIncome: response.totalIncome
    });
  } catch (error) {
    console.log(error);
  }
}

export const onGetStatistic = (params, setData) => ({
  type: ON_GET_WATCHER,
  params,
  setData
});

export default function* watcher() {
  yield takeLatest(ON_GET_WATCHER, getData);
}
