import React, { useState, useRef } from 'react';
import { EditOutlined, AntDesignOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { Wrapper } from './styles';
import ViewAllClass from './Modal/AllClass';

const editIconStyle = { marginLeft: 10, fontSize: 18, cursor: 'pointer' };

const ClassRoom = ({ item, teachers }) => {
  const [data, setData] = useState(item.courseClasses || []);
  const classDetailRef = useRef();

  return (
    <>
      <ViewAllClass
        courseId={item.id}
        setData={setData}
        teachers={teachers}
        ref={classDetailRef}
        courseClasses={data}
      />
      <Wrapper>
        <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {data.map((item) => (
            <Avatar key={item.id} style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} />
          ))}
        </Avatar.Group>
        <EditOutlined style={editIconStyle} onClick={() => classDetailRef.current.open()} />
      </Wrapper>
    </>
  );
};
export default ClassRoom;
