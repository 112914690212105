import styled from 'styled-components';
import { List, Space } from 'antd';

export const StyledList = styled(List)`
  max-height: calc(100vh - 376px);
  overflow-y: auto;

  .ant-list-item {
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    transform: 0.4s ease 0s;
    position: relative;

    &.active {
      border: 1px solid #9b9b9b;
    }
  }

  .ant-list-item-meta-title {
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  .ant-list-item-action {
    margin-left: 10px;
  }
`;

export const StyledSpace = styled(Space)`

`;

export const ProgressWrapper = styled.div`
  margin-left: 10px;
  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;

export const NoticeTag = styled.div`
  width: 140px;
  outline: none;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease-in-out;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: wrap row;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: ${({ totalPages }) => (totalPages > 1 ? '20px' : 0)};
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  background: ${({ color }) => color || '#333333'};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const Owner = styled.div`
  font-size: 12px;
  font-style: italic;
  color: #9b9b9b;
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  background: ${({ color }) => color};
  width: 40px;
  height: 20px;
  border-radius: 5px;
  margin-right: 10px;
`;

export const Description = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-space {
    display: flex !important;
    flex-flow: wrap row !important;

    .ant-space-item {
      display: flex;
      align-items: center;
    }
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Time = styled.div`
  font-size: 12px;
  color: #9b9b9b;
  display: flex;
  margin-left: 10px;
`;

export const StatusWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  width: 100%;
`;
