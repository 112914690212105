import React from 'react';
import { Row, Col } from 'antd';
import Resource from './Resource';
import Chart from './Chart';
import TopResource from './TopResource';

const Statistic = () => (
  <Row gutter={48}>
    <Col span={24}>
      <Resource />
    </Col>
    <Col span={24}>
      <Chart />
    </Col>
    <Col span={24}>
      <TopResource />
    </Col>
  </Row>
);

export default Statistic;
