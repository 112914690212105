import {
  get, put as axiosPut, post, delete as axiosDelete,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_PROMOTION_SELECT_VALUE = 'ON_GET_PROMOTION_SELECT_VALUE';
const ON_GET_PROMOTIONS = 'ON_GET_PROMOTIONS';
const ON_EDIT_PROMOTION = 'ON_EDIT_PROMOTION';
const ON_NEW_PROMOTION = 'ON_NEW_PROMOTION';
const ON_REMOVE_PROMOTION = 'ON_REMOVE_PROMOTION';

function* getPromotions({ params, setData }) {
  try {
    const response = yield call(get, '/promotions', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* getPromotionSelectValues({ setData }) {
  try {
    const response = yield call(get, '/promotions/select_values');
    setData(response.items);
  } catch (error) {
    console.log(error);
  }
}

function* editPromotion({ params, callback }) {
  try {
    yield call(axiosPut, `/promotions/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa khuyến mãi thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newPromotion({ params, callback }) {
  try {
    yield call(post, '/promotions', params);
    Notification.success({ message: 'Thêm khuyến mãi thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removePromotion({ params, callback }) {
  try {
    yield call(axiosDelete, `/promotions/${params.id}`);
    Notification.success({ message: 'Xóa khuyến mãi thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewPromotion = (params, callback) => ({
  type: ON_NEW_PROMOTION, params, callback,
});

export const onEditPromotion = (params, callback) => ({
  type: ON_EDIT_PROMOTION, params, callback,
});

export const onGetPromotions = (params, setData) => ({
  type: ON_GET_PROMOTIONS, params, setData,
});

export const onGetPromotionSelectValue = (setData) => ({
  type: ON_GET_PROMOTION_SELECT_VALUE, setData,
});

export const onDeletePromotion = (params, callback) => ({
  type: ON_REMOVE_PROMOTION, params, callback,
});

export default function* promotionWatcher() {
  yield takeLatest(ON_GET_PROMOTIONS, getPromotions);
  yield takeLatest(ON_EDIT_PROMOTION, editPromotion);
  yield takeLatest(ON_NEW_PROMOTION, newPromotion);
  yield takeLatest(ON_REMOVE_PROMOTION, removePromotion);
  yield takeLatest(ON_GET_PROMOTION_SELECT_VALUE, getPromotionSelectValues);
}
