import React, { useRef, useCallback, useState, useEffect } from 'react';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Button, Modal, Row, Col, Input, Table, Descriptions, Typography } from 'antd';
import {
  onGetCourseClassStudents,
  onRemoveCourseClassStudent,
  onUpdateCourseClassStudent
} from '../../../saga';
import AddStudent from './AddStudent';
import Information from './Information';
import { columns } from './function';

const Students = ({ courseClassId, info }) => {
  let typing = null;
  const dispatch = useDispatch();
  const createRef = useRef();
  const infoRef = useRef();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 0,
    currentStudents: []
  });
  const currentFilter = useRef({});

  const { loading, items, currentPage, totalPages, currentStudents } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetCourseClassStudents(params, setState)), [
    dispatch,
    courseClassId
  ]);

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      fetchData();
    }, 1000);
  };

  const removeStudent = useCallback((params) => dispatch(onRemoveCourseClassStudent(params, fetchData)), [
    dispatch
  ]);

  const updateStudent = useCallback((params) => dispatch(onUpdateCourseClassStudent(params, fetchData)), [
    dispatch
  ]);

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn loại Học viên này ra khỏi lớp học?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        removeStudent({ courseClassId, course_class_student_id: item.id });
      }
    });
  };

  const onUpdateReceiveGiftStatus = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn điều chỉnh thông tin nhận quà của học viên này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        updateStudent({ courseClassId, course_class_student_id: item.id, receive_gift: !item.receiveGift });
      }
    });
  };

  const onView = ({ user }) => infoRef.current.open(user);
  const onChange = (pagination, filters, sorter) => {
    if (pagination.current) currentFilter.current.page = pagination.current;
    fetchData();
  };

  useEffect(() => {
    currentFilter.current.courseClassId = courseClassId;
    getData(currentFilter.current);
  }, []);

  return (
    <Row>
      <Information ref={infoRef} />
      <Input.Search
        style={{ marginBottom: 15 }}
        placeholder="Từ khóa"
        allowClear
        enterButton
        size="large"
        onChange={onSearch}
        onSearch={onSearch}
      />
      <AddStudent
        ref={createRef}
        fetchData={fetchData}
        users={[]}
        courseClassId={courseClassId}
        currentCourseStudentIds={currentStudents}
      />
      <Col span={24} style={{ marginBottom: 15 }}>
        <Descriptions layout="vertical" bordered columns={1}>
          <Descriptions.Item label="Quà tặng">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {(info.gifts || []).map((gift) => (
                <Typography.Text key={gift}>{`- ${gift}`}</Typography.Text>
              ))}
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => createRef.current.open()} type="primary" icon={<PlusOutlined />}>
          Thêm học viên
        </Button>
      </Col>
      <Col span={24}>
        <Table
          columns={columns(onView, onDelete, onUpdateReceiveGiftStatus)}
          loading={loading}
          dataSource={items}
          onChange={onChange}
          pagination={{
            total: 25 * totalPages,
            position: ['', 'bottomCenter'],
            pageSize: 25,
            showLessItems: true,
            current: currentPage,
            defaultCurrent: 1
          }}
        />
      </Col>
    </Row>
  );
};
export default Students;
