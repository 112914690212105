import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, DatePicker, Select, Space, Table, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import slug from 'slug';
import { ChartHeader } from './styles';
import { onGetTopResourceStatistic } from '../saga';
import { dateFormat } from '@shared/function';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function PickerWithType({ type, onChange }) {
  if (type === 'date') {
    return (
      <RangePicker
        format="DD-MM-YYYY"
        onChange={onChange}
        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
        defaultValue={[moment().startOf('months'), moment().endOf('months')]}
      />
    );
  }
  return <DatePicker picker={type} onChange={onChange} defaultValue={moment()} />;
}

const columns = [
  {
    title: 'Bài viết',
    dataIndex: 'name',
    key: 'name',
    width: '50%'
  },
  {
    title: 'Số lượng',
    dataIndex: 'count',
    key: 'count',
    width: '20%'
  },
  {
    title: 'Người đăng',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: 'Xem',
    render: (item) => (
      <a href={`${process.env.WEBSITE_URL}/forum/${item.id}/${slug(item.name)}`} target="_blank">
        Xem bài viết
      </a>
    )
  }
];

const TopResource = () => {
  const [type, setType] = useState('date');
  const [state, setState] = useState({
    loading: true,
    data: {
      topViews: [],
      topLike: [],
      topComments: []
    }
  });
  const { loading, data } = state;
  const currentFilter = useRef({});
  const dispatch = useDispatch();

  const getData = useCallback(
    (params) =>
      dispatch(
        onGetTopResourceStatistic(params, (response) =>
          setState({
            loading: false,
            data: response
          })
        )
      ),
    [dispatch]
  );

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const setDateFilter = (dates, calendarType) => {
    if (calendarType === 'date') {
      currentFilter.current.start_date = dates[0].format(dateFormat);
      currentFilter.current.end_date = dates[1].format(dateFormat);
    }

    if (calendarType === 'month') {
      currentFilter.current.start_date = dates.startOf('month').format(dateFormat);
      currentFilter.current.end_date = dates.endOf('month').format(dateFormat);
    }

    if (calendarType === 'year') {
      currentFilter.current.start_date = dates.startOf('year').format(dateFormat);
      currentFilter.current.end_date = dates.endOf('year').format(dateFormat);
    }

    fetchData();
  };

  const onChangeDate = (dates) => setDateFilter(dates, type);

  const changeType = (value) => {
    setType(value);
    if (value === 'date') {
      setDateFilter([moment().startOf('months'), moment().endOf('months')], value);
    } else {
      setDateFilter(moment(), value);
    }
  };

  useEffect(() => {
    currentFilter.current.start_date = moment().startOf('months').format(dateFormat);
    currentFilter.current.end_date = moment().endOf('months').format(dateFormat);
    fetchData();
  }, []);

  return (
    <Row gutter={24} className="site-layout-background" style={{ padding: 0, height: '100%' }}>
      <ChartHeader span={24} style={{ padding: 24 }}>
        <Title level={4} style={{ margin: 0 }}>
          Thống kê
        </Title>
        <Space>
          <Select value={type} onChange={changeType}>
            <Option value="date">Ngày</Option>
            <Option value="month">Tháng</Option>
            <Option value="year">Năm</Option>
          </Select>
          <PickerWithType type={type} onChange={onChangeDate} />
        </Space>
      </ChartHeader>
      <Col span={24} style={{ padding: 24 }}>
        <Row gutter={24}>
          <Col span={24}>
            <Table
              size="small"
              columns={columns}
              loading={loading}
              dataSource={data.topViews}
              pagination={false}
              title={() => <Title level={4}>Top 5 bài viết được xem nhiều nhất</Title>}
            />
          </Col>
          <Col span={24} style={{ marginTop: 20 }}>
            <Table
              size="small"
              columns={columns}
              loading={loading}
              dataSource={data.topLike}
              pagination={false}
              title={() => <Title level={4}>Top 5 bài viết được like nhiều nhất</Title>}
            />
          </Col>
          <Col span={24} style={{ marginTop: 20 }}>
            <Table
              size="small"
              columns={columns}
              loading={loading}
              dataSource={data.topComments}
              pagination={false}
              title={() => <Title level={4}>Top 5 bài viết được comment nhiều nhất</Title>}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TopResource;
