import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button, DatePicker, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate, dateFormat } from '@shared/function';
import { onEditNotification } from '../saga';
import Ckeditor from '../../../../shared/Ckeditor';
import moment from 'moment';

const { Item } = Form;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmitting(false);
  };

  const editData = useCallback((params) => dispatch(onEditNotification(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmitting(true);
    values.start_date = values.start_date.format(dateFormat);
    values.end_date = values.end_date.format(dateFormat);
    editData({ page_notification: values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      const defaultValue = {
        start_date: moment(item.startDate, 'YYYY-MM-DD'),
        end_date: moment(item.endDate, 'YYYY-MM-DD'),
        title: item.title,
        content: item.content,
        is_public: item.isPublic
      };

      form.setFieldsValue(defaultValue);

      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} title="Chỉnh sửa thông báo" width="50%">
      <Form
        layout="vertical"
        form={form}
        name="createPromotion"
        scrollToFirstError
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={48}>
          <Col span={24}>
            <Item name="title" label="Tiêu đề" rules={[fieldValidate.required]}>
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={8}>
            <Item
              name="is_public"
              label="Trạng thái"
              rules={[fieldValidate.required]}
              valuePropName="checked"
            >
              <Switch checkedChildren="Công bố" unCheckedChildren="Ẩn" />
            </Item>
          </Col>
          <Col span={8}>
            <Item name="start_date" rules={[fieldValidate.required]} label="Ngày bắt đầu">
              <DatePicker format={dateFormat} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={8}>
            <Item name="end_date" rules={[fieldValidate.required]} label="Ngày kết thúc">
              <DatePicker format={dateFormat} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={24}>
            <Item label="Nội dung" name="content">
              <Ckeditor
                onChange={(value) => form.setFieldsValue({ content: value })}
                defaultValue={item?.content}
              />
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
