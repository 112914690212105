import styled from 'styled-components';
import { Card, List, Modal, Comment } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

export const StyledCard = styled(Card)`
  margin-top: 15px;

  .ant-card-body {
    padding: 0;
  }

  .ant-card-meta {
    display: flex;
    align-items: center;

    .ant-card-meta-title {
      margin: 0;
    }
  }
`;

export const StyledInputComment = styled(Comment)`
  .ant-comment-inner {
    padding: 0;
  }

  .ant-comment-content {
    padding: 15px;
    background: #f4f4f4;
    border-radius: 3px;
  }
`;

export const StyledList = styled(List)`
  margin-top: 20px;
  overflow-y: auto;
  max-height: 600px;
`;

export const StyledComment = styled(Comment)`
  .ant-comment-inner {
    padding: 0;
  }
`;

export const HasTagWrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  padding: 10px 15px 15px 15px;
`;

export const StyledModal = styled(Modal)`
  width: 70% !important;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const CommentWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
`;

export const InputChat = styled.div`
  bottom: 0;
  display: flex;
  width: 100%;
  min-height: 40px;
  background: transparent;
  align-items: center;

  .custom-input {
    &:before {
      position: absolute;
      color: #72808e;
      pointer-events: none;
      content: attr(placeholder);
    }
  }

  @media (max-width: 767px) {
    min-height: 30px;

    .anticon {
      font-size: 15px !important;
    }
  }
`;

export const Input = styled.div`
  user-select: text;
  width: 100%;
  -webkit-user-modify: read-write;
  border: none;
  outline: none;
  max-height: 140px;
  overflow-y: auto;

  @media (max-width: 767px) {
    font-size: 13px !important;
  }
`;

export const Send = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
`;

export const PickerWrapper = styled.div`
  display: flex;
  z-index: 300;
  flex-direction: column;
  align-items: center;
  background: #fff;
  position: absolute;
  bottom: 37px;
  right: 50px;

  .emoji-mart-preview {
    display: none;
  }

  .emoji-mart-anchors {
    padding-top: 20px;
  }

  .emoji-mart-scroll {
    &::-webkit-scrollbar-track {
      background-color: transparent !important;
      -webkit-box-shadow: none;
      border-color: none;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
      border-radius: 4px;
      background-color: transparent;
      -webkit-box-shadow: none;
      border-color: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      -webkit-box-shadow: none;
      border-color: none;
      border: none;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.28);
      }
    }
  }

  @media (max-width: 767px) {
    right: 0;
  }
`;

export const StyledCloseCircleOutlined = styled(CloseCircleOutlined)`
  font-size: 17px;
  cursor: pointer;
  color: #333333;
  position: absolute;
  right: 6px;
  top: 6px;

  @media (max-width: 767px) {
    right: 35px;
  }
`;

export default StyledCard;
