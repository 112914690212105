import React, { useState, useImperativeHandle, useCallback, useRef, useEffect } from 'react';
import {
  Col,
  Input,
  Row,
  Form,
  Modal,
  Button,
  Select,
  DatePicker,
  Typography,
  Avatar,
  Popconfirm,
  Tooltip,
  Checkbox
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fieldValidate } from '../../../../shared/function';
import { onEditTask } from '../saga';
import Ckeditor from '../../../../shared/Ckeditor';
import UploadComponent from './UploadComponent';
import SelectSearch from '../../../../components/SelectSearch';
import { Action, HeaderWrapper, Items, Item, Name } from './styles';

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option, OptGroup } = Select;

const Checklist = React.forwardRef(({ defaultValues, form }, ref) => {
  const [stuffs, setStuffs] = useState(defaultValues);

  useImperativeHandle(ref, () => ({
    listItem: () => stuffs,
    resetStuff: () => setStuffs([])
  }));

  const addStuff = () => {
    setStuffs(stuffs.concat([{}]));
  };

  const removeStuff = (index) => {
    const currentStuffs = stuffs;
    currentStuffs.splice(index, 1);
    setStuffs([].concat(currentStuffs));
  };

  useEffect(() => {
    setStuffs(defaultValues);
    const values = {};
    values.checklist_title = defaultValues.map((item) => item.title);
    values.checklist_check = defaultValues.map((item) => item.checked === 'true');

    form.setFieldsValue(values);
  }, [defaultValues]);

  return (
    <>
      <Text style={{ marginBottom: 10 }}>Danh sách công việc</Text>
      <Items>
        <HeaderWrapper>
          <Name withBackground>Nội dung</Name>
          <Action withBackground />
        </HeaderWrapper>
        {stuffs.map((item, index) => (
          <Item key={index}>
            <Name>
              <Form.Item name={['checklist_check', index]} valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item
                style={{ width: '100%', marginLeft: 10 }}
                name={['checklist_title', index]}
                rules={[fieldValidate.required]}
              >
                <Input placeholder="Nội dung" />
              </Form.Item>
            </Name>
            <Action>
              <Tooltip title="Delete" color="red" key="red">
                <Popconfirm
                  placement="top"
                  title="Do you want to remove this record?"
                  onConfirm={() => removeStuff(index)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                </Popconfirm>
              </Tooltip>
            </Action>
          </Item>
        ))}
        <Button type="dashed" onClick={addStuff} block>
          <PlusOutlined /> Add More
        </Button>
      </Items>
    </>
  );
});

const Edit = React.forwardRef(({ fetchData, employees }, ref) => {
  const uploadFileRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((reduxState) => reduxState.user.data);
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const parent = item.parent || {};

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onEditTask(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const selectAssignee = values.assignee_ids || [];
    const newAssignee = [];
    const removeAssignee = [];

    const checklist = (values.checklist_title || []).map((item, index) => ({
      title: item,
      checked: values.checklist_check[index] ? values.checklist_check[index].toString() : 'false'
    }));

    selectAssignee.map((id) => {
      const filtered = item.assigneeIds.filter((a) => a === parseInt(id, 10))[0];

      if (!filtered) {
        newAssignee.push({
          user_id: id
        });
      }
    });

    item.assigneeInfo.map((assignee) => {
      if (!selectAssignee.includes(assignee.user_id)) {
        removeAssignee.push({
          id: assignee.task_assignee_id,
          _destroy: true
        });
      }
    });

    if (values.time && values.time.length > 0) {
      values.start_at = values.time[0].format('DD/MM/YYYY HH:mm');
      values.deadline = values.time[1].format('DD/MM/YYYY HH:mm');
    }

    editData({
      ...values,
      id: item.id,
      checklist,
      task_assignees_attributes: newAssignee.concat(removeAssignee)
    });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        time:
          item.startAt && item.deadline
            ? [moment(item.startAt, 'DD/MM/YYYY HH:mm'), moment(item.deadline, 'DD/MM/YYYY HH:mm')]
            : [],
        title: item.title,
        content: item.content,
        assignee_ids: item.assigneeIds
      });
      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} width={992} title="Chỉnh sửa Task">
      <Form layout="vertical" form={form} name="editTask" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          {item.ownerInfo && (
            <Col span={24}>
              <Form.Item label="Người tạo:">
                <Avatar size={30} src={item.ownerInfo.avatarUrl} />
                <Text style={{ marginLeft: 10 }}>{item.ownerInfo.name}</Text>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item name="title" label="Tiêu đề" rules={[fieldValidate.required]}>
              <Input placeholder="Tiêu đề" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="assignee_ids" label="Nhân viên" rules={[fieldValidate.required]}>
              <SelectSearch placeholder="Nhân viên" mode="multiple">
                <OptGroup label="Nhân viên trong dự án">
                  {(parent.assigneeInfo || []).map((user) => (
                    <Option value={user.id} key={user.id}>
                      {user.name}
                    </Option>
                  ))}
                </OptGroup>
                {parent.supporterInfo && parent.supporterInfo.length > 0 && (
                  <OptGroup label="Trợ thủ">
                    {parent.supporterInfo.map((user) => (
                      <Option value={user.id} key={user.id}>
                        {user.name}
                      </Option>
                    ))}
                  </OptGroup>
                )}
                <OptGroup label="Nhân viên ngoài dự án">
                  {employees.map((user) => {
                    if (
                      parent.assigneeIds &&
                      (parent.assigneeIds.includes(user.id) || parent.supporterIds.includes(user.id))
                    )
                      return null;
                    return (
                      <Option value={user.id} key={user.id}>
                        {user.name}
                      </Option>
                    );
                  })}
                </OptGroup>
              </SelectSearch>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="time" label="Ngày bắt đầu - Ngày kết thúc" rules={[fieldValidate.required]}>
              <RangePicker
                showTime={{
                  hideDisabledOptions: false,
                  defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]
                }}
                format="DD/MM/YYYY HH:mm"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="content" label="Nội dung">
              <Ckeditor
                defaultValue={item.content || ''}
                onChange={(value) => form.setFieldsValue({ content: value })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Checklist form={form} defaultValues={item.checklist || []} />
          </Col>
          {((item.files && item.files.length > 0) || user.id === item.assigneeId) && (
            <Col span={24}>
              <Form.Item name="files" label="Files">
                <UploadComponent
                  onChange={(files) => form.setFieldsValue({ files })}
                  ref={uploadFileRef}
                  defaultValue={item.files || []}
                  disabled={user.id !== item.assigneeId}
                  canDelete={user.id === item.assigneeId}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
