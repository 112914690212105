import React from 'react';
import { Layout } from 'antd';
import Header from './Header';
import Content from './Content';

const PrivateLayout = ({ children }) => (
  <Layout style={{ height: '100%', background: 'transparent', width: '100%' }}>
    <Header />
    <Content>{children}</Content>
  </Layout>
);

export default PrivateLayout;
