import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

firebase.initializeApp({
  apiKey: 'AIzaSyCyLTFjKoJCRqskCUZn7u1QCK2Jd2IMgwM',
  authDomain: 'yclassroom-8172b.firebaseapp.com',
  projectId: 'yclassroom-8172b',
  storageBucket: 'yclassroom-8172b.appspot.com',
  messagingSenderId: '230421808661',
  appId: '1:230421808661:web:0c9d63ea42fb5f7b12f01c',
  measurementId: 'G-CBX04886MN',
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const currentTime = () => firebase.firestore.FieldValue.serverTimestamp();

export { auth, firestore, currentTime };
