import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker, Tooltip, Popconfirm, Typography,
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  fieldValidate,
} from '../../../../shared/function';
import { onCreateTask } from '../saga';
import Ckeditor from '../../../../shared/Ckeditor';
import SelectSearch from '../../../../components/SelectSearch';

import {
  Action, HeaderWrapper, Items, Item, Name,
} from './styles';

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const { Text } = Typography;

const Checklist = React.forwardRef(({}, ref) => {
  const [stuffs, setStuffs] = useState([]);

  useImperativeHandle(ref, () => ({
    listItem: () => stuffs,
    resetStuff: () => setStuffs([]),
  }));

  const addStuff = () => {
    setStuffs(stuffs.concat([{}]));
  };

  const removeStuff = (index) => {
    const currentStuffs = stuffs;
    currentStuffs.splice(index, 1);
    setStuffs([].concat(currentStuffs));
  };

  return (
    <>
      <Text style={{ marginBottom: 10 }}>Danh sách công việc</Text>
      <Items>
        <HeaderWrapper>
          <Name withBackground>Nội dung</Name>
          <Action withBackground />
        </HeaderWrapper>
        {
          stuffs.map((item, index) => (
            <Item key={index}>
              <Name>
                <Form.Item
                  style={{ width: '100%' }}
                  name={['checklist_title', index]}
                  rules={[fieldValidate.required]}
                >
                  <Input placeholder='Nội dung' />
                </Form.Item>
              </Name>
              <Action>
                <Tooltip title='Delete' color='red' key='red'>
                  <Popconfirm placement='top' title='Do you want to remove this record?' onConfirm={() => removeStuff(index)} okText='Yes' cancelText='No'>
                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                  </Popconfirm>
                </Tooltip>
              </Action>
            </Item>
          ))
        }
        <Button
          type='dashed'
          onClick={addStuff}
          block
        >
          <PlusOutlined />
          {' '}
          Add More
        </Button>
      </Items>
    </>
  );
});

const Create = React.forwardRef(({ fetchData, employees }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ isShow: false, item: {} });
  const { isShow, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ isShow: false, item: {} });
  };

  const callback = (status, response) => {
    if (status === 200) {
      fetchData(response);
      close();
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(
    onCreateTask(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const taskAssignees = (values.assignee_ids || []).map((employee) => ({
      user_id: employee,
    }));

    const checklist = (values.checklist_title || []).map((item) => ({
      title: item,
      checked: 'false',
    }));

    values.parent_id = item.id;

    if (values.time && values.time.length > 0) {
      values.start_at = values.time[0].format('DD/MM/YYYY HH:mm');
      values.deadline = values.time[1].format('DD/MM/YYYY HH:mm');
    }

    createData({
      user_task: {
        ...values, task_type: 'task', task_assignees_attributes: taskAssignees, checklist,
      },
    });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ isShow: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      width={992}
      title='Thêm task'
    >
      <Form
        layout='vertical'
        form={form}
        name='createQuestion'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name='title'
              label='Tiêu đề'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tiêu đề' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='assignee_ids'
              label='Nhân viên'
              rules={[fieldValidate.required]}
            >
              <SelectSearch placeholder='Nhân viên' mode='multiple'>
                <OptGroup label='Nhân viên trong dự án'>
                  {(item.assigneeInfo || []).map((user) => (
                    <Option value={user.id} key={user.id}>{user.name}</Option>
                  ))}
                </OptGroup>
                {item.supporterInfo && item.supporterInfo.length > 0 && (
                  <OptGroup label='Trợ thủ'>
                    {item.supporterInfo.map((user) => (
                      <Option value={user.id} key={user.id}>{user.name}</Option>
                    ))}
                  </OptGroup>
                )}
                <OptGroup label='Nhân viên ngoài dự án'>
                  {employees.map((user) => {
                    if (item.assigneeIds && (item.assigneeIds.includes(user.id) || item.supporterIds.includes(user.id))) return null;
                    return (
                      <Option value={user.id} key={user.id}>{user.name}</Option>
                    );
                  })}
                </OptGroup>
              </SelectSearch>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='time'
              label='Ngày bắt đầu - Ngày kết thúc'
              rules={[fieldValidate.required]}
            >
              <RangePicker
                showTime={{
                  hideDisabledOptions: false,
                  defaultValue: [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')],
                }}
                format='DD/MM/YYYY HH:mm'
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='content'
              label='Nội dung'
            >
              <Ckeditor onChange={(value) => form.setFieldsValue({ content: value })} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Checklist />
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
