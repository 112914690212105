import { put as axiosPut } from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';
import Notification from '../../../../../components/Notification';
import { onUpdateUserInfo } from '../../../../../shared/CurrentUser/redux';
import { getErrorMessage } from '../../../../../shared/function';

const ON_UPDATE_PROFILE = 'ON_UPDATE_PROFILE';
const ON_UPDATE_PASSWORD = 'ON_UPDATE_PASSWORD';

const handleError = (error) => {
  if (error.status === 500) {
    return 'Tải dữ liệu xảy ra lỗi!';
  }

  if (error.data && error.data.errors && error.data.errors.password) {
    return 'Mật khẩu hiện tại không chính xác!';
  }

  if (error.data && error.data.errors && error.data.errors.email_no_found) {
    return 'Tài khoản không tồn tại trong hệ thống!';
  }

  if (error.data && error.data.errors && error.data.errors.phone) {
    return 'Số điện thoại không hợp lệ.';
  }

  return error.message;
};

function* updateProfile({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('user[email]', params.email);
    formData.append('user[first_name]', params.firstName);
    formData.append('user[last_name]', params.lastName);
    formData.append('user[birthday]', params.birthday ? params.birthday.format('DD/MM/YYYY') : '');
    formData.append('user[gender]', params.gender === 'male' ? 0 : 1);
    formData.append('user[phone]', params.phone);
    formData.append('user[jobs]', params.jobs);
    if (params.avatar) formData.append('user[avatar]', params.avatar);

    if (params.roles.includes('teacher')) {
      formData.append('user[teacher_info_attributes][specialty]', params.specialty || '');
      formData.append('user[teacher_info_attributes][introduce]', params.introduce || '');
      formData.append('user[teacher_info_attributes][school]', params.school || '');
      formData.append('user[teacher_info_attributes][graduation_year]', params.graduation_year || '');
      formData.append('user[teacher_info_attributes][specialty_introduce]', params.specialty_introduce || '');

      if (params.images) {
        params.images.forEach((file) => {
          formData.append('user[images][]', file.id ? file.id : file);
        });
      }
    }

    const response = yield call(axiosPut, '/current_user/info', formData);
    yield put(onUpdateUserInfo(response));
    Notification.success({ message: 'Cập nhập thông tin cá nhân thành công' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* updatePassword({ params, callback }) {
  try {
    yield call(axiosPut, '/current_user/info/password', params);
    Notification.success({ message: 'Cập nhập mật khẩu thành công' });
    callback(200);
  } catch (error) {
    Notification.error({ message: handleError(error) });
    callback(400);
  }
}

export const onUpdateProfile = (params, callback) => ({
  type: ON_UPDATE_PROFILE, params, callback,
});

export const onUpdatePassword = (params, callback) => ({
  type: ON_UPDATE_PASSWORD, params, callback,
});


export default function* profileWatcher() {
  yield takeLatest(ON_UPDATE_PROFILE, updateProfile);
  yield takeLatest(ON_UPDATE_PASSWORD, updatePassword);

}
