import React, {
  useCallback, useState, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import {
  Col, Input, Row, Form, Typography, Modal, Button,
} from 'antd';
import { fieldValidate } from '../../../../shared/function';

import { onReplyQuestion } from '../saga';

const { Title, Paragraph } = Typography;
const { Item } = Form;
const { TextArea } = Input;

const ReplyModal = React.forwardRef(({ fetchData }, ref) => {
  const [state, setState] = useState({ status: false, item: {} });
  const dispatch = useDispatch();
  const [submiting, setSubmiting] = useState(false);
  const [form] = Form.useForm();
  const { status, item } = state;

  useImperativeHandle(ref, () => ({
    open: (data) => {
      form.resetFields();
      setState({ status: true, item: data || {} });
    },
  }));

  const callback = (status) => {
    if (status === 200) {
      setState({ status: false, item: {} });
      fetchData();
    }
    setSubmiting(false);
  };

  const replyQuestion = useCallback((id, params) => dispatch(
    onReplyQuestion(id, params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    replyQuestion(item.id, values);
  };

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
    >
      <Title level={2} style={{ marginBottom: 15 }}>Trả lời câu hỏi</Title>
      <Form
        layout='vertical'
        form={form}
        name='register'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Col span={24}>
          <Item
            label='Khách hàng:'
          >
            <Paragraph style={{ marginBottom: 0 }}>{item.fullName}</Paragraph>
          </Item>
        </Col>
        <Col span={24}>
          <Item
            label='Câu hỏi:'
          >
            <Paragraph style={{ marginBottom: 0 }}>{item.content}</Paragraph>
          </Item>
        </Col>
        <Row gutter={48}>
          <Col span={24}>
            <Item
              name='subject'
              rules={[fieldValidate.required]}
              label='Tiêu đề của Email'
            >
              <Input placeholder='Tiêu đề của Email' />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name='content'
              rules={[fieldValidate.required]}
              label='Nội dung câu trả lời'
            >
              <TextArea rows={6} placeholder='Nội dung câu trả lời' />
            </Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button type='primary' size='large' style={{ width: 150 }} htmlType='submit' disabled={submiting}>Gửi</Button>
          <Button size='large' style={{ width: 150, marginLeft: 20 }} onClick={() => setState({ mode: '', status: false, item: {} })}>Đóng</Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default ReplyModal;
