import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Tag } from 'antd';

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {}
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name',
    sorter: {}
  },
  {
    title: 'Loại',
    dataIndex: 'categoryType',
    key: 'category_type',
    sorter: {},
    render: (categoryType) => {
      switch (categoryType) {
        case 'income':
          return <Tag color="gray">Thu</Tag>;
        default:
          return <Tag color="green">Chi</Tag>;
      }
    }
  },

  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => onEdit(item)}>
              <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
            </Menu.Item>
            <Menu.Item onClick={() => onDelete(item)}>
              <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
      </Dropdown>
    )
  }
];
