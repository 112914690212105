import React, { useRef, useCallback } from 'react';
import { Table, Row, Col, Typography, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { columns } from './function';
import EditModal from '../../Modal/EditSubTopic';
import { onDeleteTopic } from '../../saga';

const { Title, Text } = Typography;

const Childrens = ({ record, fetchData }) => {
  const dispatch = useDispatch();
  const editRef = useRef();
  const onEdit = (item) => editRef.current.open(item);

  const removeSubTopic = useCallback((params) => dispatch(onDeleteTopic(params, fetchData)), [dispatch]);

  const onDelete = (item) => {
    Modal.confirm({
      title: (
        <div>
          <Text strong>Tất cả bài viết trong chủ đề sẽ bị xóa.</Text> Bạn có muốn chủ đề này không?
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        removeSubTopic({ id: item.id });
      }
    });
  };

  return (
    <Row style={{ padding: '40px 30px', background: '#f0f2f5' }}>
      <EditModal ref={editRef} parentId={record.id} fetchData={fetchData} />
      <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
        <Title level={4}>Chủ đề con</Title>
      </Col>
      <Table columns={columns(onEdit, onDelete)} dataSource={record.childrens} pagination={false} />
    </Row>
  );
};

export default Childrens;
