import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Row, Button, Tabs, Skeleton } from 'antd';
import { useDispatch } from 'react-redux';

import { StyledModal } from '../styles';
import { onGetRegistrationInfo, onUpdateRegistrationInfo } from '../../saga';
import InfoTab from './InfoTab';
import IncomeTab from './IncomeTab';

const { TabPane } = Tabs;

const Loading = () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Skeleton active />
    <Skeleton active />
    <Skeleton active />
  </div>
);

const Preview = React.forwardRef(({ fetchData, employees }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [state, setState] = useState({ loading: true, item: {} });
  const { item, loading } = state;

  const close = () => {
    setState({ loading: true, item: {} });
    setShowModal(false);
  };

  const getData = useCallback((id) => dispatch(onGetRegistrationInfo(id, setState)), [dispatch]);

  const editData = useCallback((params, callback) => dispatch(onUpdateRegistrationInfo(params, callback)), [
    dispatch
  ]);

  const updateData = (response) => {
    fetchData();
    setState((state_) => ({ ...state_, item: response }));
  };

  useImperativeHandle(ref, () => ({
    open: (id) => {
      getData(id);
      setShowModal(true);
    }
  }));

  return (
    <StyledModal closable={false} visible={isShow} footer={null} title="Thông tin đăng kí khoá học">
      <Row>
        <Col span={24}>
          {loading ? (
            <Loading />
          ) : (
            <Tabs defaultActiveKey="register">
              <TabPane tab="Thông tin đăng kí" key="register">
                <InfoTab
                  item={item}
                  updateData={updateData}
                  onEdit={editData}
                  fetchData={fetchData}
                  employees={employees}
                />
              </TabPane>
              <TabPane tab="Phiếu thu" key="income">
                <IncomeTab items={item.incomes} updateData={updateData} registrationId={item.id} />
              </TabPane>
            </Tabs>
          )}
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button size="large" style={{ width: 150 }} onClick={close}>
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default Preview;
