import React, { useState, useImperativeHandle, useCallback, useRef, useEffect } from 'react';
import { Col, Row, Form, Button, Select, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate } from '@shared/function';
import SelectSearch from '@components/SelectSearch';
import { onGetStudentBaseRecord } from '@pages/Admin/Students/saga';
import { onEditCourseClassField } from '../../../saga';

const { Item } = Form;
const { Option } = Select;

const AddStudent = React.forwardRef(({ courseClassId, currentCourseStudentIds, fetchData }, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [students, setStudents] = useState([]);

  const close = () => {
    if (uploadRef.current) uploadRef.current.reset();
    form.resetFields();
    setShowModal(false);
  };

  const requestCallback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmitting(false);
  };

  const getStudents = useCallback((params) => dispatch(onGetStudentBaseRecord(setStudents)), [dispatch]);

  const editData = useCallback((params) => dispatch(onEditCourseClassField(params, requestCallback)), [
    dispatch
  ]);

  const onFinish = (values) => {
    const params = values.users.map((userId) => ({
      user_id: userId
    }));
    setSubmitting(true);
    editData({ course_class: { course_class_students_attributes: params }, id: courseClassId });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  useEffect(() => {
    getStudents();
  }, []);

  return (
    <Modal closable={false} visible={isShow} footer={null} title="Thêm học viên">
      <Form layout="vertical" form={form} name="addTeacher" scrollToFirstError onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <Item name="users" rules={[fieldValidate.required]} label="Học viên">
              <SelectSearch placeholder="Học viên" mode="multiple">
                {students.map((user) => {
                  if (currentCourseStudentIds.includes(user.id)) return null;
                  return (
                    <Option value={user.id} key={user.id}>
                      {`${user.first_name} ${user.last_name}`}
                    </Option>
                  );
                })}
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default AddStudent;
