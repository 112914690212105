import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_COMPANY_EXPENSES = 'ON_GET_COMPANY_EXPENSES';
const ON_EDIT_COMPANY_EXPENSE = 'ON_EDIT_COMPANY_EXPENSE';
const ON_NEW_COMPANY_EXPENSE = 'ON_NEW_COMPANY_EXPENSE';
const ON_REMOVE_COMPANY_EXPENSE = 'ON_REMOVE_COMPANY_EXPENSE';

function* getList({ params, setData }) {
  try {
    const response = yield call(get, '/company_expenses', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* editItem({ params, callback }) {
  try {
    yield call(axiosPut, `/company_expenses/${params.id}`, params.formData);
    Notification.success({ message: 'Chỉnh sửa phiếu chi thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newItem({ params, callback }) {
  try {
    yield call(post, '/company_expenses', params.formData);
    Notification.success({ message: 'Thêm phiếu chi thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeItem({ params, callback }) {
  try {
    yield call(axiosDelete, `/company_expenses/${params.id}`);
    Notification.success({ message: 'Xóa phiếu chi thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewData = (params, callback) => ({
  type: ON_NEW_COMPANY_EXPENSE,
  params,
  callback
});

export const onEditData = (params, callback) => ({
  type: ON_EDIT_COMPANY_EXPENSE,
  params,
  callback
});

export const onGetData = (params, setData) => ({
  type: ON_GET_COMPANY_EXPENSES,
  params,
  setData
});

export const onDeleteData = (params, callback) => ({
  type: ON_REMOVE_COMPANY_EXPENSE,
  params,
  callback
});

export default function* watcher() {
  yield takeLatest(ON_GET_COMPANY_EXPENSES, getList);
  yield takeLatest(ON_EDIT_COMPANY_EXPENSE, editItem);
  yield takeLatest(ON_NEW_COMPANY_EXPENSE, newItem);
  yield takeLatest(ON_REMOVE_COMPANY_EXPENSE, removeItem);
}
