import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate } from '../../../../shared/function';
import Upload from '../../../../components/Upload';
import { onNewTopic } from '../saga';

const { Item } = Form;

const Create = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmitting(false);
  };

  const createData = useCallback((params) => dispatch(onNewTopic(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmitting(true);
    createData(values);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  return (
    <Modal closable={false} visible={isShow} footer={null} width={992} title="Thêm chủ đề khóa học">
      <Form layout="vertical" form={form} name="createTopic" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Item name="name" label="Chủ đề" rules={[fieldValidate.required]}>
              <Input placeholder="Chủ đề" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item label="Hình ảnh" name="image" rules={[fieldValidate.required]}>
              <Upload onFileChange={(file) => form.setFieldsValue({ image: file })} />
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
