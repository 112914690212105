import React, { useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Input, Row, Form, Button, Modal, Select } from 'antd';
import { fieldValidate } from '@shared/function';
import { onEditCourseLesson } from '../../../saga';
import SelectSearch from '@components/SelectSearch';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const Edit = React.forwardRef(({ courseId, fetchData, documents }, ref) => {
  const [state, setState] = useState({ status: false, item: {} });
  const dispatch = useDispatch();
  const [submiting, setSubmiting] = useState(false);
  const [form] = Form.useForm();
  const { status, item } = state;

  const onClose = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      onClose();
      fetchData();
    }
    setSubmiting(false);
  };

  const updateData = useCallback((params) => dispatch(onEditCourseLesson(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    updateData({ course_lesson: { ...values, id: item.id }, courseId });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        ...item,
        lesson_documents_attributes: item.lessonDocuments.map((document) => ({
          course_document_id: document.course_document_id,
          position: document.position
        })),
        lesson_type: item.lessonType
      });
      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} title="Chỉnh sửa buổi học">
      <Form layout="vertical" form={form} name="editLesson" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item label="Tiêu đề" name="title" rules={[fieldValidate.required]}>
              <Input placeholder="Tiêu đề" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="lesson_type" rules={[fieldValidate.required]} label="Loại">
              <Select placeholder="Loại">
                <Option value="practice">Thực hành</Option>
                <Option value="theory">Lý thuyết</Option>
                <Option value="theory_and_practice">Lý thuyết & Thực hành</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Tài liệu">
              <Form.List name="lesson_documents_attributes" style={{ marginTop: 10 }}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Row gutter={16} key={key}>
                        <Col span={14}>
                          <Form.Item
                            {...restField}
                            name={[name, 'course_document_id']}
                            fieldKey={[fieldKey, 'course_document_id']}
                            rules={[fieldValidate.required]}
                          >
                            <SelectSearch placeholder="Tài liệu">
                              {documents.map((document) => (
                                <Option value={document.id} key={document.id}>
                                  {document.name}
                                </Option>
                              ))}
                            </SelectSearch>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'position']}
                            fieldKey={[fieldKey, 'position']}
                            rules={[fieldValidate.required]}
                          >
                            <Input placeholder="Vị trí" />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Thêm tài liệu
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Nội dung" name="description" rules={[fieldValidate.required]}>
              <Input.TextArea placeholder="Nội dung" rows={5} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" size="large" style={{ width: 150 }} htmlType="submit" disabled={submiting}>
            Lưu
          </Button>
          <Button size="large" style={{ width: 150, marginLeft: 20 }} onClick={onClose}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
