import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import Table from '../../../shared/Table';
import { onGetNews, onDeleteNews } from './saga';
import { onGetHashtag } from '@pages/Admin/Hashtags/saga';
import Filter from './Filter';
import CreateModal from './Modal/Create';
import EditModal from './Modal/Edit';

const News = ({ type }) => {
  const location = useLocation();
  const history = useHistory();
  const createRef = useRef();
  const editRef = useRef();
  const dispatch = useDispatch();
  const [hashtags, setHashtags] = useState([]);
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetNews(params, setState)), [dispatch]);
  const getHashtag = (params) => dispatch(onGetHashtag(params, (response) => setHashtags(response.items)));

  const changeLocation = () => {
    history.push({
      pathname: type === 'promotion' ? '/admin/news-promotions' : '/admin/news',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const deleteData = useCallback((params) => dispatch(onDeleteNews(params, fetchData)), [dispatch]);

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);
    currentFilter.current.type = type === 'promotion' ? '1' : '0';

    fetchData();
  }, [location.search]);

  useEffect(() => {
    if (type === 'specialty') getHashtag();
  }, []);

  const onEdit = (item) => editRef.current.open(item);

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn xóa bài viết này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        deleteData({ id: item.id });
      }
    });
  };

  const ActionButton = () => (
    <Button onClick={() => createRef.current.open()} type="primary" icon={<PlusOutlined />}>
      Thêm bài viết
    </Button>
  );

  return (
    <>
      <CreateModal ref={createRef} fetchData={fetchData} type={type} />
      <EditModal ref={editRef} fetchData={fetchData} type={type} />
      <Table
        actionButton={<ActionButton />}
        filter={
          <Filter
            currentFilter={currentFilter}
            changeLocation={changeLocation}
            type={type}
            hashtags={hashtags}
          />
        }
        title={type === 'promotion' ? 'Bài viết Khuyến mãi / Ưu đãi' : 'Bài viết chuyên môn'}
        columns={columns(onEdit, onDelete, type)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default News;
