import {
  get,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';

const ON_GET_EMPLOYEE_DEBTS = 'ON_GET_EMPLOYEE_DEBTS';

function* getList({ params, setData }) {
  try {
    const response = yield call(get, '/employees/debts', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

export const onGetData = (params, setData) => ({
  type: ON_GET_EMPLOYEE_DEBTS, params, setData,
});

export default function* watcher() {
  yield takeLatest(ON_GET_EMPLOYEE_DEBTS, getList);
}
