import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import queryString from 'query-string';
import { Descriptions } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import Table from '../../../shared/Table';
import { onGetDebts } from './saga';
import Preview from './Modal/Preview';
import Filter from './Filter';
import { numberToCurrency } from '../../../shared/function';

const Debts = () => {
  const location = useLocation();
  const history = useHistory();
  const viewRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true, items: [], currentPage: 0, totalPages: 1, totalPrice: 0, totalIncome: 0,
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const {
    loading, items, currentPage, totalPages, totalIncome, totalPrice,
  } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(
    onGetDebts(params, setState),
  ), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/admin/debts',
      search: queryString.stringify(currentFilter.current),
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);

    fetchData();
  }, [location.search]);

  const onView = (item) => viewRef.current.open(item);

  return (
    <>
      <Preview ref={viewRef} />
      <Table
        filter={<Filter currentFilter={currentFilter} changeLocation={changeLocation} />}
        extra={(
          <Descriptions layout='vertical' bordered>
            <Descriptions.Item label='Tổng công nợ'>{numberToCurrency(totalPrice)}</Descriptions.Item>
            <Descriptions.Item label='Đã thu'>{numberToCurrency(totalIncome)}</Descriptions.Item>
            <Descriptions.Item label='Còn nợ'>{numberToCurrency(totalPrice - totalIncome)}</Descriptions.Item>
          </Descriptions>
        )}
        title='Công nợ'
        columns={columns(onView)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default Debts;
