import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_HASHTAG = 'ON_GET_HASHTAG';
const ON_EDIT_HASHTAG = 'ON_EDIT_HASHTAG';
const ON_NEW_HASHTAG = 'ON_NEW_HASHTAG';
const ON_REMOVE_HASHTAG = 'ON_REMOVE_HASHTAG';

function* getHashtag({ params, setData }) {
  try {
    const response = yield call(get, '/hashtags', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* editHashtag({ params, callback }) {
  try {
    yield call(axiosPut, `/hashtags/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa hashtag thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newHashtag({ params, callback }) {
  try {
    yield call(post, '/hashtags', params);
    Notification.success({ message: 'Thêm hashtag thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeHashtag({ params, callback }) {
  try {
    yield call(axiosDelete, `/hashtags/${params.id}`);
    Notification.success({ message: 'Xóa hashtag thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewHashtag = (params, callback) => ({
  type: ON_NEW_HASHTAG,
  params,
  callback
});

export const onEditHashtag = (params, callback) => ({
  type: ON_EDIT_HASHTAG,
  params,
  callback
});

export const onGetHashtag = (params, setData) => ({
  type: ON_GET_HASHTAG,
  params,
  setData
});

export const onDeleteHashtag = (params, callback) => ({
  type: ON_REMOVE_HASHTAG,
  params,
  callback
});

export default function* HashtagWatcher() {
  yield takeLatest(ON_GET_HASHTAG, getHashtag);
  yield takeLatest(ON_EDIT_HASHTAG, editHashtag);
  yield takeLatest(ON_NEW_HASHTAG, newHashtag);
  yield takeLatest(ON_REMOVE_HASHTAG, removeHashtag);
}
