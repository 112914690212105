import React, { useRef } from 'react';

import { Button } from 'antd';
import { numberToCurrency } from '@shared/function';
import Detail from '../Detail';

export const columns = (teachers, fetchData, onEdit) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Mã Lớp',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Địa điểm',
    dataIndex: 'address',
    key: 'address'
  },
  {
    title: 'Ngày bắt đầu',
    dataIndex: 'startDate',
    key: 'start_date'
  },
  {
    title: 'Loại hình',
    dataIndex: 'classType',
    key: 'classType'
  },
  {
    title: 'Giá',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price)
  },
  {
    title: '',
    width: 100,
    render: (item) => {
      const detailRef = useRef();
      return (
        <div style={{ display: 'flex' }}>
          <Detail ref={detailRef} fetchData={fetchData} teachers={teachers} courseClassId={item.id} />
          <Button type="primary" onClick={() => detailRef.current.open(item)}>
            Chi tiết
          </Button>
          <Button type="primary" onClick={() => onEdit(item)} style={{ marginLeft: 10 }}>
            Chỉnh sửa
          </Button>
        </div>
      );
    }
  }
];

export default columns;
