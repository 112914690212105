import React, {
  useCallback, useState, useRef, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  List, Row, Col, Button
} from 'antd';
import { PlusOutlined, } from '@ant-design/icons';
import CreatePost from './CreatePost';
import EditPost from './EditPost';
import PostItem from './PostItem';
import { onGetClassForums } from './saga';

const Forum = ({
  courseClassId,
}) => {
  const editRef = useRef();
  const createRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true, items: [], currentPage: 0, totalPages: 0,
  });
  const currentFilter = useRef({ course_class_id: courseClassId });
  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(
    onGetClassForums(params, setState),
  ), [dispatch, courseClassId]);

  const {
    loading, items, currentPage, totalPages,
  } = state;

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  useEffect(() => {
    getData(currentFilter.current);
  }, []);

  return (
    <Row>
      <EditPost ref={editRef} fetchData={fetchData} />
      <CreatePost ref={createRef} classId={courseClassId} fetchData={fetchData} />
      <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => createRef.current.open() }
          type='primary'
          icon={<PlusOutlined />}
        >
          Thêm bài viết
        </Button>
      </Col>
      <Col span={24}>
        <List
          loading={loading}
          style={{ padding: '0 12px' }}
          grid={{
            gutter: 24, xxl: 3, xl: 2, lg: 2, md: 1, sm: 1, xs: 1,
          }}
          pagination={{
            onChange: (page) => {
              currentFilter.current.page = page;
              fetchData();
            },
            total: (5 * totalPages),
            pageSize: 5,
            showLessItems: true,
            current: currentPage,
            defaultCurrent: 1,
          }}
          dataSource={items}
          renderItem={(item) => (
            <PostItem courseClassId={courseClassId} item={item} fetchData={fetchData} editRef={editRef} />
          )}
        />
      </Col>
    </Row>
  );
};
export default Forum;
