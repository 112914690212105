import React from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  PauseCircleOutlined,
  CheckCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import styled from 'styled-components';
import { numberToCurrency } from '../../../shared/function';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const iconStyle = { cursor: 'pointer', fontSize: 17 };

export const columns = (onEdit, onDelete, onPrint, onUpdateStatus, isManager) => [
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code',
    sorter: {}
  },
  {
    title: 'Nhân viên & Học viên',
    key: 'name',
    render: (item) => (
      <div>
        <Tag color={item.incomeType === 'employee' ? 'green' : 'gray'} style={{ marginright: 10 }}>
          {item.incomeType === 'employee' ? 'NV' : 'HV'}
        </Tag>
        {item.name}
      </div>
    )
  },
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title',
    render: (title) => <div style={{ whiteSpace: 'pre-line' }}>{title}</div>
  },
  {
    title: 'Nội dung',
    dataIndex: 'note',
    key: 'note',
    render: (note) => <div style={{ whiteSpace: 'pre-line' }}>{note}</div>
  },
  {
    title: 'Số tiền',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Ngày lập phiếu',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: 'Tính công nợ',
    dataIndex: 'isDebt',
    key: 'is_debt',
    render: (isDebt) => (isDebt ? 'Có' : 'Không')
  },
  {
    title: 'Kế toán duyệt',
    dataIndex: 'accountantConfirm',
    key: 'accountantConfirm',
    width: 120,
    render: (accountantConfirm) => {
      if (!accountantConfirm) {
        return (
          <Tooltip title="Đang chờ duyệt" color="red" key="red">
            <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã duyệt" color="green" key="green">
          <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
        </Tooltip>
      );
    }
  },
  {
    title: 'Xác nhận',
    dataIndex: 'confirmed',
    key: 'confirmed',
    width: 120,
    render: (confirmed) => {
      if (!confirmed) {
        return (
          <Tooltip title="Đang chờ xác nhận" color="red" key="red">
            <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã xác nhận" color="green" key="green">
          <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
        </Tooltip>
      );
    }
  },
  {
    title: '',
    width: 180,
    render: (item) => (
      <ActionWrapper>
        {isManager && (
          <>
            <Tooltip title={item.confirmed ? 'Hủy xác nhận' : 'Xác nhận'} color="geekblue" key="geekblue">
              {item.confirmed ? (
                <PauseCircleOutlined
                  style={iconStyle}
                  onClick={() => onUpdateStatus(item.id, { confirmed: false })}
                />
              ) : (
                <CheckCircleOutlined
                  style={iconStyle}
                  onClick={() => onUpdateStatus(item.id, { confirmed: true })}
                />
              )}
            </Tooltip>
            <Tooltip title="Chỉnh sửa" color="geekblue" key="geekblue">
              <EditOutlined style={iconStyle} onClick={() => onEdit(item)} />
            </Tooltip>
          </>
        )}
        {!isManager && (
          <>
            {!item.accountantConfirm && (
              <Tooltip title="Duyệt phiếu" color="geekblue" key="geekblue">
                <CheckCircleOutlined
                  style={iconStyle}
                  onClick={() => onUpdateStatus(item.id, { accountant_confirm: true })}
                />
              </Tooltip>
            )}
            <Tooltip
              title={item.accountantConfirm || item.confirmed ? 'Xem chi tiết ' : 'Chỉnh sửa'}
              color="geekblue"
              key="geekblue"
            >
              {item.accountantConfirm || item.confirmed ? (
                <EyeOutlined style={iconStyle} onClick={() => onEdit(item)} />
              ) : (
                <EditOutlined style={iconStyle} onClick={() => onEdit(item)} />
              )}
            </Tooltip>
          </>
        )}
        <Tooltip title="In phiếu" color="green" key="green">
          <PrinterOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onPrint(item)} />
        </Tooltip>
        {((!isManager && !item.accountantConfirm && !item.confirmed) || isManager) && (
          <Tooltip title="Xóa" color="red" key="red">
            <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
          </Tooltip>
        )}
      </ActionWrapper>
    )
  }
];
