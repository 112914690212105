import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  EditOutlined, DeleteOutlined,
  CheckOutlined, ClockCircleOutlined, PauseCircleOutlined,
} from '@ant-design/icons';
import {
  List, Typography, Tooltip, Popconfirm, Space, Divider, Avatar, Checkbox, Select,
} from 'antd';
import {
  onGetTasks, onDeleteTask, onEditTask,
} from '../../saga';
import {
  StyledList, Wrapper, Header, Description, TimeWrapper, Time, TitleWrapper, Label, StatusWrapper, TextWrapper, ProgressWrapper,
} from '../../styles';
import EditTask from '../../Modal/EditTask';
import Preview from '../../Modal/Preview';
import StatusText from '../../StatusText';

const { Option } = Select;
const { Title } = Typography;

const ListItem = ({
  item, fetchData, changeStatus, deleteData, employees,
}) => {
  const editRef = useRef();
  const previewRef = useRef();
  const subProject = item.parent;
  const project = subProject.parent;
  const doneQuantity = item.checklist ? item.checklist.filter((task) => task.checked === 'true').length : 0;

  const actionGroup = (item) => [
    <Tooltip placement='bottom' title={item.status === 'complete' ? 'Đang thực hiện' : 'Xác nhận đã hoàn thành'}>
      <Popconfirm
        placement='top'
        title={item.status === 'complete' ? 'Bạn có muốn chuyển thành trạng thái đang thực hiện' : 'Bạn có muốn xác nhận sub-project này đã hoàn thành?'}
        onConfirm={() => changeStatus(item.id, item.status === 'complete' ? 'processing' : 'complete')}
        okText='Có'
        cancelText='Không'
      >
        {item.status !== 'complete' ? <CheckOutlined style={{ cursor: 'pointer' }} /> : <PauseCircleOutlined style={{ cursor: 'pointer' }} />}
      </Popconfirm>
    </Tooltip>,
    <Tooltip placement='bottom' title='Chỉnh sửa'>
      <EditOutlined style={{ cursor: 'pointer' }} onClick={() => editRef.current.open(item)} />
    </Tooltip>,
    <Tooltip placement='bottom' title='Xóa'>
      <Popconfirm placement='top' title='Bạn có muốn xóa Sub Project này không?' onConfirm={() => deleteData(item.id)} okText='Có' cancelText='Không'>
        <DeleteOutlined style={{ cursor: 'pointer' }} />
      </Popconfirm>
    </Tooltip>,
  ];

  return (
    <>
      <Preview ref={previewRef} fetchData={fetchData} />
      <EditTask ref={editRef} employees={employees} fetchData={fetchData} />
      <List.Item
        key={item.key}
        actions={actionGroup(item)}
      >
        <List.Item.Meta
          onClick={() => previewRef.current.open(item)}
          title={(
            <TitleWrapper>
              <StatusWrapper>
                {project.color && <Label color={project.color} />}
                {project.title}
                {' > '}
                {subProject.title}
              </StatusWrapper>
              <StatusWrapper style={{ margin: '5px 0' }}>
                <StatusText status={item.status} />
                {item.checklist && item.checklist.length > 0 && (
                  <ProgressWrapper>
                    <Checkbox checked={true}>
                      {`${doneQuantity} / ${item.checklist.length}`}
                    </Checkbox>
                  </ProgressWrapper>
                )}
              </StatusWrapper>
              <TextWrapper style={{ marginBottom: 10 }}>
                {item.title}
              </TextWrapper>
            </TitleWrapper>
        )}
          description={(
            <Description>
              <Space split={<Divider type='vertical' />}>
                <TimeWrapper>
                  <ClockCircleOutlined />
                  <Time>{item.datetime}</Time>
                </TimeWrapper>
                <Avatar.Group
                  style={{ marginLeft: 10 }}
                  maxCount={1}
                  maxStyle={{
                    color: '#f56a00', backgroundColor: '#fde3cf', width: 25, height: 25, display: 'flex', alignItems: 'center',
                  }}
                >
                  {item.assigneeInfo.map((assignee) => (
                    <Tooltip key={assignee.name} title={assignee.name} placement='top'>
                      <Avatar src={assignee.avatarUrl} size={25} />
                    </Tooltip>
                  ))}
                </Avatar.Group>
              </Space>
            </Description>
        )}
        />
      </List.Item>
    </>
  );
};

const CreatedTask = ({ employees }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false, items: [], currentPage: 0, totalPages: 0,
  });

  const {
    items, loading,
  } = state;

  const callback = (status, response) => {
    if (status === 200) {
      setState({
        loading: false,
        items: response.items,
        totalPages: response.meta.totalPages,
        currentPage: response.meta.currentPage,
      });
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
  };

  const showLoading = () => setState((state) => ({
    ...state,
    loading: true,
  }));

  const getData = useCallback((params) => dispatch(
    onGetTasks(params, callback),
  ), [dispatch]);

  const fetchData = () => {
    showLoading();
    getData({ task_type: '2', type: 'myTask' });
  };

  const deleteData = useCallback((id) => dispatch(
    onDeleteTask(id, fetchData),
  ), [dispatch]);

  const changeStatus = useCallback((id, status) => dispatch(
    onEditTask({ id, status }, fetchData),
  ), [dispatch]);

  const onFilter = (value) => {
    getData({ task_type: '2', type: 'myTask', status: value });
  };

  useEffect(() => {
    getData({ task_type: '2', type: 'myTask' });
  }, []);

  return (
    <Wrapper>
      <Header>
        <Title level={4} style={{ color: '#fff', marginBottom: 0 }}>{`Task Đã tạo (${items.length})`}</Title>
        <Select defaultValue='Tất cả' onChange={onFilter} style={{ width: 150 }}>
          <Option value=''>Tất cả</Option>
          <Option value='0'>Chưa bắt đầu</Option>
          <Option value='4'>Đang thực hiện</Option>
          <Option value='1'>Đã hoàn thành</Option>
          <Option value='3'>Sắp tới deadline</Option>
          <Option value='2'>Trễ deadline</Option>
        </Select>
      </Header>
      <StyledList
        itemLayout='horizontal'
        dataSource={items}
        loading={loading}
        renderItem={(item) => <ListItem item={item} fetchData={fetchData} changeStatus={changeStatus} deleteData={deleteData} employees={employees} />}
      />
    </Wrapper>
  );
};

export default CreatedTask;
