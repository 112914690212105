import React, {
  useCallback, useState, useImperativeHandle,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Col, Input, Row, Form, Button, Modal, Select,
} from 'antd';

import {
  fieldValidate,
} from '../../../../../shared/function';
import SelectSearch from '../../../../../components/SelectSearch';
import { onEditCourseField } from '../../saga';

const { Option } = Select;

const EditCourseGift = React.forwardRef(({
  courseId, fetchData, gifts, courseGifts,
}, ref) => {
  const [state, setState] = useState({ status: false });
  const dispatch = useDispatch();
  const [submiting, setSubmiting] = useState(false);
  const [form] = Form.useForm();
  const { status } = state;

  const onClose = () => {
    form.resetFields();
    setState({ status: false });
  };

  const callback = (status) => {
    if (status === 200) {
      onClose();
      fetchData();
    }
    setSubmiting(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      form.setFieldsValue({
        gifts,
      });
      setState({ status: true });
    },
  }));

  const updateData = useCallback((params) => dispatch(
    onEditCourseField(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    values.gifts = values.gifts || [];
    setSubmiting(true);
    updateData({ course: values, id: courseId });
  };

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      title='Quà tặng khóa học'
    >
      <Form
        layout='vertical'
        form={form}
        name='createGift'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              label='Lựa chọn quà tặng'
              name='gifts'
            >
              <SelectSearch placeholder='Lựa chọn quà tặng' mode='multiple'>
                {courseGifts.map(
                  (item) => <Option value={item.name} key={item.id}>{item.name}</Option>,
                )}
              </SelectSearch>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button type='primary' size='large' style={{ width: 150 }} htmlType='submit' disabled={submiting}>Lưu</Button>
          <Button
            size='large'
            style={{ width: 150, marginLeft: 20 }}
            onClick={onClose}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default EditCourseGift;
