import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, DatePicker, Transfer, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { customValidate, fieldValidate, dateFormat } from '../../../../shared/function';
import SelectSearch from '../../../../components/SelectSearch';
import Upload from '../../../../components/Upload';
import UploadList from '../../../../components/UploadList';
import { onEditEmployee } from '../saga';

const { Title } = Typography;

const { Item } = Form;
const { Option } = Select;

const data = [
  {
    key: 'saler',
    title: 'Nhân viên Sale'
  },
  {
    key: 'teacher',
    title: 'Giảng viên'
  },
  {
    key: 'manager',
    title: 'Quản lý nội dung'
  },
  {
    key: 'course_manager',
    title: 'Quản lí khóa học'
  },
  {
    key: 'student_manager',
    title: 'Quản lí học viên'
  },
  {
    key: 'accountant',
    title: 'Kế toán'
  },
  {
    key: 'sale_manager',
    title: 'Quản lí Sales'
  },
  {
    key: 'task_manager',
    title: 'Quản lí Task'
  }
];

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);

  const listYears = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 40))
      .fill('')
      .map((v, idx) => now - idx);
  };
  const close = () => {
    form.resetFields();
    if (uploadRef.current) uploadRef.current.reset();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onEditEmployee(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    editData({ ...values, id: item.id, roles: targetKeys });
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      const { teacherInfo } = item;

      form.setFieldsValue({
        ...item,
        birthday: item.birthday ? moment(item.birthday, dateFormat) : null,
        specialty: teacherInfo ? teacherInfo.specialty : '',
        introduce: teacherInfo ? teacherInfo.introduce : '',
        graduation_year: teacherInfo ? teacherInfo.graduation_year : '',
        school: teacherInfo ? teacherInfo.school : '',
        specialty_introduce: teacherInfo ? teacherInfo.specialty_introduce : ''
      });
      setTargetKeys(item.roles);
      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} width={992} title="Chỉnh sửa thông tin nhân viên">
      <Form layout="vertical" form={form} name="editEmployee" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Title level={3}>Phân quyền</Title>
          </Col>
          <Col span={24}>
            <Transfer
              dataSource={data}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onSelectChange={handleSelectChange}
              onChange={handleChange}
              render={(item) => item.title}
              oneWay
              style={{ marginTop: 10 }}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="email" label="E-mail">
              <Input placeholder="E-mail" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="gender" rules={[fieldValidate.required]} label="Giới tính">
              <Select placeholder="Giới tính">
                <Option value="male">Nam</Option>
                <Option value="female">Nữ</Option>
              </Select>
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="firstName" label="Họ" rules={[fieldValidate.required]}>
              <Input placeholder="Họ" />
            </Item>
            <Item name="lastName" label="Tên" rules={[fieldValidate.required]}>
              <Input placeholder="Tên" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="avatar">
              <Upload
                square
                defaultValue={item.avatarUrl}
                onFileChange={(file) => form.setFieldsValue({ avatar: file })}
              />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="birthday" label="Ngày sinh">
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder="Ngày sinh" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name="phone"
              label="Số điện thoại"
              rules={[
                fieldValidate.required,
                () => ({
                  validator(rule, value) {
                    return customValidate.validateNumber(value);
                  }
                })
              ]}
            >
              <Input placeholder="Số điện thoại" />
            </Item>
          </Col>
          {targetKeys.includes('teacher') && (
            <>
              <Col span={24} style={{ marginTop: 20 }}>
                <Title level={3}>Thông tin giảng viên</Title>
              </Col>
              <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                <Item rules={[fieldValidate.required]} name="specialty" label="Chuyên môn">
                  <Input placeholder="Chuyên môn" />
                </Item>
              </Col>
              <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                <Item rules={[fieldValidate.required]} name="school" label="Tốt nghiệp trường">
                  <Input placeholder="Tốt nghiệp trường" />
                </Item>
              </Col>
              <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                <Item name="graduation_year" rules={[fieldValidate.required]} label="Năm tốt nghiệp">
                  <SelectSearch placeholder="Năm tốt nghiệp">
                    {listYears().map((year) => (
                      <Option value={year} key={year}>
                        {year}
                      </Option>
                    ))}
                  </SelectSearch>
                </Item>
              </Col>
              <Col span={24}>
                <Item name="introduce" label="Giới thiệu">
                  <Input.TextArea placeholder="Giới thiệu" rows={4} />
                </Item>
              </Col>
              <Col span={24}>
                <Item name="specialty_introduce" label="Chuyên môn và bằng cấp">
                  <Input.TextArea placeholder="Chuyên môn và bằng cấp" rows={4} />
                </Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Hình ảnh" name="images" />
                <UploadList
                  noCrop={false}
                  limitFile={10}
                  defaultValue={item.images || []}
                  aspect={1.92}
                  ref={uploadRef}
                  circle
                  onChange={(images) => form.setFieldsValue({ images })}
                />
              </Col>
            </>
          )}
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
