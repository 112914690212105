import styled from 'styled-components';
import { numberToCurrency } from '../../../shared/function';
import { sumBy } from 'lodash';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = () => [
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Nhân viên',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Công nợ',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => numberToCurrency(amount)
  },
  {
    title: 'Đã trả',
    dataIndex: 'subIncomes',
    key: 'subIncomes',
    render: (subIncomes) => numberToCurrency(sumBy(subIncomes, (a) => parseFloat(a.price)))
  },
  {
    title: 'Còn lại',
    render: ({ amount, subIncomes }) =>
      numberToCurrency(amount - sumBy(subIncomes, (a) => parseFloat(a.price)))
  },
  {
    title: 'Ngày lập',
    dataIndex: 'createdAt',
    key: 'createdAt'
  }
];
