import React, { useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Input, Row, Form, Button, Modal, DatePicker, Select } from 'antd';
import { fieldValidate, numberToCurrency, removeCurrency, dateFormat } from '@shared/function';
import SelectSearch from '@components/SelectSearch';

import { onNewCourseClass } from '../saga';

const { Option } = Select;

const Create = React.forwardRef(({ courseId, teachers, setData, courseClasses }, ref) => {
  const [state, setState] = useState({ status: false });
  const [type, setType] = useState('create');
  const dispatch = useDispatch();
  const [submiting, setSubmiting] = useState(false);
  const [form] = Form.useForm();
  const { status } = state;

  const onClose = () => {
    form.resetFields();
    setState({ status: false });
    setType('create');
  };

  const callback = (status, response) => {
    if (status === 200) {
      onClose();
      setData(response);
    }
    setSubmiting(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      form.setFieldsValue({ type });
      setState({ status: true });
    }
  }));

  const createData = useCallback((params) => dispatch(onNewCourseClass(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    values.price = removeCurrency(values.price);
    values.course_class_teachers_attributes = values.teachers.map((courseTeacherId) => ({
      course_teacher_id: courseTeacherId
    }));
    values.start_date = values.start_date.format(dateFormat);

    createData({
      course_class: { ...values, course_id: courseId },
      type: values.type,
      duplicate_course_class_id: values.duplicate_course_class_id
    });
  };

  return (
    <Modal closable={false} visible={status} footer={null} title="Thêm lớp học mới">
      <Form layout="vertical" form={form} name="create" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item name="type" rules={[fieldValidate.required]} label="Loại">
              <Select placeholder="Loại" onChange={(value) => setType(value)}>
                <Option value="create">Tạo mới</Option>
                <Option value="duplicate">Duplicate</Option>
              </Select>
            </Form.Item>
          </Col>
          {type === 'duplicate' && (
            <Col span={24}>
              <Form.Item
                name="duplicate_course_class_id"
                rules={[fieldValidate.required]}
                label="Lớp học cần Duplicate"
              >
                <SelectSearch
                  placeholder="Lớp học cần Duplicate"
                  onChange={(value) => {
                    const classInfo = courseClasses.filter((data) => data.id === parseInt(value))[0];

                    form.setFieldsValue({
                      address: classInfo.address,
                      teachers: classInfo.courseClassTeachers.map((data) => data.courseTeacherId)
                    });
                  }}
                >
                  {courseClasses.map((courseClass) => (
                    <Option
                      value={courseClass.id}
                      key={courseClass.id}
                    >{`${courseClass.code}: ${courseClass.startDate}`}</Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item label="Mã" name="code" rules={[fieldValidate.required]}>
              <Input placeholder="Mã" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="class_type" rules={[fieldValidate.required]} label="Loại hình">
              <Select placeholder="Loại hình">
                <Option value="Online lý thuyết">Online lý thuyết</Option>
                <Option value="Offline thực hành">Offline thực hành</Option>
                <Option value="Online lý thuyết & Offline thực hành">
                  Online lý thuyết & Offline thực hành
                </Option>
                <Option value="Offline lý thuyết & Offline thực hành">
                  Offline lý thuyết & Offline thực hành
                </Option>
                <Option value="Hội nghị">Hội nghị</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="price" rules={[fieldValidate.required]} label="Giá">
              <Input
                placeholder="Giá"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    price: numberToCurrency(removeCurrency(e.target.value)) || 0
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Thời gian bắt đầu" name="start_date" rules={[fieldValidate.required]}>
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder="Thời gian bắt đầu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Địa chỉ" name="address" rules={[fieldValidate.required]}>
              <Input placeholder="Địa chỉ" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="teachers" rules={[fieldValidate.required]} label="Giảng viên của lớp học">
              <SelectSearch placeholder="Giảng viên của lớp học" mode="multiple">
                {teachers.map((teacher) => (
                  <Option value={teacher.id} key={teacher.id}>
                    {teacher.name}
                  </Option>
                ))}
              </SelectSearch>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" size="large" style={{ width: 150 }} htmlType="submit" disabled={submiting}>
            Lưu
          </Button>
          <Button size="large" style={{ width: 150, marginLeft: 20 }} onClick={onClose}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
