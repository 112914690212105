import {
  get,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';

const ON_GET_GIFTS = 'ON_GET_COURSE_GIFTS';

function* getGifts({ params, setData }) {
  try {
    const response = yield call(get, `/courses/${params.courseId}/gifts`);
    setData({
      gifts: response.gifts,
      courseGifts: response.courseGifts,
      students: response.students,
    });
  } catch (error) {
    console.log(error);
  }
}

export const onGetCourseGifts = (params, setData) => ({
  type: ON_GET_GIFTS, params, setData,
});

export default function* giftWatcher() {
  yield takeLatest(ON_GET_GIFTS, getGifts);
}
