import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Input, Button, Form } from 'antd';
import { LeftColumn, RightColumn, RegisterLink, Footer, FormTitle, FormWrapper } from './styles';
import { fieldValidate } from '../../shared/function';
import { onForgotPassword } from './saga';

const { Item } = Form;

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const callback = () => form.resetFields();

  const onSubmit = useCallback((params) => dispatch(onForgotPassword(params, callback)), [dispatch]);

  const onFinish = (values) => {
    values.email = values.email.toLowerCase();
    onSubmit(values);
  };

  return (
    <Row style={{ width: '100%' }}>
      <RightColumn span={10}>
        <FormWrapper data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
          <FormTitle>Quên mật khẩu</FormTitle>
          <Form layout="vertical" form={form} name="register" scrollToFirstError onFinish={onFinish}>
            <Row>
              <Col span={24}>
                <Item name="email" label="E-mail" rules={[fieldValidate.required, fieldValidate.email]}>
                  <Input size="large" />
                </Item>
              </Col>
            </Row>
            <Row>
              <Button htmlType="submit" type="primary" size="large" style={{ marginTop: 20, width: 160 }}>
                Lấy lại mật khẩu
              </Button>
            </Row>
          </Form>
          <Footer>
            <RegisterLink href={`${process.env.DOMAIN_URL}/user/sign_in`} target="_blank">
              Đăng nhập
            </RegisterLink>
            <RegisterLink href={`${process.env.WEBSITE_URL}/register`} target="_blank">
              Đăng kí
            </RegisterLink>
          </Footer>
        </FormWrapper>
      </RightColumn>
      <LeftColumn span={14}></LeftColumn>
    </Row>
  );
};

export default ForgotPassword;
