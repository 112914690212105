import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  width: 70% !important;

  @media (max-width: 1600px) {
    width: 85% !important;
  }

  .ant-descriptions-header {
    margin-bottom: 10px;
    margin-top: 20px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: wrap row;

  .ant-form {
    display: flex;
  }
`;
export default StyledModal;
