import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../shared/function';
import Ckeditor from '../../../../shared/Ckeditor';
import { onEditQuestion } from '../saga';

const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditQuestion(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    editData({ ...values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        content: item.content,
        group: item.group,
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      width={1280}
      title='Chỉnh sửa câu hỏi'
    >
      <Form
        layout='vertical'
        form={form}
        name='createQuestion'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name='content'
              label='Nội dung câu hỏi'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Nội dung câu hỏi' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='group'
              label='Danh mục'
              rules={[fieldValidate.required]}
            >
              <Select placeholder='Chọn danh mục'>
                <Option value='account'>Account</Option>
                <Option value='payment'>Payment</Option>
                <Option value='service'>Service</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label='Câu trả lời'
              name='answer'
              rules={[fieldValidate.required]}
            >
              <Ckeditor onChange={(value) => form.setFieldsValue({ answer: value })} defaultValue={item.answer || ''} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
