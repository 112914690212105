import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, List, Col, Popconfirm } from 'antd';
import {
  FolderOpenOutlined,
  SnippetsOutlined,
  UserOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { onGetSubTopics, onDeleteTopic } from '../../saga';
import EditSubTopic from '../../Modal/EditSubTopic';

const { Text, Title } = Typography;

const SubTopic = ({ currentFilter, changeLocation }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const editRef = useRef();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1,
    parent: {}
  });
  const { items, loading, currentPage, totalPages, parent } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetSubTopics(params, setState)), [dispatch]);

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const removeSubTopic = useCallback((params) => dispatch(onDeleteTopic(params, fetchData)), [dispatch]);

  const changeTopic = (topicId) => {
    currentFilter.current.page = 0;
    currentFilter.current.sub_topic_id = topicId;
    changeLocation();
  };

  useEffect(() => {
    if (currentFilter.current.topic_id) getData(currentFilter.current);
  }, [location.search]);

  return (
    <>
      <Col span={24}>
        <EditSubTopic ref={editRef} parentId={currentFilter.current.topic_id} fetchData={fetchData} />
        <Title level={5} style={{ margin: '0 8px' }}>
          {parent.name || ''}
        </Title>
      </Col>
      <Col span={24}>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={items}
          {...(totalPages > 0 && {
            pagination: {
              onChange: (page) => {
                currentFilter.current.page = page;
                changeLocation();
              },
              total: 10 * totalPages,
              pageSize: 10,
              showLessItems: true,
              showSizeChanger: false,
              current: currentPage,
              defaultCurrent: 1
            }
          })}
          renderItem={(item) => (
            <List.Item style={{ display: 'flex', flexFlow: 'wrap row' }}>
              <List.Item.Meta
                avatar={<FolderOpenOutlined style={{ fontSize: 30 }} />}
                title={
                  <Text style={{ cursor: 'pointer' }} onClick={() => changeTopic(item.id)}>
                    {item.name}
                  </Text>
                }
                description={item.shortDescription}
              />
              <div style={{ width: '100%', paddingLeft: 45, marginTop: 10 }}>
                <Text style={{ marginRight: 20 }}>
                  <SnippetsOutlined style={{ marginRight: 5, fontSize: 15 }} />
                  <Text>{`Bài viết: ${item.totalPost}`}</Text>
                </Text>
                <Text style={{ marginRight: 20 }}>
                  <UserOutlined style={{ marginRight: 5, fontSize: 15 }} />
                  <Text>{`Người đăng: ${item.totalPostOwner}`}</Text>
                </Text>
                <Text
                  style={{ marginRight: 20, cursor: 'pointer' }}
                  onClick={() => editRef.current.open(item)}
                >
                  <EditOutlined style={{ marginRight: 5, fontSize: 15 }} />
                  <Text>Chỉnh sửa</Text>
                </Text>
                <Popconfirm
                  placement="top"
                  title={
                    <div>
                      <Text strong>Tất cả bài viết trong chủ đề sẽ bị xóa.</Text> Bạn có muốn chủ đề này
                      không?
                    </div>
                  }
                  onConfirm={() => removeSubTopic({ id: item.id })}
                  okText="Có"
                  cancelText="Không"
                >
                  <Text style={{ color: 'red', cursor: 'pointer' }}>
                    <DeleteOutlined style={{ marginRight: 5, fontSize: 15 }} />
                    <Text style={{ color: 'red' }}>Xóa</Text>
                  </Text>
                </Popconfirm>
              </div>
            </List.Item>
          )}
        />
      </Col>
    </>
  );
};

export default SubTopic;
