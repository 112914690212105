import React from 'react';
import { Select } from 'antd';

const SearchInput = ({ children, ...rest }) => (
  <Select
    autoComplete="nope"
    optionFilterProp="children"
    showSearch
    filterOption={(input, option) =>
      option.children &&
      option.children.toString().trim().toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
    }
    {...rest}
  >
    {children}
  </Select>
);

export default SearchInput;
