import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Button, Descriptions, Tag, Select, Typography, Input } from 'antd';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { sum, sumBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { fieldValidate, numberToCurrency, removeCurrency } from '../../../../../shared/function';
import { Wrapper } from '../styles';
import { onDuplicatedRegistration, onUpdateSaler } from '../../saga';
import Preview from './index';
import Notification from '../../../../../components/Notification';
import SelectSearch from '../../../../../components/SelectSearch';

const { Text } = Typography;
const { Option } = Select;

const Promotion = ({ item, type, updateData, fetchData, employees }) => {
  const dispatch = useDispatch();
  const viewRef = useRef();

  const callback = (status, response) => {
    if (status === 200) {
      updateData(response);
    }
  };

  const duplicateInfo = useCallback((params) => dispatch(onDuplicatedRegistration(params, callback)), [
    dispatch
  ]);

  if (type === 'combo') {
    return `Combo - ${item?.promotion?.name}`;
  }

  return (
    <>
      <Preview ref={viewRef} fetchData={fetchData} employees={employees} />
      <Descriptions layout="vertical" bordered column={3}>
        <Descriptions.Item label="Chính sách">Nhóm</Descriptions.Item>
        <Descriptions.Item label="Số người">{item.promotion.people}</Descriptions.Item>
        <Descriptions.Item label="Giảm giá">{`${numberToCurrency(item.promotion.amount)}${
          item.promotion.discount_type === 'percentage' ? '%' : ''
        }`}</Descriptions.Item>
        {(item.groupInfo || []).map((member, index) => (
          <Descriptions.Item
            label={
              <>
                {`Thành viên ${index + 1} `}{' '}
                <Tag color={member.user_id ? 'green' : 'red'}>
                  {!member.user_id ? 'Chưa có tài khoản' : member.active ? 'Đã kích hoạt' : 'Đang chờ duyệt'}
                </Tag>
              </>
            }
            key={index}
          >
            <div style={{ marginBottom: 10 }}>
              {' '}
              Tên: {`${member.first_name} ${member.last_name}`}
              <br />
              Email: {member.email}
              <br />
              Số điện thoại: {member.phone}
            </div>

            {member.registration_id ? (
              <Button
                type="primary"
                size="small"
                onClick={() => viewRef.current.open(member.registration_id)}
              >
                Xem phiếu đăng kí
              </Button>
            ) : (
              <Button
                type="primary"
                size="small"
                onClick={() =>
                  duplicateInfo({
                    id: item.id,
                    email: member.email
                  })
                }
              >
                Tạo phiếu đăng kí
              </Button>
            )}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
};

const Status = ({ status }) => {
  switch (status) {
    case 'pending':
      return <Tag color="orange">Đang chờ duyệt</Tag>;
    case 'processing':
      return <Tag color="blue">Đang thu tiền</Tag>;
    case 'done':
      return <Tag color="green">Đã hoàn thành</Tag>;
    case 'canceled':
      return <Tag color="red">Hủy đăng kí</Tag>;
    default:
      return '';
  }
};

const MemberType = ({ memberType }) => {
  switch (memberType) {
    case 'platinum':
      return <Tag color="lime">Platinum</Tag>;
    case 'gold':
      return <Tag color="green">Gold</Tag>;
    case 'silver':
      return <Tag color="cyan">Silver</Tag>;
    default:
      return '';
  }
};

const PaymentType = ({ text }) => {
  switch (text) {
    case 'base':
      return <Tag color="orange">Thanh toán 1 lần</Tag>;
    case 'installment':
      return <Tag color="blue">Trả góp</Tag>;
    default:
      return '';
  }
};

const FormItemComponent = ({ form, field }) => {
  switch (field) {
    case 'status':
      return (
        <Form.Item style={{ margin: 0, display: 'flex' }} name={field} rules={[fieldValidate.required]}>
          <Select style={{ width: 200 }}>
            <Option value="pending">Đang chờ duyệt</Option>
            <Option value="processing">Đang thu tiền</Option>
            <Option value="done">Đã hoàn thành</Option>
            <Option value="canceled">Hủy đăng kí</Option>
          </Select>
        </Form.Item>
      );
    case 'income_price':
      return (
        <Form.Item style={{ margin: 0, display: 'flex' }} name={field} rules={[fieldValidate.required]}>
          <Input
            onChange={(e) =>
              e.target.value &&
              form.setFieldsValue({
                income_price: numberToCurrency(removeCurrency(e.target.value))
              })
            }
          />
        </Form.Item>
      );
    case 'payment_type':
      return (
        <Form.Item style={{ margin: 0, display: 'flex' }} name={field} rules={[fieldValidate.required]}>
          <Select style={{ width: 200 }}>
            <Option value="base">Thanh toán 1 lần</Option>
            <Option value="installment">Trả góp</Option>
          </Select>
        </Form.Item>
      );
    default:
      return '';
  }
};

const EditField = ({ field, defaultValueField, item, onEdit, updateData }) => {
  const [editable, setEditable] = useState(false);
  const [form] = Form.useForm();

  const callback = (status, response) => {
    if (status === 200) {
      updateData(response);
      setEditable(false);

      if (field === 'note') Notification.success({ message: 'Chỉnh sửa ghi chú thành công.' });
    }
  };

  const onFinish = (values) => {
    if (field === 'income_price') values.income_price = removeCurrency(values.income_price);
    onEdit({ registration_info: values, id: item.id }, callback);
  };

  useEffect(() => {
    const value = {};
    value[field] =
      field === 'income_price' ? numberToCurrency(item[defaultValueField]) : item[defaultValueField];
    form.setFieldsValue(value);
  }, [item]);

  if (field === 'note') {
    return (
      <Wrapper>
        <Form
          layout="vertical"
          form={form}
          name={`${field}Inline`}
          scrollToFirstError
          onFinish={onFinish}
          style={{ flexDirection: 'column', width: '100%', alignItems: 'center' }}
        >
          <Form.Item name={field} style={{ width: '100%' }}>
            <Input.TextArea rows={5} />
          </Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginTop: 10 }}>
            Lưu
          </Button>
        </Form>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {!editable ? (
        <>
          {field === 'status' && <Status status={item[defaultValueField]} />}
          {field === 'income_price' && <Text>{numberToCurrency(item[defaultValueField])}</Text>}
          {field === 'payment_type' && <PaymentType text={item[defaultValueField]} />}
          <EditOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(true)}
          />
        </>
      ) : (
        <>
          <Form layout="vertical" form={form} name={`${field}Inline`} scrollToFirstError onFinish={onFinish}>
            <FormItemComponent form={form} field={field} />
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Form>
          <CloseCircleOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(false)}
          />
        </>
      )}
    </Wrapper>
  );
};

const InfoTab = ({ item, updateData, onEdit, fetchData, employees }) => {
  const dispatch = useDispatch();
  const totalCol = item.promotionType === 'retake' ? item.retakeInfo.length + 1 : item.courseInfo.length + 1;
  const info = item.userInfo.first_name ? item.userInfo : item.user;

  const incomePrice = () => {
    const incomeItems = item.incomes.filter((income) => income.status !== 'canceled');

    return sum(incomeItems.map((income) => sumBy(income.subIncomes, (o) => parseFloat(o.price))));
  };

  const changeSaler = (value, currentSalerId, courseId) => {
    if (value !== currentSalerId) {
      dispatch(
        onUpdateSaler({
          id: item.id,
          sale_id: value,
          course_id: courseId
        })
      );
    }
  };

  const UserInfo = () => (
    <div>
      {' '}
      Tên: {item.user ? item.user.name : `${info.first_name} ${info.last_name}`}
      <br />
      Email: {info.email}
      <br />
      Số điện thoại: {info.phone}
      <br />
      {item.user.member_type && (
        <div>
          Hạng: <MemberType memberType={item.user.member_type} />
        </div>
      )}
    </div>
  );

  if (item.promotionType !== 'retake') {
    return (
      <Descriptions bordered column={totalCol}>
        <Descriptions.Item label="Trạng thái" span={totalCol}>
          <EditField
            field="status"
            defaultValueField="status"
            item={item}
            onEdit={onEdit}
            updateData={updateData}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Thông tin đăng kí" span={totalCol}>
          <UserInfo />
        </Descriptions.Item>
        <Descriptions.Item label="Khóa học" />
        {item.courseInfo.map(({ course, course_id: courseId }) => (
          <Descriptions.Item key={courseId}>{`${course.module_name}: ${course.title}`}</Descriptions.Item>
        ))}
        <Descriptions.Item label="Lớp học" />
        {item.courseInfo.map(({ course_class: courseClass }) => (
          <Descriptions.Item key={courseClass.id}>
            {`${courseClass.class_type || ''} - ${courseClass.code} - ${moment(courseClass.start_date).format(
              'DD-MM-YYYY'
            )} - ${courseClass.address}`}
          </Descriptions.Item>
        ))}
        <Descriptions.Item label="Nhân viên sale" />
        {item.courseInfo.map(({ sale_id: saleId, course_id: courseId }) => (
          <Descriptions.Item>
            <SelectSearch
              defaultValue={saleId}
              style={{ width: 250 }}
              onChange={(value) => changeSaler(value, saleId, courseId)}
            >
              {employees.map((employee) => (
                <Option
                  value={employee.id}
                  key={employee.id}
                >{`${employee.first_name} ${employee.last_name}`}</Option>
              ))}
            </SelectSearch>
          </Descriptions.Item>
        ))}
        <Descriptions.Item label="Phương thức thanh toán" span={totalCol}>
          <EditField
            field="payment_type"
            defaultValueField="paymentType"
            item={item}
            onEdit={onEdit}
            updateData={updateData}
            fetchData={fetchData}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Chính sách" span={totalCol}>
          {item.promotion && item.promotionType && (
            <Promotion item={item} type={item.promotionType} updateData={updateData} employees={employees} />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Số tiền dự kiến thu" span={totalCol}>
          {numberToCurrency(item.originalPrice)}
        </Descriptions.Item>
        <Descriptions.Item label="Số tiền cần thu" span={totalCol}>
          <EditField
            field="income_price"
            defaultValueField="incomePrice"
            item={item}
            onEdit={onEdit}
            updateData={updateData}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Số tiền đã thu" span={totalCol}>
          {numberToCurrency(incomePrice())}
        </Descriptions.Item>
        <Descriptions.Item label="Ghi chú" span={totalCol}>
          <EditField
            field="note"
            defaultValueField="note"
            item={item}
            onEdit={onEdit}
            updateData={updateData}
          />
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <Descriptions bordered column={totalCol}>
      <Descriptions.Item label="Trạng thái" span={totalCol}>
        <EditField
          field="status"
          defaultValueField="status"
          item={item}
          onEdit={onEdit}
          updateData={updateData}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Thông tin học viên" span={totalCol}>
        <UserInfo />
      </Descriptions.Item>
      <Descriptions.Item label="Khóa học" />
      {item.retakeInfo.map(({ course, course_id }) => (
        <Descriptions.Item key={course_id}>{`${course.module_name}: ${course.title}`}</Descriptions.Item>
      ))}
      <Descriptions.Item label="Lớp học" />
      {item.retakeInfo.map(({ course_class }) => (
        <Descriptions.Item key={course_class.id}>{`${course_class.code} - ${moment(
          course_class.start_date
        ).format('DD-MM-YYYY')} - ${course_class.address}`}</Descriptions.Item>
      ))}
      <Descriptions.Item label="Buổi học" />
      {item.retakeInfo.map(({ course_class, lessons }) => (
        <Descriptions.Item key={course_class.id}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {lessons.map((lesson) => (
              <div>{`${lesson.title} - ${lessons.type === 'practice' ? 'Thực hành' : 'Lý thuyết'}`}</div>
            ))}
          </div>
        </Descriptions.Item>
      ))}
      <Descriptions.Item label="Nhân viên sale" />
      {item.retakeInfo.map(({ sale_id: saleId, course_id: courseId }) => (
        <Descriptions.Item>
          <SelectSearch
            defaultValue={saleId}
            style={{ width: 250 }}
            onChange={(value) => changeSaler(value, saleId, courseId)}
          >
            {employees.map((employee) => (
              <Option
                value={employee.id}
                key={employee.id}
              >{`${employee.first_name} ${employee.last_name}`}</Option>
            ))}
          </SelectSearch>
        </Descriptions.Item>
      ))}
      <Descriptions.Item label="Chính sách" span={totalCol}>
        Học lại
      </Descriptions.Item>
      <Descriptions.Item label="Phương thức thanh toán" span={totalCol}>
        <EditField
          field="payment_type"
          defaultValueField="paymentType"
          item={item}
          onEdit={onEdit}
          updateData={updateData}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Số tiền dự kiến thu" span={totalCol}>
        {numberToCurrency(item.originalPrice)}
      </Descriptions.Item>
      <Descriptions.Item label="Số tiền cần thu" span={totalCol}>
        <EditField
          field="income_price"
          defaultValueField="incomePrice"
          item={item}
          onEdit={onEdit}
          updateData={updateData}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Số tiền đã thu" span={totalCol}>
        {numberToCurrency(incomePrice())}
      </Descriptions.Item>
      <Descriptions.Item label="Ghi chú" span={totalCol}>
        <EditField
          field="note"
          defaultValueField="note"
          item={item}
          onEdit={onEdit}
          updateData={updateData}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

export default InfoTab;
