import { post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';

const ON_FORGOT_PASSWORD = 'ON_FORGOT_PASSWORD';

const handleError = (error) => {
  if (error.status === 500) {
    return 'Tải dữ liệu xảy ra lỗi!';
  }

  if (error.data && error.data.errors && error.data.errors.password) {
    return 'Mật khẩu hiện tại không chính xác!';
  }

  if (error.data && error.data.errors && error.data.errors.email_no_found) {
    return 'Tài khoản không tồn tại trong hệ thống!';
  }

  if (error.data && error.data.errors && error.data.errors.email_locked) {
    return 'Tài khoản này đã bị cấm truy cập từ hệ thống. Vui lòng liên hệ hotline 0909 289 005.';
  }

  if (error.data && error.data.errors && error.data.errors.email_not_confirmed) {
    return 'Tài khoản này đang chờ xác nhận từ hệ thống. Vui lòng liên hệ hotline 0909 289 005.';
  }

  if (error.data && error.data.errors && error.data.errors.phone) {
    return 'Số điện thoại không hợp lệ.';
  }

  return error.message;
};

function* forgotPassword({ params, callback }) {
  try {
    yield call(post, '/current_user/info/forgot_password', params);
    Notification.success({ message: 'Xin vui lòng kiểm tra email để lấy link thay đổi mật khẩu!' });
    callback();
  } catch (error) {
    Notification.error({ message: handleError(error) });
  }
}

export const onForgotPassword = (params, callback) => ({
  type: ON_FORGOT_PASSWORD,
  params,
  callback
});

export default function* forgotPasswordWatcher() {
  yield takeLatest(ON_FORGOT_PASSWORD, forgotPassword);
}
