import React, { useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Form, Button, Modal, DatePicker, Select, TimePicker } from 'antd';
import { fieldValidate } from '../../../../../../../../shared/function';
import SelectSearch from '../../../../../../../../components/SelectSearch';

import { onNewCourseClassLesson } from '../../../saga';

const { Option } = Select;

const Create = React.forwardRef(
  ({ courseClassId, courseClassTeachers, courseLessons, fetchData, classStudents }, ref) => {
    const [state, setState] = useState({ status: false });
    const dispatch = useDispatch();
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm();
    const { status } = state;

    const onClose = () => {
      form.resetFields();
      setState({ status: false });
    };

    const callback = (status) => {
      if (status === 200) {
        onClose();
        fetchData();
      }
      setSubmiting(false);
    };

    useImperativeHandle(ref, () => ({
      open: () => {
        setState({ status: true });
      }
    }));

    const createData = useCallback((params) => dispatch(onNewCourseClassLesson(params, callback)), [
      dispatch
    ]);

    const onFinish = (values) => {
      setSubmiting(true);
      values.time = values.time.format('HH:mm');
      values.class_lesson_students_attributes = (values.class_student_ids || []).map((id) => ({
        course_class_student_id: id
      }));
      values.course_class_lesson_teachers_attributes = (values.class_teacher_ids || []).map((id) => ({
        course_class_teacher_id: id
      }));

      createData({ course_class_lesson: { ...values }, courseClassId });
    };

    return (
      <Modal closable={false} visible={status} footer={null} width={1024} title="Thêm lịch học">
        <Form layout="vertical" form={form} name="createClassClendar" scrollToFirstError onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Ngày" name="date" rules={[fieldValidate.required]}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder="Ngày" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Giờ" name="time" rules={[fieldValidate.required]}>
                <TimePicker format="HH:mm" style={{ width: '100%' }} placeholder="Giờ" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="course_lesson_id" rules={[fieldValidate.required]} label="Buổi học">
                <SelectSearch placeholder="Buổi học">
                  {courseLessons.map((lesson) => (
                    <Option value={lesson.id} key={lesson.id}>
                      {lesson.title}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="class_teacher_ids" rules={[fieldValidate.required]} label="Giảng viên">
                <SelectSearch placeholder="Giảng viên" mode="multiple">
                  {courseClassTeachers.map((teacher) => (
                    <Option value={teacher.id} key={teacher.id}>
                      {teacher.name}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="class_student_ids" label="Học viên">
                <SelectSearch placeholder="Học viên" mode="multiple">
                  {classStudents.map((student) => (
                    <Option
                      value={student.id}
                      key={student.id}
                    >{`${student.user.firstName} ${student.user.lastName}`}</Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 20 }}>
            <Button type="primary" size="large" style={{ width: 150 }} htmlType="submit" disabled={submiting}>
              Lưu
            </Button>
            <Button size="large" style={{ width: 150, marginLeft: 20 }} onClick={onClose}>
              Đóng
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
);

export default Create;
