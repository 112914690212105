import React, { useState, useCallback, useRef, useEffect, useImperativeHandle } from 'react';
import { Col, Row, Button, Table, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { onGetCourseClassLessons, onRemoveCourseClassLesson, onGetCourseClassStudents } from '../../../saga';
import { columns } from './function';
import Create from './Create';
import Edit from './Edit';

const ClassCalendar = React.forwardRef(
  ({ courseClassId, courseClassTeachers, courseLessons, courseDocuments }, ref) => {
    const dispatch = useDispatch();
    const createRef = useRef();
    const editRef = useRef();
    const [state, setState] = useState({
      loading: true,
      items: []
    });
    const [studentState, setStudentState] = useState({
      items: [],
      currentStudents: []
    });

    const { loading, items } = state;

    const showLoading = () => setState((state) => ({ ...state, loading: true }));

    const getData = useCallback(() => dispatch(onGetCourseClassLessons({ courseClassId }, setState)), [
      dispatch,
      courseClassId
    ]);

    const getStudentData = useCallback(
      (params) => dispatch(onGetCourseClassStudents(params, setStudentState)),
      [dispatch, courseClassId]
    );

    const fetchData = () => {
      showLoading();
      getData();
    };

    const removeDocument = useCallback((params) => dispatch(onRemoveCourseClassLesson(params, fetchData)), [
      dispatch
    ]);

    const onDelete = (item) => {
      Modal.confirm({
        title: 'Bạn có muốn xóa buổi học này không?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Có',
        cancelText: 'Không',
        onOk: () => {
          removeDocument({ courseClassId, course_class_lesson_id: item.id });
        }
      });
    };

    const onEdit = (item) => editRef.current.open(item);

    useImperativeHandle(ref, () => ({
      fetchNewData: () => {
        fetchData();
        getStudentData({ per_page: 99999, courseClassId });
      }
    }));

    useEffect(() => {
      getData();
      getStudentData({ per_page: 99999, courseClassId });
    }, []);

    return (
      <>
        <Create
          ref={createRef}
          courseClassId={courseClassId}
          fetchData={fetchData}
          courseClassTeachers={courseClassTeachers}
          courseLessons={courseLessons}
          courseDocuments={courseDocuments}
          classStudents={studentState.items}
        />
        <Edit
          ref={editRef}
          courseClassId={courseClassId}
          fetchData={fetchData}
          courseClassTeachers={courseClassTeachers}
          courseLessons={courseLessons}
          courseDocuments={courseDocuments}
          classStudents={studentState.items}
        />
        <Row>
          <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => createRef.current.open()} type="primary" icon={<PlusOutlined />}>
              Thêm lịch học
            </Button>
          </Col>
          <Col span={24}>
            <Table
              columns={columns(onEdit, onDelete)}
              loading={loading}
              dataSource={items}
              pagination={false}
            />
          </Col>
        </Row>
      </>
    );
  }
);

export default ClassCalendar;
