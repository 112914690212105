import React, { useCallback, useState, useImperativeHandle, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Button, Tabs, Col } from 'antd';
import { AccountBookOutlined, FundProjectionScreenOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import { StyledModal } from '../styles';
import { onGetCourseClass } from '../../saga';
import ModalTitle from './Title';
import ClassCalendar from './ClassCalendar';
import Teacher from './Teacher';
import Student from './Student';
import Forum from './Forum';

const { TabPane } = Tabs;

const Detail = React.forwardRef(({ courseClassId, fetchData, teachers }, ref) => {
  const [state, setState] = useState({ status: false });
  const [item, setItem] = useState({});
  const [classInfo, setClassInfo] = useState({
    info: {},
    lessons: [],
    courseDocuments: []
  });
  const { info, lessons, courseDocuments } = classInfo;
  const dispatch = useDispatch();
  const { status } = state;
  const lessonRef = useRef();
  const documentRef = useRef();

  const onClose = () => {
    setClassInfo({
      info: {},
      lessons: [],
      courseDocuments: []
    });
    setState({ status: false });
  };

  const getData = useCallback((params) => dispatch(onGetCourseClass(params, setClassInfo)), [dispatch]);

  const onChange = (activeKey) => {
    if (activeKey === 'info' && lessonRef.current) lessonRef.current.fetchNewData();
    if (activeKey === 'document' && documentRef.current) documentRef.current.fetchNewData();
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setItem(item);
      getData({ id: item.id });
      setState({ status: true });
    }
  }));

  return (
    <StyledModal
      onCancel={onClose}
      closable={true}
      visible={status}
      footer={null}
      title={<ModalTitle item={item} fetchData={fetchData} />}
    >
      <Row>
        <Col span={24}>
          <Tabs defaultActiveKey="info" style={{ width: '100%' }} onChange={onChange}>
            <TabPane
              tab={
                <span>
                  <AccountBookOutlined style={{ fontSize: 17 }} />
                  Lịch học
                </span>
              }
              key="info"
            >
              <ClassCalendar
                ref={lessonRef}
                courseDocuments={courseDocuments}
                courseClassId={courseClassId}
                courseClassTeachers={info.courseClassTeachers || []}
                courseLessons={lessons}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <FundProjectionScreenOutlined style={{ fontSize: 17 }} />
                  Diễn đàn
                </span>
              }
              key="forum"
            >
              <Forum courseClassId={courseClassId} />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <UsergroupAddOutlined style={{ fontSize: 17 }} />
                  Giảng viên
                </span>
              }
              key="teacher"
            >
              <Teacher
                courseTeachers={teachers}
                setData={setClassInfo}
                courseClassId={courseClassId}
                courseClassTeachers={info.courseClassTeachers || []}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <UsergroupAddOutlined style={{ fontSize: 17 }} />
                  Học viên
                </span>
              }
              key="student"
            >
              <Student courseClassId={courseClassId} info={info} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 30 }}>
        <Button size="large" style={{ width: 150 }} onClick={onClose}>
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default Detail;
