import React from 'react';
import { GroupOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip, Tag, Typography, Popconfirm } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onView, isAdmin, onDelete) => [
  {
    title: 'Mã đăng kí',
    dataIndex: 'code',
    key: 'code',
    sorter: {}
  },
  {
    title: 'Học viên',
    dataIndex: 'user',
    key: 'user',
    render: (user) => user.name
  },
  {
    title: 'Email',
    dataIndex: 'user',
    key: 'user',
    render: (user) => user.email
  },
  {
    title: 'Chính sách',
    dataIndex: 'promotionType',
    key: 'promotionType',
    render: (promotionType) => {
      switch (promotionType) {
        case 'combo':
          return 'Combo';
        case 'group':
          return 'Nhóm';
        case 'retake':
          return 'Học lại';
        default:
          return '';
      }
    }
  },
  {
    title: 'Thanh toán',
    dataIndex: 'paymentType',
    key: 'paymentType',
    render: (paymentType) => {
      switch (paymentType) {
        case 'base':
          return 'Thanh toán 1 lần';
        case 'installment':
          return 'Trả góp';
        default:
          return '';
      }
    }
  },
  {
    title: 'Khóa học - Tên lớp - Thời gian - Địa điểm',
    key: 'course',
    render: ({ promotionType, courseInfo, retakeInfo }) => {
      const data = promotionType === 'retake' ? retakeInfo : courseInfo;

      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {data.map(({ course, course_class }) => (
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
              <Text strong>{`${course.module_name}: ${course.title}`}</Text>
              <Text style={{ paddingLeft: 10 }}>{`+ ${
                course_class.class_type ? `${course_class.class_type} - ` : ''
              } ${course_class.code} - ${course_class.start_date} - ${course_class.address}`}</Text>
            </div>
          ))}
        </div>
      );
    }
  },
  {
    title: 'Trạng thái',
    key: 'status',
    sorter: {},
    render: (item) => {
      switch (item.status) {
        case 'pending':
          return <Tag color="orange">Đang chờ duyệt</Tag>;
        case 'processing':
          return <Tag color="blue">Đang thu tiền</Tag>;
        case 'done':
          return <Tag color="green">Đã hoàn thành</Tag>;
        case 'canceled':
          return <Tag color="red">Hủy đăng kí</Tag>;
        default:
          return '';
      }
    }
  },
  {
    title: 'Đăng kí lúc',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title="Chi tiết" color="geekblue" key="geekblue">
          <GroupOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onView(item)} />
        </Tooltip>
        {isAdmin && (
          <Popconfirm
            title="Bạn có muốn xoá dữ liệu này không"
            onConfirm={() => onDelete(item)}
            okText="Có"
            cancelText="Không"
          >
            <Tooltip title="Xoá" color="red" key="red">
              <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
            </Tooltip>
          </Popconfirm>
        )}
      </ActionWrapper>
    )
  }
];
