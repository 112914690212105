import styled from 'styled-components';
import { Menu, Layout, List, Badge, Tabs } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const { Item } = List;
const { Header } = Layout;

export const StyeledTab = styled(Tabs)`
  .ant-tabs-nav-wrap {
    width: 100%;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 33.3%;
    margin: 0;
  }

  .ant-tabs-tab-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledHeader = styled(Header)`
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden !important;
  height: auto;
  padding: 15px 50px;
  position: absolute;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  height: 64px;
  padding: 30px 0;
  img {
    width: 200px;
  }
`;

export const StyledMenu = styled(Menu)`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;

  .ant-menu-submenu,
  .ant-menu-item {
    border: none !important;
    margin: 0 15px;
  }
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }

  .ant-skeleton {
    display: flex;
    margin-right: 10px;
  }
`;

export const StyledIconBadge = styled(Badge)`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .anticon {
    margin-right: 0;
  }
`;

export const StyledSubMenu = styled(Menu.SubMenu)``;
export const Profile = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-bottom: 1px solid #e5e5e5;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 17px;
`;

export const Email = styled.div`
  font-size: 14px;
  margin-top: 20px;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  right: 16px;
`;

const StyledDeleteIcon = styled(CloseOutlined)`
  position: absolute;
  display: none;
  right: 36px;
  top: 15px;
`;

const StyledItem = styled(Item)`
  padding: 12px 24px;
  cursor: pointer;
  position: relative;

  .ant-list-item-meta-title {
    padding-right: 32px;
  }

  :hover,
  &.active {
    background-color: rgb(247, 247, 247);

    ${StyledDeleteIcon} {
      display: block;
    }
  }
`;

StyledItem.Meta = Item.Meta;

export const StyledList = styled(List)``;

StyledList.Item = StyledItem;
StyledList.Badge = StyledBadge;
StyledList.DelteIcon = StyledDeleteIcon;
