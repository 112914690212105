import React from 'react';
import {
  EditOutlined, DeleteOutlined, MenuOutlined,
} from '@ant-design/icons';
import {
  Menu, Dropdown, Tag,
} from 'antd';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {},

  },
  {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name',
    sorter: {},
  },
  {
    title: 'Loại',
    key: 'status',
    sorter: {},
    render: (item) => {
      switch (item.status) {
        case 'paid':
          return <Tag color='green'>Trả phí</Tag>;
        case 'publish':
          return <Tag color='red'>Không phí</Tag>;
        default:
          return <Tag color='green' />;
      }
    },
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {},
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Xóa
              </Menu.Item>
            </Menu>
        )}
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    ),
  },
];
