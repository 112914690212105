import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, InputNumber, Select, Radio,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../shared/function';
import { onEditNews } from '../saga';
import { onGetHashtag } from '../../Hashtags/saga';
import Ckeditor from '../../../../shared/Ckeditor';
import SelectSearch from '../../../../components/SelectSearch';
import Upload from '../../../../components/Upload';

const { Item } = Form;
const { Option } = Select;

const Edit = React.forwardRef(({ fetchData, type }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [hashtags, setHashtag] = useState([]);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const getHashtag = useCallback((params) => dispatch(
    onGetHashtag(params, (response) => {
      setHashtag(response.items);
    }),
  ), [dispatch]);

  const editData = useCallback((params) => dispatch(
    onEditNews(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const formData = new FormData();
    formData.append('news[title]', values.title);
    formData.append('news[short_description]', values.short_description);
    formData.append('news[content]', values.content);
    formData.append('news[status]', values.status || '');
    formData.append('news[position]', values.position);
    if (values.image) formData.append('news[image]', values.image);

    if (type === 'specialty') {
      const selectHashtag = values.status === 'paid' ? values.paid_hashtag : values.publish_hashtag;

      const newHashtag = [];
      const removeHashtag = [];

      (selectHashtag || []).map((id) => {
        const filterdCourse = item.hashtags.filter((a) => a.id === parseInt(id, 10))[0];

        if (!filterdCourse) newHashtag.push(id);
      });

      item.newsHashtags.map((newsHashtag) => {
        if (!selectHashtag.includes(newsHashtag.hashtag_id)) {
          removeHashtag.push({
            id: newsHashtag.id,
            hashtag_id: newsHashtag.hashtag_id,
          });
        }
      });

      newHashtag.map((id) => {
        formData.append('news[news_hashtags_attributes][][hashtag_id]', id);
      });

      removeHashtag.map((item) => {
        formData.append('news[news_hashtags_attributes][][hashtag_id]', item.hashtag_id);
        formData.append('news[news_hashtags_attributes][][id]', item.id);
        formData.append('news[news_hashtags_attributes][][_destroy]', true);
      });
    }

    editData({ formData, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        title: item.title,
        short_description: item.shortDescription,
        position: item.position,
        status: item.status,
      });

      if (item.status === 'paid') {
        form.setFieldsValue({
          paid_hashtag: item.hashtags.map((a) => a.id),
        });
      } else {
        form.setFieldsValue({
          publish_hashtag: item.hashtags.map((a) => a.id),
        });
      }

      getHashtag({ per_page: 99999 });
      setSelectedType(item.status);
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      title='Chỉnh sửa bài viết'
      width={1140}
    >
      <Form
        layout='vertical'
        form={form}
        name='editNews'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          { type === 'promotion' && (
            <Col span={24}>
              <Item
                name='position'
                label='Vị trí'
                rules={[fieldValidate.required]}
              >
                <Radio.Group style={{ width: '100%' }}>
                  <Row>
                    <Col span={8}>
                      <Radio value='Forum-1'>Forum-1</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value='Forum-2'>Forum-2</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value='Forum-3'>Forum-3</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value='Forum-4'>Forum-4</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value='Forum-5'>Forum-5</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Item>
            </Col>
          )}
          { type === 'specialty' && (
            <>
              <Col span={12}>
                <Item
                  name='status'
                  label='Loại'
                  rules={[fieldValidate.required]}
                >
                  <Select
                    placeholder='Loại'
                    onChange={(value) => {
                      setSelectedType(value);
                      form.setFieldsValue({ paid_hashtag: [] });
                      form.setFieldsValue({ publish_hashtag: [] });
                    }}
                  >
                    <Option value='paid'>Trả phí</Option>
                    <Option value='unpaid'>Không trả phí</Option>
                  </Select>
                </Item>
              </Col>
              {selectedType === 'paid' && (
              <Col span={12}>
                <Item
                  name='paid_hashtag'
                  label='Hashtag trả phí'
                >
                  <SelectSearch placeholder='Hashtag trả phí' mode='multiple'>
                    {hashtags.filter((a) => a.status === 'paid').map(
                      (hashtag) => (<Option value={hashtag.id} key={hashtag.id}>{hashtag.name}</Option>),
                    )}
                  </SelectSearch>
                </Item>
              </Col>
              )}
              {selectedType === 'unpaid' && (
              <Col span={12}>
                <Item
                  name='publish_hashtag'
                  label='Hashtag không trả phí'
                >
                  <SelectSearch placeholder='Hashtag không trả phí' mode='multiple'>
                    {hashtags.filter((a) => a.status === 'publish').map(
                      (hashtag) => (<Option value={hashtag.id} key={hashtag.id}>{hashtag.name}</Option>),
                    )}
                  </SelectSearch>
                </Item>
              </Col>
              )}
            </>
          )}
          <Col span={24}>
            <Item
              name='title'
              label='Tiêu đề'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tiêu đề' />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name='short_description'
              label='Mô tả'
              rules={[fieldValidate.required]}
            >
              <Input.TextArea placeholder='Mô tả' rows={3} />
            </Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label='Hình ảnh'
              name='image'
            >
              <Upload
                cropImageProps={{ aspect: type === 'promotion' ? 1 : (595 / 842) }}
                cropImage
                defaultValue={item.image}
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Item
              label='Nội dung'
              name='content'
            >
              <Ckeditor onChange={(value) => form.setFieldsValue({ content: value })} defaultValue={item.content} />
            </Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
