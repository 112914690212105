import React, { useState, useEffect, useCallback } from 'react';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Form, Tag, Select, Button } from 'antd';
import { fieldValidate } from '../../../../../shared/function';
import { Wrapper } from './styles';
import { onEditCourseField } from '../../saga';

const { Option } = Select;

const StatusText = ({ status }) => {
  switch (status) {
    case 'draft':
      return <Tag color="red">Bản nháp</Tag>;
    default:
      return <Tag color="green">Công bố</Tag>;
  }
};

const Status = ({ item }) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [form] = Form.useForm();
  const [defaultPrice, setDefaultPrice] = useState(item.status);

  const callback = (status, response) => {
    if (status === 200) {
      setDefaultPrice(response.status);
      setEditable(false);
    }
  };

  const editField = useCallback((params) => dispatch(onEditCourseField(params, callback)), [dispatch]);

  const onFinish = (values) => {
    editField({ course: { ...values }, id: item.id });
  };

  useEffect(() => {
    if (editable) form.setFieldsValue({ status: defaultPrice });
  }, [editable]);

  return (
    <Wrapper>
      {!editable ? (
        <>
          <StatusText status={defaultPrice} />
          <EditOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(true)}
          />
        </>
      ) : (
        <>
          <Form layout="vertical" form={form} name="statusInline" scrollToFirstError onFinish={onFinish}>
            <Form.Item style={{ margin: 0, display: 'flex' }} name="status" rules={[fieldValidate.required]}>
              <Select style={{ width: 200 }}>
                <Option value="publish">Công bố</Option>
                <Option value="draft">Ẩn</Option>
              </Select>
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Form>
          <CloseCircleOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(false)}
          />
        </>
      )}
    </Wrapper>
  );
};
export default Status;
