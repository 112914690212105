import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call, all } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';
import classRoomWatcher from './CourseItem/ClassRoom/saga';
import giftWatcher from './Modal/Gift/saga';
import classForumWatcher from './CourseItem/ClassRoom/Modal/Detail/Forum/saga';

const ON_GET_COURSES = 'ON_GET_COURSES';
const ON_GET_MY_MANAGEMENT_COURSES = 'ON_GET_MY_MANAGEMENT_COURSES';
const ON_NEW_COURSE = 'ON_NEW_COURSE';
const ON_EDIT_COURSE = 'ON_EDIT_COURSE';
const ON_EDIT_COURSE_FIELD = 'ON_EDIT_COURSE_FIELD';
const ON_DELETE_COURSE = 'ON_DELETE_COURSE';
const ON_GET_COURSE_SELECT_VALUES = 'ON_GET_COURSE_SELECT_VALUES';
const ON_REMOVE_COURSE_TEACHER = 'ON_REMOVE_COURSE_TEACHER';
const ON_REMOVE_COURSE_MANAGER = 'ON_REMOVE_COURSE_MANAGER';

const ON_GET_COURSE_DOCUMENTS = 'ON_GET_COURSE_DOCUMENTS';
const ON_NEW_COURSE_DOCUMENT = 'ON_NEW_COURSE_DOCUMENT';
const ON_REMOVE_COURSE_DOCUMENT = 'ON_REMOVE_COURSE_DOCUMENT';
const ON_EDIT_COURSE_DOCUMENT = 'ON_EDIT_COURSE_DOCUMENT';

const ON_GET_COURSE_LESSONS = 'ON_GET_COURSE_LESSONS';
const ON_NEW_COURSE_LESSON = 'ON_NEW_COURSE_LESSON';
const ON_REMOVE_COURSE_LESSON = 'ON_REMOVE_COURSE_LESSON';
const ON_EDIT_COURSE_LESSON = 'ON_EDIT_COURSE_LESSON';

function* getMyManagementCourses({ params, setData }) {
  try {
    const response = yield call(get, '/courses/my_management', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* getCourses({ params, setData }) {
  try {
    const response = yield call(get, '/courses', { params });

    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* getCourseDocuments({ params, setData }) {
  try {
    const response = yield call(get, `/courses/${params.courseId}/documents`);
    setData({
      loading: false,
      items: response
    });
  } catch (error) {
    console.log(error);
  }
}

function* newCourseDocument({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('course_document[name]', params.name);
    formData.append('course_document[video_id]', params?.video_id);
    if (params.file) formData.append('course_document[file]', params.file);

    yield call(post, `/courses/${params.courseId}/create_document`, formData);

    Notification.success({ message: 'Thêm tài liệu thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* editCourseDocument({ params, callback, setProgress }) {
  try {
    const formData = new FormData();
    formData.append('course_document[name]', params.name);
    formData.append('course_document[id]', params.id);
    formData.append('course_document[video_id]', params?.video_id);
    if (params.file) formData.append('course_document[file]', params.file);

    yield call(axiosPut, `/courses/${params.courseId}/update_document`, formData);

    Notification.success({ message: 'Chỉnh sửa tài liệu thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* getCourseLessons({ params, setData }) {
  try {
    const response = yield call(get, `/courses/${params.courseId}/lessons`);
    setData({
      loading: false,
      items: response.items,
      documents: response.documents
    });
  } catch (error) {
    console.log(error);
  }
}

function* newCourseLesson({ params, callback }) {
  try {
    yield call(post, `/courses/${params.courseId}/create_lesson`, params);

    Notification.success({ message: 'Thêm buổi học thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* editCourseLesson({ params, callback }) {
  try {
    yield call(axiosPut, `/courses/${params.courseId}/update_lesson`, params);

    Notification.success({ message: 'Chỉnh sửa buổi học thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* deleteCourseLesson({ params, callback }) {
  try {
    yield call(axiosDelete, `/courses/${params.courseId}/remove_lesson`, { params });
    Notification.success({ message: 'Xóa buổi học thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* getCourseSelectValues({ setData }) {
  try {
    const response = yield call(get, '/courses/select_values');
    setData({
      teachers: response.teachers,
      managers: response.managers,
      topics: response.topics,
      employees: response.employees
    });
  } catch (error) {
    console.log(error);
  }
}

function* editCourseField({ params, callback }) {
  try {
    const response = yield call(axiosPut, `/courses/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa thành công.' });
    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* editCourse({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('course[module_name]', params.moduleName || '');
    formData.append('course[title]', params.title || '');
    formData.append('course[introduce]', params.introduce || '');
    formData.append('course[introduce_title]', params.introduceTitle || '');
    formData.append('course[target]', params.target || '');
    formData.append('course[target_title]', params.targetTitle || '');
    formData.append('course[theory]', params.theory || '');
    formData.append('course[theory_title]', params.theoryTitle || '');
    formData.append('course[practice_title]', params.practiceTitle || '');
    formData.append('course[practice]', params.practice || '');

    if (params.mainImage) formData.append('course[main_image]', params.mainImage);

    if (params.images) {
      params.images.forEach((file) => {
        formData.append('course[images][]', file.id ? file.id : file);
      });
    }

    if (params.calendar_images) {
      params.calendar_images.forEach((file) => {
        formData.append('course[calendar_images][]', file.id ? file.id : file);
      });
    }

    const response = yield call(axiosPut, `/courses/${params.id}`, formData);
    Notification.success({ message: 'Chỉnh sửa nội dung khóa học thành công.' });
    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newCourse({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('course[module_name]', params.moduleName || '');
    formData.append('course[title]', params.title || '');
    formData.append('course[course_topic_id]', params.courseTopicId || '');
    formData.append('course[status]', params.status || '');
    formData.append('course[introduce]', params.introduce || '');
    formData.append('course[introduce_title]', params.introduceTitle || '');
    formData.append('course[target]', params.target || '');
    formData.append('course[target_title]', params.targetTitle || '');
    formData.append('course[theory]', params.theory || '');
    formData.append('course[theory_title]', params.theoryTitle || '');
    formData.append('course[practice_title]', params.practiceTitle || '');
    formData.append('course[practice]', params.practice || '');
    params.teachers.forEach((id) => {
      formData.append('course[course_teachers_attributes][][user_id]', id);
    });

    params.managers.forEach((id) => {
      formData.append('course[course_managers_attributes][][user_id]', id);
    });

    if (params.mainImage) formData.append('course[main_image]', params.mainImage);
    if (params.images) {
      params.images.forEach((file) => {
        formData.append('course[images][]', file);
      });
    }

    if (params.calendar_images) {
      params.calendar_images.forEach((file) => {
        formData.append('course[calendar_images][]', file);
      });
    }

    yield call(post, '/courses', formData);
    Notification.success({ message: 'Thêm khóa học thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* deleteCourseTeacher({ params, callback }) {
  try {
    const response = yield call(axiosDelete, `/courses/${params.id}/remove_teacher`, { params });
    Notification.success({ message: 'Loại giảng viên khỏi khóa học thành công.' });
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* deleteCourseManager({ params, callback }) {
  try {
    const response = yield call(axiosDelete, `/courses/${params.id}/remove_manager`, { params });
    Notification.success({ message: 'Loại quản lí khỏi khóa học thành công.' });
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* deleteCourseDocument({ params, callback }) {
  try {
    yield call(axiosDelete, `/courses/${params.courseId}/remove_document`, { params });
    Notification.success({ message: 'Xóa tài liệu thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* deleteCourse({ id, callback }) {
  try {
    yield call(axiosDelete, `/courses/${id}`);
    Notification.success({ message: 'Xóa khóa học thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewCourse = (params, callback) => ({
  type: ON_NEW_COURSE,
  params,
  callback
});

export const onEditCourseContent = (params, callback) => ({
  type: ON_EDIT_COURSE,
  params,
  callback
});

export const onEditCourseField = (params, callback) => ({
  type: ON_EDIT_COURSE_FIELD,
  params,
  callback
});

export const onGetCourses = (params, setData) => ({
  type: ON_GET_COURSES,
  params,
  setData
});

export const onGetMyManagementCourses = (params, setData) => ({
  type: ON_GET_MY_MANAGEMENT_COURSES,
  params,
  setData
});

export const onGetCourseSelectValues = (setData) => ({
  type: ON_GET_COURSE_SELECT_VALUES,
  setData
});

export const onRemoveCourseTeacher = (params, callback) => ({
  type: ON_REMOVE_COURSE_TEACHER,
  params,
  callback
});

export const onRemoveCourseManager = (params, callback) => ({
  type: ON_REMOVE_COURSE_MANAGER,
  params,
  callback
});

export const onGetCourseDocuments = (params, setData) => ({
  type: ON_GET_COURSE_DOCUMENTS,
  params,
  setData
});

export const onCreateCourseDocument = (params, callback) => ({
  type: ON_NEW_COURSE_DOCUMENT,
  params,
  callback
});

export const onRemoveCourseDocument = (params, callback) => ({
  type: ON_REMOVE_COURSE_DOCUMENT,
  params,
  callback
});

export const onEditCourseDocument = (params, callback) => ({
  type: ON_EDIT_COURSE_DOCUMENT,
  params,
  callback
});

export const onGetCourseLessons = (params, setData) => ({
  type: ON_GET_COURSE_LESSONS,
  params,
  setData
});

export const onCreateCourseLesson = (params, callback) => ({
  type: ON_NEW_COURSE_LESSON,
  params,
  callback
});

export const onRemoveCourseLesson = (params, callback) => ({
  type: ON_REMOVE_COURSE_LESSON,
  params,
  callback
});

export const onEditCourseLesson = (params, callback) => ({
  type: ON_EDIT_COURSE_LESSON,
  params,
  callback
});

export const onDeleteCourse = (id, callback) => ({
  type: ON_DELETE_COURSE,
  id,
  callback
});

export default function* courseWatcher() {
  yield takeLatest(ON_GET_COURSES, getCourses);
  yield takeLatest(ON_GET_MY_MANAGEMENT_COURSES, getMyManagementCourses);

  yield takeLatest(ON_GET_COURSE_SELECT_VALUES, getCourseSelectValues);
  yield takeLatest(ON_NEW_COURSE, newCourse);
  yield takeLatest(ON_EDIT_COURSE, editCourse);
  yield takeLatest(ON_EDIT_COURSE_FIELD, editCourseField);
  yield takeLatest(ON_REMOVE_COURSE_TEACHER, deleteCourseTeacher);
  yield takeLatest(ON_REMOVE_COURSE_MANAGER, deleteCourseManager);
  yield takeLatest(ON_GET_COURSE_DOCUMENTS, getCourseDocuments);
  yield takeLatest(ON_NEW_COURSE_DOCUMENT, newCourseDocument);
  yield takeLatest(ON_REMOVE_COURSE_DOCUMENT, deleteCourseDocument);
  yield takeLatest(ON_EDIT_COURSE_DOCUMENT, editCourseDocument);
  yield takeLatest(ON_GET_COURSE_LESSONS, getCourseLessons);
  yield takeLatest(ON_NEW_COURSE_LESSON, newCourseLesson);
  yield takeLatest(ON_REMOVE_COURSE_LESSON, deleteCourseLesson);
  yield takeLatest(ON_EDIT_COURSE_LESSON, editCourseLesson);
  yield takeLatest(ON_DELETE_COURSE, deleteCourse);
  yield all([classRoomWatcher(), giftWatcher(), classForumWatcher()]);
}
