import React, { useState, useEffect, useCallback, useRef } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { List, Card, Input } from 'antd';
import { onGetCourseSelectValues, onGetMyManagementCourses } from './saga';
import CourseItem from './CourseItem';

const { Search } = Input;

const View = ({ type }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });
  const [selectValues, setSelectValues] = useState({
    teachers: [],
    managers: [],
    topics: [],
    employees: []
  });
  let typing = null;
  const currentFilter = useRef(queryString.parse(location.search));
  const { teachers, managers, topics, employees } = selectValues;
  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, ...{ loading: true } }));

  const getData = useCallback((params) => dispatch(onGetMyManagementCourses(params, setState)), [
    dispatch,
    setState
  ]);

  const getSelectValues = useCallback(() => dispatch(onGetCourseSelectValues(setSelectValues)), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: type === 'support' ? '/admin/my-support-courses' : '/admin/my-management-courses',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      currentFilter.current.page = 1;
      changeLocation();
    }, 1000);
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);
    currentFilter.current.type = type;

    fetchData();
  }, [location.search]);

  useEffect(() => {
    getSelectValues();
  }, []);

  return (
    <>
      <Card style={{ marginBottom: 15 }}>
        <Search
          defaultValue={currentFilter.current.keyword}
          placeholder="Từ khóa"
          allowClear
          enterButton
          size="large"
          onChange={onSearch}
          onSearch={onSearch}
        />
      </Card>
      <List
        loading={loading}
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: (page) => {
            currentFilter.current.page = page;
            changeLocation();
          },
          total: 5 * totalPages,
          pageSize: 5,
          showLessItems: true,
          current: currentPage,
          defaultCurrent: 1
        }}
        dataSource={items}
        renderItem={(item) => (
          <CourseItem
            item={item}
            teachers={teachers}
            managers={managers}
            topics={topics}
            fetchData={fetchData}
            isManager={type === 'manager'}
            isSupport={type === 'support'}
            employees={employees}
          />
        )}
      />
    </>
  );
};
export default View;
