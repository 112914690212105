import React, {
  useState, useImperativeHandle,
} from 'react';
import {
  Col, Row, Button, Descriptions, Table, Tag,
} from 'antd';
import { sumBy } from 'lodash';
import {
  numberToCurrency,
} from '../../../../../shared/function';
import { StyledModal } from '../styles';

const columns = [
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Giá',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price),
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'Tạo bởi',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: 'Thu lúc',
    dataIndex: 'incomeDate',
    key: 'incomeDate',
  },
];

const Status = ({ status }) => {
  switch (status) {
    case 'pending':
      return <Tag color='orange'>Chưa tiến hành</Tag>;
    case 'processing':
      return <Tag color='blue'>Đang thu tiền</Tag>;
    case 'complete':
      return <Tag color='green'>Đã hoàn thành</Tag>;
    case 'canceled':
      return <Tag color='red'>Hủy thanh toán</Tag>;
    default:
      return '';
  }
};

const Preview = React.forwardRef(({}, ref) => {
  const [state, setState] = useState({ isShow: false, item: {} });
  const { isShow, item } = state;

  const close = () => {
    setState({ isShow: false, item: {} });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ isShow: true, item });
    },
  }));

  return (
    <StyledModal
      closable={false}
      visible={isShow}
      footer={null}
      title='Công nợ chi tiết'
    >
      {item.id && (
        <Row>
          <Col span={24}>
            {item.registrations.map((data) => data.incomes).flat().map((income) => {
              const { course, course_class } = income.info;

              return (
                <Descriptions bordered column={3} layout='vertical' title={`${course.module_name}: ${course.title}`}>
                  <Descriptions.Item label='Cần thu'>
                    {numberToCurrency(income.totalPrice)}
                  </Descriptions.Item>
                  <Descriptions.Item label='Đã thu'>
                    {numberToCurrency(sumBy(income.subIncomes, (o) => o.price))}
                  </Descriptions.Item>
                  <Descriptions.Item label='Trạng thái'>
                    <Status status={income.status} />
                  </Descriptions.Item>
                  <Descriptions.Item span={6}>
                    {income.subIncomes.length > 0 && <Table dataSource={income.subIncomes} columns={columns} pagination={false} />}
                  </Descriptions.Item>
                </Descriptions>
              );
            })}
          </Col>
        </Row>
      )}
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          size='large'
          style={{ width: 150 }}
          onClick={close}
        >
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default Preview;
