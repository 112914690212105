import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '../config/store';
import { history } from '../vendor/history';

import Employees from '../pages/Admin/Employees';
import Students from '../pages/Admin/Students';
import Courses from '../pages/Admin/Courses';
import CourseTopics from '../pages/Admin/CourseTopic';
import Promotion from '../pages/Admin/Promotion';
import GroupPromotion from '../pages/Admin/GroupPromotion';
import Forum from '../pages/Admin/Forum';
import ForumTopic from '../pages/Admin/Forum/Topic';
import News from '../pages/Admin/News';
import Hashtags from '../pages/Admin/Hashtags';
import Questions from '../pages/Admin/Questions';
import UserQuestions from '../pages/Admin/UserQuestions';
import UserTasks from '../pages/Admin/UserTasks';
import MyTask from '../pages/Admin/UserTasks/MyTask';
import Overview from '../pages/Admin/UserTasks/Overview';
import RegistrationInfo from '../pages/Admin/RegistrationInfo';
import Debts from '../pages/Admin/Debts';
import Gifts from '../pages/Admin/Gifts';
import MyCourse from '../pages/Admin/Courses/MyCourse';
import MyRegistration from '../pages/Admin/RegistrationInfo/MyRegistration';
import MySale from '../pages/Admin/RegistrationInfo/MySale';
import CompanyExpense from '../pages/Admin/CompanyExpense';
import CompanyIncome from '../pages/Admin/CompanyIncome';
import CompanyCategories from '../pages/Admin/CompanyCategories';
import EmployeeDebts from '../pages/Admin/EmployeeDebts';
import Statistic from '../pages/Admin/Statistic';
import ForumMembers from '../pages/Admin/ForumMembers';
import Notifications from '../pages/Admin/Notifications';
import Membership from '../pages/Admin/Membership';
import PreviewFile from '../pages/Admin/PreviewFile';

import Main from '../pages/Main';
import Login from '../pages/Login';
import SetUpPassword from '../pages/SetUpPassword';
import ForgotPassword from '../pages/ForgotPassword';
import Error from '../pages/Error';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import GlobalStyle from './styles';

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path="/" component={Main} redirect />
        <PrivateRoute exact path="/admin/employees" component={Employees} />
        <PrivateRoute exact path="/admin/courses" component={Courses} />
        <PrivateRoute exact path="/admin/course-topics" component={CourseTopics} />
        <PrivateRoute exact path="/admin/students" component={Students} />
        <PrivateRoute exact path="/admin/combo-promotions" component={Promotion} />
        <PrivateRoute exact path="/admin/group-promotions" component={GroupPromotion} />
        <PrivateRoute exact path="/admin/forums" component={Forum} />
        <PrivateRoute exact path="/admin/news" component={(props) => <News type="specialty" {...props} />} />
        <PrivateRoute
          exact
          path="/admin/news-promotions"
          component={(props) => <News type="promotion" {...props} />}
        />
        <PrivateRoute exact path="/admin/hashtags" component={Hashtags} />
        <PrivateRoute exact path="/admin/questions" component={Questions} />
        <PrivateRoute exact path="/admin/user-questions" component={UserQuestions} />
        <PrivateRoute exact path="/admin/user-tasks" component={UserTasks} />
        <PrivateRoute exact path="/admin/user-tasks/my-task" component={MyTask} />
        <PrivateRoute exact path="/admin/user-tasks/overview" component={Overview} />
        <PrivateRoute exact path="/admin/registration-infos" component={RegistrationInfo} />
        <PrivateRoute exact path="/admin/debts" component={Debts} />
        <PrivateRoute exact path="/admin/gifts" component={Gifts} />
        <PrivateRoute exact path="/admin/memberships" component={Membership} />
        <PrivateRoute exact path="/admin/forum-members" component={ForumMembers} />
        <PrivateRoute
          exact
          path="/admin/my-management-courses"
          component={() => <MyCourse type="manager" />}
        />
        <PrivateRoute
          exact
          path="/admin/my-management-registration-info"
          component={() => <MyRegistration type="manager" />}
        />
        <PrivateRoute exact path="/admin/my-support-courses" component={() => <MyCourse type="support" />} />
        <PrivateRoute
          exact
          path="/admin/my-support-registration-info"
          component={() => <MyRegistration type="support" />}
        />
        <PrivateRoute exact path="/admin/my-sales" component={() => <MySale type="my_sale" />} />
        <PrivateRoute
          exact
          path="/admin/my-course-salers"
          component={() => <MySale type="my_course_sale" />}
        />
        <PrivateRoute exact path="/admin/sales" component={() => <MySale type="all" />} />
        <PrivateRoute exact path="/admin/company-expenses" component={CompanyExpense} />
        <PrivateRoute exact path="/admin/company-incomes" component={CompanyIncome} />
        <PrivateRoute exact path="/admin/employee-debts" component={EmployeeDebts} />
        <PrivateRoute exact path="/admin/forum-topics" component={ForumTopic} />
        <PrivateRoute exact path="/admin/company-categories" component={CompanyCategories} />
        <PrivateRoute exact path="/admin/statistic" component={Statistic} />
        <PrivateRoute exact path="/admin/notifications" component={Notifications} />

        <PublicRoute exact path="/preview-file" component={PreviewFile} noLogout />
        <PublicRoute exact path="/user/sign_in" component={Login} />
        <PublicRoute exact path="/reset-password" component={SetUpPassword} />
        <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/error/500"
          component={() => (
            <Error
              status="500"
              title="Hệ thống xảy ra lỗi."
              subTitle="Chúng tôi sẽ tiến hành khắc phục trong thời gian sớm nhất."
            />
          )}
        />
        <Route
          exact
          path="/error/403"
          component={() => <Error status="403" title="Bạn không có quyền truy cập trang này." />}
        />
        <Route
          component={() => (
            <Error status="404" title="404" subTitle="Trang bạn đang tìm kiếm không tồn tại." />
          )}
        />
      </Switch>
      <GlobalStyle />
    </Router>
  </Provider>
);

export default App;
