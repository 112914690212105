import React, {
  useState, useImperativeHandle, useCallback, useRef,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  customValidate, fieldValidate,
} from '../../../../shared/function';
import Upload from '../../../../components/Upload';
import UploadList from '../../../../components/UploadList';
import { onNewStudent, onUpdateFireBaseUid } from '../saga';
import { auth } from '../../../../config/firebase';

const { Item } = Form;
const { Option } = Select;

const Create = React.forwardRef(({ fetchData }, ref) => {
  const uploadRef = useRef();
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    if (uploadRef.current) uploadRef.current.reset();
    setShowModal(false);
  };

  const updateFireBase = useCallback((params) => dispatch(
    onUpdateFireBaseUid(params),
  ), [dispatch]);

  const callback = async (status, response) => {
    if (status === 200) {
      fetchData();
      close();
      await auth.createUserWithEmailAndPassword(response.email, response.email).then((firebaseResponse) => {
        updateFireBase({ user_id: response.id, uid: firebaseResponse.user.uid });
      });
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(
    onNewStudent(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    createData(values);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      width={992}
      title='Thêm học viên'
    >
      <Form
        layout='vertical'
        form={form}
        name='createStudent'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='email'
              label='E-mail'
            >
              <Input placeholder='E-mail' />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='gender'
              rules={[fieldValidate.required]}
              label='Giới tính'
            >
              <Select placeholder='Giới tính'>
                <Option value='male'>Nam</Option>
                <Option value='female'>Nữ</Option>
              </Select>
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='firstName'
              label='Họ'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Họ' />
            </Item>
            <Item
              name='lastName'
              label='Tên'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tên' />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='avatar'
            >
              <Upload
                square
                onFileChange={(file) => form.setFieldsValue({ avatar: file })}
              />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='birthday'
              label='Ngày sinh'
            >
              <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} placeholder='Ngày sinh' />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='phone'
              label='Số điện thoại'
              rules={[
                fieldValidate.required,
                () => ({
                  validator(rule, value) {
                    return customValidate.validateNumber(value);
                  },
                }),
              ]}
            >
              <Input placeholder='Số điện thoại' />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='job'
              label='Nghề nghiệp'
              rules={[fieldValidate.required]}
            >
              <Select placeholder='Nghề nghiệp'>
                <Option value='Bác sỹ Răng Hàm Mặt'>Bác sỹ Răng Hàm Mặt</Option>
                <Option value='Sinh viên Răng Hàm Mặt'>Sinh viên Răng Hàm Mặt</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item label='Hình chụp Bằng Tốt Nghiệp Bác sỹ RHM hoặc Thẻ Sinh viên RHM (bắt buộc)' name='images' />
            <UploadList
              noCrop
              limitFile={10}
              ref={uploadRef}
              circle
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
