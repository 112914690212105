import React from 'react';
import { Wrapper, Content } from './styles';
import TabMenu from './TabMenu';

const LayoutContent = ({ children }) => (
  <Wrapper>
    <TabMenu />
    <Content>{children}</Content>
  </Wrapper>
);

export default LayoutContent;
