import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Row, Col, Form, Button, DatePicker, Input, Typography, Select } from 'antd';
import SelectSearch from '../../../components/SelectSearch';
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const dateFormat = 'DD/MM/YYYY';

const Filter = ({ currentFilter, changeLocation, createRef, incomeCategories, expenseCategories }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.resetFields();
    changeLocation();
  };

  useEffect(() => {
    const { time_gteq, time_lteq, keyword } = queryString.parse(location.search);

    form.setFieldsValue({
      keyword: keyword || '',
      time: time_gteq && time_lteq ? [moment(time_gteq, dateFormat), moment(time_lteq, dateFormat)] : []
    });
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Title level={4}>Thống kê</Title>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item name="keyword" label="Nhân viên & Học viên:">
            <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="income_category" label="Mã phiếu thu:">
            <SelectSearch onChange={(e) => onFilter('income_category', e)} placeholder="Mã phiếu thu">
              {incomeCategories.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="expense_category" label="Mã phiếu chi:">
            <SelectSearch onChange={(e) => onFilter('expense_category', e)} placeholder="Mã phiếu chi">
              {expenseCategories.map((item) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={4}>
          <Form.Item name="time" label="Thời gian:">
            <RangePicker
              format={dateFormat}
              style={{ width: '100%' }}
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              onChange={(dates) => {
                currentFilter.current.time_gteq = dates ? dates[0].format(dateFormat) : null;
                currentFilter.current.time_lteq = dates ? dates[1].format(dateFormat) : null;
                changeLocation();
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
              Xóa bộ lọc
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item style={{ marginTop: 30 }}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => {
                const {
                  keyword,
                  time_gteq,
                  time_lteq,
                  income_category,
                  expense_category
                } = currentFilter.current;

                window.open(
                  `/export-incomes?export=true&keyword=${keyword || ''}&time_gteq=${
                    time_gteq || ''
                  }&time_lteq=${time_lteq || ''}&company_category_id=${income_category}&confirmed=true`
                );

                window.open(
                  `/export-expenses?export=true&keyword=${keyword || ''}&time_gteq=${
                    time_gteq || ''
                  }&time_lteq=${time_lteq || ''}&company_category_id=${expense_category}&status=approve`
                );
              }}
            >
              Xuất Excel
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
