import React from 'react';
import { Typography } from 'antd';
import { numberToCurrency } from '@shared/function';
import { Wrapper } from './styles';
const { Text } = Typography;

const Title = ({ item, fetchData }) => {
  return (
    <Wrapper>
      <Text style={{ marginRight: 10 }}>Lớp học:</Text>
      <Text style={{ margin: '0 8px' }}>{item.code}</Text>-
      <Text style={{ margin: '0 8px' }}>{item.address}</Text>-
      <Text style={{ margin: '0 8px' }}>{item.startDate}</Text>-
      <Text style={{ margin: '0 8px' }}>{item.classType}</Text>-
      <Text style={{ margin: '0 8px' }}>{numberToCurrency(item.price)}</Text>
    </Wrapper>
  );
};
export default Title;
