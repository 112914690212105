import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate, removeSpecialCharacter } from '@shared/function';
import { onNewForumPost, onGetForumSelectValue } from '../saga';
import { StyledModal } from './styles';
import Ckeditor from '../../../../shared/Ckeditor';
import SelectSearch from '../../../../components/SelectSearch';
import Upload from '../../../../components/Upload';

const { Item } = Form;
const { Option } = Select;

const CreatePost = React.forwardRef(({ postRef, currentTopic }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [selectValue, setSelectValue] = useState([]);
  const [hashtag, setHashtag] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({});
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setSelectValue([]);
    setSelectedTopic({});
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      if (parseInt(currentTopic) === parseInt(form.getFieldValue('forum_topic_id')) && postRef.current) {
        postRef.current.fetchData();
      }

      close();
    }

    setSubmiting(false);
  };

  const onSelectTopic = (topicId) => {
    const filteredTopic = selectValue.filter((item) => item.id === topicId)[0];

    if (filteredTopic) {
      setSelectedTopic(filteredTopic);
      setHashtag([]);
      form.setFieldsValue({ forum_topic_id: null });
    }
  };

  const onChangeSubTopic = (subTopicId) => {
    const filtered = selectedTopic.childrens.filter((subTopic) => subTopic.id === subTopicId)[0];

    if (filtered) setHashtag(filtered.hashtag);
  };

  const createData = useCallback((params) => dispatch(onNewForumPost(params, callback)), [dispatch]);

  const getSelectValue = useCallback(() => dispatch(onGetForumSelectValue(setSelectValue)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const formData = new FormData();
    formData.append('forum_post[short_description]', values.short_description);
    formData.append('forum_post[title]', values.title);
    formData.append('forum_post[content]', values.content);
    formData.append('forum_post[forum_topic_id]', values.forum_topic_id);
    values.hashtag.map((hashtag) => {
      formData.append('forum_post[hashtag][]', hashtag);
    });

    if (values.image) formData.append('forum_post[image]', values.image);

    createData(formData);
  };

  const onChangeHashTag = (values) => {
    const hashtag = (values || []).map((a) => removeSpecialCharacter(a));

    form.setFieldsValue({ hashtag: hashtag });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      getSelectValue();
      setShowModal(true);
    }
  }));

  return (
    <StyledModal closable={false} visible={isShow} footer={null} title="Thêm bài viết">
      <Form layout="vertical" form={form} name="createPost" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Item name="title" label="Tiêu đề" rules={[fieldValidate.required]}>
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={12}>
            <Form.Item rules={[fieldValidate.required]} label="Hình ảnh" name="image">
              <Upload cropImage onFileChange={(file) => form.setFieldsValue({ image: file })} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Item name="short_description" label="Mô tả" rules={[fieldValidate.required]}>
              <Input.TextArea placeholder="Mô tả" rows={2} />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="topic_id" rules={[fieldValidate.required]} label="Danh mục">
              <SelectSearch placeholder="Danh mục" onChange={onSelectTopic}>
                {selectValue.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={12}>
            <Item name="forum_topic_id" rules={[fieldValidate.required]} label="Chủ đề">
              <SelectSearch placeholder="Chủ đề" onChange={onChangeSubTopic}>
                {(selectedTopic.childrens || []).map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={24}>
            <Item name="hashtag" label="Hashtag (Gợi ý: viết không dấu, có khoảng trắng)">
              <Select placeholder="Hashtag" mode="tags" tokenSeparators={[',']} onChange={onChangeHashTag}>
                {hashtag.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>

          <Col span={24}>
            <Item name="content" label="Nội dung">
              <Ckeditor onChange={(value) => form.setFieldsValue({ content: value })} />
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </StyledModal>
  );
});

export default CreatePost;
