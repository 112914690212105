import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_COMPANY_INCOMES = 'ON_GET_COMPANY_INCOMES';
const ON_EDIT_COMPANY_INCOME = 'ON_EDIT_COMPANY_INCOME';
const ON_NEW_COMPANY_INCOME = 'ON_NEW_COMPANY_INCOME';
const ON_REMOVE_COMPANY_INCOME = 'ON_REMOVE_COMPANY_INCOME';
const ON_GET_COMPANY_INCOMES_EMPLOYEES = 'ON_GET_COMPANY_INCOMES_EMPLOYEES';

function* getList({ params, setData }) {
  try {
    const response = yield call(get, '/company_incomes', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* getEmployees({ params, setData }) {
  try {
    const response = yield call(get, '/company_incomes/employees');
    setData({
      items: response.items
    });
  } catch (error) {
    console.log(error);
  }
}

function* editItem({ params, callback }) {
  try {
    yield call(axiosPut, `/company_incomes/${params.id}`, params.formData);
    Notification.success({ message: 'Chỉnh sửa phiếu thu thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newItem({ params, callback }) {
  try {
    yield call(post, '/company_incomes', params.formData);
    Notification.success({ message: 'Thêm phiếu thu thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeItem({ params, callback }) {
  try {
    yield call(axiosDelete, `/company_incomes/${params.id}`);
    Notification.success({ message: 'Xóa phiếu thu thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewData = (params, callback) => ({
  type: ON_NEW_COMPANY_INCOME,
  params,
  callback
});

export const onEditData = (params, callback) => ({
  type: ON_EDIT_COMPANY_INCOME,
  params,
  callback
});

export const onGetData = (params, setData) => ({
  type: ON_GET_COMPANY_INCOMES,
  params,
  setData
});

export const onDeleteData = (params, callback) => ({
  type: ON_REMOVE_COMPANY_INCOME,
  params,
  callback
});

export const onGetEmployees = (params, setData) => ({
  type: ON_GET_COMPANY_INCOMES_EMPLOYEES,
  params,
  setData
});

export default function* watcher() {
  yield takeLatest(ON_GET_COMPANY_INCOMES, getList);
  yield takeLatest(ON_EDIT_COMPANY_INCOME, editItem);
  yield takeLatest(ON_NEW_COMPANY_INCOME, newItem);
  yield takeLatest(ON_REMOVE_COMPANY_INCOME, removeItem);
  yield takeLatest(ON_GET_COMPANY_INCOMES_EMPLOYEES, getEmployees);
}
