import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../shared/function';
import Ckeditor from '../../../../shared/Ckeditor';
import SelectSearch from '../../../../components/SelectSearch';
import { onEditTask } from '../saga';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

const EditSubProject = React.forwardRef(({
  fetchData, employees,
}, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ isShow: false, item: {} });
  const { isShow, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const parent = item.parent || {};

  const close = () => {
    form.resetFields();
    setState({ isShow: false, item: {} });
  };

  const callback = (status, response) => {
    if (status === 200) {
      fetchData(response);
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditTask(params, callback),
  ), [dispatch]);

  const modifyAssignee = (selectAssignee) => {
    const newAssignee = [];
    const removeAssignee = [];

    selectAssignee.map((id) => {
      const filtered = item.assigneeIds.filter((a) => a === parseInt(id, 10))[0];

      if (!filtered) {
        newAssignee.push({
          user_id: id,
          status: 'employee',
        });
      }
    });

    item.assigneeInfo.map((assignee) => {
      if (!selectAssignee.includes(assignee.user_id)) {
        removeAssignee.push({
          id: assignee.task_assignee_id,
          _destroy: true,
        });
      }
    });

    return newAssignee.concat(removeAssignee);
  };

  const modifySupporter = (selectSupporter) => {
    const newSupporter = [];
    const removeSupporter = [];

    selectSupporter.map((id) => {
      const filtered = item.supporterIds.filter((a) => a === parseInt(id, 10))[0];

      if (!filtered) {
        newSupporter.push({
          user_id: id,
          status: 'supporter',
        });
      }
    });

    item.supporterInfo.map((assignee) => {
      if (!selectSupporter.includes(assignee.user_id)) {
        removeSupporter.push({
          id: assignee.task_assignee_id,
          _destroy: true,
        });
      }
    });

    return newSupporter.concat(removeSupporter);
  };

  const onFinish = (values) => {
    setSubmiting(true);

    const selectAssignee = modifyAssignee(values.assignee_ids || []);
    const selectSupporter = modifySupporter(values.supporter_ids || []);

    if (values.time && values.time.length > 0) {
      values.start_at = values.time[0].format('DD/MM/YYYY');
      values.deadline = values.time[1].format('DD/MM/YYYY');
    } else {
      values.start_at = '';
      values.deadline = '';
    }

    editData({ id: item.id, user_task: { ...values, task_assignees_attributes: selectAssignee.concat(selectSupporter) } });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        assignee_ids: item.assigneeIds,
        supporter_ids: item.supporterIds,
        title: item.title,
        color: item.color,
        time: item.startAt && item.deadline ? [moment(item.startAt, 'DD/MM/YYYY'), moment(item.deadline, 'DD/MM/YYYY')] : [],
      });
      setState({ isShow: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      width={992}
      title='Chỉnh sửa Sub-Project'
    >
      <Form
        layout='vertical'
        form={form}
        name='editSubProject'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name='title'
              label='Tên'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tên' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='time'
              label='Ngày bắt đầu - Ngày kết thúc'
            >
              <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='assignee_ids'
              label='Nhân viên'
              rules={[fieldValidate.required]}
            >
              <SelectSearch placeholder='Nhân viên' mode='multiple'>
                {(parent.assigneeInfo || []).map((employee) => (
                  <Option value={employee.id} key={employee.id}>{employee.name}</Option>
                ))}
              </SelectSearch>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='supporter_ids'
              label='Trợ thủ'
            >
              <SelectSearch placeholder='Trợ thủ' mode='multiple'>
                {employees.map((employee) => {
                  if (item.assigneeIds && item.assigneeIds.includes(employee.id)) return null;
                  return (
                    <Option value={employee.id} key={employee.id}>{employee.name}</Option>
                  );
                })}
              </SelectSearch>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name='content'
              label='Nội dung'
            >
              <Ckeditor defaultValue={item.content || ''} onChange={(value) => form.setFieldsValue({ content: value })} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default EditSubProject;
