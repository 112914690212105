import styled from 'styled-components';
import { Avatar } from 'antd';

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardImage = styled(Avatar)`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;
