import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { customValidate, fieldValidate, dateFormat } from '../../../../shared/function';
import Upload from '../../../../components/Upload';
import UploadList from '../../../../components/UploadList';
import { onEditStudent } from '../saga';

const { Item } = Form;
const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const isAdmin = user.roles.includes('admin');

  const close = () => {
    form.resetFields();
    if (uploadRef.current) uploadRef.current.reset();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onEditStudent(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    editData({ ...values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        ...item,
        birthday: item.birthday ? moment(item.birthday, dateFormat) : null
      });
      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} width={992} title="Chỉnh sửa thông tin học viên">
      <Form layout="vertical" form={form} name="editStudent" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="email" label="E-mail">
              <Input placeholder="E-mail" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="gender" rules={[fieldValidate.required]} label="Giới tính">
              <Select placeholder="Giới tính">
                <Option value="male">Nam</Option>
                <Option value="female">Nữ</Option>
              </Select>
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="firstName" label="Họ" rules={[fieldValidate.required]}>
              <Input placeholder="Họ" />
            </Item>
            <Item name="lastName" label="Tên" rules={[fieldValidate.required]}>
              <Input placeholder="Tên" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="avatar">
              <Upload
                square
                defaultValue={item.avatarUrl}
                onFileChange={(file) => form.setFieldsValue({ avatar: file })}
              />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="birthday" label="Ngày sinh">
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder="Ngày sinh" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name="phone"
              label="Số điện thoại"
              rules={[
                fieldValidate.required,
                () => ({
                  validator(rule, value) {
                    return customValidate.validateNumber(value);
                  }
                })
              ]}
            >
              <Input placeholder="Số điện thoại" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="job" label="Nghề nghiệp" rules={[fieldValidate.required]}>
              <Select placeholder="Nghề nghiệp">
                <Option value="Bác sỹ Răng Hàm Mặt">Bác sỹ Răng Hàm Mặt</Option>
                <Option value="Sinh viên Răng Hàm Mặt">Sinh viên Răng Hàm Mặt</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Hình chụp Bằng Tốt Nghiệp Bác sỹ RHM hoặc Thẻ Sinh viên RHM (bắt buộc)"
              name="images"
            />
            <UploadList
              noCrop
              canDelete={isAdmin}
              limitFile={10}
              defaultValue={item.images || []}
              ref={uploadRef}
              circle
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
