export const ON_UPDATE_USER_INFO = 'ON_UPDATE_USER_INFO';
export const ON_UPDATE_EXPIRED_NOTICE_STATUS = 'ON_UPDATE_EXPIRED_NOTICE_STATUS';

const INITIAL_STATE = {
  data: {},
  showExpiredNotice: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ON_UPDATE_USER_INFO:
      return { ...state, data: action.payload.user };
    case ON_UPDATE_EXPIRED_NOTICE_STATUS:
      return { ...state, showExpiredNotice: action.payload.status };
    default:
      return state;
  }
};

export const onUpdateUserInfo = (user) => ({
  type: ON_UPDATE_USER_INFO,
  payload: {
    user,
  },
});

export const onUpdateExpiredNoticeStatus = (status) => ({
  type: ON_UPDATE_EXPIRED_NOTICE_STATUS,
  payload: {
    status,
  },
});
