import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Avatar } from 'antd';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const file = (fileType) => {
  switch (fileType) {
    case 'image':
      return <Avatar src="/images/icon/image.png" size={60} />;
    case 'pdf':
      return <Avatar src="/images/icon/pdf.png" size={60} />;
    case 'word':
      return <Avatar src="/images/icon/word.png" size={60} />;
    case 'excel':
      return <Avatar src="/images/icon/excel.png" size={60} />;
    case 'video':
      return <Avatar src="/images/icon/ic-video.png" size={60} />;
    default:
      return null;
  }
};

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Loại',
    render: (item) => file(item.fileType)
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt'
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    )
  }
];
