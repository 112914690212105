import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Descriptions, Typography } from 'antd';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import { numberToCurrency } from '../../../../shared/function';
import Table from '../../../../shared/Table';
import { onGetMySales } from '../saga';
import Filter from './Filter';
import Preview from './ViewIncomeDetail';

const { Text, Title } = Typography;

const View = ({ type }) => {
  const previewRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1,
    totalIncome: 0
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const { loading, items, currentPage, totalPages, totalIncome } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetMySales(params, setState)), [dispatch]);

  const changeLocation = () => {
    let pathname = '/admin/sales';

    if (type === 'my_sale') pathname = '/admin/my-sales';
    if (type === 'my_course_sale') pathname = '/admin/my-course-salers';

    history.push({
      pathname,
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  const onView = (item) => previewRef.current.open(item.income);

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);
    if (type === 'my_sale') currentFilter.current.my_sale = 'my_sale';
    if (type === 'my_course_sale') currentFilter.current.my_course_sale = 'my_course_sale';

    fetchData();
  }, [location.search]);

  return (
    <>
      <Preview ref={previewRef} mySale={type === 'my_sale'} fetchData={fetchData} />
      <Table
        filter={<Filter currentFilter={currentFilter} changeLocation={changeLocation} type={type} />}
        extra={
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text>Tổng tiền đã sale:</Text>
                  <Title level={4} style={{ margin: 0, marginLeft: 5 }}>
                    {numberToCurrency(totalIncome)}
                  </Title>
                </div>
              }
            />
          </Descriptions>
        }
        title={type === 'my_sale' ? 'Khóa học tôi đang sale' : 'Quản lí sale'}
        columns={columns(onView, type)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default View;
