import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, DatePicker, Select, Space, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Bar } from '@ant-design/charts';
import { ChartHeader } from './styles';
import { onGetChartOverview } from '../saga';
import { dateFormat, numberToCurrency } from '@shared/function';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function PickerWithType({ type, onChange }) {
  if (type === 'date') {
    return (
      <RangePicker
        format="DD-MM-YYYY"
        onChange={onChange}
        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
        defaultValue={[moment().startOf('months'), moment().endOf('months')]}
      />
    );
  }
  return <DatePicker picker={type} onChange={onChange} defaultValue={moment()} />;
}

const configBarHorizontal = (data) => ({
  data,
  xField: 'value',
  yField: 'name',
  isRange: true,
  xAxis: {
    grid: {
      line: {
        style: {
          stroke: '#F0F0F0',
          cursor: 'pointer'
        }
      }
    },
    label: {
      formatter: function formatter(v) {
        return `${numberToCurrency(v)}`;
      }
    }
  },
  yAxis: {
    verticalFactor: 1,
    min: 0
  },
  seriesField: 'name',
  legend: {
    position: 'top-left',
    itemSpacing: 20,
    flipPage: false
  },
  barStyle: {
    radius: [2, 2, 0, 0]
  }
});

const Chart = () => {
  const [type, setType] = useState('date');
  const [state, setState] = useState([]);

  const currentFilter = useRef({});
  const dispatch = useDispatch();

  const getData = useCallback((params) => dispatch(onGetChartOverview(params, setState)), [dispatch]);

  const setDateFilter = (dates, calendarType) => {
    if (calendarType === 'date') {
      currentFilter.current.start_date = dates[0].format(dateFormat);
      currentFilter.current.end_date = dates[1].format(dateFormat);
    }

    if (calendarType === 'month') {
      currentFilter.current.start_date = dates.startOf('month').format(dateFormat);
      currentFilter.current.end_date = dates.endOf('month').format(dateFormat);
    }

    if (calendarType === 'year') {
      currentFilter.current.start_date = dates.startOf('year').format(dateFormat);
      currentFilter.current.end_date = dates.endOf('year').format(dateFormat);
    }

    getData(currentFilter.current);
  };

  const onChangeDate = (dates) => setDateFilter(dates, type);

  const changeType = (value) => {
    setType(value);
    if (value === 'date') {
      setDateFilter([moment().startOf('months'), moment().endOf('months')], value);
    } else {
      setDateFilter(moment(), value);
    }
  };

  useEffect(() => {
    currentFilter.current.start_date = moment().startOf('months').format(dateFormat);
    currentFilter.current.end_date = moment().endOf('months').format(dateFormat);
    getData(currentFilter.current);
  }, []);

  return (
    <Row gutter={24} className="site-layout-background" style={{ padding: 0, height: '100%' }}>
      <ChartHeader span={24} style={{ padding: 24 }}>
        <Title level={4} style={{ margin: 0 }}>
          Biểu đồ
        </Title>
        <Space>
          <Select value={type} onChange={changeType}>
            <Option value="date">Ngày</Option>
            <Option value="month">Tháng</Option>
            <Option value="year">Năm</Option>
          </Select>
          <PickerWithType type={type} onChange={onChangeDate} />
        </Space>
      </ChartHeader>
      <Col span={24} style={{ padding: 24 }}>
        <div style={{ overflowX: 'hidden', margin: '0 20px' }}>
          <Bar {...configBarHorizontal(state)} />
        </div>
      </Col>
    </Row>
  );
};

export default Chart;
