import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spin, Card, Statistic } from 'antd';
import { UserOutlined, DollarCircleOutlined, AccountBookOutlined } from '@ant-design/icons';
import { onGetOverviewStatistic } from '../saga';

const Resource = () => {
  const [state, setState] = useState({
    loading: true,
    data: {}
  });
  const { loading, data } = state;
  const dispatch = useDispatch();

  const getData = useCallback(
    (params) =>
      dispatch(
        onGetOverviewStatistic(params, (response) =>
          setState({
            loading: false,
            data: response
          })
        )
      ),
    [dispatch]
  );

  useEffect(() => {
    getData();
  }, []);

  return (
    <Spin tip="Đang tải dữ liệu..." spinning={loading}>
      <Row gutter={24} className="site-layout-background" style={{ padding: 0, height: '100%' }}>
        <Col span={24} style={{ padding: 24 }}>
          <Row gutter={24}>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Tổng số bài viết"
                  value={data?.totalPost || 0}
                  prefix={<AccountBookOutlined />}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Tổng số thành viên"
                  value={data?.totalMember || 0}
                  prefix={<UserOutlined />}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Statistic
                  title="Tổng số truy cập"
                  value={data?.totalAccess}
                  prefix={<DollarCircleOutlined />}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default Resource;
