import React from 'react';
import { GroupOutlined } from '@ant-design/icons';
import { Tooltip, Tag, Typography } from 'antd';
import { numberToCurrency } from '../../../../shared/function';

const { Text } = Typography;

export const columns = (onView, type) => [
  type && {
    title: 'Nhân viên sale',
    dataIndex: 'saler',
    key: 'saler',
    render: (saler) => <Text strong>{saler}</Text>
  },
  {
    title: 'Mã đăng kí',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Khóa học - Tên lớp - Thời gian - Địa điểm',
    dataIndex: 'course',
    key: 'course',
    render: ({ course, course_class }) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
          <Text strong>{`${course.module_name}: ${course.title}`}</Text>
          <Text style={{ paddingLeft: 10 }}>{`+ ${
            course_class.class_type ? `${course_class.class_type} - ` : ''
          } - ${course_class.code} - ${course_class.start_date} - ${course_class.address}`}</Text>
        </div>
      </div>
    )
  },
  {
    title: 'Thông tin học viên',
    dataIndex: 'user',
    key: 'user',
    render: (user) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text>{`- ${user.name}`}</Text>
        <Text>{`- ${user.email}`}</Text>
        <Text>{`- ${user.phone}`}</Text>
      </div>
    )
  },
  {
    title: 'Chính sách',
    dataIndex: 'promotionType',
    key: 'promotionType',
    render: (promotionType) => {
      switch (promotionType) {
        case 'combo':
          return 'Combo';
        case 'group':
          return 'Nhóm';
        case 'retake':
          return 'Học lại';
        default:
          return '';
      }
    }
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      switch (status) {
        case 'pending':
          return <Tag color="orange">Chưa tiến hành</Tag>;
        case 'processing':
          return <Tag color="blue">Đang thu tiền</Tag>;
        case 'complete':
          return <Tag color="green">Đã hoàn thành</Tag>;
        case 'canceled':
          return <Tag color="red">Hủy thanh toán</Tag>;
        default:
          return '';
      }
    }
  },
  {
    title: 'Đã thu',
    dataIndex: 'totalIncome',
    key: 'totalIncome',
    render: (totalIncome) => numberToCurrency(totalIncome)
  },
  {
    title: 'Ngày đăng kí',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: '',
    render: (item) => (
      <Tooltip title="Chi tiết" color="geekblue" key="geekblue">
        <GroupOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onView(item)} />
      </Tooltip>
    )
  }
];

export default columns;
