import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Input, Button, Typography, Form } from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { LeftColumn, RightColumn, RegisterLink, Footer, FormTitle, FormWrapper } from './styles';
import { fieldValidate } from '../../shared/function';
import { onSetupPassword } from './saga';

const { Item } = Form;

export default function () {
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showError, setShowError] = useState(false);

  const onValuesChange = () => {
    if (showError) setShowError(false);
  };

  const onSubmit = useCallback((params) => dispatch(onSetupPassword(params, setShowError)), [dispatch]);

  const onFinish = (values) => {
    const query = queryString.parse(location.search);
    onSubmit({ ...values, ...{ reset_password_token: query.reset_password_token } });
  };

  return (
    <Row style={{ width: '100%' }}>
      <RightColumn span={10}>
        <FormWrapper data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
          <FormTitle>Cập nhập mật khẩu</FormTitle>
          <Form layout="vertical" form={form} name="register" scrollToFirstError onFinish={onFinish}>
            {showError && (
              <Typography.Text type="danger" style={{ marginBottom: 20 }}>
                Đường dẫn lỗi. Xin vui lòng kiểm tra lại đường dẫn trong email của bạn!
              </Typography.Text>
            )}
            <Row>
              <Col span={24}>
                <Item
                  name="password"
                  rules={[
                    fieldValidate.required,
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (getFieldValue('confirm_password') && value !== getFieldValue('confirm_password'))
                          form.resetFields(['confirm_password']);

                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input.Password size="large" placeholder="Mật khẩu mới" bordered={false} />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  name="confirm_password"
                  rules={[
                    fieldValidate.required,
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (value && value.length > 0 && getFieldValue('password') !== value) {
                          return Promise.reject('Mật khẩu không trùng khớp.');
                        }

                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input.Password size="large" placeholder="Nhập lại mật khẩu" bordered={false} />
                </Item>
              </Col>
            </Row>
            <Row>
              <Button htmlType="submit" type="primary" size="large" style={{ marginTop: 20, width: 160 }}>
                CẬP NHẬP
              </Button>
            </Row>
          </Form>
          <Footer>
            <RegisterLink href={`${process.env.DOMAIN_URL}/user/sign_in`} target="_blank">
              Đăng nhập
            </RegisterLink>
            <RegisterLink href={`${process.env.WEBSITE_URL}/register`} target="_blank">
              Đăng kí
            </RegisterLink>
          </Footer>
        </FormWrapper>
      </RightColumn>
      <LeftColumn span={14}></LeftColumn>
    </Row>
  );
}
