import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Form,
  Button,
  Descriptions,
  Tag,
  Typography,
  Input,
  Table,
  Menu,
  Dropdown,
  Radio,
  Tooltip
} from 'antd';
import {
  EditOutlined,
  MenuOutlined,
  LoginOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PauseCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sumBy } from 'lodash';
import { fieldValidate, numberToCurrency, removeCurrency } from '../../../../../shared/function';
import { Wrapper } from '../styles';
import { onUpdateIncome, onRemoveSubIncome, onUpdateSubIncome } from '../../saga';
import CreateSubIncome from './CreateSubIncome';
import EditSubIncome from './EditSubIncome';

const { Text, Title } = Typography;

const Status = ({ status }) => {
  switch (status) {
    case 'pending':
      return <Tag color="orange">Chưa tiến hành</Tag>;
    case 'processing':
      return <Tag color="blue">Đang thu tiền</Tag>;
    case 'complete':
      return <Tag color="green">Đã hoàn thành</Tag>;
    case 'canceled':
      return <Tag color="red">Hủy thanh toán</Tag>;
    default:
      return '';
  }
};

const EditField = ({ field, defaultValueField, item, onEdit, updateData }) => {
  const [editable, setEditable] = useState(false);
  const [form] = Form.useForm();

  const callback = (status, response) => {
    if (status === 200) {
      updateData(response);
      setEditable(false);
    }
  };

  const onFinish = (values) => {
    if (field === 'total_price') values.total_price = removeCurrency(values.total_price);

    onEdit({ income: { ...values, id: item.id } }, callback);
  };

  useEffect(() => {
    const value = {};
    value[field] =
      field === 'total_price' ? numberToCurrency(item[defaultValueField]) : item[defaultValueField];
    form.setFieldsValue(value);
  }, [item]);

  return (
    <Wrapper>
      {!editable ? (
        <>
          {field === 'status' ? (
            <Status status={item[defaultValueField]} />
          ) : (
            <Text>{numberToCurrency(item[defaultValueField])}</Text>
          )}
          <EditOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(true)}
          />
        </>
      ) : (
        <>
          <Form layout="vertical" form={form} name={`${field}Inline`} scrollToFirstError onFinish={onFinish}>
            <Form.Item style={{ margin: 0, display: 'flex' }} name={field} rules={[fieldValidate.required]}>
              {field === 'status' ? (
                <Radio.Group>
                  <Radio.Button value="pending">Chưa tiến hành</Radio.Button>
                  <Radio.Button value="processing">Đang thu tiền</Radio.Button>
                  <Radio.Button value="complete">Đã hoàn thành</Radio.Button>
                  <Radio.Button value="canceled">Hủy thanh toán</Radio.Button>
                </Radio.Group>
              ) : (
                <Input
                  onChange={(e) =>
                    e.target.value &&
                    form.setFieldsValue({
                      total_price: numberToCurrency(removeCurrency(e.target.value))
                    })
                  }
                />
              )}
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Form>
          <CloseCircleOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(false)}
          />
        </>
      )}
    </Wrapper>
  );
};

const EditStatusField = ({ item, onEdit, updateData }) => {
  const [defaultValue, setDefaultValue] = useState(item.status);
  const callback = (status, response) => {
    if (status === 200) {
      updateData(response);
    }
  };

  const submit = ({ target: { value } }) => {
    onEdit({ income: { status: value, id: item.id } }, callback);
  };

  useEffect(() => {
    setDefaultValue(item.status);
  }, [item]);

  return (
    <Wrapper>
      <Radio.Group value={defaultValue} onChange={submit} buttonStyle="solid" optionType="button">
        <Radio.Button value="pending">Chưa tiến hành</Radio.Button>
        <Radio.Button value="processing">Đang thu tiền</Radio.Button>
        <Radio.Button value="complete">Đã hoàn thành</Radio.Button>
        <Radio.Button value="canceled">Hủy thanh toán</Radio.Button>
      </Radio.Group>
    </Wrapper>
  );
};

const columns = (onEdit, onDelete, onConfirm, canModify) => [
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Số tiền',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note'
  },
  {
    title: 'Người tạo',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: 'Xác nhận',
    dataIndex: 'confirmed',
    key: 'confirmed',
    width: 120,
    render: (confirmed, item) => {
      if (!confirmed) {
        return (
          <Tooltip title="Đang chờ xác nhận" color="red" key="red">
            <PauseCircleOutlined
              style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }}
              onClick={() => canModify && onConfirm(item, true)}
            />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã xác nhận" color="green" key="green">
          <CheckCircleOutlined
            style={{ cursor: 'pointer', color: 'green', fontSize: 20 }}
            onClick={() => canModify && onConfirm(item, false)}
          />
        </Tooltip>
      );
    }
  },
  {
    title: 'Thu lúc',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: '',
    key: 'action',
    render: (item) =>
      canModify && (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onConfirm(item, !item.confirmed)}>
                <CheckCircleOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {!item.confirmed ? 'Duyệt phiếu' : 'Huỷ duyệt phiếu'}
              </Menu.Item>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item.id)}>
                <LoginOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      )
  }
];

const IncomeTab = ({ items, updateData, registrationId }) => {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const createSubIncomeRef = useRef();
  const editSubIncomeRef = useRef();

  const editData = useCallback(
    (params, callback) => dispatch(onUpdateIncome({ ...params, id: registrationId }, callback)),
    [dispatch, registrationId]
  );

  const deleteSubIncome = useCallback(
    (subIncomeId) =>
      dispatch(onRemoveSubIncome({ sub_income_id: subIncomeId, id: registrationId }, updateData)),
    [dispatch, registrationId]
  );

  const onConfirm = (subIncone, confirmed) => {
    dispatch(
      onUpdateSubIncome(
        { sub_income: { id: subIncone.id, confirmed }, id: registrationId },
        (status, response) => {
          updateData(response);
        }
      )
    );
  };

  const onEdit = (item) => editSubIncomeRef.current.open(item);

  const canModify = user.id && (user.roles.includes('course_manager') || user.roles.includes('admin'));

  return (
    <>
      <EditSubIncome registrationId={registrationId} ref={editSubIncomeRef} updateData={updateData} />
      <CreateSubIncome registrationId={registrationId} ref={createSubIncomeRef} updateData={updateData} />
      {items.map((item) => {
        const { course, course_class } = item.info;
        const totalIncome = sumBy(item.subIncomes, (o) => (o.confirmed ? parseFloat(o.price) : 0));
        return (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 25 }}>
            <Title level={4} style={{ textAlign: 'center', marginBottom: 5 }}>
              {`${course.module_name}: ${course.title}`}
            </Title>
            <Text style={{ textAlign: 'center', marginBottom: 20 }}>
              {`Lớp học: ${course_class.class_type} - ${course_class.code} - ${moment(
                course_class.start_date
              ).format('DD-MM-YYYY')} - ${course_class.address}`}
            </Text>
            <Descriptions bordered column={4} layout="vertical">
              <Descriptions.Item label="Cần thu">
                <EditField
                  field="total_price"
                  defaultValueField="totalPrice"
                  item={item}
                  onEdit={editData}
                  updateData={updateData}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Đã thu">{numberToCurrency(totalIncome)}</Descriptions.Item>
              <Descriptions.Item label="Còn lại">
                {numberToCurrency(parseFloat(item.totalPrice) - totalIncome)}
              </Descriptions.Item>
              <Descriptions.Item label="Trạng thái">
                <EditStatusField item={item} onEdit={editData} updateData={updateData} />
              </Descriptions.Item>
              <Descriptions.Item span={6} label="Phiếu thu">
                {canModify && (
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Button
                      type="primary"
                      onClick={() => createSubIncomeRef.current.open(item.id)}
                      style={{ marginBottom: 10 }}
                    >
                      Tạo phiếu thu
                    </Button>
                  </div>
                )}
                <Table
                  dataSource={item.subIncomes}
                  columns={columns(onEdit, deleteSubIncome, onConfirm, canModify)}
                  pagination={false}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        );
      })}
    </>
  );
};

export default IncomeTab;
