import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import SelectSearch from '@components/SelectSearch';
import { fieldValidate } from '@shared/function';
import { onNewTopic } from '../saga';
import Upload from '@components/Upload';

const { Item } = Form;
const { Option } = Select;

const Create = React.forwardRef(({ fetchData, users }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmitting(false);
  };

  const createData = useCallback((params) => dispatch(onNewTopic(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmitting(true);

    const userForumTopicsAttributes = [];

    (values.student_ids || []).map((userId) => {
      userForumTopicsAttributes.push({
        user_id: userId,
        user_type: 'student'
      });
    });

    (values.employee_ids || []).map((userId) => {
      userForumTopicsAttributes.push({
        user_id: userId,
        user_type: 'employee'
      });
    });

    values.user_forum_topics_attributes = userForumTopicsAttributes;
    values.position = values.position || 0;

    createData(values);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  return (
    <Modal closable={false} visible={isShow} footer={null} title="Thêm danh mục" width={'70%'}>
      <Form layout="vertical" form={form} name="createTopic" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={12}>
            <Item name="position" label="Vị trí">
              <InputNumber placeholder="Vị trí" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Hình ảnh" name="image">
              <Upload onFileChange={(file) => form.setFieldsValue({ image: file })} />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="name" label="Tên" rules={[fieldValidate.required]}>
              <Input placeholder="Tên" />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="private" label="Loại" rules={[fieldValidate.required]}>
              <Select placeholder="Loại">
                <Option value="true">Chủ đề chuyên ngành</Option>
                <Option value="false">Chủ đề chung</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24}>
            <Item name="student_ids" label="Học viên">
              <SelectSearch placeholder="Học viên" mode="multiple">
                {users.students.map((user) => (
                  <Option value={user.id} key={user.id}>
                    {user.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={24}>
            <Item name="employee_ids" label="Nhân viên">
              <SelectSearch placeholder="Nhân viên" mode="multiple">
                {users.employees.map((user) => (
                  <Option value={user.id} key={user.id}>
                    {user.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
