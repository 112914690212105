import { post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';
import { history } from '../../vendor/history';

export const ON_SETUP_PASSWORD = 'ON_SETUP_PASSWORD';

function* setupPassword({ params, setShowError }) {
  try {
    yield call(post, '/current_user/info/setup_password', params);

    Notification.success({ message: 'Cập nhập mật khẩu thành công.' });
    history.push('/user/sign_in');
  } catch (error) {
    setShowError(true);
  }
}

export const onSetupPassword = (params, setShowError) => ({
  type: ON_SETUP_PASSWORD, params, setShowError,
});

export default function* loginWatcher() {
  yield takeLatest(ON_SETUP_PASSWORD, setupPassword);
}
