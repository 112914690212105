import React, {
  useCallback,
} from 'react';
import {
  CloseOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  Avatar, Typography, Popconfirm,
} from 'antd';
import { ChipWrapper } from './styles';
import { onRemoveCourseTeacher, onRemoveCourseManager } from '../../saga';

const { Text } = Typography;

const Chip = ({
  item, type, setData, courseId, isManager, isSupport,
}) => {
  const dispatch = useDispatch();

  const removeTeacher = useCallback((params) => dispatch(
    onRemoveCourseTeacher(params, setData),
  ), [dispatch]);

  const removeManager = useCallback((params) => dispatch(
    onRemoveCourseManager(params, setData),
  ), [dispatch]);

  return (
    <ChipWrapper>
      <Avatar src={item.avatarUrl} size={30} />
      <Text style={{ margin: '0 8px' }}>{item.name}</Text>
      {((isManager && type === 'support') || (!isManager && !isSupport)) && (
      <Popconfirm
        placement='top'
        title={`Bạn có muốn loại ${type === 'teacher' ? 'Giảng viên' : type === 'manager' ? 'Quản lý' : 'Trợ thủ'} này ra khỏi khóa học?`}
        onConfirm={() => (type === 'teacher' ? removeTeacher({
          id: courseId,
          course_teacher_id: item.id,
        }) : removeManager({
          id: courseId,
          course_manager_id: item.id,
        }))}
        okText='Có'
        cancelText='Không'
      >
        <CloseOutlined style={{
          cursor: 'pointer', background: '#9b9b9b', borderRadius: 25, padding: 5, color: 'white',
        }}
        />
      </Popconfirm>
      )}
    </ChipWrapper>
  );
};
export default Chip;
