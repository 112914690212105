import React, { useRef, useEffect, useState, useCallback, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  PauseCircleOutlined,
  EyeOutlined,
  DeliveredProcedureOutlined
} from '@ant-design/icons';
import { List, Typography, Tooltip, Popconfirm, Divider, Avatar, Checkbox, Space } from 'antd';
import { onGetTasks, onDeleteTask, onEditTask, onGetTask, onDuplicateTask } from '../saga';
import {
  StyledList,
  Wrapper,
  Header,
  Description,
  TimeWrapper,
  Time,
  TitleWrapper,
  StatusWrapper,
  ProgressWrapper,
  TextWrapper
} from '../styles';
import CreateTask from '../Modal/CreateTask';
import EditTask from '../Modal/EditTask';
import Preview from '../Modal/Preview';
import EditMyTask from '../Modal/MemberEditTask';
import StatusText from '../StatusText';

const { Title } = Typography;

const Task = React.forwardRef(({ currentFilter, employees }, ref) => {
  const location = useLocation();
  const createRef = useRef();
  const editRef = useRef();
  const previewRef = useRef();
  const editMyTaskRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
    items: [],
    currentPage: 0,
    totalPages: 0
  });
  const [project, setProject] = useState({});
  const [headerColor, setHeaderColor] = useState('');

  const { items, loading } = state;

  const callback = (status, response) => {
    if (status === 200) {
      setState({
        loading: false,
        items: response.items,
        totalPages: response.meta.totalPages,
        currentPage: response.meta.currentPage
      });
    } else {
      setState((state) => ({
        ...state,
        loading: false
      }));
    }
  };

  const showLoading = () =>
    setState((state) => ({
      ...state,
      loading: true
    }));

  const getData = useCallback((params) => dispatch(onGetTasks(params, callback)), [dispatch]);

  const getProject = useCallback(
    (id) =>
      dispatch(
        onGetTask(id, (status, response) => {
          if (status === 200) setProject(response);
        })
      ),
    [dispatch]
  );

  const fetchData = () => {
    if (currentFilter.current.sub_project_id) {
      showLoading();
      getData({ task_type: '2', parent_id: currentFilter.current.sub_project_id });
    }
  };

  const deleteData = useCallback((id) => dispatch(onDeleteTask(id, fetchData)), [dispatch]);

  const duplicateTask = useCallback((id) => dispatch(onDuplicateTask(id, fetchData)), [dispatch]);

  const changeStatus = useCallback((id, status) => dispatch(onEditTask({ id, status }, fetchData)), [
    dispatch
  ]);

  const actionGroup = (item) =>
    item.canModify
      ? [
          <Tooltip
            placement="bottom"
            title={item.status === 'complete' ? 'Đang thực hiện' : 'Xác nhận đã hoàn thành'}
          >
            <Popconfirm
              placement="top"
              title={
                item.status === 'complete'
                  ? 'Bạn có muốn chuyển thành trạng thái đang thực hiện'
                  : 'Bạn có muốn xác nhận sub-project này đã hoàn thành?'
              }
              onConfirm={() => changeStatus(item.id, item.status === 'complete' ? 'processing' : 'complete')}
              okText="Có"
              cancelText="Không"
            >
              {item.status !== 'complete' ? (
                <CheckOutlined style={{ cursor: 'pointer' }} />
              ) : (
                <PauseCircleOutlined style={{ cursor: 'pointer' }} />
              )}
            </Popconfirm>
          </Tooltip>,
          <Tooltip placement="bottom" title="Chỉnh sửa">
            <EditOutlined style={{ cursor: 'pointer' }} onClick={() => editRef.current.open(item)} />
          </Tooltip>,
          <Tooltip placement="bottom" title="Duplicate Task">
            <Popconfirm
              placement="top"
              title="Bạn có muốn Duplicate Task này không?"
              onConfirm={() => duplicateTask(item.id)}
              okText="Có"
              cancelText="Không"
            >
              <DeliveredProcedureOutlined style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </Tooltip>,
          <Tooltip placement="bottom" title="Xóa">
            <Popconfirm
              placement="top"
              title="Bạn có muốn xóa Sub Project này không?"
              onConfirm={() => deleteData(item.id)}
              okText="Có"
              cancelText="Không"
            >
              <DeleteOutlined style={{ cursor: 'pointer' }} />
            </Popconfirm>
          </Tooltip>
        ]
      : [
          item.myTask && (
            <Tooltip
              placement="bottom"
              title={item.status === 'complete' ? 'Đang thực hiện' : 'Xác nhận đã hoàn thành'}
            >
              <Popconfirm
                placement="top"
                title={
                  item.status === 'complete'
                    ? 'Bạn có muốn chuyển thành trạng thái đang thực hiện'
                    : 'Bạn có muốn xác nhận sub-project này đã hoàn thành?'
                }
                onConfirm={() =>
                  changeStatus(item.id, item.status === 'complete' ? 'processing' : 'complete')
                }
                okText="Có"
                cancelText="Không"
              >
                {item.status !== 'complete' ? (
                  <CheckOutlined style={{ cursor: 'pointer' }} />
                ) : (
                  <PauseCircleOutlined style={{ cursor: 'pointer' }} />
                )}
              </Popconfirm>
            </Tooltip>
          ),
          item.myTask && (
            <Tooltip placement="bottom" title="Chỉnh sửa">
              <EditOutlined style={{ cursor: 'pointer' }} onClick={() => editMyTaskRef.current.open(item)} />
            </Tooltip>
          ),
          <Tooltip placement="bottom" title="Xem chi tiết">
            <EyeOutlined style={{ cursor: 'pointer' }} onClick={() => previewRef.current.open(item)} />
          </Tooltip>
        ];

  useImperativeHandle(ref, () => ({
    emptyData: () => {
      setProject({});
      setState({
        loading: false,
        items: [],
        currentPage: 0,
        totalPages: 0
      });
    },
    refresh: () => {
      if (currentFilter.current.sub_project_id) {
        getData({ task_type: '2', parent_id: currentFilter.current.sub_project_id });
        getProject(currentFilter.current.sub_project_id);
      }
    },
    openCreateModal: (item) => createRef.current.open(item),
    changeColor: (color) => setHeaderColor(color)
  }));

  useEffect(() => {
    if (currentFilter.current.sub_project_id) {
      getData({ task_type: '2', parent_id: currentFilter.current.sub_project_id });
      getProject(currentFilter.current.sub_project_id);
    }
  }, [location.search]);

  return (
    <Wrapper>
      <Preview ref={previewRef} fetchData={fetchData} />
      <CreateTask ref={createRef} employees={employees} fetchData={fetchData} />
      <EditTask ref={editRef} employees={employees} fetchData={fetchData} />
      <EditMyTask ref={editMyTaskRef} fetchData={fetchData} />
      <Header color={headerColor}>
        <Title level={4} style={{ color: '#fff', marginBottom: 0 }}>{`${project.title || 'Task'} (${
          items.length
        })`}</Title>
      </Header>
      <StyledList
        itemLayout="horizontal"
        dataSource={items}
        loading={loading}
        renderItem={(item) => {
          const doneQuantity = item.checklist
            ? item.checklist.filter((task) => task.checked === 'true').length
            : 0;

          return (
            <List.Item key={item.key} actions={actionGroup(item)}>
              <List.Item.Meta
                onClick={() => previewRef.current.open(item)}
                title={
                  <TitleWrapper>
                    <StatusWrapper>
                      <StatusText status={item.status} />
                      {item.checklist && item.checklist.length > 0 && (
                        <ProgressWrapper>
                          <Checkbox checked={true}>{`${doneQuantity} / ${item.checklist.length}`}</Checkbox>
                        </ProgressWrapper>
                      )}
                    </StatusWrapper>
                    <TextWrapper style={{ marginBottom: 10 }}>{item.title}</TextWrapper>
                  </TitleWrapper>
                }
                description={
                  <Description>
                    <Space split={<Divider type="vertical" />}>
                      <TimeWrapper>
                        <ClockCircleOutlined />
                        <Time>{item.datetime}</Time>
                      </TimeWrapper>
                      <Avatar.Group
                        style={{ marginLeft: 10 }}
                        maxCount={1}
                        maxStyle={{
                          color: '#f56a00',
                          backgroundColor: '#fde3cf',
                          width: 25,
                          height: 25,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {item.assigneeInfo.map((assignee) => (
                          <Tooltip key={assignee.name} title={assignee.name} placement="top">
                            <Avatar src={assignee.avatarUrl} size={25} />
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    </Space>
                  </Description>
                }
              />
            </List.Item>
          );
        }}
      />
    </Wrapper>
  );
});

export default Task;
