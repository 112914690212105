import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  EditOutlined, DeleteOutlined, PlusOutlined,
  CheckOutlined, ClockCircleOutlined, PauseCircleOutlined, EyeOutlined, PlusSquareOutlined,
} from '@ant-design/icons';
import {
  List, Typography, Tooltip, Button, Popconfirm, Space, Divider, Avatar, Popover,
} from 'antd';
import {
  StyledList, Wrapper, Header, Owner, Label, Description, TitleWrapper, TimeWrapper, Time, StatusWrapper,
} from '../styles';
import CreateProject from '../Modal/CreateProject';
import EditProject from '../Modal/EditProject';
import PreviewProject from '../Modal/PreviewProject';
import { onGetTasks, onDeleteTask, onEditTask } from '../saga';
import StatusText from '../StatusText';

const { Title } = Typography;

const Project = ({
  employees, changeLocation, currentFilter, subProjectRef, taskRef,
}) => {
  const previewRef = useRef();
  const createRef = useRef();
  const editRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false, items: [], currentPage: 0, totalPages: 0,
  });
  const [selected, setSelected] = useState(currentFilter.current.project_id);
  const {
    items, loading,
  } = state;

  const callback = (status, response) => {
    if (status === 200) {
      setState({
        loading: false,
        items: response.items,
        totalPages: response.meta.totalPages,
        currentPage: response.meta.currentPage,
      });
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
  };

  const showLoading = () => setState((state) => ({
    ...state,
    loading: true,
  }));

  const getData = useCallback((params) => dispatch(
    onGetTasks(params, callback),
  ), [dispatch]);

  const fetchData = (response) => {
    showLoading();
    getData({ task_type: '0' });

    if (response && response.id === parseInt(currentFilter.current.project_id, 10)) {
      subProjectRef.current.refresh();
      taskRef.current.refresh();
    }
  };

  const deleteData = useCallback((id) => dispatch(
    onDeleteTask(id, () => {
      if (id === parseInt(currentFilter.current.project_id, 10)) {
        currentFilter.current.project_id = '';
        currentFilter.current.sub_project_id = '';
        changeLocation();
        subProjectRef.current.emptyData();
        taskRef.current.emptyData();
      }
      fetchData();
    }),
  ), [dispatch]);

  const changeStatus = useCallback((id, status) => dispatch(
    onEditTask({ id, status }, fetchData),
  ), [dispatch]);

  useEffect(() => {
    getData({ task_type: '0' });
  }, []);

  useEffect(() => {
    if (selected) {
      const selectedItem = items.filter((item) => item.id === parseInt(selected, 10));
      if (selectedItem[0]) taskRef.current.changeColor(selectedItem[0].color);
    }
  }, [selected, items]);

  return (
    <Wrapper>
      <PreviewProject ref={previewRef} />
      <CreateProject ref={createRef} employees={employees} fetchData={fetchData} />
      <EditProject
        ref={editRef}
        employees={employees}
        fetchData={fetchData}
      />
      <Header>
        <Title level={4} style={{ color: '#fff', marginBottom: 0 }}>{`Project (${items.length})`}</Title>
        <Button icon={<PlusOutlined />} onClick={() => createRef.current.open()} />
      </Header>
      <StyledList
        itemLayout='horizontal'
        dataSource={items}
        loading={loading}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            className={parseInt(selected, 10) === item.id && 'active'}
            actions={item.canModify ? [
              <Tooltip placement='bottom' title={item.status === 'complete' ? 'Đang thực hiện' : 'Xác nhận đã hoàn thành'}>
                <Popconfirm
                  placement='top'
                  title={item.status === 'complete' ? 'Bạn có muốn chuyển thành trạng thái đang thực hiện' : 'Bạn có muốn xác nhận project này đã hoàn thành?'}
                  onConfirm={() => changeStatus(item.id, item.status === 'complete' ? 'processing' : 'complete')}
                  okText='Có'
                  cancelText='Không'
                >
                  {item.status !== 'complete' ? <CheckOutlined style={{ cursor: 'pointer' }} /> : <PauseCircleOutlined style={{ cursor: 'pointer' }} />}
                </Popconfirm>
              </Tooltip>,
              <Tooltip placement='bottom' title='Chỉnh sửa'>
                <EditOutlined style={{ cursor: 'pointer' }} onClick={() => editRef.current.open(item)} />
              </Tooltip>,
              <Tooltip placement='bottom' title='Xóa'>
                <Popconfirm placement='top' title='Bạn có muốn xóa project này không?' onConfirm={() => deleteData(item.id)} okText='Có' cancelText='Không'>
                  <DeleteOutlined style={{ cursor: 'pointer' }} />
                </Popconfirm>
              </Tooltip>,
              <Tooltip placement='bottom' title='Thêm Sub Project'>
                <PlusSquareOutlined style={{ cursor: 'pointer' }} onClick={() => subProjectRef.current.openCreateModal(item)} />
              </Tooltip>,
            ] : [
              <Tooltip placement='bottom' title='Xem chi tiết'>
                <EyeOutlined onClick={() => previewRef.current.open(item)} />
              </Tooltip>,
              item.myTask && (
                <Tooltip placement='bottom' title='Thêm Sub Project'>
                  <PlusSquareOutlined style={{ cursor: 'pointer' }} onClick={() => subProjectRef.current.openCreateModal(item)} />
                </Tooltip>
              ),
            ]}
          >
            <Popover
              content={
                (
                  <Description>
                    <Space split={<Divider type='vertical' />}>
                      <Owner>{`Tạo bởi: ${item.ownerInfo ? item.ownerInfo.name : ''}`}</Owner>
                      <Owner>
                        Nhân viên:
                        <Avatar.Group
                          style={{ marginLeft: 10 }}
                          maxCount={1}
                          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                        >
                          {item.assigneeInfo.map((assignee) => (
                            <Tooltip key={assignee.name} title={assignee.name} placement='top'>
                              <Avatar src={assignee.avatarUrl} />
                            </Tooltip>
                          ))}
                        </Avatar.Group>
                      </Owner>
                      {item.time && (
                      <TimeWrapper>
                        <ClockCircleOutlined />
                        <Time>{item.time}</Time>
                      </TimeWrapper>
                      )}
                    </Space>
                  </Description>
                )
              }
              title='Thông tin'
            >
              <List.Item.Meta
                onClick={() => {
                  currentFilter.current.project_id = item.id;
                  currentFilter.current.sub_project_id = '';
                  taskRef.current.emptyData();
                  setSelected(item.id);
                  changeLocation();
                }}
                title={(
                  <TitleWrapper>
                    <StatusWrapper>
                      {item.color && <Label color={item.color} />}
                      <StatusText status={item.status} />
                    </StatusWrapper>
                    <div>{item.title}</div>
                  </TitleWrapper>
                )}
              />
            </Popover>

          </List.Item>
        )}
      />
    </Wrapper>
  );
};

export default Project;
