import React, { useCallback, useState, useImperativeHandle, useRef } from 'react';
import {
  LikeOutlined,
  CommentOutlined,
  DeleteOutlined,
  SmileOutlined,
  SendOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { List, Typography, Avatar, Tag, Button, Row, Col, Divider } from 'antd';
import {
  StyledCard,
  ActionWrapper,
  StyledModal,
  StyledComment,
  HasTagWrapper,
  ContentWrapper,
  StyledList,
  CommentWrapper,
  InputChat,
  Input,
  StyledCloseCircleOutlined,
  PickerWrapper,
  StyledInputComment
} from './styles';
import { Picker } from 'emoji-mart';
import { fallbackImage } from '../../../../../shared/function';
import { onRemoveComment, onCreatePostComment } from '../../saga';

const { Text, Title } = Typography;
const actionIconStyle = { fontSize: 18, cursor: 'pointer' };

const InfoModal = React.forwardRef(({ removeCommentData, item }, ref) => {
  const inputRef = useRef();
  const [comments, setComment] = useState(item.comments);
  const user = useSelector((state) => state.user.data);
  const [isShow, setShowModal] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const dispatch = useDispatch();
  const close = () => {
    setShowModal(false);
  };

  const callback = (commentId) => {
    removeCommentData(commentId);
    setComment((state) => state.filter((a) => a.id !== commentId));
  };

  const removeComment = useCallback((params) => dispatch(onRemoveComment(params, callback)), [dispatch]);

  const commentCallback = (response) => {
    setComment((state) => [response].concat(state));
  };

  const createComment = useCallback((params) => dispatch(onCreatePostComment(params, commentCallback)), [
    dispatch
  ]);

  const sendMessage = () => {
    setShowEmoji(false);
    if (inputRef.current.innerHTML) {
      createComment({ forum_comment: { forum_post_id: item.id, content: inputRef.current.innerHTML } });
      inputRef.current.innerHTML = '';
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const onInputChange = (e) => {
    const value = inputRef.current.innerHTML;

    if (value) {
      inputRef.current.classList.remove('custom-input');
    } else {
      inputRef.current.classList.add('custom-input');
    }
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  return (
    <StyledModal onCancel={close} closable={true} visible={isShow} footer={null}>
      <Row>
        <Col span={24}>
          <StyledCard
            actions={[
              <ActionWrapper>
                <LikeOutlined style={actionIconStyle} />
                <Text style={{ marginLeft: 5 }}>{`Thích (${item.likes})`}</Text>
              </ActionWrapper>,
              <ActionWrapper>
                <CommentOutlined style={actionIconStyle} />
                <Text style={{ marginLeft: 5 }}>{`Bình luận (${item.comments.length})`}</Text>
              </ActionWrapper>
            ]}
          >
            <Title level={4} style={{ padding: '15px 15px', margin: 0 }}>
              {item.title}
            </Title>
            <ContentWrapper dangerouslySetInnerHTML={{ __html: item.content }} className="ck-content" />
            <Divider style={{ margin: 0 }} />
            {item.hashtag.length > 0 && (
              <HasTagWrapper>
                {item.hashtag.map((hashtag) => (
                  <Tag style={{ marginTop: 5, marginRight: 5 }} color="#393939">
                    {hashtag}
                  </Tag>
                ))}
              </HasTagWrapper>
            )}
          </StyledCard>
        </Col>
        <Col span={24}>
          <StyledList
            itemLayout="horizontal"
            dataSource={comments}
            renderItem={(item) => (
              <List.Item>
                <StyledComment
                  actions={[
                    <ActionWrapper onClick={() => removeComment({ id: item.id })}>
                      <DeleteOutlined style={{ ...actionIconStyle, color: 'red' }} />
                      <Text style={{ marginLeft: 5, color: 'red' }}>Xóa bình luận</Text>
                    </ActionWrapper>
                  ]}
                  author={<Text strong>{item.name}</Text>}
                  avatar={<Avatar src={item.avatar} alt={item.name} />}
                  content={
                    <p
                      style={{ whiteSpace: 'pre-line' }}
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  }
                  datetime={<span>{item.createdAt}</span>}
                />
              </List.Item>
            )}
          />
          <StyledInputComment
            className="comment-input"
            avatar={<Avatar size={40} src={user.avatarUrl || fallbackImage} />}
            content={
              <CommentWrapper>
                <InputChat>
                  <Input
                    ref={inputRef}
                    placeholder="Nhập tin nhắn"
                    rows={1}
                    className="custom-input"
                    onKeyPress={onKeyPress}
                    onInput={onInputChange}
                  />
                  <SmileOutlined
                    style={{ fontSize: 20, marginRight: 10, cursor: 'pointer' }}
                    onClick={() => setShowEmoji(true)}
                  />
                  <SendOutlined style={{ fontSize: 20, cursor: 'pointer' }} onClick={() => sendMessage()} />
                </InputChat>
                {showEmoji && (
                  <PickerWrapper>
                    <StyledCloseCircleOutlined onClick={() => setShowEmoji(false)} />
                    <Picker
                      showPreview={false}
                      onSelect={(value) => {
                        inputRef.current.innerHTML = `${inputRef.current.innerHTML} ${value.native} `;
                        inputRef.current.classList.remove('custom-input');
                      }}
                    />
                  </PickerWrapper>
                )}
              </CommentWrapper>
            }
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button size="large" style={{ width: 150 }} onClick={close}>
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default InfoModal;
