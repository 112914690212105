import styled, { css } from 'styled-components';
import { Modal } from 'antd';

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 300px;
  }
`;

export const StyledModal = styled(Modal)`
  width: 1140px !important;

  @media (max-width: 1140px) {
    width: 1024px !important;
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media print{
     padding: 50px;
  }
`;

export const ClinicInfo = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #bbbbbb;
`;

export const InforWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoItem = styled.div`
  display: flex;
  margin-top: 5px;
`;
