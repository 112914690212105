import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, Table, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import SelectSearch from '../../../../components/SelectSearch';
import { removeCurrency, fieldValidate, numberToCurrency } from '../../../../shared/function';
import { onEditMembership, onGetMembership } from '../saga';

const { Item } = Form;
const { Option } = Select;

const columns = [
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Giá',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note'
  },
  {
    title: 'Tạo bởi',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: 'Thu lúc',
    dataIndex: 'createdAt',
    key: 'createdAt'
  }
];

const Edit = React.forwardRef(({ fetchData, courses }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onEditMembership(params, callback)), [dispatch]);

  const getData = useCallback(
    (id) =>
      dispatch(
        onGetMembership(id, (response) => {
          setState((state) => ({ ...state, item: response }));
          form.setFieldsValue({
            ...response,
            member_type: response.memberType,
            old_amount: numberToCurrency(response.oldAmount)
          });
        })
      ),
    [dispatch]
  );

  const onFinish = (values) => {
    setSubmiting(true);
    values.old_amount = removeCurrency(values.old_amount);
    editData({ membership: values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      getData(item.id);
      setState((state) => ({ ...state, status: true }));
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} title="Chỉnh sửa phân hạng" width={992}>
      <Form layout="vertical" form={form} name="editMembership" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={12}>
            <Item name="member_type" rules={[fieldValidate.required]} label="Loại">
              <Select placeholder="Loại">
                <Option value="platinum">Platinum</Option>
                <Option value="gold">Gold</Option>
                <Option value="silver">Silver</Option>
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item name="old_amount" label="Số tiền đã đóng từ trước">
              <Input
                placeholder="Số tiền đã đóng từ trước"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    old_amount: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="note" label="Ghi chú">
              <Input.TextArea placeholder="Ghi chú" rows={3} />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="course" label="Khóa học đã học">
              <SelectSearch placeholder="Khóa học đã học" mode="multiple">
                {courses.map((course) => (
                  <Option
                    value={`${course.module_name}: ${course.title}`}
                  >{`${course.module_name}: ${course.title}`}</Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={24}>
            {(item.incomes || []).map((income) => {
              const { course, course_class } = income.info;

              return (
                income.subIncomes.length > 0 && (
                  <Table
                    title={() => (
                      <Typography.Text strong>
                        {`${course.module_name}: ${course.title} - ${course_class.start_date} - ${course_class.address}`}
                      </Typography.Text>
                    )}
                    dataSource={income.subIncomes}
                    columns={columns}
                    pagination={false}
                  />
                )
              );
            })}
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
