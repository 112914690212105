import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Row, Form, Modal, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { onUpdateForumTopic } from '../saga';
import { onGetTopics } from '../../Forum/saga';
import SelectSearch from '../../../../components/SelectSearch';

const { Item } = Form;
const { Option } = Select;

const AddForumTopic = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const [topics, setTopics] = useState([]);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const getTopics = useCallback(
    (params) =>
      dispatch(
        onGetTopics(params, (response) => {
          setTopics(response.items);
        })
      ),
    [dispatch]
  );

  const editData = useCallback((params) => dispatch(onUpdateForumTopic(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const selectTopic = values.topics;

    const newTopic = [];
    const removeTopic = [];

    (selectTopic || []).map((id) => {
      const filterd = item.userForumTopics.filter((a) => a.forum_topic_id === parseInt(id, 10))[0];

      if (!filterd) newTopic.push({ forum_topic_id: id, user_type: 'student' });
    });

    item.userForumTopics.map((userTopic) => {
      if (!selectTopic.includes(userTopic.forum_topic_id)) {
        removeTopic.push({
          _destroy: true,
          id: userTopic.id,
          forum_topic_id: userTopic.forum_topic_id
        });
      }
    });

    editData({ user: { user_forum_topics_attributes: newTopic.concat(removeTopic) }, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        topics: item.userForumTopics.map((a) => a.forum_topic_id)
      });

      getTopics({ type: true });
      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} title="Thêm chủ đề diễn đàn">
      <Form layout="vertical" form={form} name="addHashtag" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Item name="topics">
              <SelectSearch placeholder="Chủ đề" mode="multiple">
                {topics.map((topic) => (
                  <Option value={topic.id} key={topic.id}>
                    {topic.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default AddForumTopic;
