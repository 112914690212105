import React, {
  useState, useRef,
} from 'react';
import {
  EditOutlined,
} from '@ant-design/icons';
import {
  Typography, Avatar,
} from 'antd';
import {
  ItemWrapper,
} from '../styles';

import DocumentViewModal from './Modal';

const { Text } = Typography;

const editIconStyle = { marginLeft: 10, fontSize: 18, cursor: 'pointer' };
const FileComponent = ({ fileType }) => {
  switch (fileType) {
    case 'image':
      return <Avatar src='/images/icon/image.png' />;
    case 'pdf':
      return <Avatar src='/images/icon/pdf.png' />;
    case 'word':
      return <Avatar src='/images/icon/word.png' />;
    case 'excel':
      return <Avatar src='/images/icon/excel.png' />;
    default:
      return null;
  }
};
const DocumentComponent = ({ item }) => {
  const [data, setData] = useState(item.courseDocuments || []);
  const documentRef = useRef();

  return (
    <>
      <DocumentViewModal ref={documentRef} courseId={item.id} setData={setData} />
      <ItemWrapper>
        <Text strong style={{ marginRight: 5 }}>Tài liệu chung:</Text>
        <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {data.map((file) => <FileComponent fileType={file.fileType} key={file.id} />)}
        </Avatar.Group>
        <EditOutlined style={editIconStyle} onClick={() => documentRef.current.open()} />
      </ItemWrapper>
    </>
  );
};
export default DocumentComponent;
