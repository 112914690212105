import {
  post, get, put, delete as axiosDelete,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { history } from '../../../vendor/history';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_QUESTION = 'ON_GET_QUESTION';
const ON_CREATE_QUESTION = 'ON_CREATE_QUESTION';
const ON_EDIT_QUESTION = 'ON_EDIT_QUESTION';
const ON_DELETE_QUESTION = 'ON_DELETE_QUESTION';

function* getQuestions({ params, setData }) {
  try {
    const response = yield call(get, '/questions', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* deleteQuestion({ id, callback }) {
  try {
    yield call(axiosDelete, `/questions/${id}`);

    Notification.success({ message: 'Xóa câu hỏi thành công.' });
    callback();
  } catch (error) {
    console.log(error);
  }
}

function* createQuestion({ params, callback }) {
  try {
    yield call(post, '/questions', { question: { ...params } });

    Notification.success({ message: 'Tạo câu hỏi thành công' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* editQuestion({ params, callback }) {
  try {
    yield call(put, `/questions/${params.id}`, { question: { ...params } });
    Notification.success({ message: 'Chỉnh sửa câu hỏi thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

export const onEditQuestion = (params, callback) => ({
  type: ON_EDIT_QUESTION, params, callback,
});

export const onCreateQuestion = (params, callback) => ({
  type: ON_CREATE_QUESTION, params, callback,
});

export const onGetQuestions = (params, setData) => ({
  type: ON_GET_QUESTION, params, setData,
});

export const onDeleteQuestion = (id, callback) => ({
  type: ON_DELETE_QUESTION, id, callback,
});

export default function* blogWatcher() {
  yield takeLatest(ON_DELETE_QUESTION, deleteQuestion);
  yield takeLatest(ON_GET_QUESTION, getQuestions);
  yield takeLatest(ON_CREATE_QUESTION, createQuestion);
  yield takeLatest(ON_EDIT_QUESTION, editQuestion);
}
