import React, { useState, useEffect, useCallback, useRef } from 'react';
import queryString from 'query-string';
import { Row, Col, Typography, Spin, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import { onGetStatistic } from './saga';
import { onGetCompanyCategories } from '../CompanyCategories/saga';
import Filter from './Filter';
import { numberToCurrency } from '../../../shared/function';
import { Column } from '@ant-design/charts';
import Income from './Income';
import Expense from './Expense';

const { TabPane } = Tabs;
const { Text } = Typography;

const config = (data) => ({
  data: data,
  xField: 'label',
  yField: 'value',
  seriesField: 'label',
  label: {
    layout: [
      { type: 'interval-adjust-position' },
      { type: 'interval-hide-overlap' },
      { type: 'adjust-color' }
    ],
    position: 'middle',
    style: {
      fill: '#FFFFFF',
      opacity: 0.6
    },
    formatter: (a) => {
      return numberToCurrency(a.value);
    }
  }
});

const Debts = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState({});
  const [incomeCategories, setIncomeCategories] = useState([]);
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [state, setState] = useState({
    loading: true,
    studentDebts: 0,
    empployeeDebts: 0,
    totalExpenses: 0,
    totalIncome: 0
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const { studentDebts, empployeeDebts, totalExpenses, totalIncome, loading } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetStatistic(params, setState)), [dispatch]);
  const getCategory = useCallback(
    (params, setData) => dispatch(onGetCompanyCategories(params, ({ items }) => setData(items))),
    [dispatch]
  );

  const changeLocation = () => {
    history.push({
      pathname: '/admin/statistic',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);
    setSearchParams(currentFilter.current);
    fetchData();
  }, [location.search]);

  useEffect(() => {
    getCategory({ category_type: '0' }, setIncomeCategories);
    getCategory({ category_type: '1' }, setExpenseCategories);
  }, []);

  const filtering =
    searchParams.income_category ||
    searchParams.expense_category ||
    searchParams.keyword ||
    searchParams.time_gteq;

  const data = filtering
    ? [
        {
          label: 'Tổng thu',
          value: totalIncome
        },
        {
          label: 'Tổng chi',
          value: totalExpenses
        },
        {
          label: 'Tổng doanh thu',
          value: totalIncome - totalExpenses
        }
      ]
    : [
        {
          label: 'Tổng thu',
          value: totalIncome
        },
        {
          label: 'Tổng chi',
          value: totalExpenses
        },
        {
          label: 'Tổng doanh thu',
          value: totalIncome - totalExpenses
        },
        {
          label: 'Công nợ',
          value: studentDebts + empployeeDebts
        }
      ];

  return (
    <>
      <Filter
        currentFilter={currentFilter}
        changeLocation={changeLocation}
        incomeCategories={incomeCategories}
        expenseCategories={expenseCategories}
      />
      <Spin spinning={loading} tip="Đang tải dữ liệu...">
        <Row style={{ padding: 25, margin: '25px 0', background: '#fff' }}>
          <Col span={12}>
            <Column {...config(data)} />
          </Col>
          <Col span={12}>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                padding: 100
              }}
            >
              <Col span={24}>
                <Text strong style={{ fontSize: 20 }}>
                  Tổng thu:
                </Text>
                <Text style={{ fontSize: 20, marginLeft: 10 }}>{numberToCurrency(totalIncome)}</Text>
              </Col>
              <Col span={24}>
                <Text strong style={{ fontSize: 20 }}>
                  Tổng chi:
                </Text>
                <Text style={{ fontSize: 20, marginLeft: 10 }}>{numberToCurrency(totalExpenses)}</Text>
              </Col>
              <Col span={24}>
                <Text strong style={{ fontSize: 20 }}>
                  Tổng doanh thu:
                </Text>
                <Text style={{ fontSize: 20, marginLeft: 10 }}>
                  {numberToCurrency(totalIncome - totalExpenses)}
                </Text>
              </Col>
              {!filtering && (
                <Col span={24}>
                  <div>
                    <Text strong style={{ fontSize: 20 }}>
                      Tổng Công nợ:
                    </Text>
                    <Text style={{ fontSize: 20, marginLeft: 10 }}>
                      {numberToCurrency(studentDebts + empployeeDebts)}
                    </Text>
                  </div>
                  <ul>
                    <li>
                      <Text strong>Công nợ học viên:</Text>
                      <Text style={{ marginLeft: 10 }}>{numberToCurrency(studentDebts)}</Text>
                    </li>
                    <li>
                      <Text strong>Công nợ nhân viên:</Text>
                      <Text style={{ marginLeft: 10 }}>{numberToCurrency(empployeeDebts)}</Text>
                    </li>
                  </ul>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        {filtering && (
          <Row gutter={24}>
            <Col span={24}>
              <Tabs defaultActiveKey="income">
                <TabPane tab="Danh sách phiếu thu" key="income">
                  <Income searchParams={searchParams} />
                </TabPane>
                <TabPane tab="Danh sách phiếu chi" key="expense">
                  <Expense searchParams={searchParams} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        )}
      </Spin>
    </>
  );
};

export default Debts;
