import React from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';

export const StatusType = ({ status }) => {
  switch (status) {
    case 'pending':
      return (
        <Tag color='gray'>Chưa phản hồi</Tag>
      );
    case 'responded':
      return (
        <Tag color='green'>Đã gửi mail</Tag>
      );
    default:
      return null;
  }
};

export const columns = (replyEmail) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {},

  },
  {
    title: 'Họ và tên',
    dataIndex: 'fullName',
    key: 'full_name',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    sorter: {},
  },
  {
    title: 'Nội dung',
    dataIndex: 'content',
    key: 'content',
    sorter: {},
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    sorter: {},
    render: (status) => <StatusType status={status} />,
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {},
  },
  {
    title: '',
    width: 120,
    render: (item) => (
      <Tooltip title='Trả lời ngay' color='geekblue' key='geekblue'>
        <SendOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => replyEmail(item)} />
      </Tooltip>
    ),
  },
];

export default columns;
