import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_TOPICS = 'ON_GET_TOPICS';
const ON_EDIT_TOPIC = 'ON_EDIT_TOPIC';
const ON_NEW_TOPIC = 'ON_NEW_TOPIC';
const ON_REMOVE_TOPIC = 'ON_REMOVE_TOPIC';

function* getTopics({ params, setData }) {
  try {
    const response = yield call(get, '/course_topics', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* editTopic({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('course_topic[name]', params.name);
    formData.append('course_topic[description]', params.description);
    if (params.image) formData.append('course_topic[image]', params.image);

    yield call(axiosPut, `/course_topics/${params.id}`, formData);
    Notification.success({ message: 'Chỉnh sửa chủ đề khoá học thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newTopic({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('course_topic[name]', params.name);
    formData.append('course_topic[description]', params.description);
    if (params.image) formData.append('course_topic[image]', params.image);

    yield call(post, '/course_topics', formData);
    Notification.success({ message: 'Thêm chủ đề khoá học thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeTopic({ params, callback }) {
  try {
    yield call(axiosDelete, `/course_topics/${params.id}`);
    Notification.success({ message: 'Xóa chủ đề khoá học thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewTopic = (params, callback) => ({
  type: ON_NEW_TOPIC,
  params,
  callback
});

export const onEditTopic = (params, callback) => ({
  type: ON_EDIT_TOPIC,
  params,
  callback
});

export const onGetTopics = (params, setData) => ({
  type: ON_GET_TOPICS,
  params,
  setData
});

export const onDeleteTopic = (params, callback) => ({
  type: ON_REMOVE_TOPIC,
  params,
  callback
});

export default function* TopicWatcher() {
  yield takeLatest(ON_GET_TOPICS, getTopics);
  yield takeLatest(ON_EDIT_TOPIC, editTopic);
  yield takeLatest(ON_NEW_TOPIC, newTopic);
  yield takeLatest(ON_REMOVE_TOPIC, removeTopic);
}
