import React from 'react';
import {
  WechatOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  FileWordOutlined,
  LaptopOutlined,
  WhatsAppOutlined,
  NotificationOutlined
} from '@ant-design/icons';

export const employeePath = ['/admin/employees', '/admin/teachers'];

export const studentPath = ['/admin/students', '/admin/debts', '/admin/memberships'];

export const coursePath = [
  '/admin/courses',
  '/admin/course-topics',
  '/admin/registration-infos',
  '/admin/combo-promotions',
  '/admin/group-promotions',
  '/admin/gifts'
];

export const myManagementCoursePath = [
  '/admin/my-management-courses',
  '/admin/my-management-registration-info',
  '/admin/my-course-salers'
];

export const promotionPath = ['/admin/combo-promotions', '/admin/group-promotions'];

export const newsPath = [
  '/admin/news-promotions',
  '/admin/news',
  '/admin/hashtags',
  '/admin/forum-topics',
  '/admin/forums'
];

export const questionsPath = ['/admin/questions', '/admin/user-questions'];

export const mySupportCoursePath = ['/admin/my-support-courses', '/admin/my-support-registration-info'];

export const accountantPath = [
  '/admin/sales',
  '/admin/registration-infos',
  '/admin/company-incomes',
  '/admin/company-expenses',
  '/admin/employee-debts',
  '/admin/company-categories',
  '/admin/statistic'
];

export const notificationRoutes = [
  {
    pathname: '/admin/notifications',
    icon: <NotificationOutlined style={{ fontSize: 20 }} />,
    label: 'Quản lí thông báo'
  }
];

export const workingRoutes = [
  {
    pathname: '/admin/user-tasks',
    icon: <LaptopOutlined style={{ fontSize: 20 }} />,
    label: 'Quản lí công việc'
  }
];

export const adminRoutes = [
  {
    pathname: '/admin/employees',
    icon: <TeamOutlined style={{ fontSize: 20 }} />,
    label: 'Quản lí nhân sự'
  },
  {
    pathname: 'courses',
    icon: <FileWordOutlined style={{ fontSize: 20 }} />,
    label: 'Quản lí khóa học',
    children: [
      {
        pathname: '/admin/gifts',
        label: 'Danh sách quà tặng'
      },
      {
        pathname: '/admin/combo-promotions',
        label: 'Khuyến mãi theo combo'
      },
      {
        pathname: '/admin/group-promotions',
        label: 'Khuyến mãi theo nhóm'
      },
      {
        pathname: '/admin/course-topics',
        label: 'Danh sách chủ đề'
      },
      {
        pathname: '/admin/courses',
        label: 'Danh sách khóa học'
      },
      {
        pathname: '/admin/registration-infos',
        label: 'Danh sách đăng kí khóa học'
      }
    ]
  }
];

export const contentRoutes = [
  {
    pathname: 'news',
    icon: <FileWordOutlined style={{ fontSize: 20 }} />,
    label: 'Bài viết & Diễn đàn',
    groupChildren: [
      {
        title: 'Diễn đàn',
        keys: 'forums',
        children: [
          {
            pathname: '/admin/forum-members',
            label: 'Thành viên diễn đàn'
          },
          {
            pathname: '/admin/forum-topics',
            label: 'Chủ đề'
          },
          {
            pathname: '/admin/forums',
            label: 'Bài viết'
          }
        ]
      },
      {
        title: 'Chuyên trang',
        keys: 'news',
        children: [
          {
            pathname: '/admin/hashtags',
            label: 'Quản lí Hashtag'
          },
          {
            pathname: '/admin/news',
            label: 'Bài viết chuyên ngành'
          },
          {
            pathname: '/admin/news-promotions',
            label: 'Khuyễn mãi / Ưu đãi'
          }
        ]
      }
    ]
  },
  {
    pathname: 'questions',
    icon: <FileWordOutlined style={{ fontSize: 20 }} />,
    label: 'Q & A',
    children: [
      {
        pathname: '/admin/questions',
        label: 'Danh sách câu hỏi'
      },
      {
        pathname: '/admin/user-questions',
        label: 'Câu hỏi khách hàng'
      }
    ]
  }
];

export const accountantRoutes = [
  {
    pathname: 'accountants',
    icon: <FileWordOutlined style={{ fontSize: 20 }} />,
    label: 'Quản lí tài chính',
    children: [
      {
        pathname: '/admin/sales',
        label: 'Quản lí sale'
      },
      {
        pathname: '/admin/company-categories',
        label: 'Mã phiếu Thu & Chi'
      },
      {
        pathname: '/admin/company-expenses',
        label: 'Phiếu chi'
      },
      {
        pathname: '/admin/registration-infos',
        label: 'Phiếu thu khóa học'
      },
      {
        pathname: '/admin/company-incomes',
        label: 'Phiếu thu'
      },
      {
        pathname: '/admin/employee-debts',
        label: 'Công nợ nhân viên'
      },
      {
        pathname: '/admin/statistic',
        label: 'Thống kê'
      }
    ]
  }
];

export const studentRoutes = [
  {
    pathname: 'students',
    icon: <UsergroupAddOutlined style={{ fontSize: 20 }} />,
    label: 'Quản lí học viên',
    children: [
      {
        pathname: '/admin/students',
        label: 'Danh sách học viên'
      },
      {
        pathname: '/admin/memberships',
        label: 'Phân hạng học viên'
      },
      {
        pathname: '/admin/debts',
        label: 'Công nợ'
      }
    ]
  }
];

export const supportRoutes = [
  {
    pathname: 'mySupportCourses',
    icon: <FileWordOutlined style={{ fontSize: 20 }} />,
    label: 'Khóa học đang hỗ trợ',
    children: [
      {
        pathname: '/admin/my-support-courses',
        label: 'Danh sách khóa học'
      },
      {
        pathname: '/admin/my-support-registration-info',
        label: 'Danh sách đăng kí khóa học'
      }
    ]
  }
];

export const courseManagerRoutes = [
  {
    pathname: 'myCourses',
    icon: <FileWordOutlined style={{ fontSize: 20 }} />,
    label: 'Khóa học đang quản lí',
    children: [
      {
        pathname: '/admin/gifts',
        label: 'Danh sách quà tặng'
      },
      {
        pathname: '/admin/combo-promotions',
        label: 'Khuyến mãi theo combo'
      },
      {
        pathname: '/admin/group-promotions',
        label: 'Khuyến mãi theo nhóm'
      },
      {
        pathname: '/admin/course-topics',
        label: 'Danh sách chủ đề'
      },
      {
        pathname: '/admin/my-course-salers',
        label: 'Quản lí sale'
      },
      {
        pathname: '/admin/my-management-courses',
        label: 'Danh sách khóa học'
      },
      {
        pathname: '/admin/my-management-registration-info',
        label: 'Danh sách đăng kí khóa học'
      }
    ]
  }
];

export const salerRoutes = [
  {
    pathname: '/admin/my-sales',
    icon: <WhatsAppOutlined style={{ fontSize: 20 }} />,
    label: 'Khóa học tôi đang sale'
  }
];

export const saleManagerRoutes = [
  {
    pathname: '/admin/sales',
    icon: <WechatOutlined style={{ fontSize: 20 }} />,
    label: 'Quản lí sale'
  }
];
