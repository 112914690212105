import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Avatar, Skeleton } from 'antd';
import { BellOutlined, InfoCircleOutlined, UnlockOutlined, LogoutOutlined } from '@ant-design/icons';
import { StyledHeader, StyledMenu, StyledIconBadge, Logo } from './styles';
import Notifications from './Notifications';
import ProfileModal from './Profile';
import UpdatePasswordModal from './Profile/UpdatePassword';
import { onMerchantLoginAs } from '../../../../pages/Login/saga';

const HeaderLayout = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  const user = useSelector((state) => state.user.data);
  const profileRef = useRef();
  const changePasswordRef = useRef();
  const notificationRef = useRef();
  const { accounts } = user;

  const dispatch = useDispatch();
  const loginAs = (userId, roleId, role) => () => {
    dispatch(onMerchantLoginAs(userId, roleId, role));
  };

  return (
    <StyledHeader>
      <ProfileModal ref={profileRef} />
      <UpdatePasswordModal ref={changePasswordRef} />
      <Logo>
        <a href={process.env.WEBSITE_URL} target="_blank">
          <img src="/images/logo-white.svg" alt="error" />
        </a>
      </Logo>
      <StyledMenu selectedKeys={[]} mode="horizontal">
        <Menu.SubMenu
          key="profile"
          onClick={({ key }) => {
            if (key === 'info') profileRef.current.open();
            if (key === 'changePassword') changePasswordRef.current.open();
          }}
          icon={
            !user.avatarUrl ? (
              <Skeleton.Avatar shape="circle" size={45} />
            ) : (
              <Avatar alt="error" src={user.avatarUrl} size={45} style={{ marginRight: 5 }} />
            )
          }
        >
          <Menu.Item
            style={{
              width: 300,
              height: 100,
              borderBottom: '1px solid #e5e5e5',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <span style={{ fontWeight: 'bold', fontSize: 17 }}>{`${user.firstName} ${user.lastName}`}</span>
            <span style={{ lineHeight: '20px' }}>{user.email}</span>
          </Menu.Item>
          <Menu.Item
            key="info"
            icon={<InfoCircleOutlined style={{ fontSize: 20 }} />}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Thông tin cá nhân
          </Menu.Item>
          <Menu.Item
            key="changePassword"
            icon={<UnlockOutlined style={{ fontSize: 20 }} />}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Thay đổi mật khẩu
          </Menu.Item>
          <Menu.Item
            key="setting:3"
            icon={<LogoutOutlined style={{ fontSize: 20 }} />}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <a href="/user/sign_out" data-method="delete">
              Đăng xuất
            </a>
          </Menu.Item>
          {accounts && accounts.length && (
            <Menu.ItemGroup title="Tài khoản đã đăng nhập">
              {accounts.map(({ name, userId, avatarUrl, role, roleId }) => (
                <Menu.Item
                  onClick={loginAs(userId, roleId, role)}
                  key={userId}
                  style={{ height: 'auto', padding: '5px 16px' }}
                  icon={
                    !avatarUrl ? (
                      <Skeleton.Avatar shape="circle" style={{ marginRight: 5 }} size={45} />
                    ) : (
                      <Avatar alt="error" src={avatarUrl} size={45} style={{ marginRight: 5 }} />
                    )
                  }
                >
                  <>{name}</>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          )}
        </Menu.SubMenu>
        <Menu.Item
          style={{ marginRight: 40 }}
          onClick={() => notificationRef.current.open()}
          key="notification"
          icon={
            <StyledIconBadge count={notificationCount} offset={[0, 5]}>
              <BellOutlined style={{ fontSize: 23 }} />
            </StyledIconBadge>
          }
        />
      </StyledMenu>
      <Notifications ref={notificationRef} setNotificationCount={setNotificationCount} user={user} />
    </StyledHeader>
  );
};

export default HeaderLayout;
