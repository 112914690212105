import React, { useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Input, Row, Form, Button, Modal, DatePicker, Select } from 'antd';
import { fieldValidate, numberToCurrency, removeCurrency } from '@shared/function';
import moment from 'moment';
import { onEditCourseClassField } from '../saga';

const { Option } = Select;

const Create = React.forwardRef(({ fetchData }, ref) => {
  const [state, setState] = useState({ status: false, item: {} });
  const dispatch = useDispatch();
  const [submiting, setSubmiting] = useState(false);
  const [form] = Form.useForm();
  const { status, item } = state;

  const onClose = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status, response) => {
    if (status === 200) {
      onClose();
      fetchData();
    }
    setSubmiting(false);
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        ...item,
        class_type: item.classType,
        price: numberToCurrency(item.price),
        start_date: moment(item.startDate, 'DD-MM-YYYY')
      });
      setState({ status: true, item });
    }
  }));

  const updateData = useCallback((params) => dispatch(onEditCourseClassField(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    values.price = removeCurrency(values.price);
    values.start_date = values.start_date.format('DD-MM-YYYY');

    updateData({
      course_class: values,
      id: item.id
    });
  };

  return (
    <Modal closable={false} visible={status} footer={null} title="Thêm lớp học mới">
      <Form layout="vertical" form={form} name="create" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item label="Mã" name="code" rules={[fieldValidate.required]}>
              <Input placeholder="Mã" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="class_type" rules={[fieldValidate.required]} label="Loại hình">
              <Select placeholder="Loại hình">
                <Option value="Online lý thuyết">Online lý thuyết</Option>
                <Option value="Offline thực hành">Offline thực hành</Option>
                <Option value="Online lý thuyết & Offline thực hành">
                  Online lý thuyết & Offline thực hành
                </Option>
                <Option value="Offline lý thuyết & Offline thực hành">
                  Offline lý thuyết & Offline thực hành
                </Option>
                <Option value="Hội nghị">Hội nghị</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="price" rules={[fieldValidate.required]} label="Giá">
              <Input
                placeholder="Giá"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    price: numberToCurrency(removeCurrency(e.target.value)) || 0
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Thời gian bắt đầu" name="start_date" rules={[fieldValidate.required]}>
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder="Thời gian bắt đầu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Địa chỉ" name="address" rules={[fieldValidate.required]}>
              <Input placeholder="Địa chỉ" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" size="large" style={{ width: 150 }} htmlType="submit" disabled={submiting}>
            Lưu
          </Button>
          <Button size="large" style={{ width: 150, marginLeft: 20 }} onClick={onClose}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
