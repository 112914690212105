import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { fieldValidate, numberToCurrency, removeCurrency } from '../../../../../shared/function';
import { onUpdateSubIncome } from '../../saga';

const { Item } = Form;

const EditSubIncome = React.forwardRef(({ updateData, registrationId }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ isShow: false, item: {} });
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const { isShow, item } = state;

  const close = () => {
    form.resetFields();
    setState({ isShow: false, item: {} });
  };

  const callback = (status, response) => {
    if (status === 200) {
      updateData(response);
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onUpdateSubIncome(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    values.id = item.id;
    values.price = removeCurrency(values.price);
    editData({ sub_income: values, id: registrationId });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        title: item.title,
        note: item.note,
        price: numberToCurrency(item.price),
        income_date: item.createdAt ? moment(item.createdAt, 'DD/MM/YYYY') : moment()
      });
      setState({ isShow: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={isShow} footer={null} title="Chỉnh sửa phiếu thu">
      <Form layout="vertical" form={form} name="editSubIcome" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Item name="title" label="Tiêu đề" rules={[fieldValidate.required]}>
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item name="income_date" label="Ngày thu" rules={[fieldValidate.required]}>
              <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder="Ngày thu" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="price" label="Số tiền" rules={[fieldValidate.required]}>
              <Input
                placeholder="Số tiền"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    price: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="note" label="Ghi chú">
              <Input placeholder="Ghi chú" />
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default EditSubIncome;
