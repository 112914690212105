import React, { useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Input, Row, Form, Button, Modal, message, Select } from 'antd';
import { fieldValidate } from '../../../../../../shared/function';
import { onCreateCourseDocument } from '../../../saga';
import { Upload, VideoUpload } from '../../../../../../components/Upload';

const Create = React.forwardRef(({ courseId, fetchData }, ref) => {
  const [state, setState] = useState({ status: false });
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const { status } = state;
  const [uploadType, setUploadType] = useState('file');

  const onClose = () => {
    form.resetFields();
    setState({ status: false });
  };

  const callback = (status) => {
    if (status === 200) {
      onClose();
      fetchData();
    }
    setSubmitting(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setState({ status: true });
    }
  }));

  const createdDocument = useCallback((params) => dispatch(onCreateCourseDocument(params, callback)), [
    dispatch
  ]);

  const onFinish = (values) => {
    if (!values.video_id && !values.file) {
      return message.error('Vui lòng tải file lên hệ thống.');
    }
    setSubmitting(true);
    createdDocument({ ...values, courseId });
  };

  return (
    <Modal closable={false} visible={status} footer={null} title="Thêm tài liệu">
      <Form layout="vertical" form={form} name="create" scrollToFirstError onFinish={onFinish}>
        <Form.Item name="video_id" hidden />

        <Row gutter={48}>
          <Col span={24}>
            <Form.Item label="Tên" name="name" rules={[fieldValidate.required]}>
              <Input placeholder="Tên" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Loại file">
              <Select onChange={setUploadType} value={uploadType}>
                <Select.Option value="file">File</Select.Option>
                <Select.Option value="video">Video</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            {uploadType === 'file' ? (
              <Form.Item label="File" name="file" rules={[fieldValidate.required]}>
                <Upload isDocument onFileChange={(file) => form.setFieldsValue({ file })} />
              </Form.Item>
            ) : (
              <Form.Item label="Video" name="video_id" rules={[fieldValidate.required]}>
                <VideoUpload
                  onVideoFileChange={(id) => form.setFieldsValue({ video_id: id })}
                  chunkVideoUrl={`/courses/${courseId}/chunk_upload_video`}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" size="large" style={{ width: 150 }} htmlType="submit" disabled={submitting}>
            Lưu
          </Button>
          <Button size="large" style={{ width: 150, marginLeft: 20 }} onClick={onClose}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
