const adminRoutes = [
  '/admin/dashboard',
  '/admin/employees',
  '/admin/courses',
  '/admin/course-topics',
  '/admin/students',
  '/admin/combo-promotions',
  '/admin/group-promotions',
  '/admin/forums',
  '/admin/news-promotions',
  '/admin/news',
  '/admin/hashtags',
  '/admin/questions',
  '/admin/user-questions',
  '/admin/user-tasks',
  '/admin/user-tasks/my-task',
  '/admin/user-tasks/overview',
  '/admin/registration-infos',
  '/admin/debts',
  '/admin/memberships',
  '/admin/gifts',
  '/admin/sales',
  '/admin/forum-topics',
  '/admin/statistic',
  '/admin/forum-members',
  '/admin/notifications'
];

const courseManagerRoutes = [
  '/admin/combo-promotions',
  '/admin/group-promotions',
  '/admin/gifts',
  '/admin/course-topics',
  '/admin/my-management-courses',
  '/admin/my-course-salers',
  '/admin/my-management-registration-info'
];

const managereRoutes = [
  '/admin/forum-members',
  '/admin/forums',
  '/admin/news-promotions',
  '/admin/news',
  '/admin/hashtags',
  '/admin/questions',
  '/admin/user-questions',
  '/admin/forum-topics'
];

const employeeRoutes = [
  '/admin/user-tasks',
  '/admin/user-tasks/my-task',
  '/admin/user-tasks/overview',
  '/admin/my-support-courses',
  '/admin/my-support-registration-info'
];

const accountantRoutes = [
  '/admin/students',
  '/admin/debts',
  '/admin/registration-infos',
  '/admin/memberships',
  '/admin/company-expenses',
  '/admin/company-incomes',
  '/admin/employee-debts',
  '/admin/sales',
  '/admin/company-categories',
  '/admin/statistic'
];

const accountantManager = [
  '/admin/registration-infos',
  '/admin/company-expenses',
  '/admin/company-incomes',
  '/admin/employee-debts',
  '/admin/sales',
  '/admin/company-categories',
  '/admin/statistic'
];

const studentManagerRoutes = ['/admin/students', '/admin/debts', '/admin/memberships'];

const saleManagerRoutes = ['/admin/sales'];

const salerRoutes = ['/admin/my-sales'];

export const pathByRole = {
  accountant_manager: accountantManager,
  saler: salerRoutes,
  sale_manager: saleManagerRoutes,
  admin: adminRoutes,
  employee: employeeRoutes,
  manager: managereRoutes,
  accountant: accountantRoutes,
  course_manager: courseManagerRoutes,
  student_manager: studentManagerRoutes
};

export default pathByRole;
