import React from 'react';
import { PauseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { numberToCurrency } from '../../../../shared/function';

export const columns = () => [
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code',
    sorter: {}
  },
  {
    title: 'Nhân viên & Học viên',
    key: 'name',
    render: (item) => (
      <div>
        <Tag color={item.incomeType === 'employee' ? 'green' : 'gray'} style={{ marginright: 10 }}>
          {item.incomeType === 'employee' ? 'NV' : 'HV'}
        </Tag>
        {item.name}
      </div>
    )
  },
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title',
    render: (title) => <div style={{ whiteSpace: 'pre-line' }}>{title}</div>
  },
  {
    title: 'Nội dung',
    dataIndex: 'note',
    key: 'note',
    render: (note) => <div style={{ whiteSpace: 'pre-line' }}>{note}</div>
  },
  {
    title: 'Số tiền',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Ngày lập phiếu',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: 'Tính công nợ',
    dataIndex: 'isDebt',
    key: 'is_debt',
    render: (isDebt) => (isDebt ? 'Có' : 'Không')
  },
  {
    title: 'Kế toán duyệt',
    dataIndex: 'accountantConfirm',
    key: 'accountantConfirm',
    width: 120,
    render: (accountantConfirm) => {
      if (!accountantConfirm) {
        return (
          <Tooltip title="Đang chờ duyệt" color="red" key="red">
            <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã duyệt" color="green" key="green">
          <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
        </Tooltip>
      );
    }
  },
  {
    title: 'Xác nhận',
    dataIndex: 'confirmed',
    key: 'confirmed',
    width: 120,
    render: (confirmed) => {
      if (!confirmed) {
        return (
          <Tooltip title="Đang chờ xác nhận" color="red" key="red">
            <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã xác nhận" color="green" key="green">
          <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
        </Tooltip>
      );
    }
  }
];
