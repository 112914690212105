import React, {
  useState, useRef,
} from 'react';
import {
  EditOutlined, AntDesignOutlined,
} from '@ant-design/icons';
import {
  Typography, Avatar,
} from 'antd';
import {
  ItemWrapper,
} from '../styles';

import LessonViewModal from './Modal';

const { Text } = Typography;

const editIconStyle = { marginLeft: 10, fontSize: 18, cursor: 'pointer' };

const LessonComponent = ({ item }) => {
  const [data, setData] = useState(item.courseLessons || []);
  const lessonRef = useRef();

  return (
    <>
      <LessonViewModal ref={lessonRef} courseId={item.id} setData={setData} />
      <ItemWrapper>
        <Text strong style={{ marginRight: 5 }}>Buổi học:</Text>
        <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
          {data.map((lesson) => <Avatar key={lesson.id} style={{ backgroundColor: '#1890ff' }} icon={<AntDesignOutlined />} />)}
        </Avatar.Group>
        <EditOutlined style={editIconStyle} onClick={() => lessonRef.current.open()} />
      </ItemWrapper>
    </>
  );
};
export default LessonComponent;
