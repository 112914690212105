import React, {
  useState, useImperativeHandle,
} from 'react';
import {
  Row, Button, Col,
} from 'antd';
import {
  StyledModal,
} from './styles';
import Viewer from './Viewer';

const PreviewFile = React.forwardRef(({}, ref) => {
  const [state, setState] = useState({ item: {}, status: false });
  const { status, item } = state;

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ status: true, item });
    },
  }));

  const onClose = () => {
    setState({ status: false, item: {} });
  };

  return (
    <StyledModal
      closable={true}
      visible={status}
      footer={null}
      onCancel={onClose}
    >
      <Row gutter={48}>
        <Col span={24}>
          {item.url && <Viewer item={item} />}
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          size='large'
          style={{ width: 150, marginLeft: 20 }}
          onClick={onClose}
        >
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default PreviewFile;
