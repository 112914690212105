import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, InputNumber, DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  removeCurrency, fieldValidate, numberToCurrency, dateFormat,
} from '../../../../shared/function';
import { onEditPromotion } from '../saga';

const { Item } = Form;
const { Option } = Select;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditPromotion(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    values.amount = removeCurrency(values.amount);
    values.expired_date = values.expired_date.format(dateFormat);
    editData({ group_promotion: values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        expired_date: moment(item.expiredDate, 'DD/MM/YYYY'),
        discount_type: item.discountType,
        amount: numberToCurrency(item.amount),
        people: item.people,
      });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      title='Chỉnh sửa khuyến mãi'
    >
      <Form
        layout='vertical'
        form={form}
        name='editTopic'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Item
              name='discount_type'
              rules={[fieldValidate.required]}
              label='Loại giảm giá'
            >
              <Select placeholder='Loại giảm giá'>
                <Option value='percentage'>Phần trăm</Option>
                <Option value='amount'>Tiền</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name='expired_date'
              rules={[fieldValidate.required]}
              label='Ngày hết hạn'
            >
              <DatePicker format={dateFormat} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name='amount'
              label='Giảm'
              rules={[fieldValidate.required]}
            >
              <Input
                placeholder='Giảm'
                onChange={(e) => e.target.value && form.setFieldsValue({
                  amount: numberToCurrency(removeCurrency(e.target.value)),
                })}
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              name='people'
              label='Số người'
              rules={[fieldValidate.required]}
            >
              <InputNumber placeholder='Số người' min={1} />
            </Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
