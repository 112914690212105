import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, DatePicker, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import UploadList from '../../../../components/UploadList';
import SelectSearch from '../../../../components/SelectSearch';
import { fieldValidate, numberToCurrency, removeCurrency, dateFormat } from '../../../../shared/function';
import { onNewData } from '../saga';

const { Item } = Form;
const { Option } = Select;

const Create = React.forwardRef(({ fetchData, employees, categories }, ref) => {
  const uploadRef = useRef();
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [isDebt, setIsDebt] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(0);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    if (uploadRef.current) uploadRef.current.reset();
    setShowModal(false);
    setSelectedEmployee(0);
    setIsDebt(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(onNewData(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const formData = new FormData();
    formData.append('company_income[title]', values.title);
    formData.append('company_income[user_id]', values.userId);
    formData.append('company_income[note]', values.note);
    formData.append('company_income[is_debt]', values.isDebt);
    formData.append('company_income[income_date]', values.time.format('DD/MM/YYYY'));
    formData.append('company_income[company_category_id]', values.company_category_id);
    formData.append('company_income[price]', removeCurrency(values.amount));

    if (values.company_expense_id)
      formData.append('company_income[company_expense_id]', values.company_expense_id);

    if (values.images) {
      values.images.forEach((file) => {
        formData.append('company_income[images][]', file);
      });
    }

    createData({ formData });
  };

  const expenses = () => {
    const filterdEmployee = employees.filter((a) => a.id === selectedEmployee)[0];

    if (filterdEmployee) return filterdEmployee.expenses;

    return [];
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      form.setFieldsValue({
        time: moment()
      });
      setShowModal(true);
    }
  }));

  return (
    <Modal closable={false} visible={isShow} footer={null} width={992} title="Tạo phiếu thu cho nhân viên">
      <Form layout="vertical" form={form} name="createTopic" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={8}>
            <Item name="company_category_id" rules={[fieldValidate.required]} label="Mã phiếu">
              <SelectSearch placeholder="Mã phiếu">
                {categories.map((category) => (
                  <Option value={category.id} key={category.id}>
                    {category.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={8}>
            <Item name="time" rules={[fieldValidate.required]} label="Ngày lập phiếu">
              <DatePicker format={dateFormat} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tính vào công nợ" name="isDebt" valuePropName="checked">
              <Switch checkedChildren="Có" unCheckedChildren="Không" onChange={(value) => setIsDebt(value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Item name="userId" rules={[fieldValidate.required]} label="Nhân viên">
              <SelectSearch placeholder="Nhân viên" onChange={(value) => setSelectedEmployee(value)}>
                {employees.map((employee) => (
                  <Option value={employee.id} key={employee.id}>
                    {employee.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          {isDebt && (
            <Col span={12}>
              <Item name="company_expense_id" rules={[fieldValidate.required]} label="Công nợ">
                <SelectSearch placeholder="Công nợ">
                  {expenses().map((debtItem) => (
                    <Option value={debtItem.id} key={debtItem.id}>
                      {`${debtItem.code} - ${debtItem.createdAt}`}
                    </Option>
                  ))}
                </SelectSearch>
              </Item>
            </Col>
          )}
          <Col span={12}>
            <Item name="amount" rules={[fieldValidate.required]} label="Số tiền">
              <Input
                placeholder="Số tiền"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    amount: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="title" rules={[fieldValidate.required]} label="Tiêu đề">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="note" label="Nội dung thu">
              <Input.TextArea placeholder="Nội dung thu" />
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh" name="images" />
            <UploadList
              noCrop
              limitFile={10}
              ref={uploadRef}
              circle
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
