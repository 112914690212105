import styled from 'styled-components';

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  flex-flow: wrap row;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MemberWrapper = styled.div`
  display: flex;
  margin-left: 7px;
  flex-flow: wrap row;
`;

export default TitleWrapper;
