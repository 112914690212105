import React, {
  useState, useImperativeHandle,
} from 'react';
import {
  Col, Row, List, Button, Card, Modal, Typography,
} from 'antd';
import {
  numberToCurrency,
} from '../../../../../shared/function';
import { StyledModal } from './styles';

const { Text } = Typography;

const ComboPromotion = React.forwardRef(({
  comboPromotions,
}, ref) => {
  const [isShow, setShowModal] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <StyledModal
      closable={true}
      onCancel={close}
      visible={isShow}
      footer={null}
      title='Các khuyến mãi theo combo'
    >
      <Row>
        <Col span={24}>
          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={comboPromotions}
            renderItem={(item) => (
              <List.Item>
                <Card title={item.name}>
                  <Text strong>
                    Giá gốc:
                    <Text style={{ marginLeft: 10, fontWeight: 'normal' }}>{numberToCurrency(item.originalPrice)}</Text>
                  </Text>
                  <Text strong>
                    Giá khuyến mãi:
                    <Text style={{ marginLeft: 10, fontWeight: 'normal' }}>{numberToCurrency(item.price)}</Text>
                  </Text>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
                    <Text strong>Khoá học: </Text>
                    {item.courseName.map((name) => (
                      <Text>{`- ${name}`}</Text>
                    ))}
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          size='large'
          style={{ width: 150 }}
          onClick={close}
        >
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default ComboPromotion;
