import styled from 'styled-components';
import { Col } from 'antd';

const backgrounImg = '/images/login-bg.png';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 200px 100px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-row {
      width: 100%;
    }

    .ant-form-item-has-error .ant-input:focus,
    .ant-form-item-has-error .ant-input-affix-wrapper:focus,
    .ant-form-item-has-error .ant-input-focused,
    .ant-form-item-has-error .ant-input-affix-wrapper-focused {
      border-color: transparent;
      box-shadow: none;
    }

    .ant-form-item-control-input {
      border-bottom: 1px solid #bdbdbd;
    }

    .ant-input-affix-wrapper,
    .ant-input {
      border: none !important;
      padding-left: 0;
      box-shadow: none !important;
    }
  }

  @media (max-width: 1024px) {
    background: white;
    width: 70%;
    height: 70%;
    border-radius: 3px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 768px) {
    width: 80%;
    height: 80%;
    padding: 120px 40px;
  }

  @media (max-width: 414px) {
    width: 100%;
    height: 100%;
    padding: 70px 20px;
  }
`;

export const LeftColumn = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #333333;
  flex-direction: column;
  padding: 100px;
  background-image: url('/images/login-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Title = styled.div`
  color: #fff;
  width: 700px;
  font-size: 50px;
  font-family: 'Lora';
  text-align: center;
  font-weight: 500;

  @media (max-width: 1280px) {
    font-size: 35px;
  }
`;

export const Name = styled.div`
  color: #fff;
  font-size: 100px;
  font-weight: bold;
  line-height: 0.98;
  font-family: sans-serif;

  @media (max-width: 1280px) {
    font-size: 70px;
  }
`;

export const SubTitle = styled.div`
  color: #fff;
  font-size: 20px;
  width: 700px;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 17px;
  }
`;

export const RightColumn = styled(Col)`
  display: flex;
  justify-content: space-between;
  height: 100vh !important;
  flex-direction: column;
  background: white;

  @media (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    background: #333333;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 40px 15px 40px 15px;
  }

  @media (max-width: 414px) {
    height: auto;
    padding: 50px 15px 50px 15px;
  }
`;

export const FormTitle = styled.div`
  color: #000000;
  font-size: 48px;
  font-family: 'Lora';
  font-weight: 500;
`;

export const RegisterLink = styled.a`
  margin-left: 3px;
  color: #828282;
`;
export const Footer = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
`;
