import React from 'react';
import {
  EditOutlined, DeleteOutlined, MenuOutlined,
} from '@ant-design/icons';
import {
  Menu, Dropdown, Avatar, Tooltip,
} from 'antd';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const FileComponent = ({ fileType, name, fileUrl }) => {
  switch (fileType) {
    case 'image':
      return (
        <Tooltip title={name}>
          <Avatar src='/images/icon/image.png' onClick={() => window.open(fileUrl, '_blank')} />
        </Tooltip>
      );
    case 'pdf':
      return <Tooltip title={name}><Avatar src='/images/icon/pdf.png' onClick={() => window.open(fileUrl, '_blank')} /></Tooltip>;
    case 'word':
      return <Tooltip title={name}><Avatar src='/images/icon/word.png' onClick={() => window.open(fileUrl, '_blank')} /></Tooltip>;
    case 'excel':
      return <Tooltip title={name}><Avatar src='/images/icon/excel.png' onClick={() => window.open(fileUrl, '_blank')} /></Tooltip>;
    default:
      return null;
  }
};

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Nội dung',
    dataIndex: 'description',
    key: 'description',
    render: (description) => <div style={{ whiteSpace: 'pre-line' }}>{description}</div>,
  },
  {
    title: 'Loại',
    dataIndex: 'lessonType',
    key: 'lesson_type',
    render: (lessonType) => (lessonType === 'practice' ? 'Thực hành' : 'Lý thuyết'),
  },
  {
    title: 'Tài liệu',
    render: ({ courseDocuments }) => (
      <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
        {courseDocuments.map((file) => (
          <FileComponent fileType={file.fileType} key={file.id} name={file.name} fileUrl={file.fileUrl}/>
        ))}
      </Avatar.Group>
    ),
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Xóa
              </Menu.Item>
            </Menu>
        )}
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    ),
  },
];
