import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  FormOutlined,
  GiftOutlined,
  MacCommandOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { List, Card, Typography, Button, Popconfirm } from 'antd';
import { ItemWrapper, ActionWrapper, MemberWrapper } from './styles';
import Chip from './Chip';
import ClassRoom from './ClassRoom';
import Status from './Status';
import Document from './Document';
import Lesson from './Lesson';
import AddTeacher from './Modal/AddTeacher';
import AddManager from './Modal/AddManager';
import AddSupport from './Modal/AddSupport';
import ComboPromotion from './Modal/ComboPromotion';
import EditContent from '../Modal/Edit';
import Gift from '../Modal/Gift';
import Topic from './Topic';
import { onDeleteCourse } from '../saga';

const { Title, Text } = Typography;

const actionIconStyle = { fontSize: 18, cursor: 'pointer' };

const CourseItem = ({ item, teachers, managers, topics, fetchData, isManager, isSupport, employees }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(item || {});
  const editContentRef = useRef();
  const addTeacherRef = useRef();
  const addSupportRef = useRef();
  const addManagerRef = useRef();
  const giftRef = useRef();
  const comboPromotionRef = useRef();

  const removeCourse = useCallback((id) => dispatch(onDeleteCourse(id, fetchData)), [dispatch]);

  useEffect(() => {
    setData(item);
  }, [item]);

  return (
    <>
      <ComboPromotion ref={comboPromotionRef} comboPromotions={item.comboPromotions} />
      <Gift ref={giftRef} courseId={item.id} />
      <EditContent ref={editContentRef} item={data} setData={setData} />
      <AddTeacher ref={addTeacherRef} teachers={teachers} item={data} setData={setData} />
      {!isManager && !isSupport && (
        <AddManager ref={addManagerRef} managers={managers} item={data} setData={setData} />
      )}
      {!isSupport && <AddSupport ref={addSupportRef} employees={employees} item={data} setData={setData} />}
      <Card
        style={{ marginBottom: 15 }}
        actions={[
          <ActionWrapper onClick={() => editContentRef.current.open()}>
            <FormOutlined style={actionIconStyle} />
            <Text style={{ marginLeft: 5 }}>Chỉnh sửa nội dung</Text>
          </ActionWrapper>,
          <ActionWrapper onClick={() => giftRef.current.open()}>
            <GiftOutlined style={actionIconStyle} />
            <Text style={{ marginLeft: 5 }}>Quà tặng</Text>
          </ActionWrapper>,
          <ActionWrapper onClick={() => comboPromotionRef.current.open()}>
            <MacCommandOutlined style={actionIconStyle} />
            <Text style={{ marginLeft: 5 }}>Khuyến mãi theo Combo</Text>
          </ActionWrapper>,
          ...[
            !isManager && !isSupport && (
              <Popconfirm
                placement="top"
                title="Bạn có muốn xóa khóa học này không?"
                onConfirm={() => removeCourse(item.id)}
                okText="Có"
                cancelText="Không"
              >
                <ActionWrapper>
                  <DeleteOutlined style={actionIconStyle} />
                  <Text style={{ marginLeft: 5 }}>Xóa khóa học</Text>
                </ActionWrapper>
              </Popconfirm>
            )
          ]
        ]}
      >
        <List.Item
          key={data.id}
          extra={
            <img style={{ height: 400, objectFit: 'cover' }} width={400} alt="logo" src={data.mainImage} />
          }
        >
          <List.Item.Meta
            title={<Title level={3} style={{ margin: 0 }}>{`${data.moduleName}: ${data.title}`}</Title>}
          />
          <ItemWrapper>
            <Text strong style={{ marginRight: 5, marginTop: 10 }}>
              Quản lí:
            </Text>
            <MemberWrapper>
              {data.managers.map((manager) => (
                <Chip
                  item={manager}
                  type="manager"
                  key={manager.id}
                  setData={setData}
                  courseId={data.id}
                  isManager={isManager}
                  isSupport={isSupport}
                />
              ))}
              {!isManager && !isSupport && (
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  size="large"
                  style={{ borderRadius: 25, marginTop: 10 }}
                  onClick={() => addManagerRef.current.open()}
                >
                  Thêm quản lí
                </Button>
              )}
            </MemberWrapper>
          </ItemWrapper>
          <ItemWrapper>
            <Text strong style={{ marginRight: 5, marginTop: 10 }}>
              Trợ thủ:
            </Text>
            <MemberWrapper>
              {data.supports.map((support) => (
                <Chip
                  item={support}
                  type="support"
                  key={support.id}
                  setData={setData}
                  courseId={data.id}
                  isManager={isManager}
                  isSupport={isSupport}
                />
              ))}
              {!isSupport && (
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  size="large"
                  style={{ borderRadius: 25, marginTop: 10 }}
                  onClick={() => addSupportRef.current.open()}
                >
                  Thêm trợ thủ
                </Button>
              )}
            </MemberWrapper>
          </ItemWrapper>
          <ItemWrapper>
            <Text strong style={{ marginRight: 5, marginTop: 10 }}>
              Giảng viên:
            </Text>
            <MemberWrapper>
              {data.teachers.map((teacher) => (
                <Chip item={teacher} type="teacher" key={teacher.id} setData={setData} courseId={data.id} />
              ))}
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                size="large"
                style={{ borderRadius: 25, marginTop: 10 }}
                onClick={() => addTeacherRef.current.open()}
              >
                Thêm giảng viên
              </Button>
            </MemberWrapper>
          </ItemWrapper>
          <ItemWrapper>
            <Text strong style={{ marginRight: 10 }}>
              Lớp học:
            </Text>
            <ClassRoom item={item} teachers={data.teachers} />
          </ItemWrapper>
          <Lesson item={item} />
          <Document item={item} />
          <ItemWrapper>
            <Text strong style={{ marginRight: 5 }}>
              Trạng thái:
            </Text>
            <Status item={item} />
          </ItemWrapper>
          <ItemWrapper>
            <Text strong style={{ marginRight: 5 }}>
              Chủ đề:
            </Text>
            <Topic item={item} topics={topics} />
          </ItemWrapper>
        </List.Item>
      </Card>
    </>
  );
};
export default CourseItem;
