import React, { useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Input, Row, Form, Button, Modal, Select } from 'antd';
import { fieldValidate } from '../../../../../../shared/function';
import { onEditCourseDocument } from '../../../saga';
import { Upload, VideoUpload } from '../../../../../../components/Upload';

const Edit = React.forwardRef(({ courseId, fetchData }, ref) => {
  const [state, setState] = useState({ status: false, item: {} });
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const { status, item } = state;
  const [uploadType, setUploadType] = useState('file');

  const onClose = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      onClose();
      fetchData();
    }
    setSubmitting(false);
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({ name: item.name });
      setState({ status: true, item });

      if (item.fileType === 'video') {
        setUploadType('video');
      } else {
        setUploadType('file');
      }
    }
  }));

  const editDocument = useCallback((params) => dispatch(onEditCourseDocument(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmitting(true);
    console.log(values);
    editDocument({ ...values, id: item.id, courseId });
  };

  return (
    <Modal closable={false} visible={status} footer={null} title="Chỉnh sửa tài liệu">
      <Form layout="vertical" form={form} name="create" scrollToFirstError onFinish={onFinish}>
        <Form.Item name="video_id" hidden />

        <Row gutter={48}>
          <Col span={24}>
            <Form.Item label="Tên" name="name" rules={[fieldValidate.required]}>
              <Input placeholder="Tên" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Loại file">
              <Select onChange={setUploadType} value={uploadType}>
                <Select.Option value="file">File</Select.Option>
                <Select.Option value="video">Video</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            {uploadType === 'file' ? (
              <Form.Item label="File" name="file">
                <Upload
                  isDocument
                  onFileChange={(file) => form.setFieldsValue({ file })}
                  defaultValue={item.file}
                />
              </Form.Item>
            ) : (
              <Form.Item label="Video" name="video_id">
                <VideoUpload
                  onVideoFileChange={(id) => form.setFieldsValue({ video_id: id })}
                  chunkVideoUrl={`/courses/${courseId}/chunk_upload_video`}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button type="primary" size="large" style={{ width: 150 }} htmlType="submit" disabled={submitting}>
            Lưu
          </Button>
          <Button size="large" style={{ width: 150, marginLeft: 20 }} onClick={onClose}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
