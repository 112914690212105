import styled from 'styled-components';
import {
  Tag,
} from 'antd';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: wrap row;
`;

export const StyledTag = styled(Tag)`
  margin-right: 10px;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
`;

export default Wrapper;
