import React, {
  useState, useImperativeHandle, useCallback, useRef,
} from 'react';
import {
  Col, Row, Form, Button, Select, Modal,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../../shared/function';
import SelectSearch from '../../../../../components/SelectSearch';

import { onEditCourseField } from '../../saga';

const { Item } = Form;
const { Option } = Select;

const AddTeacher = React.forwardRef(({
  teachers, setData, item,
}, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    if (uploadRef.current) uploadRef.current.reset();
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status, response) => {
    if (status === 200) {
      setData(response);
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditCourseField(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    const params = values.teachers.map((teacherId) => ({
      user_id: teacherId,
    }));
    setSubmiting(true);
    editData({ course: { course_teachers_attributes: params }, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      title='Thêm giảng viên'
    >
      <Form
        layout='vertical'
        form={form}
        name='addTeacher'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Item
              name='teachers'
              rules={[fieldValidate.required]}
              label='Giảng viên'
            >
              <SelectSearch placeholder='Giảng viên' mode='multiple'>
                {teachers.map(
                  (teacher) => {
                    if (item.teacherUserIds.includes(teacher.id)) return null;
                    return (<Option value={teacher.id} key={teacher.id}>{teacher.name}</Option>);
                  },
                )}
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default AddTeacher;
