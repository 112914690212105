import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../shared/function';
import Ckeditor from '../../../../shared/Ckeditor';
import SelectSearch from '../../../../components/SelectSearch';
import { onCreateTask } from '../saga';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Create = React.forwardRef(({
  fetchData, employees,
}, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ isShow: false, item: {} });
  const { isShow, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ isShow: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(
    onCreateTask(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const taskAssignees = (values.assignee_ids || []).map((employee) => ({
      user_id: employee,
      status: 'employee',
    }));

    const taskSupporter = (values.supporter_ids || []).map((employee) => ({
      user_id: employee,
      status: 'supporter',
    }));

    values.parent_id = item.id;

    if (values.time && values.time.length > 0) {
      values.start_at = values.time[0].format('DD/MM/YYYY');
      values.deadline = values.time[1].format('DD/MM/YYYY');
    }

    createData({ user_task: { ...values, task_type: 'sub_project', task_assignees_attributes: taskAssignees.concat(taskSupporter) } });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ isShow: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      width={992}
      title='Tạo Sub-Project'
    >
      <Form
        layout='vertical'
        form={form}
        name='createSubProject'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name='title'
              label='Tên'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tên' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='time'
              label='Ngày bắt đầu - Ngày kết thúc'
            >
              <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='assignee_ids'
              label='Nhân viên'
              rules={[fieldValidate.required]}
            >
              <SelectSearch placeholder='Nhân viên' mode='multiple'>
                {(item.assigneeInfo || []).map((employee) => (
                  <Option value={employee.id} key={employee.id}>{employee.name}</Option>
                ))}
              </SelectSearch>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='supporter_ids'
              label='Trợ thủ'
            >
              <SelectSearch placeholder='Trợ thủ' mode='multiple'>
                {employees.map((employee) => {
                  if (item.assigneeIds && item.assigneeIds.includes(employee.id)) return null;
                  return (
                    <Option value={employee.id} key={employee.id}>{employee.name}</Option>
                  );
                })}
              </SelectSearch>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name='content'
              label='Nội dung'
            >
              <Ckeditor onChange={(value) => form.setFieldsValue({ content: value })} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
