import styled from 'styled-components';
import { Col } from 'antd';

export const ChartHeader = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
  height: 53px;
`;

export default ChartHeader;
