import { get } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';

const ON_GET_DEBTS = 'ON_GET_DEBTS';
const ON_GET_DEBT_SELECT_VALUE = 'ON_GET_DEBT_SELECT_VALUE';

function* getDebts({ params, setData }) {
  try {
    const response = yield call(get, '/registration_infos/debts', { params });
    setData({
      loading: false,
      items: response.items,
      totalIncome: response.totalIncome,
      totalPrice: response.totalPrice,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* selectValue({ setData }) {
  try {
    const response = yield call(get, '/registration_infos/debt_select_value');
    setData({
      students: response.students,
      courses: response.courses
    });
  } catch (error) {
    console.log(error);
  }
}

export const onGetDebts = (params, setData) => ({
  type: ON_GET_DEBTS,
  params,
  setData
});

export const onGetSelectValue = (setData) => ({
  type: ON_GET_DEBT_SELECT_VALUE,
  setData
});

export default function* debtsWatcher() {
  yield takeLatest(ON_GET_DEBTS, getDebts);
  yield takeLatest(ON_GET_DEBT_SELECT_VALUE, selectValue);
}
