import React, { useState, useEffect, useCallback, useRef, useImperativeHandle } from 'react';
import {
  Form,
  Button,
  Descriptions,
  Tag,
  Typography,
  Input,
  Table,
  Menu,
  Dropdown,
  Col,
  Row,
  Tooltip
} from 'antd';
import {
  EditOutlined,
  MenuOutlined,
  LoginOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { sumBy } from 'lodash';
import { fieldValidate, numberToCurrency, removeCurrency } from '../../../../shared/function';
import { onUpdateSubIncome, onUpdateIncome, onRemoveSubIncome } from '../saga';
import CreateSubIncome from '../Modal/Preview/CreateSubIncome';
import EditSubIncome from '../Modal/Preview/EditSubIncome';
import { Wrapper, StyledModal } from '../Modal/styles';

const { Text, Title } = Typography;

const Status = ({ status }) => {
  switch (status) {
    case 'pending':
      return <Tag color="orange">Chưa tiến hành</Tag>;
    case 'processing':
      return <Tag color="blue">Đang thu tiền</Tag>;
    case 'complete':
      return <Tag color="green">Đã hoàn thành</Tag>;
    case 'canceled':
      return <Tag color="red">Hủy thanh toán</Tag>;
    default:
      return '';
  }
};

const EditField = ({ field, defaultValueField, item, onEdit, updateData }) => {
  const [editable, setEditable] = useState(false);
  const [form] = Form.useForm();

  const callback = (status, response) => {
    if (status === 200) {
      updateData(response);
      setEditable(false);
    }
  };

  const onFinish = (values) => {
    values.total_price = removeCurrency(values.total_price);

    onEdit({ income: { ...values, id: item.id, has_changed_price: true } }, callback);
  };

  useEffect(() => {
    const value = {};
    value[field] = numberToCurrency(item[defaultValueField]);
    form.setFieldsValue(value);
  }, [item]);

  return (
    <Wrapper>
      {!editable ? (
        <>
          <Text>{numberToCurrency(item[defaultValueField])}</Text>
          <EditOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(true)}
          />
        </>
      ) : (
        <>
          <Form layout="vertical" form={form} name={`${field}Inline`} scrollToFirstError onFinish={onFinish}>
            <Form.Item style={{ margin: 0, display: 'flex' }} name={field} rules={[fieldValidate.required]}>
              <Input
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    total_price: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </Form>
          <CloseCircleOutlined
            style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }}
            onClick={() => setEditable(false)}
          />
        </>
      )}
    </Wrapper>
  );
};

const columns = (onEdit, onDelete, onAccountantConfirm, mySale, canConfirm) => [
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Số tiền',
    dataIndex: 'price',
    key: 'price',
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note'
  },
  {
    title: 'Người tạo',
    dataIndex: 'creator',
    key: 'creator'
  },
  {
    title: 'Xác nhận',
    dataIndex: 'confirmed',
    key: 'confirmed',
    width: 120,
    render: (confirmed, item) => {
      if (!confirmed) {
        return (
          <Tooltip title="Đang chờ xác nhận" color="red" key="red">
            <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Đã xác nhận" color="green" key="green">
          <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
        </Tooltip>
      );
    }
  },
  {
    title: 'Thu lúc',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: '',
    key: 'action',
    render: (item) =>
      ((mySale && !item.confirmed) || canConfirm) && (
        <Dropdown
          overlay={
            <Menu>
              {canConfirm && (
                <Menu.Item onClick={() => onAccountantConfirm(item, !item.confirmed)}>
                  <CheckCircleOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                  {!item.confirmed ? 'Duyệt phiếu' : 'Huỷ duyệt phiếu'}
                </Menu.Item>
              )}
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item.id)}>
                <LoginOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      )
  }
];

const View = React.forwardRef(({ mySale }, ref) => {
  const user = useSelector((state) => state.user.data);
  const [state, setState] = useState({ isShow: false, item: {} });
  const { isShow, item } = state;
  const { course, course_class: courseClass } = item.info || {};
  const dispatch = useDispatch();
  const createSubIncomeRef = useRef();
  const editSubIncomeRef = useRef();

  const editData = (params, callback) =>
    dispatch(onUpdateIncome({ ...params, id: item.registrationInfoId }, callback));

  const updateData = (response) => {
    const newItem = response.incomes.filter((a) => a.id === item.id)[0];

    if (newItem) setState((_state) => ({ ..._state, item: newItem }));
  };

  const deleteSubIncome = useCallback(
    (subIncomeId) =>
      dispatch(onRemoveSubIncome({ sub_income_id: subIncomeId, id: item.registrationInfoId }, updateData)),
    [dispatch, item]
  );

  const onAccountantConfirm = (subIncone, confirmed) => {
    dispatch(
      onUpdateSubIncome(
        { sub_income: { id: subIncone.id, confirmed: confirmed }, id: item.registrationInfoId },
        (status, response) => {
          updateData(response);
        }
      )
    );
  };

  const onEdit = (item) => editSubIncomeRef.current.open(item);

  const close = () => {
    setState({ isShow: false, item: [] });
  };

  const totalIncome = sumBy(item.subIncomes, (o) => (o.confirmed ? parseFloat(o.price) : 0));

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ isShow: true, item });
    }
  }));

  return (
    <StyledModal closable={false} visible={isShow} footer={null} title="Thông tin thu tiền">
      <Row>
        <Col span={24}>
          <EditSubIncome
            registrationId={item.registrationInfoId}
            ref={editSubIncomeRef}
            updateData={updateData}
          />
          <CreateSubIncome
            registrationId={item.registrationInfoId}
            ref={createSubIncomeRef}
            updateData={updateData}
          />
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 25 }}>
            {course && (
              <Title level={4} style={{ textAlign: 'center', marginBottom: 5 }}>
                {`${course.module_name}: ${course.title}`}
              </Title>
            )}
            {courseClass && (
              <Text style={{ textAlign: 'center', marginBottom: 20 }}>
                {`Lớp học: ${courseClass.code} - ${moment(courseClass.start_date).format('DD-MM-YYYY')} - ${
                  courseClass.address
                }`}
              </Text>
            )}
            <Descriptions bordered column={4} layout="vertical">
              <Descriptions.Item label="Cần thu">
                {item.hasChangedPrice ? (
                  numberToCurrency(item.totalPrice)
                ) : (
                  <EditField
                    field="total_price"
                    defaultValueField="totalPrice"
                    item={item}
                    onEdit={editData}
                    updateData={updateData}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Đã thu">{numberToCurrency(totalIncome)}</Descriptions.Item>
              <Descriptions.Item label="Còn lại">
                {numberToCurrency(parseFloat(item.totalPrice) - totalIncome)}
              </Descriptions.Item>
              <Descriptions.Item label="Trạng thái">
                <Status status={item.status} />
              </Descriptions.Item>
              <Descriptions.Item span={6} label="Phiếu thu">
                {(mySale || user.roles.includes('course_manager')) && (
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Button
                      type="primary"
                      onClick={() => createSubIncomeRef.current.open(item.id)}
                      style={{ marginBottom: 10 }}
                    >
                      Tạo phiếu thu
                    </Button>
                  </div>
                )}
                <Table
                  dataSource={item.subIncomes}
                  columns={columns(onEdit, deleteSubIncome, onAccountantConfirm, mySale, false)}
                  pagination={false}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button size="large" style={{ width: 150 }} onClick={close}>
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default View;
