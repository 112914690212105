import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { AlignCenterOutlined, DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Affix } from 'antd';
import { StyledMenu } from './styles';
import {
  adminRoutes,
  workingRoutes,
  contentRoutes,
  studentRoutes,
  supportRoutes,
  courseManagerRoutes,
  salerRoutes,
  saleManagerRoutes,
  notificationRoutes
} from './routes';

const { SubMenu } = Menu;

const TabMenu = () => {
  const user = useSelector((state) => state.user.data);
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);
  const [menuClassName, setMenuClassName] = useState('');

  const menuContent = () => {
    let menuItems = workingRoutes;
    const isSaler = user.roles.includes('saler');
    const isAdmin = user.roles.includes('admin');
    const isSaleManager = user.roles.includes('sale_manager');
    const isManager = user.roles.includes('manager');
    const isAccountant = user.roles.includes('accountant');
    const isCourseManagement = user.roles.includes('course_manager');
    const isStudentManager = user.roles.includes('student_manager');
    const isSupportCourse = user.hasSupportCourse;

    if (isAdmin || isAccountant || isStudentManager) menuItems = menuItems.concat(studentRoutes);
    if (isAdmin) menuItems = menuItems.concat(adminRoutes);
    if (isAdmin || isManager) menuItems = menuItems.concat(contentRoutes);
    if (isCourseManagement) menuItems = menuItems.concat(courseManagerRoutes);
    if (isSupportCourse) menuItems = menuItems.concat(supportRoutes);
    if (isSaler) menuItems = menuItems.concat(salerRoutes);
    if (isSaleManager) menuItems = menuItems.concat(saleManagerRoutes);
    if (isAdmin) menuItems = menuItems.concat(notificationRoutes);

    return menuItems;
  };

  const onScroll = (affixed) => {
    if (affixed) setMenuClassName('fixed-tab-menu');
    if (!affixed && menuClassName !== '') setMenuClassName('');
  };

  useEffect(() => {
    setSelected(location.pathname);
  }, [location.pathname]);

  return (
    <Affix onChange={onScroll}>
      <StyledMenu
        overflowedIndicator={<AlignCenterOutlined style={{ fontSize: 20 }} />}
        selectedKeys={[selected]}
        mode="horizontal"
        className={menuClassName}
      >
        {menuContent().map((item) => {
          if (item.groupChildren) {
            return (
              <SubMenu
                popupClassName="custom-submenu"
                key={item.pathname}
                icon={item.icon}
                title={
                  <span>
                    {item.label}
                    <DownOutlined style={{ marginLeft: 10, marginRight: 0, fontSize: 13 }} />
                  </span>
                }
              >
                {item.groupChildren.map((groupItem) => (
                  <Menu.ItemGroup key={groupItem.key} title={groupItem.title}>
                    {groupItem.children.map((child) => (
                      <Menu.Item key={child.pathname}>
                        <Link to={child.pathname}>{child.label}</Link>
                      </Menu.Item>
                    ))}
                  </Menu.ItemGroup>
                ))}
              </SubMenu>
            );
          }

          if (item.children) {
            return (
              <SubMenu
                popupClassName="custom-submenu"
                key={item.pathname}
                icon={item.icon}
                title={
                  <span>
                    {item.label}
                    <DownOutlined style={{ marginLeft: 10, marginRight: 0, fontSize: 13 }} />
                  </span>
                }
              >
                {item.children.map((child) => (
                  <Menu.Item key={child.pathname}>
                    <Link to={child.pathname}>{child.label}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }
          return (
            <Menu.Item key={item.pathname} icon={item.icon}>
              <Link to={item.pathname}>{item.label}</Link>
            </Menu.Item>
          );
        })}
      </StyledMenu>
    </Affix>
  );
};

export default TabMenu;
