import React, {
  useRef, useCallback,
} from 'react';
import {
  DeleteOutlined, AuditOutlined, PlusOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  List, Card, Typography, Button, Popconfirm, Row, Col,
} from 'antd';
import {
  ActionWrapper, CardImage, ContentWrapper,
} from './styles';
import { onRemoveCourseClassTeacher } from '../../../saga';
import AddTeacher from './AddTeacher';
import TeacherInfo from './TeacherInfo';

const { Text } = Typography;

const actionIconStyle = { fontSize: 18, cursor: 'pointer' };

const Teacher = ({
  courseClassTeachers, courseClassId, setData, courseTeachers,
}) => {
  const dispatch = useDispatch();
  const createRef = useRef();
  const infoRef = useRef();

  const callback = (response) => {
    setData((state) => ({ ...state, info: { ...state.info, courseClassTeachers: response } }));
  };

  const removeTeacher = useCallback((params) => dispatch(
    onRemoveCourseClassTeacher(params, callback),
  ), [dispatch]);

  const getCurrentTeachers = () => courseClassTeachers.map((item) => item.courseTeacherId);

  return (
    <Row>
      <AddTeacher ref={createRef} callback={callback} courseTeachers={courseTeachers} courseClassId={courseClassId} currentCourseTeacherIds={getCurrentTeachers()} />
      <TeacherInfo ref={infoRef} />
      <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => createRef.current.open() }
          type='primary'
          icon={<PlusOutlined />}
        >
          Thêm giảng viên
        </Button>
      </Col>
      <Col span={24}>
        <List
          grid={{ gutter: 24, column: 6 }}
          dataSource={courseClassTeachers}
          renderItem={({ id, user }) => (
            <List.Item>
              <Card
                bodyStyle={{ padding: '10px 15px' }}
                actions={[
                  <ActionWrapper>
                    <Popconfirm
                      placement='top'
                      title='Bạn có muốn loại Giảng viên này ra khỏi lớp học?'
                      onConfirm={() => removeTeacher({ courseClassId, course_class_teacher_id: id })}
                      okText='Có'
                      cancelText='Không'
                    >
                      <DeleteOutlined style={actionIconStyle} />
                      <Text style={{ marginLeft: 5 }}>Xóa</Text>
                    </Popconfirm>

                  </ActionWrapper>,

                  <ActionWrapper onClick={() => infoRef.current.open(user)}>
                    <AuditOutlined style={actionIconStyle} />
                    <Text style={{ marginLeft: 5 }}>Thông tin</Text>
                  </ActionWrapper>,
                ]}
                hoverable
                cover={<CardImage shape='square' src={user.avatarUrl} />}
              >
                <ContentWrapper>
                  <Text strong>{user.name}</Text>
                  <Text>{user.email}</Text>
                  <Text>{user.phone}</Text>
                </ContentWrapper>
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};
export default Teacher;
