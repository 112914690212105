import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, DatePicker, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import UploadList from '../../../../components/UploadList';
import SelectSearch from '../../../../components/SelectSearch';
import { fieldValidate, numberToCurrency, removeCurrency, dateFormat } from '../../../../shared/function';
import { onEditData } from '../saga';

const { Item } = Form;
const { Option } = Select;

const Edit = React.forwardRef(({ fetchData, employees, isManager, categories }, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [selectedType, setSelecteType] = useState('');
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    if (uploadRef.current) uploadRef.current.reset();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onEditData(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const formData = new FormData();
    if (selectedType === 'personal') formData.append('company_expense[user_id]', values.userId);
    if (selectedType === 'company') formData.append('company_expense[receive_person]', values.receive_person);
    formData.append('company_expense[note]', values.note);
    formData.append('company_expense[time]', values.time.format('DD/MM/YYYY'));
    formData.append('company_expense[expense_type]', values.expenseType);
    if (values.isDebt !== undefined) formData.append('company_expense[is_debt]', values.isDebt);
    formData.append('company_expense[amount]', removeCurrency(values.amount));
    formData.append('company_expense[company_category_id]', values.company_category_id);
    formData.append('company_expense[status]', values.status ? 'approve' : 'waiting_for_approve');

    if (values.images) {
      values.images.forEach((file) => {
        formData.append('company_expense[images][]', file.id ? file.id : file);
      });
    }

    editData({ formData, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        ...item,
        company_category_id: item.companyCategoryId,
        status: item.status === 'approve',
        receive_person: item.receivePerson,
        time: moment(item.time),
        amount: numberToCurrency(item.amount)
      });

      setSelecteType(item.expenseType);
      setState({ status: true, item });
    }
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      width={992}
      title={`Chỉnh sửa phiếu chi: ${item.code || ''}`}
    >
      <Form layout="vertical" form={form} name="createTopic" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={8}>
            <Item name="company_category_id" rules={[fieldValidate.required]} label="Mã phiếu">
              <SelectSearch placeholder="Mã phiếu">
                {categories.map((category) => (
                  <Option value={category.id} key={category.id}>
                    {category.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={8}>
            <Item name="expenseType" rules={[fieldValidate.required]} label="Loại">
              <Select placeholder="Loại" onChange={setSelecteType}>
                <Option value="personal">Nhân viên</Option>
                <Option value="company">Cá nhân</Option>
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item name="time" rules={[fieldValidate.required]} label="Ngày lập phiếu">
              <DatePicker format={dateFormat} style={{ width: '100%' }} />
            </Item>
          </Col>
          {selectedType === 'personal' && (
            <>
              <Col span={12}>
                <Item name="userId" rules={[fieldValidate.required]} label="Nhân viên">
                  <SelectSearch placeholder="Nhân viên">
                    {employees.map((employee) => (
                      <Option value={employee.id} key={employee.id}>
                        {employee.name}
                      </Option>
                    ))}
                  </SelectSearch>
                </Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Tính vào công nợ" name="isDebt" valuePropName="checked">
                  <Switch checkedChildren="Có" unCheckedChildren="Không" />
                </Form.Item>
              </Col>
            </>
          )}
          {selectedType === 'company' && (
            <Col span={24}>
              <Form.Item label="Người nhận" name="receive_person" rules={[fieldValidate.required]}>
                <Input placeholder="Người nhận" />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Item name="amount" rules={[fieldValidate.required]} label="Số tiền">
              <Input
                placeholder="Số tiền"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    amount: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tình trạng" name="status" valuePropName="checked">
              <Switch checkedChildren="Đã xác nhận" unCheckedChildren="Chưa xác nhận" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Item name="note" label="Nội dung chi">
              <Input.TextArea placeholder="Nội dung chi" />
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh" name="images" />
            <UploadList
              defaultValue={item.images || []}
              limitFile={10}
              ref={uploadRef}
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          {(isManager || (!isManager && item.status === 'waiting_for_approve')) && (
            <Button
              disabled={submiting}
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 150, marginRight: 20 }}
            >
              Lưu
            </Button>
          )}
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
