import React, { useState, useEffect, useRef } from 'react';
import { Layout, Row } from 'antd';
import { useLocation } from 'react-router';
import SubTopic from './SubTopic';
import Post from './Post';
import AllPost from './AllPost';
import CreatePost from '../Modal/CreatePost';

const { Content } = Layout;

const ForumContent = ({ currentFilter, changeLocation, createPostRef }) => {
  const location = useLocation();
  const [modeState, setModeState] = useState('allPost');
  const postRef = useRef();

  useEffect(() => {
    if (currentFilter.current.topic_id && !currentFilter.current.sub_topic_id) {
      setModeState('subTopic');
    } else if (currentFilter.current.sub_topic_id) {
      setModeState('post');
    } else if (currentFilter.current.sub_topic_id) {
      setModeState('allPost');
    }
  }, [location.search]);

  return (
    <Layout style={{ padding: '0', background: 'white' }}>
      <Content
        style={{
          padding: 24,
          margin: 0,
          maxHeight: '65vh',
          overflow: 'auto'
        }}
      >
        <Row>
          <CreatePost
            ref={createPostRef}
            postRef={postRef}
            currentTopic={currentFilter.current.sub_topic_id}
          />
          {modeState === 'subTopic' && (
            <SubTopic currentFilter={currentFilter} changeLocation={changeLocation} />
          )}
          {modeState === 'post' && (
            <Post ref={postRef} changeLocation={changeLocation} currentFilter={currentFilter} />
          )}
          {modeState === 'allPost' && (
            <AllPost changeLocation={changeLocation} currentFilter={currentFilter} />
          )}
        </Row>
      </Content>
    </Layout>
  );
};

export default ForumContent;
