import {
  post, get, put, delete as axiosDelete,
} from 'axios';
import { takeEvery, takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_TASKS = 'ON_GET_TASKS';
const ON_GET_TASK = 'ON_GET_TASK';
const ON_CREATE_TASK = 'ON_CREATE_TASK';
const ON_EDIT_TASK = 'ON_EDIT_TASK';
const ON_DELETE_TASK = 'ON_DELETE_TASK';
const ON_ASSIGNEE_EDIT_TASK = 'ON_ASSIGNEE_EDIT_TASK';
const ON_DUPLICATE_TASK = 'ON_DUPLICATE_TASK';

function* getTasks({ params, callback }) {
  try {
    const response = yield call(get, '/user_tasks', { params });
    callback(200, response);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* getTask({ id, callback }) {
  try {
    const response = yield call(get, `/user_tasks/${id}`);
    callback(200, response);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* deleteTask({ id, callback }) {
  try {
    yield call(axiosDelete, `/user_tasks/${id}`);
    Notification.success({ message: 'Xóa task thành công.' });
    callback();
  } catch (error) {
    console.log(error);
  }
}

function* duplicateTask({ id, callback }) {
  try {
    yield call(post, `/user_tasks/${id}/duplicate`);
    Notification.success({ message: 'Duplicate task thành công.' });
    callback();
  } catch (error) {
    console.log(error);
  }
}

function* createTask({ params, callback }) {
  try {
    yield call(post, '/user_tasks', params);

    Notification.success({ message: 'Tạo task thành công' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* editTask({ params, callback }) {
  try {
    const response = yield call(put, `/user_tasks/${params.id}`, params);
    if (callback) {
      Notification.success({ message: 'Chỉnh sửa thành công.' });
      callback(200, response);
    }
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    if (callback) callback(400);
    console.log(error);
  }
}

function* assigneeUpdateTask({ params, callback }) {
  try {
    const response = yield call(put, `/user_tasks/${params.id}`, params.formData);
    if (callback) {
      Notification.success({ message: 'Chỉnh sửa task thành công.' });
      callback(200, response);
    }
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    if (callback) callback(400);
    console.log(error);
  }
}

export const onAssigneeEditTask = (params, callback) => ({
  type: ON_ASSIGNEE_EDIT_TASK, params, callback,
});

export const onEditTask = (params, callback) => ({
  type: ON_EDIT_TASK, params, callback,
});

export const onCreateTask = (params, callback) => ({
  type: ON_CREATE_TASK, params, callback,
});

export const onGetTasks = (params, callback) => ({
  type: ON_GET_TASKS, params, callback,
});

export const onGetTask = (id, callback) => ({
  type: ON_GET_TASK, id, callback,
});

export const onDeleteTask = (id, callback) => ({
  type: ON_DELETE_TASK, id, callback,
});

export const onDuplicateTask = (id, callback) => ({
  type: ON_DUPLICATE_TASK, id, callback,
});

export default function* blogWatcher() {
  yield takeLatest(ON_DELETE_TASK, deleteTask);
  yield takeEvery(ON_GET_TASKS, getTasks);
  yield takeEvery(ON_GET_TASK, getTask);
  yield takeLatest(ON_CREATE_TASK, createTask);
  yield takeLatest(ON_EDIT_TASK, editTask);
  yield takeLatest(ON_ASSIGNEE_EDIT_TASK, assigneeUpdateTask);
  yield takeLatest(ON_DUPLICATE_TASK, duplicateTask);
}
