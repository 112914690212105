import React, { useCallback, useState, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Form, Button, Modal, DatePicker, Select, TimePicker } from 'antd';
import moment from 'moment';
import { fieldValidate, dateFormat } from '../../../../../../../../shared/function';
import SelectSearch from '../../../../../../../../components/SelectSearch';
import { onEditCourseClassLesson } from '../../../saga';

const { Option } = Select;

const Edit = React.forwardRef(
  ({ courseClassId, courseClassTeachers, courseLessons, fetchData, classStudents }, ref) => {
    const [state, setState] = useState({ status: false, item: {} });
    const dispatch = useDispatch();
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm();
    const { status, item } = state;

    const onClose = () => {
      form.resetFields();
      setState({ status: false, item: {} });
    };

    const callback = (status) => {
      if (status === 200) {
        onClose();
        fetchData();
      }
      setSubmiting(false);
    };

    useImperativeHandle(ref, () => ({
      open: (item) => {
        console.log(item);
        form.setFieldsValue({
          time: moment(item.time, 'HH:mm'),
          date: moment(item.date, dateFormat),
          course_class_teacher_ids: item.courseClassLessonTeachers.map(
            (teacher) => teacher.course_class_teacher_id
          ),
          course_lesson_id: item.courseLessonId,
          class_student_ids: item.courseClassStudents.map((student) => student.id)
        });
        setState({ status: true, item });
      }
    }));

    const editData = useCallback((params) => dispatch(onEditCourseClassLesson(params, callback)), [dispatch]);

    const modifyStudent = (selectedItem) => {
      const newItem = [];
      const remove = [];
      const classStudentIds = item.classLessonStudents.map((student) => student.course_class_student_id);

      selectedItem.map((id) => {
        const filtered = classStudentIds.filter((a) => a === parseInt(id, 10))[0];

        if (!filtered) {
          newItem.push({
            course_class_student_id: id
          });
        }
      });

      item.classLessonStudents.map((student) => {
        if (!selectedItem.includes(student.course_class_student_id)) {
          remove.push({
            id: student.id,
            _destroy: true
          });
        }
      });

      return newItem.concat(remove);
    };

    const modifyTeacher = (selectedItem) => {
      const newItem = [];
      const remove = [];
      const courseClassTeacherIds = item.courseClassLessonTeachers.map(
        (teacher) => teacher.course_class_teacher_id
      );

      selectedItem.map((id) => {
        const filtered = courseClassTeacherIds.filter((a) => a === parseInt(id, 10))[0];

        if (!filtered) {
          newItem.push({
            course_class_teacher_id: id
          });
        }
      });

      item.courseClassLessonTeachers.map((lessonTeacher) => {
        if (!selectedItem.includes(lessonTeacher.course_class_teacher_id)) {
          remove.push({
            id: lessonTeacher.id,
            _destroy: true
          });
        }
      });

      return newItem.concat(remove);
    };

    const onFinish = (values) => {
      setSubmiting(true);
      values.date = values.date.format(dateFormat);
      values.time = values.time.format('HH:mm');
      values.class_lesson_students_attributes = modifyStudent(values.class_student_ids || []);
      values.course_class_lesson_teachers_attributes = modifyTeacher(values.course_class_teacher_ids || []);

      editData({ course_class_lesson: { ...values, id: item.id }, courseClassId });
    };

    return (
      <Modal closable={false} visible={status} footer={null} width={1024} title="Chỉnh sửa lịch học">
        <Form layout="vertical" form={form} name="editClassClendar" scrollToFirstError onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Ngày" name="date" rules={[fieldValidate.required]}>
                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder="Ngày" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Giờ" name="time" rules={[fieldValidate.required]}>
                <TimePicker format="HH:mm" style={{ width: '100%' }} placeholder="Giờ" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="course_lesson_id" rules={[fieldValidate.required]} label="Buổi học">
                <SelectSearch placeholder="Buổi học">
                  {courseLessons.map((lesson) => (
                    <Option value={lesson.id} key={lesson.id}>
                      {lesson.title}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="course_class_teacher_ids" rules={[fieldValidate.required]} label="Giảng viên">
                <SelectSearch placeholder="Giảng viên" mode="multiple">
                  {courseClassTeachers.map((teacher) => (
                    <Option value={teacher.id} key={teacher.id}>
                      {teacher.name}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="class_student_ids" label="Học viên">
                <SelectSearch placeholder="Học viên" mode="multiple">
                  {classStudents.map((student) => (
                    <Option
                      value={student.id}
                      key={student.id}
                    >{`${student.user.firstName} ${student.user.lastName}`}</Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 20 }}>
            <Button type="primary" size="large" style={{ width: 150 }} htmlType="submit" disabled={submiting}>
              Lưu
            </Button>
            <Button size="large" style={{ width: 150, marginLeft: 20 }} onClick={onClose}>
              Đóng
            </Button>
          </Row>
        </Form>
      </Modal>
    );
  }
);

export default Edit;
