import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Modal, Descriptions } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import Table from '../../../shared/Table';
import { onGetMemberships, onDeleteMembership, onGetMembershipSelectValue } from './saga';
import CreateModal from './Modal/Create';
import EditModal from './Modal/Edit';
import Filter from './Filter';

const View = () => {
  const user = useSelector((state) => state.user.data);
  const location = useLocation();
  const history = useHistory();
  const createRef = useRef();
  const editRef = useRef();
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState({ students: [], courses: [] });
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });

  const isAdmin = user.roles.includes('admin');
  const currentFilter = useRef(queryString.parse(location.search));
  const { students, courses } = selectValue;
  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getSelectValue = useCallback(() => dispatch(onGetMembershipSelectValue(setSelectValue)), [dispatch]);

  const getData = useCallback((params) => dispatch(onGetMemberships(params, setState)), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/admin/memberships',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const deleteData = useCallback((params) => dispatch(onDeleteMembership(params, fetchData)), [dispatch]);

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  const onEdit = (item) => editRef.current.open(item);

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn xóa phân hạng này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        deleteData({ id: item.id });
      }
    });
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);

    fetchData();
  }, [location.search]);

  useEffect(() => {
    getSelectValue();
  }, []);

  return (
    <>
      <CreateModal ref={createRef} fetchData={fetchData} courses={courses} students={students} />
      <EditModal ref={editRef} fetchData={fetchData} courses={courses} />
      <Table
        extra={
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Platinum (> 300 triệu)">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>+ Giảm 50% phí retake tất cả các lớp học (không áp dụng cho lớp POS)</span>
                <span>+ Mức phí ưu đãi khi tham dự các hội nghị của Y Company</span>
                <span>
                  + Giảm 10% khi đăng ký các lớp học mới của Y Company (đối với các lớp có học phí >15 triệu)
                </span>
                <span>+ Tham gia các chương trình mua chung của Y Company</span>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Gold (100-300 triệu)">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>+ Giảm 25% phí retake tất cả các lớp học (không áp dụng cho lớp POS)</span>
                <span>+ Mức phí ưu đãi khi tham dự các hội nghị của Y Company</span>
                <span>
                  + Giảm 5% khi đăng ký các lớp học mới của Y Company (đối với các lớp có học phí >15 triệu)
                </span>
                <span>+ Tham gia các chương trình mua chung của Y Company</span>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Silver - dưới 100 triệu">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>+ Xem tài liệu khoá học, thư viện mở, bài viết free</span>
              </div>
            </Descriptions.Item>
          </Descriptions>
        }
        filter={
          <Filter
            currentFilter={currentFilter}
            changeLocation={changeLocation}
            createRef={createRef}
            fetchData={fetchData}
          />
        }
        title="Phân hạng học viên"
        columns={columns(onEdit, onDelete, isAdmin)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default View;
