import {
  get, put as axiosPut, post, delete as axiosDelete,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../../../../../../components/Notification';
import { getErrorMessage } from '../../../../../../../../shared/function';

const ON_GET_CLASS_FORUMS = 'ON_GET_CLASS_FORUMS';
const ON_REMOVE_CLASS_FORUM = 'ON_REMOVE_CLASS_FORUM';
const ON_REMOVE_CLASS_FORUM_COMMENT = 'ON_REMOVE_CLASS_FORUM_COMMENT';
const ON_CREATE_CLASS_FORUM_POST = 'ON_CREATE_CLASS_FORUM_POST';
const ON_UPDATE_CLASS_FORUM_POST = 'ON_UPDATE_CLASS_FORUM_POST';

function* getClassForums({ params, setData }) {
  try {
    const response = yield call(get, '/class_forums', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* removeClassForum({ params, callback }) {
  try {
    yield call(axiosDelete, `/class_forums/${params.id}`);
    Notification.success({ message: 'Xóa bài viết thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* removeClassForumComment({ params, callback }) {
  try {
    yield call(axiosDelete, `/class_forums/${params.forumId}/remove_comment`, { params });
    Notification.success({ message: 'Xóa bình luận thành công.' });
    callback(params.comment_id);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* createPost({ params, callback }) {
  try {
    const response = yield call(post, '/class_forums/cretate_forum_post', params.formData);
    callback(200, response);
    Notification.success({ message: 'Đăng bài viết thành công.' });
  } catch (error) {
    console.log(error);
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* updatePost({ params, callback }) {
  try {
    const response = yield call(axiosPut, '/class_forums/update_forum_post', params.formData);
    callback(200, response);
    Notification.success({ message: 'Chỉnh sửa bài viết thành công.' });
  } catch (error) {
    console.log(error);
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onRemoveClassForum = (params, callback) => ({
  type: ON_REMOVE_CLASS_FORUM, params, callback,
});

export const onGetClassForums = (params, setData) => ({
  type: ON_GET_CLASS_FORUMS, params, setData,
});

export const onRemoveClassForumComment = (params, callback) => ({
  type: ON_REMOVE_CLASS_FORUM_COMMENT, params, callback,
});

export const onCreatePost = (params, callback) => ({
  type: ON_CREATE_CLASS_FORUM_POST, params, callback,
});

export const onUpdatePost = (params, callback) => ({
  type: ON_UPDATE_CLASS_FORUM_POST, params, callback,
});

export default function* forumWatcher() {
  yield takeLatest(ON_UPDATE_CLASS_FORUM_POST, updatePost);
  yield takeLatest(ON_CREATE_CLASS_FORUM_POST, createPost);
  yield takeLatest(ON_GET_CLASS_FORUMS, getClassForums);
  yield takeLatest(ON_REMOVE_CLASS_FORUM, removeClassForum);
  yield takeLatest(ON_REMOVE_CLASS_FORUM_COMMENT, removeClassForumComment);
}
