import React, { useEffect, useCallback, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Select, Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { onGetSelectValue } from './saga';
import SelectSearch from '../../../components/SelectSearch';

const { Option } = Select;

const Filter = ({ currentFilter, changeLocation }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [state, setState] = useState({ students: [], courses: [] });
  const { students, courses } = state;

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.resetFields();
    changeLocation();
  };

  const getData = useCallback(() => dispatch(onGetSelectValue(setState)), [dispatch]);

  useEffect(() => {
    const { user_id, course_id, type } = queryString.parse(location.search);

    form.setFieldsValue({
      user_id: user_id || '',
      course_id: course_id || '',
      type: type || ''
    });

    getData();
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item name="user_id" label="Học viên:">
            <SelectSearch onChange={(e) => onFilter('user_id', e)}>
              [<Option value="">Tất cả</Option>,
              {students.map((user) => (
                <Option
                  value={user.id.toString()}
                  key={user.id}
                >{`${user.firstName} ${user.lastName}`}</Option>
              ))}
              ]
            </SelectSearch>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item name="course_id" label="Khóa học:">
            <SelectSearch onChange={(e) => onFilter('course_id', e)}>
              [<Option value="">Tất cả</Option>,
              {courses.map((course) => (
                <Option value={course.id.toString()} key={course.id}>
                  {course.name}
                </Option>
              ))}
              ]
            </SelectSearch>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item name="type" label="Tình trạng:">
            <SelectSearch onChange={(e) => onFilter('type', e)}>
              <Option value="">Còn nợ</Option>
              <Option value="done">Đã trả hết</Option>
            </SelectSearch>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={6}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
              Xóa bộ lọc
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
