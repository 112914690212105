import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Row, Col, Input, Form, Select, Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';

const { Option, OptGroup } = Select;

const Filter = ({ currentFilter, changeLocation, type, hashtags }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.setFieldsValue({
      keyword: '',
      status: ''
    });
    changeLocation();
  };

  useEffect(() => {
    const { keyword, status, hashtag_id } = queryString.parse(location.search);

    form.setFieldsValue({
      keyword: keyword || '',
      status: status || '',
      hashtag_id: hashtag_id ? parseInt(hashtag_id) : ''
    });
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item name="keyword" label="Từ khóa:">
            <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
          </Form.Item>
        </Col>
        {type === 'specialty' && (
          <>
            <Col span={24} md={8} lg={8} xl={6} xxl={4}>
              <Form.Item name="status" label="Loại:">
                <Select onChange={(e) => onFilter('status', e)}>
                  <Option value="">Tất cả </Option>
                  <Option value="paid">Trả phí</Option>
                  <Option value="unpaid">Không trả phí</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={8} lg={8} xl={6} xxl={4}>
              <Form.Item name="hashtag_id" label="Hashtag:">
                <Select onChange={(e) => onFilter('hashtag_id', e)}>
                  <Option value="">Tất cả </Option>
                  <OptGroup label="Trả phí">
                    {(groupBy(hashtags || [], 'status')?.paid || []).map((hashtag) => (
                      <Option value={hashtag.id}>{hashtag.name}</Option>
                    ))}
                  </OptGroup>
                  <OptGroup label="Không trả phí">
                    {(groupBy(hashtags || [], 'status')?.publish || []).map((hashtag) => (
                      <Option value={hashtag.id}>{hashtag.name}</Option>
                    ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={24} md={12} lg={12} xl={8} xxl={4}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
              Xóa bộ lọc
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
