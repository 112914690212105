import React, {
  useState, useImperativeHandle, useCallback, useRef,
} from 'react';
import {
  Col, Row, Button, Table, Modal,
} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { onGetCourseLessons, onRemoveCourseLesson } from '../../../saga';
import { columns } from './function';
import { StyledModal } from './styles';
import Create from './Create';
import Edit from './Edit';

const Lesson = React.forwardRef(({
  courseId, setData,
}, ref) => {
  const dispatch = useDispatch();
  const createRef = useRef();
  const editRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [state, setState] = useState({
    loading: true, items: [], documents: [],
  });

  const close = () => {
    setShowModal(false);
  };

  const {
    loading, items, documents,
  } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback(() => dispatch(
    onGetCourseLessons({ courseId }, (response) => {
      setState(response);
      setData(response.items);
    }),
  ), [dispatch]);

  const fetchData = () => {
    showLoading();
    getData();
  };

  const removeDocument = useCallback((params) => dispatch(
    onRemoveCourseLesson(params, fetchData),
  ), [dispatch]);

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn xóa buổi học này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => { removeDocument({ courseId, course_lesson_id: item.id }); },
    });
  };

  const onEdit = (item) => editRef.current.open(item);

  useImperativeHandle(ref, () => ({
    open: () => {
      fetchData();
      setShowModal(true);
    },
  }));

  return (
    <StyledModal
      closable={true}
      visible={isShow}
      footer={null}
      onCancel={close}
      title='Buổi học'
    >
      <Create ref={createRef} courseId={courseId} fetchData={fetchData} documents={documents} />
      <Edit ref={editRef} courseId={courseId} fetchData={fetchData} documents={documents} />
      <Row>
        <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => createRef.current.open() }
            type='primary'
            icon={<PlusOutlined />}
          >
            Thêm buổi học
          </Button>
        </Col>
        <Col span={24}>
          <Table
            columns={columns(onEdit, onDelete)}
            loading={loading}
            dataSource={items}
            pagination={false}
          />
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          size='large'
          style={{ width: 150 }}
          onClick={close}
        >
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default Lesson;
