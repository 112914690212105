import React, {
  useRef, useEffect, useState, useCallback, useImperativeHandle,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
  EditOutlined, DeleteOutlined,
  CheckOutlined, ClockCircleOutlined, PauseCircleOutlined, EyeOutlined, PlusSquareOutlined, DeliveredProcedureOutlined,
} from '@ant-design/icons';
import {
  List, Typography, Tooltip, Popconfirm, Space, Divider, Avatar, Popover,
} from 'antd';
import {
  StyledList, Wrapper, Header, Owner, Description, TimeWrapper, Time, TitleWrapper, StatusWrapper,
} from '../styles';
import CreateSubProject from '../Modal/CreateSubProject';
import EditSubProject from '../Modal/EditSubProject';
import PreviewProject from '../Modal/PreviewProject';
import {
  onGetTasks, onDeleteTask, onEditTask, onGetTask, onDuplicateTask,
} from '../saga';
import StatusText from '../StatusText';

const { Title } = Typography;

const SubProject = React.forwardRef(({
  changeLocation, currentFilter, taskRef, employees,
}, ref) => {
  const location = useLocation();
  const createRef = useRef();
  const editRef = useRef();
  const previewRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false, items: [], currentPage: 0, totalPages: 0,
  });
  const [project, setProject] = useState({});
  const [selected, setSelected] = useState(currentFilter.current.sub_project_id);
  const {
    items, loading,
  } = state;

  const callback = (status, response) => {
    if (status === 200) {
      setState({
        loading: false,
        items: response.items,
        totalPages: response.meta.totalPages,
        currentPage: response.meta.currentPage,
      });
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
  };

  const showLoading = () => setState((state) => ({
    ...state,
    loading: true,
  }));

  const getData = useCallback((params) => dispatch(
    onGetTasks(params, callback),
  ), [dispatch]);

  const getProject = useCallback((id) => dispatch(
    onGetTask(id, (status, response) => {
      if (status === 200) setProject(response);
    }),
  ), [dispatch]);

  const fetchData = (response) => {
    if (currentFilter.current.project_id) {
      showLoading();
      getData({ task_type: '1', parent_id: currentFilter.current.project_id });

      if (response && response.id === parseInt(currentFilter.current.sub_project_id, 10)) taskRef.current.refresh();
    }
  };

  const deleteData = useCallback((id) => dispatch(
    onDeleteTask(id, () => {
      currentFilter.current.sub_project_id = '';
      taskRef.current.emptyData();
      changeLocation();
      fetchData();
    }),
  ), [dispatch]);

  const duplicateTask = useCallback((id) => dispatch(
    onDuplicateTask(id, () => {
      fetchData();
    }),
  ), [dispatch]);

  const changeStatus = useCallback((id, status) => dispatch(
    onEditTask({ id, status }, fetchData),
  ), [dispatch]);

  const actionGroup = (item) => (item.canModify ? [
    <Tooltip placement='bottom' title={item.status === 'complete' ? 'Đang thực hiện' : 'Xác nhận đã hoàn thành'}>
      <Popconfirm
        placement='top'
        title={item.status === 'complete' ? 'Bạn có muốn chuyển thành trạng thái đang thực hiện' : 'Bạn có muốn xác nhận sub-project này đã hoàn thành?'}
        onConfirm={() => changeStatus(item.id, item.status === 'complete' ? 'processing' : 'complete')}
        okText='Có'
        cancelText='Không'
      >
        {item.status !== 'complete' ? <CheckOutlined style={{ cursor: 'pointer' }} /> : <PauseCircleOutlined style={{ cursor: 'pointer' }} />}
      </Popconfirm>
    </Tooltip>,
    <Tooltip placement='bottom' title='Chỉnh sửa'>
      <EditOutlined style={{ cursor: 'pointer' }} onClick={() => editRef.current.open(item)} />
    </Tooltip>,
    <Tooltip placement='bottom' title='Xóa'>
      <Popconfirm placement='top' title='Bạn có muốn xóa Sub Project này không?' onConfirm={() => deleteData(item.id)} okText='Có' cancelText='Không'>
        <DeleteOutlined style={{ cursor: 'pointer' }} />
      </Popconfirm>
    </Tooltip>,
    <Tooltip placement='bottom' title='Duplicate Sub-Project'>
      <Popconfirm placement='top' title='Bạn có muốn Duplicate Sub Project này không?' onConfirm={() => duplicateTask(item.id)} okText='Có' cancelText='Không'>
        <DeliveredProcedureOutlined style={{ cursor: 'pointer' }} />
      </Popconfirm>
    </Tooltip>,
    <Tooltip placement='bottom' title='Thêm Task'>
      <PlusSquareOutlined style={{ cursor: 'pointer' }} onClick={() => taskRef.current.openCreateModal(item)} />
    </Tooltip>,
  ] : [
    <Tooltip placement='bottom' title='Xem chi tiết'>
      <EyeOutlined style={{ cursor: 'pointer' }} onClick={() => previewRef.current.open(item)} />
    </Tooltip>,
    (item.myTask || item.mySupportTask) && (
    <Tooltip placement='bottom' title='Thêm Task'>
      <PlusSquareOutlined style={{ cursor: 'pointer' }} onClick={() => taskRef.current.openCreateModal(item)} />
    </Tooltip>
    ),
  ]);

  useImperativeHandle(ref, () => ({
    getProjectData: (id) => getProject(id),
    refresh: () => {
      if (currentFilter.current.project_id) {
        getData({ task_type: '1', parent_id: currentFilter.current.project_id });
        getProject(currentFilter.current.project_id);
      }
    },
    emptyData: () => setState({
      loading: false, items: [], currentPage: 0, totalPages: 0,
    }),
    openCreateModal: (item) => createRef.current.open(item),
  }));

  useEffect(() => {
    if (currentFilter.current.project_id) {
      getData({ task_type: '1', parent_id: currentFilter.current.project_id });
      getProject(currentFilter.current.project_id);
      setSelected(currentFilter.current.sub_project_id);
    }
  }, [location.search]);

  return (
    <Wrapper>
      <PreviewProject ref={previewRef} />
      <CreateSubProject ref={createRef} employees={employees} fetchData={fetchData} />
      <EditSubProject ref={editRef} employees={employees} fetchData={fetchData} />
      <Header color={project.color}>
        <Title level={4} style={{ color: '#fff', marginBottom: 0 }}>{`${project.title || 'Sub-Project'} (${items.length})`}</Title>
      </Header>
      <StyledList
        itemLayout='horizontal'
        dataSource={items}
        loading={loading}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            className={ parseInt(selected, 10) === item.id && 'active'}
            actions={actionGroup(item)}
          >
            <Popover
              content={
                (
                  <Description>
                    <Space split={<Divider type='vertical' />}>
                      <Owner>{`Tạo bởi: ${item.ownerInfo ? item.ownerInfo.name : ''}`}</Owner>
                      <Owner>
                        Nhân viên:
                        <Avatar.Group
                          style={{ marginLeft: 10 }}
                          maxCount={1}
                          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                        >
                          {item.assigneeInfo.map((assignee) => (
                            <Tooltip key={assignee.name} title={assignee.name} placement='top'>
                              <Avatar src={assignee.avatarUrl} />
                            </Tooltip>
                          ))}
                        </Avatar.Group>
                      </Owner>
                      {item.supporterInfo.length > 0 && (
                      <Owner>
                        Trợ thủ:
                        <Avatar.Group
                          style={{ marginLeft: 10 }}
                          maxCount={1}
                          maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                        >
                          {item.supporterInfo.map((support) => (
                            <Tooltip key={support.name} title={support.name} placement='top'>
                              <Avatar src={support.avatarUrl} />
                            </Tooltip>
                          ))}
                        </Avatar.Group>
                      </Owner>
                      )}
                      {item.time && (
                      <TimeWrapper>
                        <ClockCircleOutlined />
                        <Time>{item.time}</Time>
                      </TimeWrapper>
                      )}
                    </Space>
                  </Description>
                )
              }
              title='Thông tin'
            >
              <List.Item.Meta
                onClick={() => {
                  currentFilter.current.sub_project_id = item.id;
                  setSelected(item.id);
                  changeLocation();
                }}
                title={(
                  <TitleWrapper>
                    <StatusWrapper>
                      <StatusText status={item.status} />
                    </StatusWrapper>
                    <div>{item.title}</div>
                  </TitleWrapper>
                )}
              />
            </Popover>
          </List.Item>
        )}
      />
    </Wrapper>
  );
});

export default SubProject;
