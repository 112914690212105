import React, {
  useState, useImperativeHandle, useCallback, useRef,
} from 'react';
import {
  Col, Row, Button, Descriptions, Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import { onGetCourseGifts } from './saga';
import { StyledModal } from './styles';
import EditCourseGift from './EditCourseGift';

const { Text } = Typography;

const Gift = React.forwardRef(({ courseId }, ref) => {
  const dispatch = useDispatch();
  const editCourseGiftRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [state, setState] = useState({
    gifts: [], courseGifts: []
  });

  const close = () => {
    setShowModal(false);
  };

  const {
    gifts, courseGifts,
  } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback(() => dispatch(
    onGetCourseGifts({ courseId }, setState),
  ), [dispatch]);

  const fetchData = () => {
    showLoading();
    getData();
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      fetchData();
      setShowModal(true);
    },
  }));

  return (
    <StyledModal
      closable={true}
      visible={isShow}
      footer={null}
      onCancel={close}
      title='Quà tặng khóa học'
    >
      <EditCourseGift
        ref={editCourseGiftRef}
        courseId={courseId}
        fetchData={fetchData}
        gifts={gifts}
        courseGifts={courseGifts}
      />
      <Row>
        <Col span={24} style={{ marginBottom: 15 }}>
          <Descriptions layout='vertical' bordered column={4}>
            <Descriptions.Item label='Quà tặng' span={3}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {gifts.map((gift) => (
                  <Text key={gift}>{`- ${gift}`}</Text>
                ))}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label='Thao tác'>
              <Button type='primary' onClick={() => editCourseGiftRef.current.open() }>Chỉnh sửa</Button>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          size='large'
          style={{ width: 150 }}
          onClick={close}
        >
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

export default Gift;
