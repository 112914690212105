import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../shared/function';
import { onNewHashtag } from '../saga';

const { Item } = Form;
const { Option } = Select;

const Create = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(
    onNewHashtag(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    createData(values);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      title='Thêm Hashtag'
    >
      <Form
        layout='vertical'
        form={form}
        name='createTopic'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Item
              name='name'
              label='Tên'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tên' />
            </Item>
          </Col>
          <Col span={24}>
            <Item
              label='Loại'
              name='status'
              rules={[fieldValidate.required]}
            >
              <Select placeholder='Loại'>
                <Option value='paid'>Trả phí</Option>
                <Option value='publish'>Không trả phí</Option>
              </Select>
            </Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
