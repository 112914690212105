import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import Table from '../../../shared/Table';
import { onGetEmployees } from '../Employees/saga';
import { onGetCompanyCategories } from '../CompanyCategories/saga';
import { onGetData, onDeleteData, onEditData } from './saga';
import Filter from './Filter';
import CreateModal from './Modal/Create';
import EditModal from './Modal/Edit';
import PrintModal from './Modal/Print';

const View = () => {
  const user = useSelector((state) => state.user.data);
  const [employees, setEmployees] = useState([]);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const createRef = useRef();
  const printRef = useRef();
  const editRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });

  const currentFilter = useRef(queryString.parse(location.search));
  const isManager = user.roles && user.roles.includes('accountant_manager');

  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetData(params, setState)), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/admin/company-expenses',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const deleteCourseTopic = useCallback((params) => dispatch(onDeleteData(params, fetchData)), [dispatch]);

  const editData = useCallback((params) => dispatch(onEditData(params, fetchData)), [dispatch]);

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);

    fetchData();
  }, [location.search]);

  useEffect(() => {
    dispatch(onGetEmployees({ per_page: 9999 }, (response) => setEmployees(response.items)));
    dispatch(
      onGetCompanyCategories({ category_type: '1', per_page: 9999 }, (response) =>
        setCategories(response.items)
      )
    );
  }, []);

  const onEdit = (item) => editRef.current.open(item);
  const onPrint = (item) => printRef.current.open(item);

  const onDelete = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn xóa phiếu này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        deleteCourseTopic({ id: item.id });
      }
    });
  };

  const onUpdateStatus = (id, status) => {
    const formData = new FormData();
    formData.append('company_expense[status]', status);
    editData({ formData, id });
  };

  return (
    <>
      <PrintModal ref={printRef} />
      <CreateModal ref={createRef} fetchData={fetchData} employees={employees} categories={categories} />
      <EditModal
        ref={editRef}
        fetchData={fetchData}
        employees={employees}
        isManager={isManager}
        categories={categories}
      />
      <Table
        filter={
          <Filter
            currentFilter={currentFilter}
            changeLocation={changeLocation}
            employees={employees}
            createRef={createRef}
          />
        }
        title="Danh sách phiếu chi"
        columns={columns(onEdit, onDelete, onPrint, onUpdateStatus, isManager)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default View;
