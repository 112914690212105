import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Tag } from 'antd';
import styled from 'styled-components';
import { numberToCurrency } from '../../../shared/function';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete, isAdmin) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {}
  },
  {
    title: 'Học viên',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Hạng',
    dataIndex: 'memberType',
    key: 'member_type',
    sorter: {},
    render: (memberType) => {
      switch (memberType) {
        case 'platinum':
          return <Tag color="lime">Platinum</Tag>;
        case 'gold':
          return <Tag color="green">Gold</Tag>;
        case 'silver':
          return <Tag color="cyan">Silver</Tag>;
        default:
          return '';
      }
    }
  },
  {
    title: 'Tổng tiền đã đóng',
    dataIndex: 'amount',
    key: 'amount',
    sorter: {},
    render: (amount) => numberToCurrency(amount)
  },
  {
    title: 'Khóa học đã tham gia',
    dataIndex: 'course',
    key: 'course',
    render: (course) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {(course || []).map((a) => (
          <span>- {a}</span>
        ))}
      </div>
    )
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              {isAdmin && (
                <Menu.Item onClick={() => onDelete(item)}>
                  <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    )
  }
];
