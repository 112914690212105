import { get, post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';

const ON_GET_USER_QUESTION = 'ON_GET_USER_QUESTION';
const ON_REPLY_USER_QUESTION = 'ON_REPLY_USER_QUESTION';

function* getUserQuestions({ params, setData }) {
  try {
    const response = yield call(get, '/user_questions', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* replyQuestion({ id, params, callback }) {
  try {
    yield call(post, `/user_questions/${id}/reply_email`, params);
    callback(200);
    Notification.success({ message: 'Câu trả lời đã được gửi tới khách hàng.' });
  } catch (error) {
    callback(400);
    console.log(error);
  }
}

export const onReplyQuestion = (id, params, callback) => ({
  type: ON_REPLY_USER_QUESTION, id, params, callback,
});

export const onGetUserQuestions = (params, setData) => ({
  type: ON_GET_USER_QUESTION, params, setData,
});

export default function* userQuestionWatcher() {
  yield takeLatest(ON_GET_USER_QUESTION, getUserQuestions);
  yield takeLatest(ON_REPLY_USER_QUESTION, replyQuestion);
}
