import {
  get, put as axiosPut, post, delete as axiosDelete,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_GROUP_PROMOTIONS = 'ON_GET_GROUP_PROMOTIONS';
const ON_EDIT_GROUP_PROMOTION = 'ON_EDIT_GROUP_PROMOTION';
const ON_NEW_GROUP_PROMOTION = 'ON_NEW_GROUP_PROMOTION';
const ON_REMOVE_GROUP_PROMOTION = 'ON_REMOVE_GROUP_PROMOTION';

function* getPromotions({ params, setData }) {
  try {
    const response = yield call(get, '/group_promotions', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* editPromotion({ params, callback }) {
  try {
    yield call(axiosPut, `/group_promotions/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa khuyến mãi thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newPromotion({ params, callback }) {
  try {
    yield call(post, '/group_promotions', params);
    Notification.success({ message: 'Thêm khuyến mãi thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removePromotion({ params, callback }) {
  try {
    yield call(axiosDelete, `/group_promotions/${params.id}`);
    Notification.success({ message: 'Xóa khuyến mãi thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewPromotion = (params, callback) => ({
  type: ON_NEW_GROUP_PROMOTION, params, callback,
});

export const onEditPromotion = (params, callback) => ({
  type: ON_EDIT_GROUP_PROMOTION, params, callback,
});

export const onGetPromotions = (params, setData) => ({
  type: ON_GET_GROUP_PROMOTIONS, params, setData,
});

export const onDeletePromotion = (params, callback) => ({
  type: ON_REMOVE_GROUP_PROMOTION, params, callback,
});

export default function* promotionWatcher() {
  yield takeLatest(ON_GET_GROUP_PROMOTIONS, getPromotions);
  yield takeLatest(ON_EDIT_GROUP_PROMOTION, editPromotion);
  yield takeLatest(ON_NEW_GROUP_PROMOTION, newPromotion);
  yield takeLatest(ON_REMOVE_GROUP_PROMOTION, removePromotion);
}
