import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate } from '@shared/function';
import Upload from '@components/Upload';
import { onEditTopic } from '../saga';

const { Item } = Form;

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmitting(false);
  };

  const editData = useCallback((params) => dispatch(onEditTopic(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmitting(true);
    editData({ ...values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        name: item.name
      });
      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} width={992} title="Chỉnh sửa chủ đề khóa học">
      <Form layout="vertical" form={form} name="editTopic" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Item name="name" label="Chủ đề" rules={[fieldValidate.required]}>
              <Input placeholder="Chủ đề" />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item label="Hình ảnh" name="image">
              <Upload
                defaultValue={item.image}
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
