import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined, AppstoreOutlined, BarChartOutlined } from '@ant-design/icons';
import NoticeTask from './NoticeTask';
import DeadlineTask from './DeadlineTask';

const { TabPane } = Tabs;

const MyTasks = () => {
  const history = useHistory();

  const changeTab = (key) => {
    if (key === 'all') history.push('/admin/user-tasks');
    if (key === 'myTask') history.push('/admin/user-tasks/my-task');
  };

  return (
    <Tabs defaultActiveKey='overview' onTabClick={changeTab}>
      <TabPane
        tab={(
          <span>
            <AppstoreOutlined />
            Tất cả
          </span>
        )}
        key='all'
      />
      <TabPane
        tab={(
          <span>
            <UserOutlined />
            Công việc của tôi
          </span>
        )}
        key='myTask'
      />
      <TabPane
        tab={(
          <span>
            <BarChartOutlined />
            Tổng quan
          </span>
        )}
        key='overview'
      >
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <NoticeTask />
          </Col>
          <Col xs={24} md={24} lg={12}>
            <DeadlineTask />
          </Col>
        </Row>
      </TabPane>
    </Tabs>

  );
};

export default MyTasks;
