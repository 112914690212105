import React, {
  useState, useImperativeHandle, useCallback, useRef,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker, Typography,
} from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  customValidate, fieldValidate,
} from '../../../../../shared/function';
import SelectSearch from '../../../../../components/SelectSearch';
import Upload from '../../../../../components/Upload';
import UploadList from '../../../../../components/UploadList';
import { onUpdateProfile } from './saga';

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

const Profile = React.forwardRef(({}, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const user = useSelector((state) => state.user.data);
  const [isShow, setShowModal] = useState(false);
  const [images, setUserImages] = useState([]);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const listYears = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 40)).fill('').map((v, idx) => now - idx);
  };

  const close = () => {
    if (uploadRef.current) uploadRef.current.reset();
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) close();

    setSubmiting(false);
  };

  const updateProfile = useCallback((params) => dispatch(
    onUpdateProfile(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    updateProfile({ ...values, roles: user.roles });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setUserImages(user.images || [])
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      width={992}
      title='Thông tin cá nhân'
    >
      <Form
        layout='vertical'
        form={form}
        name='profile'
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          birthday: user.birthday ? moment(user.birthday, 'DD/MM/YYYY') : moment(),
          phone: user.phone,
          email: user.email,
          gender: user.gender,
          job: user.job,
          specialty: user.teacherInfo ? user.teacherInfo.specialty : '',
          introduce: user.teacherInfo ? user.teacherInfo.introduce : '',
          graduation_year: user.teacherInfo ? user.teacherInfo.graduation_year : '',
          school: user.teacherInfo ? user.teacherInfo.school : '',
          specialty_introduce: user.teacherInfo ? user.teacherInfo.specialty_introduce : '',
        }}
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='email'
              label='E-mail'
              rules={[fieldValidate.required]}
            >
              <Input />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='gender'
              rules={[fieldValidate.required]}
              label='Giới tính'
            >
              <Select placeholder='Giới tính'>
                <Option value='male'>Nam</Option>
                <Option value='female'>Nữ</Option>
              </Select>
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='firstName'
              label='Họ'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Họ' />
            </Item>
            <Item
              name='lastName'
              label='Tên'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tên' />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='avatar'
            >
              <Upload
                circle
                defaultValue={user.avatarUrl}
                onFileChange={(file) => form.setFieldsValue({ avatar: file })}
              />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='birthday'
              label='Ngày sinh'
            >
              <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='phone'
              label='Số điện thoại'
              rules={[
                fieldValidate.required,
                () => ({
                  validator(rule, value) {
                    return customValidate.validateNumber(value);
                  },
                }),
              ]}
            >
              <Input placeholder='Số điện thoại' />
            </Item>
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Item
              name='job'
              label='Nghề nghiệp'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Nghề nghiệp' />
            </Item>
          </Col>
          {user.roles.includes('teacher')
            && (
            <>
              <Col span={24} style={{ marginTop: 20 }}>
                <Title level={3}>Thông tin giảng viên</Title>
              </Col>
              <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                <Item
                  rules={[fieldValidate.required]}
                  name='specialty'
                  label='Chuyên môn'
                >
                  <Input placeholder='Chuyên môn' />
                </Item>
              </Col>
              <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                <Item
                  rules={[fieldValidate.required]}
                  name='school'
                  label='Tốt nghiệp trường'
                >
                  <Input placeholder='Tốt nghiệp trường' />
                </Item>
              </Col>
              <Col sm={{ span: 8 }} xs={{ span: 24 }}>
                <Item
                  name='graduation_year'
                  rules={[fieldValidate.required]}
                  label='Năm tốt nghiệp'
                >
                  <SelectSearch placeholder='Năm tốt nghiệp'>
                    {listYears().map((year) => <Option value={year} key={year}>{year}</Option>)}
                  </SelectSearch>
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  name='introduce'
                  label='Giới thiệu'
                >
                  <Input.TextArea placeholder='Giới thiệu' rows={4} />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  name='specialty_introduce'
                  label='Chuyên môn và bằng cấp'
                >
                  <Input.TextArea placeholder='Chuyên môn và bằng cấp' rows={4} />
                </Item>
              </Col>
              <Col span={24}>
                <Form.Item label='Hình ảnh' name='images' />
                <UploadList
                  noCrop={false}
                  limitFile={10}
                  aspect={1.92}
                  ref={uploadRef}
                  defaultValue={images}
                  circle
                  onChange={(images) => form.setFieldsValue({ images })}
                />
              </Col>
            </>
            )}
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 200, marginRight: 20 }}
          >
            Cập nhập thông tin
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Profile;
