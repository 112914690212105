import { post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../components/Notification';

export const ON_LOGIN = 'ON_LOGIN';
export const ON_LOGIN_AS = 'ON_LOGIN_AS';
export const ON_CLINIC_LOGIN_AS = 'ON_CLINIC_LOGIN_AS';
export const ON_LOCK_USER = 'ON_LOCK_USER';
export const ON_UNLOCK_USER = 'ON_UNLOCK_USER';
export const ON_ACTIVATE_USER = 'ON_ACTIVATE_USER';

function* authentication({ params }) {
  try {
    yield call(post, '/user/sign_in', params);

    Notification.success({ message: 'Đăng nhập thành công.' });
    if (params.redirect_to) {
      window.location = params.redirect_to;
    } else {
      window.location = '/';
    }
  } catch (error) {
    Notification.error({ message: 'Email hoặc mật khẩu không chính xác.' });
  }
}

function* merchantLoginAs({ userId, clinicId, role }) {
  yield call(post, `/users/${userId}/login_as`, { roleId: clinicId, role });
  window.location = '/';
}

function* loginAs({ userId }) {
  yield call(post, `/users/${userId}/login_as`);
  window.location = '/';
}

function* lockUser({ userId, callback }) {
  yield call(post, `/users/${userId}/lock`);
  callback();
}

function* unlockUser({ userId, callback }) {
  yield call(post, `/users/${userId}/unlock`);
  callback();
}

function* activateUser({ userId, callback }) {
  yield call(post, `/users/${userId}/activate`);
  callback();
}

export const onLogin = (params) => ({
  type: ON_LOGIN,
  params
});

export const onLoginAs = (userId) => ({
  type: ON_LOGIN_AS,
  userId
});

export const onMerchantLoginAs = (userId, clinicId, role) => ({
  type: ON_CLINIC_LOGIN_AS,
  userId,
  clinicId,
  role
});

export const onLockUser = (userId, callback) => ({
  type: ON_LOCK_USER,
  userId,
  callback
});

export const onUnlockUser = (userId, callback) => ({
  type: ON_UNLOCK_USER,
  userId,
  callback
});

export const onActivate = (userId, callback) => ({
  type: ON_ACTIVATE_USER,
  userId,
  callback
});

export default function* loginWatcher() {
  yield takeLatest(ON_LOGIN, authentication);
  yield takeLatest(ON_LOGIN_AS, loginAs);
  yield takeLatest(ON_LOCK_USER, lockUser);
  yield takeLatest(ON_UNLOCK_USER, unlockUser);
  yield takeLatest(ON_ACTIVATE_USER, activateUser);
  yield takeLatest(ON_CLINIC_LOGIN_AS, merchantLoginAs);
}
