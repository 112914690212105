import React, {
  useState, useImperativeHandle,
} from 'react';
import {
  Row, Modal, Button, Tag, Typography, Avatar, Form, Space, Divider,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Owner, Label, Item, StyledCol,
} from './styles';

const { Text, Title } = Typography;

const Preview = React.forwardRef(({}, ref) => {
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();

  const close = () => {
    setState({ status: false, item: {} });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={true}
      onCancel={close}
      visible={status}
      footer={null}
      width={700}
    >
      <Form
        layout='vertical'
        form={form}
        name='editTask'
        scrollToFirstError
      >
        <Row gutter={48}>
          <StyledCol span={24}>
            <Title level={4}>
              {item.title || ''}
            </Title>
            <Owner>{`Tạo bởi: ${item.ownerInfo ? item.ownerInfo.name : ''}`}</Owner>
            <div style={{ marginTop: 10 }}>
              <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                {item.time}
              </Tag>
            </div>
          </StyledCol>
          {item.assigneeInfo && (
            <StyledCol span={24}>
              <Label>Nhân viên:</Label>
              <Space>
                {item.assigneeInfo.map((assignee) => (
                  <Item>
                    <Avatar size={30} src={assignee.avatarUrl} />
                    <Text style={{ marginLeft: 10 }}>{assignee.name}</Text>
                  </Item>
                ))}
              </Space>
            </StyledCol>
          )}
          {item.supporterInfo && (
            <StyledCol span={24}>
              <Label>Trợ thủ:</Label>
              <Space>
                {item.supporterInfo.map((assignee) => (
                  <Item>
                    <Avatar size={30} src={assignee.avatarUrl} />
                    <Text style={{ marginLeft: 10 }}>{assignee.name}</Text>
                  </Item>
                ))}
              </Space>
            </StyledCol>
          )}
          <StyledCol span={24}>
            <Label>Nội dung:</Label>
            <div className='ck-content' dangerouslySetInnerHTML={{ __html: item.content || '' }} />
          </StyledCol>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Preview;
