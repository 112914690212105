import React from 'react';
import {
  EditOutlined, DeleteOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ActionWrapper } from './styles';

export const layout = {
  wrapperCol: { span: 16 },
};

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {},

  },
  {
    title: 'Câu hỏi',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: 'Danh mục',
    dataIndex: 'group',
    key: 'group',
    sorter: {},
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {},
  },
  {
    title: '',
    width: 120,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Chỉnh sửa' color='geekblue' key='geekblue'>
          <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onEdit(item)} />
        </Tooltip>
        <Tooltip title='Xóa' color='red' key='red'>
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];
