import {
  get, put as axiosPut, post, delete as axiosDelete,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_GIFTS = 'ON_GET_GIFTS';
const ON_EDIT_GIFT = 'ON_EDIT_GIFT';
const ON_NEW_GIFT = 'ON_NEW_GIFT';
const ON_REMOVE_GIFT = 'ON_REMOVE_GIFT';

function* getGifts({ params, setData }) {
  try {
    const response = yield call(get, '/gifts', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* editGift({ params, callback }) {
  try {
    yield call(axiosPut, `/gifts/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa quà tặng thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newGift({ params, callback }) {
  try {
    yield call(post, '/gifts', params);
    Notification.success({ message: 'Thêm quà tặng thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeGift({ params, callback }) {
  try {
    yield call(axiosDelete, `/gifts/${params.id}`);
    Notification.success({ message: 'Xóa quà tặng thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewGift = (params, callback) => ({
  type: ON_NEW_GIFT, params, callback,
});

export const onEditGift = (params, callback) => ({
  type: ON_EDIT_GIFT, params, callback,
});

export const onGetGifts = (params, setData) => ({
  type: ON_GET_GIFTS, params, setData,
});

export const onDeleteGift = (params, callback) => ({
  type: ON_REMOVE_GIFT, params, callback,
});

export default function* TopicWatcher() {
  yield takeLatest(ON_GET_GIFTS, getGifts);
  yield takeLatest(ON_EDIT_GIFT, editGift);
  yield takeLatest(ON_NEW_GIFT, newGift);
  yield takeLatest(ON_REMOVE_GIFT, removeGift);
}
