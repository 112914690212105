import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Row, Button, Modal, Table, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { onGetCourseClasses } from '../../saga';
import { columns } from './function';
import Create from '../Create';
import Edit from '../Edit';

const AllClassView = React.forwardRef(({ courseId, teachers, courseClasses, setData }, ref) => {
  const currentFilter = useRef({ course_id: courseId });
  const dispatch = useDispatch();
  const createRef = useRef();
  const editRef = useRef();
  let typing = null;
  const [form] = Form.useForm();
  const [isShow, setShowModal] = useState(false);
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 0
  });

  const close = () => {
    setShowModal(false);
  };

  const { loading, items, totalPages, currentPage } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback(() => dispatch(onGetCourseClasses(currentFilter.current, setState)), [
    dispatch
  ]);

  const fetchData = () => {
    showLoading();
    getData();
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    fetchData();
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      currentFilter.current.page = 1;
      fetchData();
    }, 1000);
  };

  const onEdit = (item) => editRef.current.open(item);

  useImperativeHandle(ref, () => ({
    open: () => {
      currentFilter.current.page = 1;
      currentFilter.current.keyword = '';
      form.resetFields();
      fetchData();
      setShowModal(true);
    }
  }));

  return (
    <Modal width="80%" closable={true} visible={isShow} footer={null} onCancel={close} title="Tất cả lớp học">
      <Create
        ref={createRef}
        courseId={courseId}
        setData={(response) => {
          setData((state) => state.concat(response));
          fetchData();
        }}
        teachers={teachers}
        courseClasses={courseClasses}
      />
      <Edit ref={editRef} courseId={courseId} fetchData={fetchData} />
      <Row>
        <Col span={24} style={{ marginBottom: 10, display: 'flex', justifyContent: 'space-bettween' }}>
          <Form layout="vertical" form={form} style={{ width: '100%' }}>
            <Row gutter={24}>
              <Col span={24} md={8} lg={8} xl={6} xxl={6}>
                <Form.Item name="keyword" label="Từ khóa:">
                  <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Button onClick={() => createRef.current.open()} type="primary" icon={<PlusOutlined />}>
            Thêm lớp học
          </Button>
        </Col>
        <Col span={24}>
          <Table
            columns={columns(teachers, fetchData, onEdit)}
            loading={loading}
            dataSource={items}
            onChange={onChange}
            pagination={{
              total: 30 * totalPages,
              position: ['', 'bottomCenter'],
              pageSize: 30,
              showLessItems: true,
              current: currentPage,
              defaultCurrent: 1
            }}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button size="large" style={{ width: 150 }} onClick={close}>
          Đóng
        </Button>
      </Row>
    </Modal>
  );
});

export default AllClassView;
