import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Menu, Dropdown, Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { fallbackImage } from '../../../../../../../../shared/function';

const { Text } = Typography;

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Tiêu đề',
    render: ({ courseLessonTitle, courseLessonDescription }) => (
      <Tooltip title={courseLessonDescription} color="#108ee9" key="#108ee9">
        {courseLessonTitle}
      </Tooltip>
    )
  },
  {
    title: 'Giảng viên',
    render: ({ teachers }) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {teachers.map((name, index) => (
          <Text key={index}>{`- ${name}`}</Text>
        ))}
      </div>
    )
  },
  {
    title: 'Học viên',
    render: ({ courseClassStudents }) => {
      return (
        <Avatar.Group maxCount={5}>
          {courseClassStudents.map(({ user }, index) => (
            <Tooltip title={user.name} placement="top">
              <Avatar src={user.avatarUrl || fallbackImage} />
            </Tooltip>
          ))}
        </Avatar.Group>
      );
    }
  },
  {
    title: 'Loại',
    dataIndex: 'courseLessonLessonType',
    render: (lessonType) => {
      switch (lessonType) {
        case 'practice':
          return 'Thực hành';
        case 'theory':
          return 'Lý thuyết';
        case 'theory_and_practice':
          return 'Lý thuyết & Thực hành';
        default:
          return '';
      }
    }
  },
  {
    title: 'Ngày',
    dataIndex: 'date'
  },
  {
    title: 'Giờ',
    dataIndex: 'time'
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    )
  }
];
