import React from 'react';
import {
  CheckOutlined, PauseCircleOutlined, CloseCircleOutlined, BellOutlined, PlayCircleOutlined,
} from '@ant-design/icons';
import {
  Tag,
} from 'antd';
import {
  NoticeTag,
} from '../styles';

const StatusText = ({ status, style }) => {
  switch (status) {
    case 'pending':
      return (
        <Tag icon={<PauseCircleOutlined />} color='#681a7f' style={style}>
          Chưa bắt đầu
        </Tag>
      );
    case 'processing':
      return (
        <Tag icon={<PlayCircleOutlined />} color='orange' style={style}>
          Đang thực hiện
        </Tag>
      );
    case 'complete':
      return (
        <Tag icon={<CheckOutlined />} color='green' style={style}>
          Hoàn thành
        </Tag>
      );
    case 'expired':
      return (
        <Tag icon={<CloseCircleOutlined />} color='red' style={style}>
          Trễ deadline
        </Tag>
      );
    case 'notice':
      return (
        <NoticeTag className='button-pulse' style={style}>
          <div className='button__wrapper'>
            <div className='pulsing' />
            <button>
              <BellOutlined className='bell' style={{ marginRight: 5 }} />
              Sắp tới deadline
            </button>
          </div>
        </NoticeTag>
      );
    default:
      return null;
  }
};

export default StatusText;
