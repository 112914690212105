import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-menu {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar
    {
      width: 3px;
      background-color: #F5F5F5;
    }

    ::-webkit-scrollbar-thumb
    {
      background-color: #001529;
      border: 1px solid #555555;
    }
  }
`;

export default StyledSider;
