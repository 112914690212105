import React, { useState, useEffect, useCallback, useRef } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import Table from '../../../shared/Table';
import { onGetEmployees } from '../Employees/saga';
import { onGetData } from './saga';
import Filter from './Filter';
import Childrens from './Childrens';

const View = () => {
  const [employees, setEmployees] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });

  const currentFilter = useRef(queryString.parse(location.search));

  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetData(params, setState)), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/admin/employee-debts',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);

    fetchData();
  }, [location.search]);

  useEffect(() => {
    dispatch(onGetEmployees({ per_page: 9999 }, (response) => setEmployees(response.items)));
  }, []);

  return (
    <>
      <Table
        filter={
          <Filter currentFilter={currentFilter} changeLocation={changeLocation} employees={employees} />
        }
        expandable={{
          expandedRowRender: (record) => <Childrens data={record.subIncomes} />
        }}
        title="Công nợ của nhân viên"
        columns={columns()}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default View;
