import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Row, Form, Modal, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { onUpdatePermission } from '../saga';
import SelectSearch from '../../../../components/SelectSearch';

const { Item } = Form;
const { Option } = Select;

const Permission = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onUpdatePermission(params, callback)), [dispatch]);

  const onFinish = (values) => {
    editData({ forum_member: values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        position: item.forumMemberPosition
      });

      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} title="Thay đỏi chức vụ">
      <Form layout="vertical" form={form} name="addPosition" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={24}>
            <Item name="position">
              <SelectSearch>
                <Option value="member">Thành viên</Option>
                <Option value="moderator">Moderator</Option>
                <Option value="admin">Admin</Option>
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Permission;
