import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Row, Col, Form, Button, DatePicker, Input, Select } from 'antd';
import { FilterOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';

const Filter = ({ currentFilter, changeLocation, createRef }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.resetFields();
    changeLocation();
  };

  useEffect(() => {
    const { time_gteq, time_lteq, keyword, confirmed, accountant_confirm } = queryString.parse(
      location.search
    );

    form.setFieldsValue({
      keyword: keyword || '',
      accountant_confirm: accountant_confirm || '',
      confirmed: confirmed || '',
      time: time_gteq && time_lteq ? [moment(time_gteq, dateFormat), moment(time_lteq, dateFormat)] : []
    });
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item name="keyword" label="Từ khóa:">
            <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={4}>
          <Form.Item name="time" label="Ngày lập phiếu:">
            <RangePicker
              format={dateFormat}
              style={{ width: '100%' }}
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              onChange={(dates) => {
                currentFilter.current.time_gteq = dates ? dates[0].format(dateFormat) : null;
                currentFilter.current.time_lteq = dates ? dates[1].format(dateFormat) : null;
                changeLocation();
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="accountant_confirm" label="Duyệt bởi kế toán:">
            <Select onChange={(e) => onFilter('accountant_confirm', e)}>
              <Option value="">Tất cả </Option>
              <Option value="true">Đã duyệt</Option>
              <Option value="false">Chưa duyệt</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="confirmed" label="Xác nhận:">
            <Select onChange={(e) => onFilter('confirmed', e)}>
              <Option value="">Tất cả </Option>
              <Option value="true">Đã xác nhận</Option>
              <Option value="false">Chưa xác nhận</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={4}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
              Xóa bộ lọc
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button
          style={{ marginRight: 10 }}
          onClick={() => createRef.current.open()}
          type="primary"
          icon={<PlusOutlined />}
        >
          Tạo phiếu thu
        </Button>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => {
            const { keyword, time_gteq, time_lteq, confirmed, accountant_confirm } = currentFilter.current;
            window.open(
              `/export-incomes?export=true&keyword=${keyword || ''}&time_gteq=${time_gteq || ''}&confirmed=${
                confirmed || ''
              }&accountant_confirm=${accountant_confirm || ''}&time_lteq=${time_lteq || ''}`
            );
          }}
        >
          Xuất Excel
        </Button>
      </Row>
    </Form>
  );
};

export default Filter;
