import {
  get, put as axiosPut, post, delete as axiosDelete,
} from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_NEWS = 'ON_GET_NEWS';
const ON_EDIT_NEWS = 'ON_EDIT_NEWS';
const ON_NEW_NEWS = 'ON_NEW_NEWS';
const ON_REMOVE_NEWS = 'ON_REMOVE_NEWS';

function* getNews({ params, setData }) {
  try {
    const response = yield call(get, '/news', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
    });
  } catch (error) {
    console.log(error);
  }
}

function* editNews({ params, callback }) {
  try {
    yield call(axiosPut, `/news/${params.id}`, params.formData);
    Notification.success({ message: 'Chỉnh sửa bài viết thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newNews({ params, callback }) {
  try {
    yield call(post, '/news', params);
    Notification.success({ message: 'Thêm bài viết thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeNews({ params, callback }) {
  try {
    yield call(axiosDelete, `/news/${params.id}`);
    Notification.success({ message: 'Xóa bài viết thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewNews = (params, callback) => ({
  type: ON_NEW_NEWS, params, callback,
});

export const onEditNews = (params, callback) => ({
  type: ON_EDIT_NEWS, params, callback,
});

export const onGetNews = (params, setData) => ({
  type: ON_GET_NEWS, params, setData,
});

export const onDeleteNews = (params, callback) => ({
  type: ON_REMOVE_NEWS, params, callback,
});

export default function* NewsWatcher() {
  yield takeLatest(ON_GET_NEWS, getNews);
  yield takeLatest(ON_EDIT_NEWS, editNews);
  yield takeLatest(ON_NEW_NEWS, newNews);
  yield takeLatest(ON_REMOVE_NEWS, removeNews);
}
