import React, {
  useState, useImperativeHandle, useCallback, useRef,
} from 'react';
import {
  Col, Row, Form, Button, Select, Modal,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../../shared/function';
import SelectSearch from '../../../../../components/SelectSearch';

import { onEditCourseField } from '../../saga';

const { Item } = Form;
const { Option } = Select;

const AddSupport = React.forwardRef(({ employees, setData, item }, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    if (uploadRef.current) uploadRef.current.reset();
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status, response) => {
    if (status === 200) {
      setData(response);
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditCourseField(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    const params = values.supports.map((id) => ({
      user_id: id,
      permission: 'support',
    }));
    setSubmiting(true);
    editData({ course: { course_managers_attributes: params }, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      title='Thêm trợ thủ'
    >
      <Form
        layout='vertical'
        form={form}
        name='addSupport'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Item
              name='supports'
              rules={[fieldValidate.required]}
              label='Trợ thủ'
            >
              <SelectSearch placeholder='Trợ thủ' mode='multiple'>
                {employees.map(
                  (support) => {
                    if (item.managerUserIds.includes(support.id) || item.supportUserIds.includes(support.id)) return null;
                    return (<Option value={support.id} key={support.id}>{support.name}</Option>);
                  },
                )}
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default AddSupport;
