import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, DatePicker, Switch, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import UploadList from '../../../../components/UploadList';
import SelectSearch from '../../../../components/SelectSearch';
import { fieldValidate, numberToCurrency, removeCurrency, dateFormat } from '../../../../shared/function';
import { onEditData } from '../saga';

const { Item } = Form;
const { Option } = Select;

const Edit = React.forwardRef(({ fetchData, employees, categories, isManager }, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [isDebt, setIsDebt] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(0);

  const close = () => {
    form.resetFields();
    if (uploadRef.current) uploadRef.current.reset();
    setState({ status: false, item: {} });
    setSelectedEmployee(0);
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onEditData(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const formData = new FormData();
    formData.append('company_income[title]', values.title);
    if (values.userId) formData.append('company_income[user_id]', values.userId);
    formData.append('company_income[note]', values.note);
    formData.append('company_income[is_debt]', values.isDebt);
    formData.append('company_income[income_date]', values.time.format('DD/MM/YYYY'));
    formData.append('company_income[company_category_id]', values.company_category_id);
    formData.append('company_income[price]', removeCurrency(values.amount));
    formData.append('company_income[company_category_id]', values.company_category_id);
    if (values.accountantConfirm !== undefined)
      formData.append('company_income[accountant_confirm]', values.accountantConfirm);

    if (values.images) {
      values.images.forEach((file) => {
        formData.append('company_income[images][]', file.id ? file.id : file);
      });
    }

    if (values.company_expense_id)
      formData.append('company_income[company_expense_id]', values.company_expense_id);

    editData({ formData, id: item.id });
  };

  const expenses = () => {
    const filterdEmployee = employees.filter((a) => a.id === selectedEmployee)[0];

    if (filterdEmployee) return filterdEmployee.expenses;

    return [];
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        ...item,
        accountantConfirm: item.accountantConfirm,
        company_category_id: item.companyCategoryId,
        time: moment(item.createdAt, 'DD/MM/YYYY'),
        company_expense_id: item.companyExpenseId,
        amount: numberToCurrency(item.price)
      });

      setSelectedEmployee(item.userId);
      setIsDebt(item.isDebt);

      setState({ status: true, item });
    }
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      width={992}
      title={`Chỉnh sửa phiếu thu: ${item.code || ''}`}
    >
      <Form layout="vertical" form={form} name="createTopic" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={8}>
            <Item name="company_category_id" rules={[fieldValidate.required]} label="Mã phiếu">
              <SelectSearch placeholder="Mã phiếu">
                {categories.map((category) => (
                  <Option value={category.id} key={category.id}>
                    {category.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={8}>
            <Item name="time" rules={[fieldValidate.required]} label="Ngày lập phiếu">
              <DatePicker format={dateFormat} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={isManager ? 4 : 8}>
            <Form.Item label="Tính vào công nợ" name="isDebt" valuePropName="checked">
              <Switch checkedChildren="Có" unCheckedChildren="Không" onChange={(value) => setIsDebt(value)} />
            </Form.Item>
          </Col>
          {isManager && (
            <Col span={4}>
              <Form.Item label="Kế toán duyệt" name="accountantConfirm" valuePropName="checked">
                <Switch checkedChildren="Đã duyệt" unCheckedChildren="Chưa duyệt" />
              </Form.Item>
            </Col>
          )}
          {item.incomeType === 'employee' && (
            <>
              <Col span={12}>
                <Item name="userId" rules={[fieldValidate.required]} label="Nhân viên">
                  <SelectSearch placeholder="Nhân viên" onChange={(value) => setSelectedEmployee(value)}>
                    {employees.map((employee) => (
                      <Option value={employee.id} key={employee.id}>
                        {employee.name}
                      </Option>
                    ))}
                  </SelectSearch>
                </Item>
              </Col>
              {isDebt && (
                <Col span={12}>
                  <Item name="company_expense_id" rules={[fieldValidate.required]} label="Công nợ">
                    <SelectSearch placeholder="Công nợ">
                      {expenses().map((debtItem) => (
                        <Option value={debtItem.id} key={debtItem.id}>
                          {`${debtItem.code} - ${debtItem.createdAt}`}
                        </Option>
                      ))}
                    </SelectSearch>
                  </Item>
                </Col>
              )}
            </>
          )}
          {item.incomeType === 'student' && (
            <Col span={12}>
              <Item label="Học viên ">
                <Typography.Text>{item.name}</Typography.Text>
              </Item>
            </Col>
          )}
          <Col span={12}>
            <Item name="amount" rules={[fieldValidate.required]} label="Số tiền">
              <Input
                placeholder="Số tiền"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    amount: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="title" rules={[fieldValidate.required]} label="Tiêu đề">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="note" label="Nội dung thu">
              <Input.TextArea placeholder="Nội dung thu" />
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh" name="images" />
            <UploadList
              noCrop
              limitFile={10}
              ref={uploadRef}
              circle
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          {(isManager || (!isManager && !item.accountantConfirm)) && (
            <Button
              disabled={submiting}
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 150, marginRight: 20 }}
            >
              Lưu
            </Button>
          )}
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
