import React from 'react';
import {
  GroupOutlined,
} from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import styled from 'styled-components';
import { numberToCurrency } from '../../../shared/function';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onView) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {},
  },
  {
    title: 'Học viên',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Tổng tiền',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    render: (totalPrice) => numberToCurrency(totalPrice),
  },
  {
    title: 'Đã thu',
    dataIndex: 'incomePrice',
    key: 'incomePrice',
    render: (incomePrice) => numberToCurrency(incomePrice),
  },
  {
    title: 'Còn lại',
    key: 'debt',
    render: ({ totalPrice, incomePrice }) => numberToCurrency(totalPrice - incomePrice),
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Tooltip title='Chi tiết' color='geekblue' key='geekblue'>
          <GroupOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onView(item)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
];
