import React, { useState, useImperativeHandle, useEffect, useCallback, useRef } from 'react';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import {
  Col,
  Row,
  Modal,
  Button,
  Tag,
  Typography,
  Avatar,
  Checkbox,
  Progress,
  Upload,
  Form,
  Space
} from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Owner, Label, Item, StyledCol } from './styles';
import { onAssigneeEditTask } from '../saga';
import PreviewFile from './PreviewFile';

const { Text, Title } = Typography;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const UploadComponent = React.forwardRef(
  ({ onChange, defaultValue, disabled, canDelete = true, multiple }, ref) => {
    const [fileList, setFileList] = useState([]);
    const previewRef = useRef();

    useImperativeHandle(ref, () => ({
      reset: () => {
        setFileList([]);
      }
    }));

    const handleChange = ({ fileList: newFileList }) => {
      if (onChange) {
        onChange(
          newFileList.map((file) => (file.originFileObj ? new File([file.originFileObj], file.name) : file))
        );
      }
      setFileList(newFileList);
    };

    const handlePreview = (file) => {
      if (file.url) {
        previewRef.current.open(file);
      } else {
        getBase64(file.originFileObj, (url) => {
          previewRef.current.open({ id: file.uid, url });
        });
      }
    };

    const onDownload = async (file) => {
      if (file.url) {
        saveAs(file.url, file.name);
      }
    };

    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    };

    useEffect(() => {
      if (defaultValue && defaultValue.length > 0) {
        const defaultImage = [];
        defaultValue.map((item, index) => {
          defaultImage.push({
            uid: index,
            name: item.name || `image-${index}.png`,
            status: 'done',
            url: item.url,
            id: item.id
          });
        });

        setFileList(defaultImage);
      }
    }, [defaultValue]);

    return (
      <div>
        <Upload
          multiple={multiple}
          onPreview={handlePreview}
          customRequest={dummyRequest}
          listType="picture"
          fileList={fileList}
          onChange={handleChange}
          onDownload={onDownload}
          showUploadList={{ showPreviewIcon: true, showRemoveIcon: canDelete, showDownloadIcon: true }}
        >
          {!disabled && <Button icon={<UploadOutlined />}>Tải lên</Button>}
        </Upload>

        <PreviewFile ref={previewRef} />
      </div>
    );
  }
);

const Preview = React.forwardRef(({ fetchData }, ref) => {
  const uploadImageRef = useRef();
  const uploadFileRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const [checklist, setChecklist] = useState([]);
  const { status, item, laneId } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    if (uploadImageRef.current) uploadImageRef.current.reset();
    if (uploadFileRef.current) uploadFileRef.current.reset();
    setChecklist([]);
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onAssigneeEditTask(params, callback)), [
    dispatch,
    laneId
  ]);

  const onFinish = (values) => {
    setSubmiting(true);

    const formData = new FormData();
    (checklist || []).map((item, index) => {
      formData.append('user_task[checklist][][title]', item.title);
      formData.append('user_task[checklist][][checked]', item.checked);
    });

    if (values.files) {
      values.files.forEach((file) => {
        formData.append('user_task[files][]', file.id ? file.id : file);
      });

      if (values.files.length === 0) {
        formData.append('user_task[files]', []);
      }
    }

    editData({ formData, id: item.id });
  };

  const progress = item.checklist
    ? parseInt(
        (item.checklist.filter((task) => task.checked === 'true').length / item.checklist.length) * 100
      )
    : 0;

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setChecklist(item.checklist || []);
      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={true} onCancel={close} visible={status} footer={null} width={700}>
      <Form layout="vertical" form={form} name="editTask" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <StyledCol span={24}>
            <Title level={4}>{item.title || ''}</Title>
            <Owner>{`Tạo bởi: ${item.ownerInfo ? item.ownerInfo.name : ''}`}</Owner>
            <div style={{ marginTop: 10 }}>
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                {item.datetime}
              </Tag>
            </div>
          </StyledCol>
          {item.assigneeInfo && (
            <StyledCol span={24}>
              <Label>Nhân viên:</Label>
              <Space>
                {item.assigneeInfo.map((assignee) => (
                  <Item>
                    <Avatar size={30} src={assignee.avatarUrl} />
                    <Text style={{ marginLeft: 10 }}>{assignee.name}</Text>
                  </Item>
                ))}
              </Space>
            </StyledCol>
          )}
          <StyledCol span={24}>
            <Label>Nội dung:</Label>
            <div className="ck-content" dangerouslySetInnerHTML={{ __html: item.content || '' }} />
          </StyledCol>
          {item.checklist && item.checklist.length > 0 && (
            <StyledCol span={24}>
              <Label>Danh sách công việc:</Label>
              <Row>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <Progress percent={progress} />
                </Col>
                {checklist.map((task, index) => (
                  <Col span={24} key={index}>
                    <Checkbox
                      checked={task.checked === 'true'}
                      onChange={(e) => {
                        setChecklist((state_) => {
                          state_[index].checked = e.target.checked.toString();
                          return [].concat(state_);
                        });
                      }}
                    >
                      {task.title}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </StyledCol>
          )}
          <StyledCol span={24}>
            <Label>Files:</Label>
            <Form.Item name="files">
              <UploadComponent
                onChange={(files) => form.setFieldsValue({ files })}
                ref={uploadFileRef}
                defaultValue={item.files || []}
                disabled={!item.myTask && !item.canModify}
                canDelete={item.myTask || item.canModify}
              />
            </Form.Item>
          </StyledCol>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          {(item.myTask || item.canModify) && (
            <Button
              disabled={submiting}
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 150, marginRight: 20 }}
            >
              Lưu
            </Button>
          )}
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Preview;
