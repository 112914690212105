import styled from 'styled-components';
import { Card, Image, Modal, Comment } from 'antd';

export const StyledCard = styled(Card)`
  margin-top: 15px;

  .ant-card-body {
    padding: 0;
  }

  .ant-card-meta {
    display: flex;
    align-items: center;

    .ant-card-meta-title {
      margin: 0;
    }
  }
`;

export const StyledModal = styled(Modal)`
  width: 1024px !important;
`;

export const StyledComment = styled(Comment)`
  .ant-comment-inner {
    padding: 0;
  }
`;

export const Head = styled.div`
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledImage = styled(Image)`
  height: 200px;
  object-fit: cover;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
`;

export const Description = styled.div`
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-top: 10px;
`;

export const HasTagWrapper = styled.div`
  display: flex;
  flex-flow: wrap row;
  padding: 10px 15px 15px 15px;
`;

export default StyledCard;
