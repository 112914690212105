import React, {
  useState, useImperativeHandle, useCallback, useRef, useEffect,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Typography, Avatar, Popconfirm, Tooltip, Checkbox, Tag, Space,
} from 'antd';
import { ExclamationCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldValidate,
} from '../../../../shared/function';
import { onEditTask } from '../saga';
import Ckeditor from '../../../../shared/Ckeditor';
import UploadComponent from './UploadComponent';
import {
  Action, HeaderWrapper, Items, Name, Owner, Label, Item, StyledCol,
} from './styles';

const { Text, Title } = Typography;

const Checklist = React.forwardRef(({
  defaultValues, form,
}, ref) => {
  const [stuffs, setStuffs] = useState(defaultValues);

  useImperativeHandle(ref, () => ({
    listItem: () => stuffs,
    resetStuff: () => setStuffs([]),
  }));

  const addStuff = () => {
    setStuffs(stuffs.concat([{}]));
  };

  const removeStuff = (index) => {
    const currentStuffs = stuffs;
    currentStuffs.splice(index, 1);
    setStuffs([].concat(currentStuffs));
  };

  useEffect(() => {
    setStuffs(defaultValues);
    const values = {};
    values.checklist_title = defaultValues.map((item) => item.title);
    values.checklist_check = defaultValues.map((item) => item.checked === 'true');

    form.setFieldsValue(values);
  }, [defaultValues]);

  return (
    <>
      <Text style={{ marginBottom: 10 }}>Danh sách công việc</Text>
      <Items>
        <HeaderWrapper>
          <Name withBackground>Nội dung</Name>
          <Action withBackground />
        </HeaderWrapper>
        {
          stuffs.map((item, index) => (
            <Item key={index}>
              <Name>
                <Form.Item
                  name={['checklist_check', index]}
                  valuePropName='checked'
                >
                  <Checkbox />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', marginLeft: 10 }}
                  name={['checklist_title', index]}
                  rules={[fieldValidate.required]}
                >
                  <Input placeholder='Nội dung' />
                </Form.Item>
              </Name>
              <Action>
                <Tooltip title='Delete' color='red' key='red'>
                  <Popconfirm placement='top' title='Do you want to remove this record?' onConfirm={() => removeStuff(index)} okText='Yes' cancelText='No'>
                    <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                  </Popconfirm>
                </Tooltip>
              </Action>
            </Item>
          ))
        }
        <Button
          type='dashed'
          onClick={addStuff}
          block
        >
          <PlusOutlined />
          {' '}
          Add More
        </Button>
      </Items>
    </>
  );
});

const Edit = React.forwardRef(({ fetchData }, ref) => {
  const uploadFileRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((reduxState) => reduxState.user.data);
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(
    onEditTask(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const checklist = (values.checklist_title || []).map((item, index) => ({
      title: item,
      checked: values.checklist_check[index] ? values.checklist_check[index].toString() : 'false',
    }));

    editData({
      ...values, id: item.id, checklist,
    });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      width={992}
      title='Chỉnh sửa Task'
    >
      <Form
        layout='vertical'
        form={form}
        name='memberEditTask'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <StyledCol span={24}>
            <Title level={4}>
              {item.title || ''}
            </Title>
            <Owner>{`Tạo bởi: ${item.ownerInfo ? item.ownerInfo.name : ''}`}</Owner>
            <div style={{ marginTop: 10 }}>
              <Tag icon={<ExclamationCircleOutlined />} color='warning'>
                {item.datetime}
              </Tag>
            </div>
          </StyledCol>
          {item.assigneeInfo && (
          <StyledCol span={24}>
            <Label>Nhân viên:</Label>
            <Space>
              {item.assigneeInfo.map((assignee) => (
                <Item>
                  <Avatar size={30} src={assignee.avatarUrl} />
                  <Text style={{ marginLeft: 10 }}>{assignee.name}</Text>
                </Item>
              ))}
            </Space>
          </StyledCol>
          )}
          <Col span={24}>
            <Form.Item
              name='content'
              label='Nội dung'
            >
              <Ckeditor defaultValue={item.content || ''} onChange={(value) => form.setFieldsValue({ content: value })} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Checklist form={form} defaultValues={item.checklist || []} />
          </Col>
          {((item.files && item.files.length > 0) || user.id === item.assigneeId) && (
          <Col span={24}>
            <Form.Item
              name='files'
              label='Files'
            >
              <UploadComponent
                onChange={(files) => form.setFieldsValue({ files })}
                ref={ uploadFileRef}
                defaultValue={item.files || []}
                disabled={user.id !== item.assigneeId}
                canDelete={user.id === item.assigneeId}
              />
            </Form.Item>
          </Col>
          )}
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
