import React from 'react';
import {
  EditOutlined, DeleteOutlined, MenuOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown, Tag } from 'antd';
import styled from 'styled-components';
import { numberToCurrency } from '../../../shared/function';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {},

  },
  {
    title: 'Số người',
    dataIndex: 'people',
    key: 'people',
  },
  {
    title: 'Loại',
    dataIndex: 'discountType',
    key: 'discount_type',
    sorter: {},
    render: (discountType) => {
      switch (discountType) {
        case 'percentage':
          return <Tag color='blue'>Phần trăm</Tag>;
        default:
          return <Tag color='green'>Tiền</Tag>;
      }
    },
  },
  {
    title: 'Giảm',
    dataIndex: 'amount',
    key: 'amount',
    sorter: {},
    render: (amount) => numberToCurrency(amount),
  },
  {
    title: 'Ngày hết hạn',
    dataIndex: 'expiredDate',
    key: 'epxired_date',
    sorter: {},
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {},
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Xóa
              </Menu.Item>
            </Menu>
        )}
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    ),
  },
];
