import { all } from 'redux-saga/effects';
import employeeWatcher from './Employees/saga';
import courseWatcher from './Courses/saga';
import studentWatcher from './Students/saga';
import courseTopicWatcher from './CourseTopic/saga';
import promotionWatcher from './Promotion/saga';
import groupPromotionWatcher from './GroupPromotion/saga';
import forumWatcher from './Forum/saga';
import newsWatcher from './News/saga';
import hashtagsWatcher from './Hashtags/saga';
import questionsWatcher from './Questions/saga';
import userQuestionsWatcher from './UserQuestions/saga';
import userTasksWatcher from './UserTasks/saga';
import registrationInfoWatcher from './RegistrationInfo/saga';
import debtsWatcher from './Debts/saga';
import giftsWatcher from './Gifts/saga';
import membershipWatcher from './Membership/saga';
import companyExpenseWatcher from './CompanyExpense/saga';
import companyIncomeWatcher from './CompanyIncome/saga';
import employeeDebtsWatcher from './EmployeeDebts/saga';
import companyCategoriesWatcher from './CompanyCategories/saga';
import statisticWatcher from './Statistic/saga';
import forumMembersWatcher from './ForumMembers/saga';
import notificationsWatcher from './Notifications/saga';

export default function* saga() {
  yield all([
    employeeWatcher(),
    courseWatcher(),
    studentWatcher(),
    courseTopicWatcher(),
    promotionWatcher(),
    groupPromotionWatcher(),
    forumWatcher(),
    newsWatcher(),
    hashtagsWatcher(),
    questionsWatcher(),
    userQuestionsWatcher(),
    userTasksWatcher(),
    registrationInfoWatcher(),
    debtsWatcher(),
    giftsWatcher(),
    membershipWatcher(),
    companyExpenseWatcher(),
    companyIncomeWatcher(),
    employeeDebtsWatcher(),
    companyCategoriesWatcher(),
    statisticWatcher(),
    forumMembersWatcher(),
    notificationsWatcher()
  ]);
}
