import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_UPDATE_FIREBASE_UID = 'ON_UPDATE_FIREBASE_UID';
const ON_GET_STUDENTS = 'ON_GET_STUDENTS';
const ON_EDIT_STUDENT = 'ON_EDIT_STUDENT';
const ON_NEW_STUDENT = 'ON_NEW_STUDENT';
const ON_UPDATE_STUDENT_HASHTAG = 'ON_UPDATE_STUDENT_HASHTAG';
const ON_UPDATE_STUDENT_FORUM_TOPIC = 'ON_UPDATE_STUDENT_FORUM_TOPIC';
const ON_REMOVE_STUDENT = 'ON_REMOVE_STUDENT';
const ON_GET_STUDENT_BASE_RECORD = 'ON_GET_STUDENT_BASE_RECORD';

function* getStudents({ params, setData }) {
  try {
    const response = yield call(get, '/students', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}
function* getStudentBaseRecord({ setData }) {
  try {
    const response = yield call(get, '/students/base_record');
    setData(response);
  } catch (error) {
    console.log(error);
  }
}

function* updateHashtag({ params, callback }) {
  try {
    yield call(axiosPut, `/students/${params.id}/update_hashtag`, params);
    Notification.success({ message: 'Chỉnh sửa hashtag của học viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* updateForumTopic({ params, callback }) {
  try {
    yield call(axiosPut, `/students/${params.id}/update_forum_topic`, params);
    Notification.success({ message: 'Chỉnh sửa chủ đề diễn đàn của học viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* editStudent({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('user[email]', params.email);
    formData.append('user[first_name]', params.firstName);
    formData.append('user[last_name]', params.lastName);
    formData.append('user[birthday]', params.birthday ? params.birthday.format('DD/MM/YYYY') : '');
    formData.append('user[gender]', params.gender === 'male' ? 0 : 1);
    formData.append('user[phone]', params.phone);
    formData.append('user[job]', params.job);
    formData.append('user[address][address]', params.address);
    if (params.avatar) formData.append('user[avatar]', params.avatar);
    if (params.images) {
      params.images.forEach((file) => {
        formData.append('user[images][]', file.id ? file.id : file);
      });
    }

    yield call(axiosPut, `/students/${params.id}`, formData);
    Notification.success({ message: 'Chỉnh sửa thông tin học viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* newStudent({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('user[email]', params.email);
    formData.append('user[first_name]', params.firstName);
    formData.append('user[last_name]', params.lastName);
    formData.append('user[birthday]', params.birthday ? params.birthday.format('DD/MM/YYYY') : '');
    formData.append('user[gender]', params.gender === 'male' ? 0 : 1);
    formData.append('user[phone]', params.phone);
    formData.append('user[job]', params.job);
    formData.append('user[address][address]', params.address);
    if (params.avatar) formData.append('user[avatar]', params.avatar);
    if (params.images) {
      params.images.forEach((file) => {
        formData.append('user[images][]', file);
      });
    }

    const response = yield call(post, '/students', formData);
    callback(200, { email: params.email, id: response.user_id });
    Notification.success({ message: 'Thêm học viên thành công.' });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* updateFireBaseUid({ params }) {
  try {
    yield call(post, '/current_user/info/update_uid', params);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* deleteStudent({ id, callback }) {
  try {
    yield call(axiosDelete, `/students/${id}`);

    Notification.success({ message: 'Xóa học viên thành công.' });
    callback();
  } catch (error) {
    console.log(error);
  }
}

export const onUpdateFireBaseUid = (params) => ({
  type: ON_UPDATE_FIREBASE_UID,
  params
});

export const onNewStudent = (params, callback) => ({
  type: ON_NEW_STUDENT,
  params,
  callback
});

export const onEditStudent = (params, callback) => ({
  type: ON_EDIT_STUDENT,
  params,
  callback
});

export const onGetStudents = (params, setData) => ({
  type: ON_GET_STUDENTS,
  params,
  setData
});

export const onUpdateHashtag = (params, callback) => ({
  type: ON_UPDATE_STUDENT_HASHTAG,
  params,
  callback
});

export const onUpdateForumTopic = (params, callback) => ({
  type: ON_UPDATE_STUDENT_FORUM_TOPIC,
  params,
  callback
});

export const onRemoveStudent = (id, callback) => ({
  type: ON_REMOVE_STUDENT,
  id,
  callback
});

export const onGetStudentBaseRecord = (setData) => ({
  type: ON_GET_STUDENT_BASE_RECORD,
  setData
});

export default function* StudentWatcher() {
  yield takeLatest(ON_GET_STUDENTS, getStudents);
  yield takeLatest(ON_EDIT_STUDENT, editStudent);
  yield takeLatest(ON_NEW_STUDENT, newStudent);
  yield takeLatest(ON_UPDATE_FIREBASE_UID, updateFireBaseUid);
  yield takeLatest(ON_UPDATE_STUDENT_HASHTAG, updateHashtag);
  yield takeLatest(ON_UPDATE_STUDENT_FORUM_TOPIC, updateForumTopic);
  yield takeLatest(ON_REMOVE_STUDENT, deleteStudent);
  yield takeLatest(ON_GET_STUDENT_BASE_RECORD, getStudentBaseRecord);
}
