import { get, put as axiosPut, post } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_UPDATE_EMPLOYEE_FORUM_TOPIC = 'ON_UPDATE_EMPLOYEE_FORUM_TOPIC';
const ON_GET_EMPLOYEES = 'ON_GET_EMPLOYEES';
const ON_EDIT_EMPLOYEE = 'ON_EDIT_EMPLOYEE';
const ON_NEW_EMPLOYEE = 'ON_NEW_EMPLOYEE';
const ON_STOP_WORK_EMPLOYEE = 'ON_STOP_WORK_EMPLOYEE';

function* getEmployees({ params, setData }) {
  try {
    const response = yield call(get, '/employees', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* stopWork({ params, callback }) {
  try {
    yield call(axiosPut, `/employees/${params.id}/stop_work`);
    Notification.success({ message: 'Xác nhận nhân viên ngưng hoạt động thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* editEmployee({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('user[email]', params.email);
    formData.append('user[first_name]', params.firstName);
    formData.append('user[last_name]', params.lastName);
    formData.append('user[birthday]', params.birthday ? params.birthday.format('DD/MM/YYYY') : '');
    formData.append('user[gender]', params.gender === 'male' ? 0 : 1);
    formData.append('user[phone]', params.phone);
    formData.append('roles', params.roles);

    if (params.roles.includes('teacher')) {
      formData.append('user[teacher_info_attributes][specialty]', params.specialty || '');
      formData.append('user[teacher_info_attributes][introduce]', params.introduce || '');
      formData.append('user[teacher_info_attributes][school]', params.school || '');
      formData.append('user[teacher_info_attributes][graduation_year]', params.graduation_year || '');
      formData.append('user[teacher_info_attributes][specialty_introduce]', params.specialty_introduce || '');

      if (params.images) {
        params.images.forEach((file) => {
          formData.append('user[images][]', file.id ? file.id : file);
        });
      }
    }

    if (params.avatar) formData.append('user[avatar]', params.avatar);

    yield call(axiosPut, `/employees/${params.id}`, formData);
    Notification.success({ message: 'Chỉnh sửa thông tin nhân viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* newEmployee({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('user[email]', params.email);
    formData.append('user[first_name]', params.firstName);
    formData.append('user[last_name]', params.lastName);
    formData.append('user[birthday]', params.birthday ? params.birthday.format('DD/MM/YYYY') : '');
    formData.append('user[gender]', params.gender === 'male' ? 0 : 1);
    formData.append('user[phone]', params.phone);
    formData.append('roles', params.roles);

    if (params.roles.includes('teacher')) {
      formData.append('user[teacher_info_attributes][specialty]', params.specialty || '');
      formData.append('user[teacher_info_attributes][introduce]', params.introduce || '');
      formData.append('user[teacher_info_attributes][school]', params.school || '');
      formData.append('user[teacher_info_attributes][graduation_year]', params.graduation_year || '');
      formData.append('user[teacher_info_attributes][specialty_introduce]', params.specialty_introduce || '');

      if (params.images) {
        params.images.forEach((file) => {
          formData.append('user[images][]', file);
        });
      }
    }

    if (params.avatar) formData.append('user[avatar]', params.avatar);

    const response = yield call(post, '/employees', formData);
    Notification.success({ message: 'Thêm nhân viên thành công.' });
    callback(200, { email: params.email, id: response.user_id });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* updateForumTopic({ params, callback }) {
  try {
    yield call(axiosPut, `/employees/${params.id}/update_forum_topic`, params);
    Notification.success({ message: 'Chỉnh sửa chủ đề diễn đàn của nhân viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

export const onNewEmployee = (params, callback) => ({
  type: ON_NEW_EMPLOYEE,
  params,
  callback
});

export const onEditEmployee = (params, callback) => ({
  type: ON_EDIT_EMPLOYEE,
  params,
  callback
});

export const onGetEmployees = (params, setData) => ({
  type: ON_GET_EMPLOYEES,
  params,
  setData
});

export const onUpdateForumTopic = (params, callback) => ({
  type: ON_UPDATE_EMPLOYEE_FORUM_TOPIC,
  params,
  callback
});

export const onStopWork = (params, callback) => ({
  type: ON_STOP_WORK_EMPLOYEE,
  params,
  callback
});

export default function* employeeWatcher() {
  yield takeLatest(ON_STOP_WORK_EMPLOYEE, stopWork);
  yield takeLatest(ON_GET_EMPLOYEES, getEmployees);
  yield takeLatest(ON_EDIT_EMPLOYEE, editEmployee);
  yield takeLatest(ON_NEW_EMPLOYEE, newEmployee);
  yield takeLatest(ON_UPDATE_EMPLOYEE_FORUM_TOPIC, updateForumTopic);
}
