import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_MY_SALE_REGISTRATION_INFOS = 'ON_GET_MY_SALE_REGISTRATION_INFOS';
const ON_GET_REGISTRATION_INFO_EMPLOYEES = 'ON_GET_REGISTRATION_INFO_EMPLOYEES';
const ON_GET_MY_MANAGEMENT_REGISTRATION_INFOS = 'ON_GET_MY_MANAGEMENT_REGISTRATION_INFOS';
const ON_GET_REGISTRATION_INFOS = 'ON_GET_REGISTRATION_INFOS';
const ON_GET_REGISTRATION_INFO = 'ON_GET_REGISTRATION_INFO';
const ON_UPDATE_REGISTRATION_INFO_SALER = 'ON_UPDATE_REGISTRATION_INFO_SALER';
const ON_EDIT_REGISTRATION_INFO = 'ON_EDIT_REGISTRATION_INFO';
const ON_UPDATE_INCOME = 'ON_UPDATE_INCOME';
const ON_CREATE_SUB_INCOME = 'ON_CREATE_SUB_INCOME';
const ON_REMOVE_SUB_INCOME = 'ON_REMOVE_SUB_INCOME';
const ON_UPDATE_SUB_INCOME = 'ON_UPDATE_SUB_INCOME';
const ON_DUPLICATED_REGISTRATION_INFO = 'ON_DUPLICATED_REGISTRATION_INFO';
const ON_DELETE_REGISTRATION_INFO = 'ON_DELETE_REGISTRATION_INFO';

function* getEmployess({ setData }) {
  try {
    const response = yield call(get, '/registration_infos/employees');
    setData(response.items);
  } catch (error) {
    console.log(error);
  }
}

function* getMySales({ params, setData }) {
  try {
    const response = yield call(get, '/registration_infos/my_sales', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
      totalIncome: response.totalIncome
    });
  } catch (error) {
    console.log(error);
  }
}
function* getRegistrationInfos({ params, setData }) {
  try {
    const response = yield call(get, '/registration_infos', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* getMyManagerRegistrationInfos({ params, setData }) {
  try {
    const response = yield call(get, '/registration_infos/my_management', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* getRegistrationInfo({ id, setState }) {
  try {
    const response = yield call(get, `/registration_infos/${id}`);
    setState({ loading: false, item: response });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* editRegistrationInfo({ params, callback }) {
  try {
    const response = yield call(axiosPut, `/registration_infos/${params.id}`, params);

    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* updateIncome({ params, callback }) {
  try {
    const response = yield call(axiosPut, `/registration_infos/${params.id}/update_income_field`, params);

    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* createSubIncome({ params, callback }) {
  try {
    const response = yield call(post, `/registration_infos/${params.id}/create_sub_income`, params);

    callback(200, response);
    Notification.success({ message: 'Tạo phiếu thu thành công' });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* removeSubIncome({ params, callback }) {
  try {
    const response = yield call(axiosDelete, `/registration_infos/${params.id}/remove_sub_income`, {
      params
    });

    Notification.success({ message: 'Xóa phiếu thu thành công.' });
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* updateSubIncome({ params, callback }) {
  try {
    const response = yield call(axiosPut, `/registration_infos/${params.id}/update_sub_income`, params);

    Notification.success({ message: 'Chỉnh sửa phiếu thu thành công.' });
    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* duplicatedInfo({ params, callback }) {
  try {
    const response = yield call(post, `/registration_infos/${params.id}/duplicate`, params);

    callback(200, response);
    Notification.success({ message: 'Tạo phiếu đăng kí thành công' });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* updateSaler({ params }) {
  try {
    yield call(post, `/registration_infos/${params.id}/update_saler`, params);
    Notification.success({ message: 'Thay đổi nhân viên sale thành công.' });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* removeRegistration({ params, callback }) {
  try {
    yield call(axiosDelete, `/registration_infos/${params.id}`);
    callback();
    Notification.success({ message: 'Xoá phiếu đăng kí thành công.' });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onGetMySales = (params, setData) => ({
  type: ON_GET_MY_SALE_REGISTRATION_INFOS,
  params,
  setData
});

export const onGetRegistrationInfos = (params, setData) => ({
  type: ON_GET_REGISTRATION_INFOS,
  params,
  setData
});

export const onGetMyManagementRegistrationInfos = (params, setData) => ({
  type: ON_GET_MY_MANAGEMENT_REGISTRATION_INFOS,
  params,
  setData
});

export const onGetRegistrationInfo = (id, setState) => ({
  type: ON_GET_REGISTRATION_INFO,
  id,
  setState
});

export const onUpdateRegistrationInfo = (params, callback) => ({
  type: ON_EDIT_REGISTRATION_INFO,
  params,
  callback
});

export const onUpdateIncome = (params, callback) => ({
  type: ON_UPDATE_INCOME,
  params,
  callback
});

export const onCreateSubIncome = (params, callback) => ({
  type: ON_CREATE_SUB_INCOME,
  params,
  callback
});

export const onRemoveSubIncome = (params, callback) => ({
  type: ON_REMOVE_SUB_INCOME,
  params,
  callback
});

export const onUpdateSubIncome = (params, callback) => ({
  type: ON_UPDATE_SUB_INCOME,
  params,
  callback
});

export const onDuplicatedRegistration = (params, callback) => ({
  type: ON_DUPLICATED_REGISTRATION_INFO,
  params,
  callback
});

export const onGetEmployees = (setData) => ({
  type: ON_GET_REGISTRATION_INFO_EMPLOYEES,
  setData
});

export const onUpdateSaler = (params) => ({
  type: ON_UPDATE_REGISTRATION_INFO_SALER,
  params
});

export const onDeleteRegistration = (params, callback) => ({
  type: ON_DELETE_REGISTRATION_INFO,
  params,
  callback
});

export default function* registrationInfoWatcher() {
  yield takeLatest(ON_DELETE_REGISTRATION_INFO, removeRegistration);
  yield takeLatest(ON_GET_MY_SALE_REGISTRATION_INFOS, getMySales);
  yield takeLatest(ON_UPDATE_REGISTRATION_INFO_SALER, updateSaler);
  yield takeLatest(ON_GET_REGISTRATION_INFO_EMPLOYEES, getEmployess);
  yield takeLatest(ON_GET_MY_MANAGEMENT_REGISTRATION_INFOS, getMyManagerRegistrationInfos);
  yield takeLatest(ON_GET_REGISTRATION_INFOS, getRegistrationInfos);
  yield takeLatest(ON_GET_REGISTRATION_INFO, getRegistrationInfo);
  yield takeLatest(ON_EDIT_REGISTRATION_INFO, editRegistrationInfo);
  yield takeLatest(ON_UPDATE_INCOME, updateIncome);
  yield takeLatest(ON_CREATE_SUB_INCOME, createSubIncome);
  yield takeLatest(ON_REMOVE_SUB_INCOME, removeSubIncome);
  yield takeLatest(ON_UPDATE_SUB_INCOME, updateSubIncome);
  yield takeLatest(ON_DUPLICATED_REGISTRATION_INFO, duplicatedInfo);
}
