import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_MEMBERSHIP_SELECT_VALUE = 'ON_GET_MEMBERSHIP_SELECT_VALUE';
const ON_GET_MEMBERSHIPS = 'ON_GET_MEMBERSHIPS';
const ON_GET_MEMBERSHIP = 'ON_GET_MEMBERSHIP';
const ON_EDIT_MEMBERSHIP = 'ON_EDIT_MEMBERSHIP';
const ON_NEW_MEMBERSHIP = 'ON_NEW_MEMBERSHIP';
const ON_REMOVE_MEMBERSHIP = 'ON_REMOVE_MEMBERSHIP';
const ON_IMPORT_MEMBER_SHIP = 'ON_IMPORT_MEMBER_SHIP';

function* getMembershipSelectValue({ setData }) {
  try {
    const response = yield call(get, '/memberships/select_values');
    setData({
      students: response.students,
      courses: response.course
    });
  } catch (error) {
    console.log(error);
  }
}

function* getMemberships({ params, setData }) {
  try {
    const response = yield call(get, '/memberships', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* getMembership({ id, setData }) {
  try {
    const response = yield call(get, `/memberships/${id}`);
    setData(response);
  } catch (error) {
    console.log(error);
  }
}

function* editMembership({ params, callback }) {
  try {
    yield call(axiosPut, `/memberships/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa phân hạng thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newMembership({ params, callback }) {
  try {
    yield call(post, '/memberships', params);
    Notification.success({ message: 'Thêm phân hạng thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeMembership({ params, callback }) {
  try {
    yield call(axiosDelete, `/memberships/${params.id}`);
    Notification.success({ message: 'Xóa phân hạng thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* importMemberShip({ params, callback }) {
  try {
    yield call(post, '/memberships/import_member', params);
    Notification.success({ message: 'Import phân hạng thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

export const onNewMembership = (params, callback) => ({
  type: ON_NEW_MEMBERSHIP,
  params,
  callback
});

export const onEditMembership = (params, callback) => ({
  type: ON_EDIT_MEMBERSHIP,
  params,
  callback
});

export const onGetMemberships = (params, setData) => ({
  type: ON_GET_MEMBERSHIPS,
  params,
  setData
});

export const onDeleteMembership = (params, callback) => ({
  type: ON_REMOVE_MEMBERSHIP,
  params,
  callback
});

export const onGetMembershipSelectValue = (setData) => ({
  type: ON_GET_MEMBERSHIP_SELECT_VALUE,
  setData
});

export const onGetMembership = (id, setData) => ({
  type: ON_GET_MEMBERSHIP,
  id,
  setData
});

export const onImportMemberShip = (params, callback) => ({
  type: ON_IMPORT_MEMBER_SHIP,
  params,
  callback
});

export default function* membershipWatcher() {
  yield takeLatest(ON_GET_MEMBERSHIP, getMembership);
  yield takeLatest(ON_GET_MEMBERSHIP_SELECT_VALUE, getMembershipSelectValue);
  yield takeLatest(ON_GET_MEMBERSHIPS, getMemberships);
  yield takeLatest(ON_EDIT_MEMBERSHIP, editMembership);
  yield takeLatest(ON_NEW_MEMBERSHIP, newMembership);
  yield takeLatest(ON_IMPORT_MEMBER_SHIP, importMemberShip);
  yield takeLatest(ON_REMOVE_MEMBERSHIP, removeMembership);
}
