import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { UserOutlined, AppstoreOutlined, BarChartOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import Project from './Project';
import SubProject from './SubProject';
import Task from './Task';
import { onGetEmployees } from '../Employees/saga';

const { TabPane } = Tabs;

const UserTasks = () => {
  const subProjectRef = useRef();
  const taskRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({ loading: false, items: [] });
  const { items } = state;
  const currentFilter = useRef(queryString.parse(location.search));

  const getData = useCallback((params) => dispatch(
    onGetEmployees(params, setState),
  ), [dispatch]);

  const changeLocation = () => {
    history.push({
      pathname: '/admin/user-tasks',
      search: queryString.stringify(currentFilter.current),
    });
  };

  const changeTab = (key) => {
    if (key === 'myTask') history.push('/admin/user-tasks/my-task');
    if (key === 'overview') history.push('/admin/user-tasks/overview');
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Tabs defaultActiveKey='all' onTabClick={changeTab}>
      <TabPane
        tab={(
          <span>
            <AppstoreOutlined />
            Tất cả
          </span>
        )}
        key='all'
      >
        <Row gutter={24}>
          <Col xs={24} md={24} lg={8}>
            <Project employees={items} changeLocation={changeLocation} currentFilter={currentFilter} subProjectRef={subProjectRef} taskRef={taskRef} />
          </Col>
          <Col xs={24} md={24} lg={8}>
            <SubProject employees={items} ref={subProjectRef} changeLocation={changeLocation} currentFilter={currentFilter} taskRef={taskRef} />
          </Col>
          <Col xs={24} md={24} lg={8}>
            <Task ref={taskRef} currentFilter={currentFilter} employees={items} />
          </Col>
        </Row>
      </TabPane>
      <TabPane
        tab={(
          <span>
            <UserOutlined />
            Công việc của tôi
          </span>
        )}
        key='myTask'
      />
      <TabPane
        tab={(
          <span>
            <BarChartOutlined />
            Tổng quan
          </span>
        )}
        key='overview'
      />
    </Tabs>

  );
};

export default UserTasks;
