import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, DatePicker, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { removeCurrency, fieldValidate, numberToCurrency, dateFormat } from '@shared/function';
import SelectSearch from '@components/SelectSearch';
import { onEditPromotion } from '../saga';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;

const Edit = React.forwardRef(({ fetchData, courses }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const editData = useCallback((params) => dispatch(onEditPromotion(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    values.price = removeCurrency(values.price);
    values.original_price = removeCurrency(values.original_price);
    values.expired_date = values.expired_date.format(dateFormat);

    editData({ promotion: values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      const defaultValue = {
        expired_date: moment(item.expiredDate, 'DD/MM/YYYY'),
        promotion_courses_attributes: item.promotionCourses.map((a) => ({
          course_id: a.course_id,
          course_type: a.course_type
        })),
        price: numberToCurrency(item.price),
        original_price: numberToCurrency(item.originalPrice),
        status: item.status,
        name: item.name
      };

      form.setFieldsValue(defaultValue);

      setState({ status: true, item });
    }
  }));

  return (
    <Modal closable={false} visible={status} footer={null} title="Chỉnh sửa khuyến mãi" width="50%">
      <Form
        layout="vertical"
        form={form}
        name="createPromotion"
        scrollToFirstError
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={48}>
          <Col span={12}>
            <Item name="name" label="Tên" rules={[fieldValidate.required]}>
              <Input placeholder="Tên" />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="expired_date" rules={[fieldValidate.required]} label="Ngày hết hạn">
              <DatePicker format={dateFormat} style={{ width: '100%' }} />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="original_price" label="Giá gốc" rules={[fieldValidate.required]}>
              <Input
                placeholder="Giá gốc"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    original_price: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="price" label="Giá khuyến mãi" rules={[fieldValidate.required]}>
              <Input
                placeholder="Giá khuyến mãi"
                onChange={(e) =>
                  e.target.value &&
                  form.setFieldsValue({
                    price: numberToCurrency(removeCurrency(e.target.value))
                  })
                }
              />
            </Item>
          </Col>
          <Col span={24}>
            <Text>Danh sách khóa học trong combo</Text>
            <br />
            <br />
            <Form.List name="promotion_courses_attributes" style={{ marginTop: 10 }}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row gutter={16} key={key}>
                      <Col span={14}>
                        <Form.Item
                          {...restField}
                          name={[name, 'course_id']}
                          fieldKey={[fieldKey, 'course_id']}
                          rules={[fieldValidate.required]}
                        >
                          <SelectSearch placeholder="Khóa học">
                            {courses.map((course) => (
                              <Option
                                value={course.id}
                                key={course.id}
                              >{`${course.module_name}: ${course.title}`}</Option>
                            ))}
                          </SelectSearch>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'course_type']}
                          fieldKey={[fieldKey, 'course_type']}
                          rules={[fieldValidate.required]}
                        >
                          <Select placeholder="Loại">
                            <Option value="Online lý thuyết">Online lý thuyết</Option>
                            <Option value="Offline thực hành">Offline thực hành</Option>
                            <Option value="Online lý thuyết & Offline thực hành">
                              Online lý thuyết & Offline thực hành
                            </Option>
                            <Option value="Offline lý thuyết & Offline thực hành">
                              Offline lý thuyết & Offline thực hành
                            </Option>
                            <Option value="Hội nghị">Hội nghị</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <DeleteOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Thêm khóa học
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Edit;
