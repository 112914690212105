import React, { useRef, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import WebViewer from '@pdftron/webviewer';

import { Row, Col } from 'antd';

const Files = () => {
  const [item, setItem] = useState('');
  const location = useLocation();
  const viewer = useRef(null);
  useEffect(() => {
    if (item) {
      WebViewer(
        {
          path: '/lib',
          initialDoc: item
        },
        document.getElementById('preview-webviewer')
      )
        .then((instance) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }, [item]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.url) setItem(params.url);
  }, [location.search]);

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <div id="preview-webviewer" ref={viewer} style={{ height: '100vh' }} />
      </Col>
    </Row>
  );
};

export default Files;
