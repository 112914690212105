import React, { useRef } from 'react';
import { Layout, Tabs } from 'antd';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import ForumContent from './Content';
import MainTopic from './Menu';
import Statistic from './Statistic';

const { TabPane } = Tabs;

const Forum = () => {
  const createPostRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const currentFilter = useRef(queryString.parse(location.search));

  const changeLocation = () => {
    history.push({
      pathname: '/admin/forums',
      search: queryString.stringify(currentFilter.current)
    });
  };

  return (
    <Tabs defaultActiveKey="2">
      <TabPane tab="Diễn đàn" key="1">
        <Layout>
          <MainTopic
            changeLocation={changeLocation}
            currentFilter={currentFilter}
            createPostRef={createPostRef}
          />
          <Layout style={{ padding: '0', background: 'white' }}>
            <ForumContent
              currentFilter={currentFilter}
              changeLocation={changeLocation}
              createPostRef={createPostRef}
            />
          </Layout>
        </Layout>
      </TabPane>
      <TabPane tab="Thống kê" key="2">
        <Layout style={{ padding: '0', background: 'white' }}>
          <Statistic />
        </Layout>
      </TabPane>
    </Tabs>
  );
};

export default Forum;
