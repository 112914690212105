import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Tag } from 'antd';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete, onCreateSubTopic) => [
  {
    title: 'Vị trí',
    dataIndex: 'position',
    key: 'position',
    sorter: {}
  },
  {
    title: 'Chủ đề',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Loại',
    dataIndex: 'private',
    key: 'private',
    sorter: {},
    render: (value) => {
      if (value) {
        return <Tag color="green">Chủ đề chuyên ngành</Tag>;
      }

      return <Tag color="gray">Chủ đề chung</Tag>;
    }
  },
  {
    title: 'Hình',
    dataIndex: 'image',
    key: 'image',
    render: (value) => <img src={value} alt={'error'} style={{ width: 100, height: 50 }} />
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onCreateSubTopic(item)}>
                <VerticalAlignMiddleOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Thêm chủ đề con
              </Menu.Item>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    )
  }
];
