import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button, Select, DatePicker,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  fieldValidate,
} from '../../../../shared/function';
import Ckeditor from '../../../../shared/Ckeditor';
import SelectSearch from '../../../../components/SelectSearch';
import { onCreateTask } from '../saga';
import { Color } from './styles';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Create = React.forwardRef(({
  fetchData, employees,
}, ref) => {
  const user = useSelector((reduxState) => reduxState.user.data);
  const dispatch = useDispatch();
  const [state, setState] = useState({ isShow: false });
  const { isShow } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ isShow: false, item: null });
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmiting(false);
  };

  const createData = useCallback((params) => dispatch(
    onCreateTask(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const taskAssignees = (values.assignee_ids || []).map((employee) => ({
      user_id: employee,
    }));

    taskAssignees.push({
      user_id: user.id,
    });

    if (values.time && values.time.length > 0) {
      values.start_at = values.time[0].format('DD/MM/YYYY');
      values.deadline = values.time[1].format('DD/MM/YYYY');
    }

    createData({ user_task: { ...values, task_type: 'project', task_assignees_attributes: taskAssignees } });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setState({ isShow: true });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      width={992}
      title='Tạo Project'
    >
      <Form
        layout='vertical'
        form={form}
        name='createProject'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={12}>
            <Form.Item
              name='title'
              label='Tên'
              rules={[fieldValidate.required]}
            >
              <Input placeholder='Tên' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='assignee_ids'
              label='Nhân viên'
              rules={[fieldValidate.required]}
            >
              <SelectSearch placeholder='Nhân viên' mode='multiple'>
                {employees.map((employee) => {
                  if (user.id === employee.id) return null;
                  return (
                    <Option value={employee.id} key={employee.id}>{employee.name}</Option>
                  );
                })}
              </SelectSearch>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='time'
              label='Ngày bắt đầu - Ngày kết thúc'
            >
              <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='color'
              label='Mã Màu'
              rules={[fieldValidate.required]}
            >
              <SelectSearch placeholder='Mã Màu' dropdownClassName='color-select'>
                <Option value='#000000'>
                  Black
                  <Color color='#000000' />
                </Option>
                <Option value='#FFFFFF'>
                  White
                  <Color color='#FFFFFF' />
                </Option>
                <Option value='#f44336'>
                  Red
                  <Color color='#f44336' />
                </Option>
                <Option value='#0aa89e'>
                  Lime
                  <Color color='#0aa89e' />
                </Option>
                <Option value='#2196f3'>
                  Blue
                  <Color color='#2196f3' />
                </Option>
                <Option value='#ff9800'>
                  Yellow
                  <Color color='#ff9800' />
                </Option>
                <Option value='#00FFFF'>
                  Cyan
                  <Color color='#00FFFF' />
                </Option>
                <Option value='#FF00FF'>
                  Magenta
                  <Color color='#FF00FF' />
                </Option>
                <Option value='#C0C0C0'>
                  Silver
                  <Color color='#C0C0C0' />
                </Option>
                <Option value='#808080'>
                  Gray
                  <Color color='#808080' />
                </Option>
                <Option value='#800000'>
                  Maroon
                  <Color color='#800000' />
                </Option>
                <Option value='#808000'>
                  Olive
                  <Color color='#808000' />
                </Option>
                <Option value='#008000'>
                  Green
                  <Color color='#008000' />
                </Option>
                <Option value='#008080'>
                  Teal
                  <Color color='#008080' />
                </Option>
                <Option value='#000080'>
                  Navy
                  <Color color='#000080' />
                </Option>
                <Option value='#066265'>
                  Primarydark
                  <Color color='#066265' />
                </Option>
                <Option value='#7dd8d2'>
                  Primarylight
                  <Color color='#7dd8d2' />
                </Option>
                <Option value='#681a7f'>
                  Accentdark
                  <Color color='#681a7f' />
                </Option>
                <Option value='#9c27b0'>
                  Accent
                  <Color color='#9c27b0' />
                </Option>
                <Option value='#c77bd4'>
                  Accentlight
                  <Color color='#c77bd4' />
                </Option>
                <Option value='#caf0ee'>
                  Primarybright
                  <Color color='#caf0ee' />
                </Option>
                <Option value='#e9c9ef'>
                  Accentbright
                  <Color color='#e9c9ef' />
                </Option>
                <Option value='#4caf50'>
                  Success
                  <Color color='#4caf50' />
                </Option>
                <Option value='#00bcd4'>
                  Info
                  <Color color='#00bcd4' />
                </Option>
                <Option value='#e26c1d'>
                  SSO
                  <Color color='#e26c1d' />
                </Option>
              </SelectSearch>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='content'
              label='Nội dung'
            >
              <Ckeditor onChange={(value) => form.setFieldsValue({ content: value })} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
