import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import Table from '../../../shared/Table';
import { onGetStudents, onRemoveStudent } from './saga';
import Filter from './Filter';
import CreateModal from './Modal/Create';
import EditModal from './Modal/Edit';
import AddHashtagModal from './Modal/AddHashtag';
import AddForumTopic from './Modal/AddForumTopic';

import { onLockUser, onMerchantLoginAs, onUnlockUser, onActivate } from '../../Login/saga';

const Students = () => {
  const user = useSelector((state) => state.user.data);
  const location = useLocation();
  const history = useHistory();
  const createRef = useRef();
  const editRef = useRef();
  const addHashtagRef = useRef();
  const forumTopicRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });

  const isAdmin = user.roles.includes('admin');

  const currentFilter = useRef(queryString.parse(location.search));

  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetStudents(params, setState)), [dispatch]);

  const loginAs = (user) => {
    dispatch(onMerchantLoginAs(user.id, 'student'));
  };

  const changeLocation = () => {
    history.push({
      pathname: '/admin/students',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);
    currentFilter.current.with_forum_topics = true;

    fetchData();
  }, [location.search]);

  const onEdit = (item) => editRef.current.open(item);
  const onAddHashtag = (item) => addHashtagRef.current.open(item);
  const onAddForumTopic = (item) => forumTopicRef.current.open(item);
  const removeStudent = (item) => {
    dispatch(onRemoveStudent(item.id, fetchData));
  };

  const onActive = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn kích hoạt tài khoản học viên này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        dispatch(onActivate(item.id, fetchData));
      }
    });
  };

  const onLock = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn cấm truy cập học viên này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        dispatch(onLockUser(item.id, fetchData));
      }
    });
  };

  const onUnlock = (item) => {
    Modal.confirm({
      title: 'Bạn có muốn cho phép truy cập học viên này không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        dispatch(onUnlockUser(item.id, fetchData));
      }
    });
  };

  const ActionButton = () => (
    <Button onClick={() => createRef.current.open()} type="primary" icon={<PlusOutlined />}>
      Thêm học viên
    </Button>
  );

  return (
    <>
      <CreateModal ref={createRef} fetchData={fetchData} />
      <EditModal ref={editRef} fetchData={fetchData} />
      <AddHashtagModal ref={addHashtagRef} fetchData={fetchData} />
      <AddForumTopic ref={forumTopicRef} fetchData={fetchData} />
      <Table
        actionButton={<ActionButton />}
        filter={<Filter currentFilter={currentFilter} changeLocation={changeLocation} isAdmin={isAdmin} />}
        title="Danh sách học viên"
        columns={columns(
          onEdit,
          loginAs,
          onLock,
          onUnlock,
          onAddHashtag,
          onActive,
          onAddForumTopic,
          isAdmin,
          removeStudent
        )}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default Students;
