import React, {
  useRef, useEffect,
} from 'react';
import WebViewer from '@pdftron/webviewer';

import {
  Row, Col,
} from 'antd';

const Files = ({ item }) => {
  const viewer = useRef(null);
  useEffect(() => {
    if (item.url) {
      WebViewer(
        {
          path: '/lib',
          initialDoc: item.url,
        },
        document.getElementById(`webviewer-${item.id}`),
      ).then((instance) => {
      }).catch((err) =>{
        console.log(err)
      });
    }
  }, [item]);

  return (
    <Row justify='space-between' gutter={24}>
      <Col span={24}>
        <div id={`webviewer-${item.id}`} ref={viewer} style={{ height: '100vh' }} />
      </Col>
    </Row>
  );
};

export default Files;
