import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { columns } from './function';
import Table from '../../../../shared/Table';
import { onGetData } from '../../CompanyExpense/saga';

const ExpenseView = ({ searchParams }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });

  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetData(params, setState)), [dispatch]);

  const fetchData = ({ page = 1, sort = 'created_at desc' }) => {
    showLoading();
    getData({
      time_gteq: searchParams.time_gteq,
      time_lteq: searchParams.time_lteq,
      company_category_id: searchParams.expense_category,
      keyword: searchParams.keyword,
      status: 'approve',
      page,
      s: sort
    });
  };

  const onChange = (pagination, filters, sorter) => {
    fetchData({
      page: pagination.current || 1,
      sort: `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`
    });
  };

  useEffect(() => {
    fetchData({});
  }, [searchParams]);

  return (
    <Table
      columns={columns()}
      loading={loading}
      dataSource={items}
      currentPage={currentPage}
      totalPages={totalPages}
      onChange={onChange}
    />
  );
};

export default ExpenseView;
