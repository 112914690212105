import { all } from 'redux-saga/effects';
import publicRouteWatcher from '../App/PublicRoute/saga';
import privateRouteWatcher from '../App/PrivateRoute/saga';
import myProfileWatcher from '../App/PrivateRoute/Layout/Header/Profile/saga';

import loginWatcher from '../pages/Login/saga';
import setUpPasswordWatcher from '../pages/SetUpPassword/saga';
import forgotPasswordWatcher from '../pages/ForgotPassword/saga';

import adminWatcher from '../pages/Admin/saga';

export default function* saga() {
  yield all([
    adminWatcher(),

    publicRouteWatcher(),
    privateRouteWatcher(),
    loginWatcher(),

    myProfileWatcher(),
    setUpPasswordWatcher(),
    forgotPasswordWatcher()
  ]);
}
