import styled, { css } from 'styled-components';
import { Col } from 'antd';

export const Owner = styled.div`
  font-size: 13px;
  font-style: italic;
  color: #9b9b9b;
  display: flex;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Color = styled.div`
  width: 25px;
  height: 25px;
  background: ${({ color }) => color};
  border-radius: 50%;
  margin-left: 5px;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

 .ant-typography {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-space {
    display: flex !important;
    flex-flow: wrap row;

    .ant-space-item {
      width: 200px;
      margin-bottom: 10px;
    }
  }
`;

const headerItem = (withBackground) => css`
  background: ${withBackground ? '#fafafa' : 'transparent'};
  padding: 10px;

  .ant-form-item {
    margin: 0;
  }
`;

export const Name = styled.div`
  display: flex;
  width: 90%;
  ${({ withBackground }) => headerItem(withBackground)}
`;

export const Code = styled.div`
  ${({ withBackground }) => headerItem(withBackground)}
  width: 45%;
`;

export const Action = styled.div`
  ${({ withBackground }) => headerItem(withBackground)}
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const RowItem = styled.div`
  width: 18%;
  ${({ withBackground }) => headerItem(withBackground)}
`;
