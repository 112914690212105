import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Tag } from 'antd';
import moment from 'moment';

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {}
  },
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    key: 'title',
    sorter: {}
  },
  {
    title: 'Trạng thái',
    sorter: {},
    render: (item) => {
      switch (item.isPublic) {
        case false:
          return <Tag color="red">Hết hạn</Tag>;
        default:
          return <Tag color="green">Hoạt động</Tag>;
      }
    }
  },
  {
    title: 'Ngày hết hạn',
    key: 'epxired_date',
    render: ({ startDate, endDate }) =>
      `Từ ${moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} đến ${moment(endDate, 'YYYY-MM-DD').format(
        'DD-MM-YYYY'
      )}`
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => onEdit(item)}>
              <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
            </Menu.Item>
            <Menu.Item onClick={() => onDelete(item)}>
              <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
      </Dropdown>
    )
  }
];
