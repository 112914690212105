import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { Wrapper } from './styles';

const UploadList = React.forwardRef(
  ({ onChange, defaultValue, aspect, disabled, canDelete = true, noCrop, multiple, limitFile = 8 }, ref) => {
    const [fileList, setFileList] = useState([]);
    const [preview, setPreview] = useState({ status: false, imageUrl: '' });

    useImperativeHandle(ref, () => ({
      reset: () => {
        setFileList([]);
      }
    }));

    const handleChange = ({ fileList: newFileList }) => {
      if (onChange) {
        onChange(
          newFileList.map((file) => (file.originFileObj ? new File([file.originFileObj], file.name) : file))
        );
      }
      setFileList(newFileList);
    };

    const uploadButton = !disabled && (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const handlePreview = (file) => setPreview({ status: true, imageUrl: file.url });

    useEffect(() => {
      if (defaultValue && defaultValue.length > 0) {
        const defaultImage = defaultValue.map((item, index) => ({
          uid: index,
          name: `image-${index}.png`,
          status: 'done',
          url: item.url,
          id: item.id
        }));

        setFileList(defaultImage);
      }
    }, [defaultValue]);

    return (
      <Wrapper className="clearfix" limitFile={limitFile} totalItem={fileList.length} disabled={disabled}>
        {noCrop ? (
          <Upload
            multiple={multiple}
            onPreview={handlePreview}
            action="/upload"
            listType="picture-card"
            fileList={fileList}
            onChange={handleChange}
            showUploadList={{ showPreviewIcon: true, showRemoveIcon: canDelete }}
          >
            {fileList.length >= limitFile ? null : uploadButton}
          </Upload>
        ) : (
          <ImgCrop rotate aspect={aspect || 1}>
            <Upload
              multiple={multiple}
              onPreview={handlePreview}
              action="/upload"
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: canDelete }}
            >
              {fileList.length >= limitFile ? null : uploadButton}
            </Upload>
          </ImgCrop>
        )}
        <Modal
          visible={preview.status}
          footer={null}
          onCancel={() => setPreview({ status: false, imageUrl: '' })}
        >
          <img alt="example" style={{ width: '100%' }} src={preview.imageUrl} />
        </Modal>
      </Wrapper>
    );
  }
);

export default UploadList;
