import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_FORUM_TOPICS = 'ON_GET_FORUM_TOPICS';
const ON_GET_FORUM_POSTS = 'ON_GET_FORUM_POSTS';
const ON_GET_ALL_FORUM_POSTS = 'ON_GET_ALL_FORUM_POSTS';
const ON_GET_FORUM_TOPIC_USERS = 'ON_GET_FORUM_TOPIC_USERS';
const ON_GET_FORUM_SUB_TOPICS = 'ON_GET_FORUM_SUB_TOPICS';
const ON_EDIT_FORUM_TOPIC = 'ON_EDIT_FORUM_TOPIC';
const ON_NEW_FORUM_TOPIC = 'ON_NEW_FORUM_TOPIC';
const ON_REMOVE_FORUM_TOPIC = 'ON_REMOVE_FORUM_TOPIC';
const ON_NEW_FORUM_POST = 'ON_NEW_FORUM_POST';
const ON_EDIT_FORUM_POST = 'ON_EDIT_FORUM_POST';
const ON_GET_FORUM_SELECT_VALUE = 'ON_GET_FORUM_SELECT_VALUE';
const ON_REMOVE_FORUM_POST = 'ON_REMOVE_FORUM_POST';
const ON_REMOVE_FORUM_COMMENT = 'ON_REMOVE_FORUM_COMMENT';
const ON_CREATE_POST_COMMENT = 'ON_CREATE_POST_COMMENT';
const ON_FORUM_OVERVIEW_INFO = 'ON_FORUM_OVERVIEW_INFO';
const ON_FORUM_TOP_RESOURCE = 'ON_FORUM_TOP_RESOURCE';
const ON_FORUM_CHART = 'ON_FORUM_CHART';

function* getAllForumPosts({ params, setData }) {
  try {
    const response = yield call(get, `/forum_topics/all_post`, { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* getForumPosts({ params, setData }) {
  try {
    const response = yield call(get, `/forum_topics/${params.topic_id}/forum_post`, { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
      parent: response.parent,
      rootParent: response.rootParent
    });
  } catch (error) {
    console.log(error);
  }
}

function* getSubTopics({ params, setData }) {
  try {
    const response = yield call(get, `/forum_topics/${params.topic_id}`, { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage,
      parent: response.parent
    });
  } catch (error) {
    console.log(error);
  }
}

function* getTopics({ params, setData }) {
  try {
    const response = yield call(get, '/forum_topics', { params });
    setData({
      loading: false,
      items: response
    });
  } catch (error) {
    console.log(error);
  }
}

function* editForum({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('forum_topic[position]', params.position);
    formData.append('forum_topic[name]', params.name);
    formData.append('forum_topic[private]', params.private);

    if (params.image) formData.append('forum_topic[image]', params.image);

    // eslint-disable-next-line array-callback-return
    (params.user_forum_topics_attributes || []).map((item) => {
      formData.append('forum_topic[user_forum_topics_attributes][][user_id]', item.user_id);
      formData.append('forum_topic[user_forum_topics_attributes][][user_type]', item.user_type);
      if (item.id) formData.append('forum_topic[user_forum_topics_attributes][][id]', item.id);
      if (item._destroy)
        formData.append('forum_topic[user_forum_topics_attributes][][_destroy]', item._destroy);
    });

    yield call(axiosPut, `/forum_topics/${params.id}`, formData);
    Notification.success({ message: 'Chỉnh sửa chủ đề thành công.' });
    callback(200);
  } catch (error) {
    console.log(error);
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newForum({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('forum_topic[position]', params.position);
    formData.append('forum_topic[name]', params.name);
    formData.append('forum_topic[private]', params.private);
    formData.append('forum_topic[short_description]', params.short_description || '');
    if (params.parent_id) formData.append('forum_topic[parent_id]', params.parent_id);

    if (params.image) formData.append('forum_topic[image]', params.image);

    // eslint-disable-next-line array-callback-return
    (params.user_forum_topics_attributes || []).map((item) => {
      formData.append('forum_topic[user_forum_topics_attributes][][user_id]', item.user_id);
      formData.append('forum_topic[user_forum_topics_attributes][][user_type]', item.user_type);
      if (item.id) formData.append('forum_topic[user_forum_topics_attributes][][id]', item.id);
      if (item._destroy)
        formData.append('forum_topic[user_forum_topics_attributes][][_destroy]', item._destroy);
    });

    yield call(post, '/forum_topics', formData);
    Notification.success({ message: 'Thêm chủ đề thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeForum({ params, callback }) {
  try {
    yield call(axiosDelete, `/forum_topics/${params.id}`);
    Notification.success({ message: 'Xóa chủ đề thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    console.log(error);
  }
}

function* newForumPost({ params, callback }) {
  try {
    yield call(post, '/forum_topics/create_forum_post', params);
    Notification.success({ message: 'Thêm bài viết thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* getSelectValue({ setData }) {
  try {
    const response = yield call(get, '/forum_topics/select_values');
    setData(response);
  } catch (error) {
    console.log(error);
  }
}

function* removePost({ params, callback }) {
  try {
    yield call(axiosDelete, '/forum_topics/remove_post', { params });
    Notification.success({ message: 'Xóa bài viết thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* editForumPost({ params, callback }) {
  try {
    yield call(axiosPut, '/forum_topics/edit_forum_post', params);
    Notification.success({ message: 'Chỉnh sửa bài viết thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeComment({ params, callback }) {
  try {
    yield call(axiosDelete, '/forum_topics/remove_comment', { params });
    Notification.success({ message: 'Xóa bình luận thành công.' });
    callback(params.id);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* getUsers({ setData }) {
  try {
    const response = yield call(get, '/forum_topics/users');
    setData(response);
  } catch (error) {
    console.log(error);
  }
}

function* createForumComment({ params, callback }) {
  try {
    const response = yield call(post, '/forum_topics/create_comment', params);
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* getOverviewStatistic({ params, callback }) {
  try {
    const response = yield call(get, '/forum_topics/overview_statistic', { params });
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* getTopResourceStatistic({ params, callback }) {
  try {
    const response = yield call(get, '/forum_topics/top_resource', { params });
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* getChartOverview({ params, callback }) {
  try {
    const response = yield call(get, '/forum_topics/overview_chart', { params });
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onGetChartOverview = (params, callback) => ({
  type: ON_FORUM_CHART,
  params,
  callback
});

export const onGetOverviewStatistic = (params, callback) => ({
  type: ON_FORUM_OVERVIEW_INFO,
  params,
  callback
});

export const onGetTopResourceStatistic = (params, callback) => ({
  type: ON_FORUM_TOP_RESOURCE,
  params,
  callback
});

export const onNewTopic = (params, callback) => ({
  type: ON_NEW_FORUM_TOPIC,
  params,
  callback
});

export const onEditTopic = (params, callback) => ({
  type: ON_EDIT_FORUM_TOPIC,
  params,
  callback
});

export const onGetTopics = (params, setData) => ({
  type: ON_GET_FORUM_TOPICS,
  params,
  setData
});

export const onDeleteTopic = (params, callback) => ({
  type: ON_REMOVE_FORUM_TOPIC,
  params,
  callback
});

export const onGetSubTopics = (params, setData) => ({
  type: ON_GET_FORUM_SUB_TOPICS,
  params,
  setData
});

export const onGetForumPosts = (params, setData) => ({
  type: ON_GET_FORUM_POSTS,
  params,
  setData
});

export const onNewForumPost = (params, callback) => ({
  type: ON_NEW_FORUM_POST,
  params,
  callback
});

export const onGetForumSelectValue = (setData) => ({
  type: ON_GET_FORUM_SELECT_VALUE,
  setData
});

export const onDeletePost = (params, callback) => ({
  type: ON_REMOVE_FORUM_POST,
  params,
  callback
});

export const onEditForumPost = (params, callback) => ({
  type: ON_EDIT_FORUM_POST,
  params,
  callback
});

export const onRemoveComment = (params, callback) => ({
  type: ON_REMOVE_FORUM_COMMENT,
  params,
  callback
});

export const onGetUsers = (setData) => ({
  type: ON_GET_FORUM_TOPIC_USERS,
  setData
});

export const onCreatePostComment = (params, callback) => ({
  type: ON_CREATE_POST_COMMENT,
  params,
  callback
});

export const onGetAllForumPosts = (params, setData) => ({
  type: ON_GET_ALL_FORUM_POSTS,
  params,
  setData
});

export default function* forumWatcher() {
  yield takeLatest(ON_FORUM_CHART, getChartOverview);
  yield takeLatest(ON_FORUM_TOP_RESOURCE, getTopResourceStatistic);
  yield takeLatest(ON_FORUM_OVERVIEW_INFO, getOverviewStatistic);
  yield takeLatest(ON_GET_FORUM_SUB_TOPICS, getSubTopics);
  yield takeLatest(ON_GET_FORUM_TOPICS, getTopics);
  yield takeLatest(ON_EDIT_FORUM_TOPIC, editForum);
  yield takeLatest(ON_NEW_FORUM_TOPIC, newForum);
  yield takeLatest(ON_REMOVE_FORUM_TOPIC, removeForum);
  yield takeLatest(ON_GET_FORUM_POSTS, getForumPosts);
  yield takeLatest(ON_NEW_FORUM_POST, newForumPost);
  yield takeLatest(ON_GET_FORUM_SELECT_VALUE, getSelectValue);
  yield takeLatest(ON_REMOVE_FORUM_POST, removePost);
  yield takeLatest(ON_EDIT_FORUM_POST, editForumPost);
  yield takeLatest(ON_REMOVE_FORUM_COMMENT, removeComment);
  yield takeLatest(ON_GET_FORUM_TOPIC_USERS, getUsers);
  yield takeLatest(ON_CREATE_POST_COMMENT, createForumComment);
  yield takeLatest(ON_GET_ALL_FORUM_POSTS, getAllForumPosts);
}
