import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Typography, Button,
} from 'antd';
import { useDispatch } from 'react-redux';
import Upload from '../../../../../../../../components/Upload';
import {
  onUpdatePost,
} from './saga';
import {
  fieldValidate,
} from '../../../../../../../../shared/function';
import Ckeditor from '../../../../../../../../shared/Ckeditor';
import {
  StyledModal,
} from './styles';

const { Title } = Typography;
const { Item } = Form;

const CreatePost = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [item, setItem] = useState({});
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status, response) => {
    if (status === 200) {
      fetchData();
      close();
    }
    setSubmiting(false);
  };

  const updateData = useCallback((params) => dispatch(
    onUpdatePost(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    const formData = new FormData();
    formData.append('class_forum[id]', item.id);
    formData.append('class_forum[short_description]', values.short_description);
    formData.append('class_forum[title]', values.title);
    formData.append('class_forum[content]', values.content);

    if (values.image) formData.append('class_forum[image]', values.image);

    updateData({ formData });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setItem(item);
      setShowModal(true);
      setSubmiting(false);
      form.setFieldsValue({
        short_description: item.shortDescription,
        title: item.title,
      });
    },
  }));

  return (
    <StyledModal
      closable={false}
      visible={isShow}
      footer={null}
    >
      <Form
        layout='vertical'
        form={form}
        name='editForumPost'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Title>Tạo bài viết</Title>
          </Col>
          <Col span={24}>
            <Row align='middle'>
              <Col lg={8} xs={24}>
                <Form.Item
                  name='image'
                >
                  <Upload
                    defaultValue={item.image}
                    cropImage
                    onFileChange={(file) => form.setFieldsValue({ image: file })}
                  />
                </Form.Item>
              </Col>
              <Col lg={16} xs={24}>
                <Row>
                  <Col span={24}>
                    <Item
                      name='title'
                      rules={[fieldValidate.required]}
                    >
                      <Input placeholder='Tiêu đề bài viết' />
                    </Item>
                  </Col>
                  <Col span={24}>
                    <Item
                      name='short_description'
                      rules={[fieldValidate.required]}
                    >
                      <Input.TextArea placeholder='Mô tả ngắn' rows={2} />
                    </Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Item
              name='content'
            >
              <Ckeditor
                defaultValue={item.content}
                onChange={(value) => form.setFieldsValue({ content: value })}
              />
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }} justify='center'>
          <Button
            disabled={submiting}
            type='primary'
            htmlType='submit'
          >
            Lưu
          </Button>
          <Button
            onClick={close}
            style={{
              marginLeft: 10, background: 'transparent', color: '#333333', border: '1px solid #333333',
            }}
            htmlType='submit'
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </StyledModal>
  );
});

export default CreatePost;
