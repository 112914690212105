import styled from 'styled-components';

export const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 5px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 25px;
  background: #e5e5e5;
`;

export default ChipWrapper;
