import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_FORUM_MEMBER_UPDATE_FIREBASE_UID = 'ON_FORUM_MEMBER_UPDATE_FIREBASE_UID';
const ON_GET_FORUM_MEMBERS = 'ON_GET_FORUM_MEMBERS';
const ON_EDIT_FORUM_MEMBER = 'ON_EDIT_FORUM_MEMBER';
const ON_NEW_FORUM_MEMBER = 'ON_NEW_FORUM_MEMBER';
const ON_UPDATE_FORUM_MEMBER_HASHTAG = 'ON_UPDATE_FORUM_MEMBER_HASHTAG';
const ON_UPDATE_FORUM_MEMBER_PERMISSION = 'ON_UPDATE_FORUM_MEMBER_PERMISSION';
const ON_UPDATE_FORUM_MEMBER_FORUM_TOPIC = 'ON_UPDATE_FORUM_MEMBER_FORUM_TOPIC';
const ON_REMOVE_FORUM_MEMBER = 'ON_REMOVE_FORUM_MEMBER';

function* getForumMembers({ params, setData }) {
  try {
    const response = yield call(get, '/forum_members', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* updateHashtag({ params, callback }) {
  try {
    yield call(axiosPut, `/forum_members/${params.id}/update_hashtag`, params);
    Notification.success({ message: 'Chỉnh sửa hashtag của thành viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* updateForumTopic({ params, callback }) {
  try {
    yield call(axiosPut, `/forum_members/${params.id}/update_forum_topic`, params);
    Notification.success({ message: 'Chỉnh sửa chủ đề diễn đàn của thành viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* editForumMember({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('user[email]', params.email);
    formData.append('user[first_name]', params.firstName);
    formData.append('user[last_name]', params.lastName);
    formData.append('user[birthday]', params.birthday ? params.birthday.format('DD/MM/YYYY') : '');
    formData.append('user[gender]', params.gender === 'male' ? 0 : 1);
    formData.append('user[phone]', params.phone);
    formData.append('user[job]', params.job);
    formData.append('user[address][address]', params.address);
    if (params.avatar) formData.append('user[avatar]', params.avatar);
    if (params.images) {
      params.images.forEach((file) => {
        formData.append('user[images][]', file.id ? file.id : file);
      });
    }

    yield call(axiosPut, `/forum_members/${params.id}`, formData);
    Notification.success({ message: 'Chỉnh sửa thông tin thành viên thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* newForumMember({ params, callback }) {
  try {
    const formData = new FormData();
    formData.append('user[email]', params.email);
    formData.append('user[first_name]', params.firstName);
    formData.append('user[last_name]', params.lastName);
    formData.append('user[birthday]', params.birthday ? params.birthday.format('DD/MM/YYYY') : '');
    formData.append('user[gender]', params.gender === 'male' ? 0 : 1);
    formData.append('user[phone]', params.phone);
    formData.append('user[job]', params.job);
    formData.append('user[address][address]', params.address);
    if (params.avatar) formData.append('user[avatar]', params.avatar);
    if (params.images) {
      params.images.forEach((file) => {
        formData.append('user[images][]', file);
      });
    }

    const response = yield call(post, '/forum_members', formData);
    callback(200, { email: params.email, id: response.user_id });
    Notification.success({ message: 'Thêm thành viên thành công.' });
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* updateFireBaseUid({ params }) {
  try {
    yield call(post, '/current_user/info/update_uid', params);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* deleteForumMember({ id, callback }) {
  try {
    yield call(axiosDelete, `/forum_members/${id}`);

    Notification.success({ message: 'Xóa thành viên thành công.' });
    callback();
  } catch (error) {
    console.log(error);
  }
}

function* updatePermission({ params, callback }) {
  try {
    yield call(axiosPut, `/forum_members/${params.id}/update_forum_member`, params);
    Notification.success({ message: 'Thay đổi chức vụ thành công.' });
    callback(200);
  } catch (error) {
    callback(400);
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onUpdateFireBaseUid = (params) => ({
  type: ON_FORUM_MEMBER_UPDATE_FIREBASE_UID,
  params
});

export const onNewForumMember = (params, callback) => ({
  type: ON_NEW_FORUM_MEMBER,
  params,
  callback
});

export const onEditForumMember = (params, callback) => ({
  type: ON_EDIT_FORUM_MEMBER,
  params,
  callback
});

export const onGetForumMembers = (params, setData) => ({
  type: ON_GET_FORUM_MEMBERS,
  params,
  setData
});

export const onUpdateHashtag = (params, callback) => ({
  type: ON_UPDATE_FORUM_MEMBER_HASHTAG,
  params,
  callback
});

export const onUpdateForumTopic = (params, callback) => ({
  type: ON_UPDATE_FORUM_MEMBER_FORUM_TOPIC,
  params,
  callback
});

export const onRemoveForumMember = (id, callback) => ({
  type: ON_REMOVE_FORUM_MEMBER,
  id,
  callback
});

export const onUpdatePermission = (params, callback) => ({
  type: ON_UPDATE_FORUM_MEMBER_PERMISSION,
  params,
  callback
});

export default function* forumMemberWatcher() {
  yield takeLatest(ON_GET_FORUM_MEMBERS, getForumMembers);
  yield takeLatest(ON_EDIT_FORUM_MEMBER, editForumMember);
  yield takeLatest(ON_NEW_FORUM_MEMBER, newForumMember);
  yield takeLatest(ON_FORUM_MEMBER_UPDATE_FIREBASE_UID, updateFireBaseUid);
  yield takeLatest(ON_UPDATE_FORUM_MEMBER_HASHTAG, updateHashtag);
  yield takeLatest(ON_UPDATE_FORUM_MEMBER_FORUM_TOPIC, updateForumTopic);
  yield takeLatest(ON_REMOVE_FORUM_MEMBER, deleteForumMember);
  yield takeLatest(ON_UPDATE_FORUM_MEMBER_PERMISSION, updatePermission);
}
