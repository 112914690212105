import styled from 'styled-components';
import { Spin } from 'antd';

export default styled(Spin)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BranchName = styled.div`
  color: #fff;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 20px 0;
`;
