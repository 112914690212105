import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Row, Form, Modal, Button, Select,
} from 'antd';
import { useDispatch } from 'react-redux';
import { onUpdateHashtag } from '../saga';
import { onGetHashtag } from '../../Hashtags/saga';
import SelectSearch from '../../../../components/SelectSearch';

const { Item } = Form;
const { Option } = Select;

const AddHashtag = React.forwardRef(({ fetchData }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);
  const [hashtags, setHashtag] = useState([]);

  const close = () => {
    form.resetFields();
    setState({ status: false, item: {} });
  };

  const callback = (status) => {
    if (status === 200) {
      close();
      fetchData();
    }

    setSubmiting(false);
  };

  const getHashtag = useCallback((params) => dispatch(
    onGetHashtag(params, (response) => {
      setHashtag(response.items);
    }),
  ), [dispatch]);

  const editData = useCallback((params) => dispatch(
    onUpdateHashtag(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);

    const selectHashtag = values.hashtags;

    const newHashtag = [];
    const removeHashtag = [];

    (selectHashtag || []).map((id) => {
      const filterdCourse = item.hashtags.filter((a) => a.id === parseInt(id, 10))[0];

      if (!filterdCourse) newHashtag.push({ hashtag_id: id });
    });

    item.userHashtags.map((userHashtag) => {
      if (!selectHashtag.includes(userHashtag.hashtag_id)) {
        removeHashtag.push({
          _destroy: true,
          id: userHashtag.id,
          hashtag_id: userHashtag.hashtag_id,
        });
      }
    });

    editData({ user: { user_hashtags_attributes: newHashtag.concat(removeHashtag) }, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        hashtags: item.hashtags.map((a) => a.id),
      });

      getHashtag({ per_page: 99999, status: 'paid' });
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      closable={false}
      visible={status}
      footer={null}
      title='Thêm hashtag'
    >
      <Form
        layout='vertical'
        form={form}
        name='addHashtag'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>

          <Col span={24}>
            <Item
              name='hashtags'
            >
              <SelectSearch placeholder='Hashtag' mode='multiple'>
                {hashtags.filter((a) => a.status === 'paid').map(
                  (hashtag) => (<Option value={hashtag.id} key={hashtag.id}>{hashtag.name}</Option>),
                )}
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default AddHashtag;
