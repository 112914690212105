import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 110px;
`;

export const Content = styled.div`
  border-radius: 30px;
  width: 100%;
  height: 100%;
  background: #f1f5f8;
  padding: 40px;
`;
