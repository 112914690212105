import React, { useRef, useCallback, useState, useImperativeHandle, useEffect } from 'react';
import { LikeOutlined, CommentOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { List, Card, Typography, Avatar, Divider, Tag, Button, Popconfirm, Row, Modal, Col } from 'antd';
import {
  StyledCard,
  ContentWrapper,
  Description,
  HasTagWrapper,
  ActionWrapper,
  StyledImage,
  Head,
  StyledModal,
  StyledComment
} from './styles';
import { fallbackImage, convertContent } from '@shared/function';
import { onRemoveClassForumComment, onRemoveClassForum } from './saga';

const { Text, Title } = Typography;
const actionIconStyle = { fontSize: 18, cursor: 'pointer' };

const InfoModal = React.forwardRef(({ content, image }, ref) => {
  const [isShow, setShowModal] = useState(false);
  const close = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  return (
    <StyledModal onCancel={close} closable={true} visible={isShow} footer={null}>
      <Row justify="center">
        <img src={image} style={{ width: 300, height: 300 }} />
      </Row>
      <div dangerouslySetInnerHTML={{ __html: convertContent(content) }} style={{ marginTop: 20 }} />
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button size="large" style={{ width: 150 }} onClick={close}>
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

const LikeModal = React.forwardRef(({ forumLikes }, ref) => {
  const [isShow, setShowModal] = useState(false);
  const close = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  return (
    <Modal onCancel={close} closable={true} visible={isShow} footer={null}>
      <Row>
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={forumLikes}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  style={{ alignItems: 'center' }}
                  avatar={<Avatar src={item.avatar} />}
                  title={item.name}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button size="large" style={{ width: 150 }} onClick={close}>
          Đóng
        </Button>
      </Row>
    </Modal>
  );
});

const CommentModal = React.forwardRef(({ comments, removeCommentData }, ref) => {
  const [isShow, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const close = () => {
    setShowModal(false);
  };

  const callback = (commentId) => removeCommentData(commentId);

  const removeComment = useCallback((params) => dispatch(onRemoveClassForumComment(params, callback)), [
    dispatch
  ]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  return (
    <StyledModal onCancel={close} closable={true} visible={isShow} footer={null}>
      <Row>
        <Col span={24}>
          <List
            style={{ marginTop: 20, height: 600, overflowY: 'auto' }}
            itemLayout="horizontal"
            dataSource={comments}
            renderItem={(item) => (
              <List.Item>
                <StyledComment
                  actions={[
                    <ActionWrapper
                      onClick={() => removeComment({ comment_id: item.id, forumId: item.classForumId })}
                    >
                      <DeleteOutlined style={{ ...actionIconStyle, color: 'red' }} />
                      <Text style={{ marginLeft: 5, color: 'red' }}>Xóa bình luận</Text>
                    </ActionWrapper>
                  ]}
                  author={<Text strong>{item.name}</Text>}
                  avatar={<Avatar src={item.avatar} alt={item.name} />}
                  content={<p style={{ whiteSpace: 'pre-line' }}>{item.shortDescription}</p>}
                  datetime={<span>{item.createdAt}</span>}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 20 }}>
        <Button size="large" style={{ width: 150 }} onClick={close}>
          Đóng
        </Button>
      </Row>
    </StyledModal>
  );
});

const PostItem = ({ item, fetchData, editRef }) => {
  const [comments, setComments] = useState(item.classForumComments || []);
  const dispatch = useDispatch();
  const contentRef = useRef();
  const likeRef = useRef();
  const commentRef = useRef();

  const removePost = useCallback((params) => dispatch(onRemoveClassForum(params, fetchData)), [dispatch]);

  const removeComment = (commentId) => {
    setComments((state) => state.filter((item) => item.id !== parseInt(commentId, 10)));
  };

  useEffect(() => {
    setComments(item.classForumComments || []);
  }, [item]);

  return (
    <>
      <CommentModal ref={commentRef} comments={comments} removeCommentData={removeComment} />
      <LikeModal ref={likeRef} forumLikes={item.classForumLikes} />
      <InfoModal ref={contentRef} content={item.content} image={item.image} />
      <List.Item>
        <StyledCard
          actions={[
            <ActionWrapper onClick={() => likeRef.current.open()}>
              <LikeOutlined style={actionIconStyle} />
              <Text style={{ marginLeft: 5 }}>{`Thích (${item.classForumLikes.length})`}</Text>
            </ActionWrapper>,
            <ActionWrapper onClick={() => commentRef.current.open()}>
              <CommentOutlined style={actionIconStyle} />
              <Text style={{ marginLeft: 5 }}>{`Bình luận (${comments.length})`}</Text>
            </ActionWrapper>
          ]}
          hoverable
        >
          <Head>
            <Card.Meta
              avatar={<Avatar src={item.owner.avatarUrl} size={40} />}
              title={<Text strong>{`GV. ${item.owner.name}`}</Text>}
              description={item.createdAt}
            />
            <div>
              <EditOutlined
                style={{ marginRight: 20, cursor: 'pointer', fontSize: 20 }}
                onClick={() => editRef.current.open(item)}
              />
              <Popconfirm
                placement="top"
                title="Bạn có muốn xóa bài viết này không?"
                onConfirm={() => removePost({ id: item.id })}
                okText="Có"
                cancelText="Không"
              >
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 20 }} />
              </Popconfirm>
            </div>
          </Head>
          <Divider style={{ margin: 0 }} />
          <ContentWrapper>
            <StyledImage src={item.image} fallback={fallbackImage} />
            <Title level={4} style={{ marginTop: 20, marginBottom: 5 }}>
              {item.title}
            </Title>
            <Description dangerouslySetInnerHTML={{ __html: item.content }} />
            <Button
              type="link"
              style={{ textAlign: 'right', padding: 0 }}
              onClick={() => contentRef.current.open()}
            >
              Xem thêm
            </Button>
          </ContentWrapper>
          <Divider style={{ margin: 0 }} />
          {item.hashtag.length > 0 && (
            <HasTagWrapper>
              {item.hashtag.map((hashtag) => (
                <Tag style={{ marginTop: 5, marginRight: 5 }} color="#393939">
                  {hashtag}
                </Tag>
              ))}
            </HasTagWrapper>
          )}
        </StyledCard>
      </List.Item>
    </>
  );
};
export default PostItem;
