import React from 'react';
import { PauseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { numberToCurrency } from '../../../../shared/function';

export const columns = () => [
  {
    title: 'Mã phiếu',
    dataIndex: 'code',
    key: 'code',
    sorter: {}
  },
  {
    title: 'Loại',
    dataIndex: 'expenseType',
    key: 'expense_type',
    render: (expenseType) => {
      switch (expenseType) {
        case 'personal':
          return <Tag color="gray">Nhân viên</Tag>;
        case 'company':
          return <Tag color="green">Cá nhân</Tag>;
        default:
          return '';
      }
    }
  },
  {
    title: 'Người nhận',
    key: 'name',
    render: (item) => {
      switch (item.expenseType) {
        case 'personal':
          return item.name;
        case 'company':
          return item.receivePerson;
        default:
          return '';
      }
    }
  },
  {
    title: 'Nội dung',
    dataIndex: 'note',
    key: 'note',
    render: (note) => <div style={{ whiteSpace: 'pre-line' }}>{note}</div>
  },
  {
    title: 'Số tiền',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => numberToCurrency(amount)
  },
  {
    title: 'Tính công nợ',
    key: 'is_debt',
    render: (item) => {
      switch (item.expenseType) {
        case 'personal':
          return item.isDebt ? 'Có' : 'Không';
        case 'company':
          return '';
        default:
          return '';
      }
    }
  },
  {
    title: 'Ngày Lập',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: 'Xác nhận',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (status) => {
      switch (status) {
        case 'waiting_for_approve':
          return (
            <Tooltip title="Đang chờ xác nhận" color="red" key="red">
              <PauseCircleOutlined style={{ cursor: 'pointer', color: 'orange', fontSize: 20 }} />
            </Tooltip>
          );

        case 'approve':
          return (
            <Tooltip title="Đã xác nhận" color="green" key="green">
              <CheckCircleOutlined style={{ cursor: 'pointer', color: 'green', fontSize: 20 }} />
            </Tooltip>
          );

        default:
          return null;
      }
    }
  }
];
