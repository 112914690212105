import React, { useEffect, useCallback, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Row, Col, Input, Form, Select, Button } from 'antd';
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { onGetHashtag } from '../Hashtags/saga';

const { Option } = Select;

const Filter = ({ currentFilter, changeLocation, isAdmin }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let typing = null;
  const [hashtags, setHashtag] = useState([]);

  const getHashtag = useCallback(
    (params) =>
      dispatch(
        onGetHashtag(params, (response) => {
          setHashtag(response.items);
        })
      ),
    [dispatch]
  );

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.setFieldsValue({
      status: '',
      keyword: ''
    });
    changeLocation();
  };

  useEffect(() => {
    const { keyword, status, hashtags, type } = queryString.parse(location.search);

    form.setFieldsValue({
      status: status || '',
      keyword: keyword || '',
      type: type || '',
      hashtags: hashtags ? hashtags.split(',') : []
    });
    getHashtag({ per_page: 99999, status: 'paid' });
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="keyword" label="Từ khóa:">
            <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="status" label="Trạng thái:">
            <Select onChange={(e) => onFilter('status', e)}>
              <Option value="">Tất cả </Option>
              <Option value="active">Đang hoạt động</Option>
              <Option value="locked">Cấm truy cập</Option>
              <Option value="pending">Đang chờ xác nhận</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="type" label="Loại:">
            <Select onChange={(e) => onFilter('type', e)}>
              <Option value="">Tất cả </Option>
              <Option value="member">Thành viên</Option>
              <Option value="moderator">Moderator</Option>
              <Option value="admin">Admin</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={4} xxl={4} style={{ display: 'flex' }}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
              Xóa bộ lọc
            </Button>
          </Form.Item>
          {isAdmin && (
            <Form.Item style={{ marginTop: 30, marginLeft: 15 }}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => {
                  const { keyword, status, type } = currentFilter.current;

                  window.open(
                    `/export-forum-members?export=true&keyword=${keyword || ''}&status=${status || ''}&type=${
                      type || ''
                    }`
                  );
                }}
              >
                Xuất Excel
              </Button>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
