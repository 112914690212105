import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  EditOutlined, CloseCircleOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  Form, Tag, Select, Button, Typography,
} from 'antd';
import { fieldValidate } from '../../../../../shared/function';
import SelectSearch from '../../../../../components/SelectSearch';
import { Wrapper } from './styles';
import { onEditCourseField } from '../../saga';

const { Option } = Select;
const { Text } = Typography;

const Topic = ({ item, topics }) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [form] = Form.useForm();
  const [defaultValue, setDefaultValue] = useState(item.courseTopicId);

  const callback = (status, response) => {
    if (status === 200) {
      setDefaultValue(response.courseTopicId);
      setEditable(false);
    }
  };

  const editField = useCallback((params) => dispatch(
    onEditCourseField(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    editField({ course: { ...values }, id: item.id });
  };

  const topicValue = () => {
    const topic = topics.filter((a) => a.id === parseInt(defaultValue, 10))[0]

    return topic ? topic.name : '';
  }

  useEffect(() => {
    if (editable) form.setFieldsValue({ course_topic_id: defaultValue });
  }, [editable]);

  return (
    <Wrapper>
      {!editable
        ? (
          <>
            <Text style={{ marginLeft: 5 }}>{topicValue()}</Text>
            <EditOutlined style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }} onClick={() => setEditable(true)} />
          </>
        )
        : (
          <>
            <Form
              layout='vertical'
              form={form}
              name='statusInline'
              scrollToFirstError
              onFinish={onFinish}
            >
              <Form.Item
                style={{ margin: 0, display: 'flex' }}
                name='course_topic_id'
                rules={[fieldValidate.required]}
              >
              <SelectSearch placeholder='Chủ đề' style={{ width: 200 }}>
                {topics.map(
                  (item) => <Option value={item.id} key={item.id}>{item.name}</Option>,
                )}
              </SelectSearch>
              </Form.Item>
              <Button type='primary' htmlType='submit'>Lưu</Button>
            </Form>
            <CloseCircleOutlined style={{ marginLeft: 10, fontSize: 18, cursor: 'pointer' }} onClick={() => setEditable(false)} />
          </>
        )}

    </Wrapper>
  );
};
export default Topic;
