import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Button, Input, Select, DatePicker } from 'antd';
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons';
import { onGetEmployees } from '../../Employees/saga';
import { onGetStudents } from '../../Students/saga';
import moment from 'moment';
import { dateFormat } from '@shared/function';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filter = ({ currentFilter, changeLocation, type }) => {
  let typing = null;
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [students, setStudents] = useState([]);
  const location = useLocation();
  const [form] = Form.useForm();

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.resetFields();
    changeLocation();
  };

  useEffect(() => {
    const { keyword, user_id, status, student_id, time_gteq, time_lteq } = queryString.parse(location.search);

    form.setFieldsValue({
      keyword: keyword || '',
      user_id: user_id || '',
      student_id: student_id || '',
      status: status || '',
      time: time_gteq && time_lteq ? [moment(time_gteq, dateFormat), moment(time_lteq, dateFormat)] : []
    });

    if (type === 'my_course_sale') {
      dispatch(onGetEmployees({ per_page: 9999, saler: true }, (response) => setEmployees(response.items)));
      dispatch(onGetStudents({ per_page: 9999 }, (response) => setStudents(response.items)));
    }
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={4}>
          <Form.Item name="keyword" label="Từ khóa:">
            <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
          </Form.Item>
        </Col>
        {type === 'my_course_sale' ? (
          <>
            <Col span={24} md={8} lg={8} xl={6} xxl={4}>
              <Form.Item name="user_id" label="Nhân viên Sale:">
                <Select onChange={(e) => onFilter('user_id', e)}>
                  [<Option value="">Tất cả </Option>,
                  {employees.map((item) => (
                    <Option value={item.id.toString()}>{item.name}</Option>
                  ))}
                  ]
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={8} lg={8} xl={6} xxl={4}>
              <Form.Item name="student_id" label="Học viên:">
                <Select onChange={(e) => onFilter('student_id', e)}>
                  [<Option value="">Tất cả</Option>,
                  {students.map((item) => (
                    <Option value={item.id.toString()}>{item.name}</Option>
                  ))}
                  ]
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} md={8} lg={8} xl={6} xxl={4}>
              <Form.Item name="status" label="Trạng thái:">
                <Select onChange={(e) => onFilter('status', e)}>
                  <Option value="">Tất cả </Option>
                  <Option value="0">Chưa tiến hành</Option>
                  <Option value="1">Đang thu tiền</Option>
                  <Option value="2">Đã hoàn thành</Option>
                  <Option value="3">Hủy thanh toán</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={12} xl={8} xxl={6}>
              <Form.Item name="time" label="Ngày lập phiếu:">
                <RangePicker
                  format={dateFormat}
                  style={{ width: '100%' }}
                  placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                  onChange={(dates) => {
                    currentFilter.current.time_gteq = dates ? dates[0].format(dateFormat) : null;
                    currentFilter.current.time_lteq = dates ? dates[1].format(dateFormat) : null;
                    changeLocation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row justify="end" style={{ marginTop: 10 }}>
                <Form.Item style={{ marginRight: 20 }}>
                  <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
                    Xóa bộ lọc
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      const {
                        keyword,
                        user_id,
                        status,
                        student_id,
                        time_gteq,
                        time_lteq
                      } = queryString.parse(location.search);

                      window.open(
                        `/export-my-sale?export=true&keyword=${keyword || ''}&status=${
                          status || ''
                        }&student_id=${student_id || ''}&user_id=${user_id || ''}&time_gteq=${
                          time_gteq || ''
                        }&time_lteq=${time_lteq || ''}`
                      );
                    }}
                  >
                    Xuất Excel
                  </Button>
                </Form.Item>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col span={24} md={8} lg={8} xl={6} xxl={4}>
              <Form.Item name="status" label="Trạng thái:">
                <Select onChange={(e) => onFilter('status', e)}>
                  <Option value="">Tất cả </Option>
                  <Option value="0">Chưa tiến hành</Option>
                  <Option value="1">Đang thu tiền</Option>
                  <Option value="2">Đã hoàn thành</Option>
                  <Option value="3">Hủy thanh toán</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={12} lg={12} xl={8} xxl={4}>
              <Form.Item style={{ marginTop: 30 }}>
                <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
                  Xóa bộ lọc
                </Button>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default Filter;
