import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Input, Row, Form, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate } from '../../../../shared/function';
import SelectSearch from '../../../../components/SelectSearch';
import Upload from '../../../../components/Upload';
import UploadList from '../../../../components/UploadList';
import { onNewCourse } from '../saga';
import { StyledModal } from './styles';
import Ckeditor from '../../../../shared/Ckeditor';

const { Item } = Form;
const { Option } = Select;

const Create = React.forwardRef(({ teachers, managers, fetchData, topics }, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const uploadCalendarRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    if (uploadRef.current) uploadRef.current.reset();
    if (uploadCalendarRef.current) uploadCalendarRef.current.reset();
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmitting(false);
  };

  const createData = useCallback((params) => dispatch(onNewCourse(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmitting(true);
    createData(values);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    }
  }));

  return (
    <StyledModal closable={false} visible={isShow} footer={null} title="Thêm khóa học">
      <Form layout="vertical" form={form} name="createCourse" scrollToFirstError onFinish={onFinish}>
        <Row gutter={32}>
          <Col span={6}>
            <Item name="moduleName" rules={[fieldValidate.required]} label="Tên module">
              <Input placeholder="Tên module" />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="title" rules={[fieldValidate.required]} label="Tiêu đề">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="courseTopicId" rules={[fieldValidate.required]} label="Chủ đề">
              <SelectSearch placeholder="Chủ đề">
                {topics.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={6}>
            <Item name="status" rules={[fieldValidate.required]} label="Trạng thái">
              <Select placeholder="Trạng thái">
                <Option value="publish">Công bố</Option>
                <Option value="draft">Ẩn</Option>
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item name="teachers" rules={[fieldValidate.required]} label="Giảng viên">
              <SelectSearch placeholder="Giảng viên" mode="multiple">
                {teachers.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={12}>
            <Item name="managers" rules={[fieldValidate.required]} label="Quản lí">
              <SelectSearch placeholder="Quản lí" mode="multiple">
                {managers.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item rules={[fieldValidate.required]} label="Hình ảnh" name="mainImage">
              <Upload cropImage onFileChange={(file) => form.setFieldsValue({ mainImage: file })} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Item name="introduceTitle" label="Mục 1">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="introduce">
              <Ckeditor onChange={(value) => form.setFieldsValue({ introduce: value })} />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="targetTitle" label="Mục 2">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="target">
              <Ckeditor onChange={(value) => form.setFieldsValue({ target: value })} />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="theoryTitle" label="Mục 3">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="theory">
              <Ckeditor onChange={(value) => form.setFieldsValue({ theory: value })} />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="practiceTitle" label="Mục 4">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="practice">
              <Ckeditor onChange={(value) => form.setFieldsValue({ practice: value })} />
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh lịch học" name="calendar_images" />
            <UploadList
              noCrop={false}
              limitFile={10}
              aspect={1}
              ref={uploadCalendarRef}
              circle
              onChange={(images) => form.setFieldsValue({ calendar_images: images })}
            />
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh" name="images" />
            <UploadList
              noCrop={false}
              limitFile={10}
              aspect={1.92}
              ref={uploadRef}
              circle
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </StyledModal>
  );
});

export default Create;
