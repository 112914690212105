import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Typography, Tag } from 'antd';
import styled from 'styled-components';
import { numberToCurrency } from '../../../shared/function';

const { Text } = Typography;

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {}
  },
  {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name',
    sorter: {}
  },
  {
    title: 'Giá gốc',
    dataIndex: 'originalPrice',
    key: 'originalPrice',
    sorter: {},
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Giá khuyễn mãi',
    dataIndex: 'price',
    key: 'price',
    sorter: {},
    render: (price) => numberToCurrency(price)
  },
  {
    title: 'Trạng thái',
    sorter: {},

    render: (item) => {
      switch (item.status) {
        case 'expired':
          return <Tag color="red">Hết hạn</Tag>;
        default:
          return <Tag color="green">Hoạt động</Tag>;
      }
    }
  },
  {
    title: 'Khóa học',
    dataIndex: 'courseName',
    render: (courseName) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {courseName.map((item) => (
          <Text>{`- ${item}`}</Text>
        ))}
      </div>
    )
  },
  {
    title: 'Ngày hết hạn',
    dataIndex: 'expiredDate',
    key: 'epxired_date',
    sorter: {}
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    key: 'created_at',
    sorter: {}
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    )
  }
];
