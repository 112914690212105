import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_PAGE_NOTIFICATION = 'ON_GET_PAGE_NOTIFICATION';
const ON_EDIT_PAGE_NOTIFICATION = 'ON_EDIT_PAGE_NOTIFICATION';
const ON_NEW_PAGE_NOTIFICATION = 'ON_NEW_PAGE_NOTIFICATION';
const ON_REMOVE_PAGE_NOTIFICATION = 'ON_REMOVE_PAGE_NOTIFICATION';

function* getNotifications({ params, setData }) {
  try {
    const response = yield call(get, '/page_notifications', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* editNotification({ params, callback }) {
  try {
    yield call(axiosPut, `/page_notifications/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa thông báo thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newNotification({ params, callback }) {
  try {
    yield call(post, '/page_notifications', params);
    Notification.success({ message: 'Thêm thông báo thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeNotification({ params, callback }) {
  try {
    yield call(axiosDelete, `/page_notifications/${params.id}`);
    Notification.success({ message: 'Xóa thông báo thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewNotification = (params, callback) => ({
  type: ON_NEW_PAGE_NOTIFICATION,
  params,
  callback
});

export const onEditNotification = (params, callback) => ({
  type: ON_EDIT_PAGE_NOTIFICATION,
  params,
  callback
});

export const onGetNotifications = (params, setData) => ({
  type: ON_GET_PAGE_NOTIFICATION,
  params,
  setData
});

export const onDeleteNotification = (params, callback) => ({
  type: ON_REMOVE_PAGE_NOTIFICATION,
  params,
  callback
});

export default function* notificationsWatcher() {
  yield takeLatest(ON_GET_PAGE_NOTIFICATION, getNotifications);
  yield takeLatest(ON_EDIT_PAGE_NOTIFICATION, editNotification);
  yield takeLatest(ON_NEW_PAGE_NOTIFICATION, newNotification);
  yield takeLatest(ON_REMOVE_PAGE_NOTIFICATION, removeNotification);
}
