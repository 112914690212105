import { createGlobalStyle, keyframes } from 'styled-components';

const dotTyping = keyframes`
  0% {
    box-shadow: 9984px 0 0 0 #001529, 9999px 0 0 0 #001529, 10010px 0 0 0 #001529;
  }
  16.667% {
    box-shadow: 9984px -5px 0 0 #001529, 9999px 0 0 0 #001529, 10010px 0 0 0 #001529;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #001529, 9999px 0 0 0 #001529, 10010px 0 0 0 #001529;
  }
  50% {
    box-shadow: 9984px 0 0 0 #001529, 9999px -5px 0 0 #001529, 10010px 0 0 0 #001529;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #001529, 9999px 0 0 0 #001529, 10010px 0 0 0 #001529;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #001529, 9999px 0 0 0 #001529, 10010px -5px 0 0 #001529;
  }
  100% {
    box-shadow: 9984px 0 0 0 #001529, 9999px 0 0 0 #001529, 10010px 0 0 0 #001529;
  }
`;

export default createGlobalStyle`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #090513;
    -webkit-box-shadow: 0 0 0px 1000px #F8F6FB inset !important;
  }

  input,
  textarea,
  button,
  select,
  a{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  ::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar
  {
    width: 5px;
    height: 5px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb
  {
    background-color: #001529;
    border: 1px solid #555555;
  }

  body {
    color: #090513;
    /*background-image: url('/images/background.png');*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: auto;
    background: rgb(11,11,18);
    background: linear-gradient(45deg, rgba(11,11,18,1) 12%, rgba(126,128,134,1) 48%, rgba(11,11,18,1) 78%);
  }

  .bell{
    font-size: 15px;
    color: #fff;
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
  }

  .button__wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-pulse {
    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: #FFA600;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      z-index: 2;
      outline: none;
      border: none;
      border-radius: 5px;
      font-size: 14px;
    }
  }

  .ant-table-content {
    overflow-x: auto;
  }

  @media (max-width: 1440px) {
    .ant-table-cell {
      min-width: 150px;
      font-size: 12px;
    }

    .ant-descriptions-view {
      overflow: auto;
    }
  }

  .pulsing {
    width: 99%;
    height: 99%;
    border-radius: 50px;
    z-index: 1;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      border: inherit;
      top: 0;
      left: 0;
      z-index: 0;
      position: absolute;
      background: #FFA600;
      border-radius: 5px;
      animation: pulsing 2s linear infinite;
    }
    &:after {
      animation: pulsing1 2s linear infinite;
    }
  }

  @keyframes pulsing {
    0% {
      opacity: 1;
      transform: scaleY(1) scaleX(1);
    }
    20% {
      opacity: 0.5;
    }
    70% {
      opacity: 0.2;
      transform: scaleY(1.3) scaleX(1.1);
    }
    80% {
      opacity: 0;
      transform: scaleY(1.3) scaleX(1.1);
    }
    90% {
      opacity: 0;
      transform: scaleY(1) scaleX(1);
    }
  }
  @keyframes pulsing1 {
    0% {
      opacity: 1;
      transform: scaleY(1) scaleX(1);
    }
    20% {
      opacity: 0.5;
    }
    70% {
      opacity: 0.2;
      transform: scaleY(1.15) scaleX(1.05);
    }
    80% {
      opacity: 0;
      transform: scaleY(1.15) scaleX(1.05);
    }
    90% {
      opacity: 0;
      transform: scaleY(1) scaleX(1);
    }
  }


  .spin-full-height {
    height: 100%;

    .ant-spin-container {
      height: 100%;
    }
  }

  .color-select {
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }

  .custom-submenu {
    min-width: 250px;
  }

  .ant-list-pagination {
    text-align: center;
  }

  .editor {
    display: inherit;
  }

  .ant-table-cell {
    max-width: 700px;
  }

  .editorHide {
    display: none;
  }

  .bold {
    font-weight: bold;
  }

  .fixed-tab-menu {
    background: #232323;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .react-trello-lane {
    margin: 5px 15px;
  }

  .site-statistic-card {
    margin-top: 64px !important;
    background: #f0f2f5;
  }

  .site-layout {
    position: relative;
  }

  .site-layout .site-layout-background {
    overflow: auto;
    background: #fff;
    padding: 0 20px;
  }

  .ant-modal-root {
    background: transparent !important;
  }

  .rdw-editor-wrapper {
    border: 1px solid #F1F1F1;
    color: black;
  }

  .rdw-editor-main {
    padding: 10px;
    max-height: 300px;
    overflow: auto;
  }

  .custom-collapse {
    position: relative;
  }

  .rdw-dropdown-wrapper{
    a {
      color: black;
    }
  }

  .ant-table-wrapper {
    width: 100% !important;
  }

  #root {
    display: flex;
  }

  .custom-spin {
    width: 100%;

    .ant-spin-container {
      width: 100%;
    }
  }

  .custom-tooltip {
    .ant-tooltip-inner {
      color: #171E44;
    }
  }

  .ant-table-expanded-row {
    background: #f0f2f5;
    & > .ant-table-cell {
      padding: 0;
    }

    .ant-table {
      margin: 0 !important;
    }
  }

  .ant-breadcrumb {
    margin-bottom: 20px;

    .ant-breadcrumb-link {
      cursor: pointer;
      font-size: 15px;
    }
  }

  .dot-typing {
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    margin-top: 8px;
    border-radius: 5px;
    background-color: #001529;
    color: #001529;
    box-shadow: 9984px 0 0 0 #001529, 9999px 0 0 0 #001529, 10010px 0 0 0 #001529;
    animation: ${dotTyping} 1.5s infinite linear;
  }


  @-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }

    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
  }

  @-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }

    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
  }

  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }

    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }

/*
 * CKEditor 5 (v21.0.0) content styles.
 * Generated on Wed, 29 Jul 2020 12:14:43 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

  :root {
      --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
      --ck-color-mention-text: hsl(341, 100%, 30%);
      --ck-highlight-marker-blue: hsl(201, 97%, 72%);
      --ck-highlight-marker-green: hsl(120, 93%, 68%);
      --ck-highlight-marker-pink: hsl(345, 96%, 73%);
      --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
      --ck-highlight-pen-green: hsl(112, 100%, 27%);
      --ck-highlight-pen-red: hsl(0, 85%, 49%);
      --ck-image-style-spacing: 1.5em;
      --ck-todo-list-checkmark-size: 16px;
  }

  .ck-content blockquote {
      display: flex !important;
      flex-flow: wrap row !important;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
  }

  .ck-content blockquote figure{
      margin: 25px !important;
  }

  /* ckeditor5-image/theme/image.css */
  .ck-content .image {
      display: table;
      clear: both;
      text-align: center;
      margin: 1em auto;
  }
  /* ckeditor5-image/theme/image.css */
  .ck-content .image img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      min-width: 50px;
  }
  /* ckeditor5-image/theme/imagecaption.css */
  .ck-content .image > figcaption {
      display: table-caption;
      caption-side: bottom;
      word-break: break-word;
      color: hsl(0, 0%, 20%);
      background-color: hsl(0, 0%, 97%);
      padding: .6em;
      font-size: .75em;
      outline-offset: -1px;
  }
  /* ckeditor5-image/theme/imageresize.css */
  .ck-content .image.image_resized {
      max-width: 100%;
      display: block;
      box-sizing: border-box;
  }
  /* ckeditor5-image/theme/imageresize.css */
  .ck-content .image.image_resized img {
      width: 100%;
  }
  /* ckeditor5-image/theme/imageresize.css */
  .ck-content .image.image_resized > figcaption {
      display: block;
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image-style-side {
      float: right;
      margin-left: var(--ck-image-style-spacing);
      max-width: 50%;
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image-style-align-left {
      float: left;
      margin-right: var(--ck-image-style-spacing);
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image-style-align-center {
      margin-left: auto;
      margin-right: auto;
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .ck-content .image-style-align-right {
      float: right;
      margin-left: var(--ck-image-style-spacing);
  }
  /* ckeditor5-block-quote/theme/blockquote.css */
  .ck-content blockquote {
      overflow: hidden;
      padding-right: 1.5em;
      padding-left: 1.5em;
      margin-left: 0;
      margin-right: 0;
      font-style: italic;
      border-left: solid 5px hsl(0, 0%, 80%);
  }
  /* ckeditor5-block-quote/theme/blockquote.css */
  .ck-content[dir="rtl"] blockquote {
      border-left: 0;
      border-right: solid 5px hsl(0, 0%, 80%);
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list {
      list-style: none;
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list li {
      margin-bottom: 5px;
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list li .todo-list {
      margin-top: 5px;
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input {
      -webkit-appearance: none;
      display: inline-block;
      position: relative;
      width: var(--ck-todo-list-checkmark-size);
      height: var(--ck-todo-list-checkmark-size);
      vertical-align: middle;
      border: 0;
      left: -25px;
      margin-right: -15px;
      right: 0;
      margin-left: 0;
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input::before {
      display: block;
      position: absolute;
      box-sizing: border-box;
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid hsl(0, 0%, 20%);
      border-radius: 2px;
      transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input::after {
      display: block;
      position: absolute;
      box-sizing: content-box;
      pointer-events: none;
      content: '';
      left: calc( var(--ck-todo-list-checkmark-size) / 3 );
      top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
      width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
      height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
      border-style: solid;
      border-color: transparent;
      border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
      transform: rotate(45deg);
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input[checked]::before {
      background: hsl(126, 64%, 41%);
      border-color: hsl(126, 64%, 41%);
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label > input[checked]::after {
      border-color: hsl(0, 0%, 100%);
  }
  /* ckeditor5-list/theme/todolist.css */
  .ck-content .todo-list .todo-list__label .todo-list__label__description {
      vertical-align: middle;
  }
  /* ckeditor5-horizontal-line/theme/horizontalline.css */
  .ck-content hr {
      margin: 15px 0;
      height: 4px;
      background: hsl(0, 0%, 87%);
      border: 0;
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-yellow {
      background-color: var(--ck-highlight-marker-yellow);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-green {
      background-color: var(--ck-highlight-marker-green);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-pink {
      background-color: var(--ck-highlight-marker-pink);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .marker-blue {
      background-color: var(--ck-highlight-marker-blue);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .pen-red {
      color: var(--ck-highlight-pen-red);
      background-color: transparent;
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .ck-content .pen-green {
      color: var(--ck-highlight-pen-green);
      background-color: transparent;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .ck-content .text-tiny {
      font-size: .7em;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .ck-content .text-small {
      font-size: .85em;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .ck-content .text-big {
      font-size: 1.4em;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .ck-content .text-huge {
      font-size: 1.8em;
  }
  /* ckeditor5-basic-styles/theme/code.css */
  .ck-content code {
      background-color: hsla(0, 0%, 78%, 0.3);
      padding: .15em;
      border-radius: 2px;
  }
  /* ckeditor5-table/theme/table.css */
  .ck-content .table {
      margin: 1em auto;
      display: table;
  }
  /* ckeditor5-table/theme/table.css */
  .ck-content .table table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      height: 100%;
      border: 1px double hsl(0, 0%, 70%);
  }
  /* ckeditor5-table/theme/table.css */
  .ck-content .table table td,
  .ck-content .table table th {
      min-width: 2em;
      padding: .4em;
      border: 1px solid hsl(0, 0%, 75%);
  }
  /* ckeditor5-table/theme/table.css */
  .ck-content .table table th {
      font-weight: bold;
      background: hsla(0, 0%, 0%, 5%);
  }
  /* ckeditor5-table/theme/table.css */
  .ck-content[dir="rtl"] .table th {
      text-align: right;
  }
  /* ckeditor5-table/theme/table.css */
  .ck-content[dir="ltr"] .table th {
      text-align: left;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
      position: relative;
      clear: both;
      padding: 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
      content: '';
      position: absolute;
      border-bottom: 2px dashed hsl(0, 0%, 77%);
      width: 100%;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break__label {
      position: relative;
      z-index: 1;
      padding: .3em .6em;
      display: block;
      text-transform: uppercase;
      border: 1px solid hsl(0, 0%, 77%);
      border-radius: 2px;
      font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
      font-size: 0.75em;
      font-weight: bold;
      color: hsl(0, 0%, 20%);
      background: hsl(0, 0%, 100%);
      box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  /* ckeditor5-media-embed/theme/mediaembed.css */
  .ck-content .media {
      clear: both;
      margin: 1em 0;
      display: block;
      min-width: 15em;
  }
  /* ckeditor5-code-block/theme/codeblock.css */
  .ck-content pre {
      padding: 1em;
      color: hsl(0, 0%, 20.8%);
      background: hsla(0, 0%, 78%, 0.3);
      border: 1px solid hsl(0, 0%, 77%);
      border-radius: 2px;
      text-align: left;
      direction: ltr;
      tab-size: 4;
      white-space: pre-wrap;
      font-style: normal;
      min-width: 200px;
  }
  /* ckeditor5-code-block/theme/codeblock.css */
  .ck-content pre code {
      background: unset;
      padding: 0;
      border-radius: 0;
  }
  /* ckeditor5-mention/theme/mention.css */
  .ck-content .mention {
      background: var(--ck-color-mention-background);
      color: var(--ck-color-mention-text);
  }

  .ck-balloon-panel_visible {
    z-index: 99999 !important;
  }

  @media print {
      /* ckeditor5-page-break/theme/pagebreak.css */
      .ck-content .page-break {
          padding: 0;
      }
      /* ckeditor5-page-break/theme/pagebreak.css */
      .ck-content .page-break::after {
          display: none;
      }
  }

`;
