import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button, Radio, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate } from '../../../../shared/function';

import { onNewNews } from '../saga';
import { onGetHashtag } from '../../Hashtags/saga';
import SelectSearch from '../../../../components/SelectSearch';
import Ckeditor from '../../../../shared/Ckeditor';
import Upload from '../../../../components/Upload';

const { Option } = Select;
const { Item } = Form;

const Create = React.forwardRef(({ fetchData, type }, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [hashtags, setHashtag] = useState([]);
  const [selectedType, setSelectedType] = useState('');

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmitting(false);
  };

  const createData = useCallback((params) => dispatch(onNewNews(params, callback)), [dispatch]);

  const getHashtag = useCallback(
    (params) =>
      dispatch(
        onGetHashtag(params, (response) => {
          setHashtag(response.items);
        })
      ),
    [dispatch]
  );

  const onFinish = (values) => {
    setSubmitting(true);

    const formData = new FormData();
    formData.append('news[title]', values.title);
    formData.append('news[news_type]', type);
    formData.append('news[short_description]', values.short_description);
    formData.append('news[content]', values.content);
    formData.append('news[status]', values.status || '');
    formData.append('news[position]', values.position);
    if (values.image) formData.append('news[image]', values.image);

    if (type === 'specialty') {
      if (values.status === 'paid') {
        (values.paid_hashtag || []).map((id) => {
          formData.append('news[news_hashtags_attributes][][hashtag_id]', id);
        });
      }

      if (values.status === 'unpaid') {
        (values.publish_hashtag || []).map((id) => {
          formData.append('news[news_hashtags_attributes][][hashtag_id]', id);
        });
      }
    }

    createData(formData);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
      getHashtag({ per_page: 99999 });
    }
  }));

  return (
    <Modal closable={false} visible={isShow} footer={null} title="Thêm bài viết" width={1140}>
      <Form layout="vertical" form={form} name="createPromotion" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          {type === 'promotion' && (
            <Col span={24}>
              <Item name="position" label="Vị trí" rules={[fieldValidate.required]}>
                <Radio.Group style={{ width: '100%' }}>
                  <Row>
                    <Col span={8}>
                      <Radio value="Forum-1">Forum-1</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value="Forum-2">Forum-2</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value="Forum-3">Forum-3</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value="Forum-4">Forum-4</Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value="Forum-5">Forum-5</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Item>
            </Col>
          )}
          {type === 'specialty' && (
            <>
              <Col span={12}>
                <Item name="status" label="Loại" rules={[fieldValidate.required]}>
                  <Select
                    placeholder="Loại"
                    onChange={(value) => {
                      setSelectedType(value);
                      form.setFieldsValue({ paid_hashtag: [] });
                      form.setFieldsValue({ publish_hashtag: [] });
                    }}
                  >
                    <Option value="paid">Trả phí</Option>
                    <Option value="unpaid">Không trả phí</Option>
                  </Select>
                </Item>
              </Col>
              {selectedType === 'paid' && (
                <Col span={12}>
                  <Item name="paid_hashtag" label="Hashtag trả phí">
                    <SelectSearch placeholder="Hashtag trả phí" mode="multiple">
                      {hashtags
                        .filter((a) => a.status === 'paid')
                        .map((hashtag) => (
                          <Option value={hashtag.id} key={hashtag.id}>
                            {hashtag.name}
                          </Option>
                        ))}
                    </SelectSearch>
                  </Item>
                </Col>
              )}
              {selectedType === 'unpaid' && (
                <Col span={12}>
                  <Item name="publish_hashtag" label="Hashtag không trả phí">
                    <SelectSearch placeholder="Hashtag không trả phí" mode="multiple">
                      {hashtags
                        .filter((a) => a.status === 'publish')
                        .map((hashtag) => (
                          <Option value={hashtag.id} key={hashtag.id}>
                            {hashtag.name}
                          </Option>
                        ))}
                    </SelectSearch>
                  </Item>
                </Col>
              )}
            </>
          )}
          <Col span={24}>
            <Item name="title" label="Tiêu đề" rules={[fieldValidate.required]}>
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="short_description" label="Mô tả" rules={[fieldValidate.required]}>
              <Input.TextArea placeholder="Mô tả" rows={3} />
            </Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Hình ảnh" name="image" rules={[fieldValidate.required]}>
              <Upload
                cropImageProps={{ aspect: type === 'promotion' ? 1 : 595 / 842 }}
                cropImage
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Item label="Nội dung" name="content">
              <Ckeditor onChange={(value) => form.setFieldsValue({ content: value })} />
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Create;
