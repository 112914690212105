import React, {
  useState, useImperativeHandle, useCallback,
} from 'react';
import {
  Col, Input, Row, Form, Modal, Button,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fieldValidate,
} from '../../../../../shared/function';
import { onUpdatePassword } from './saga';

const Profile = React.forwardRef(({}, ref) => {
  const dispatch = useDispatch();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submiting, setSubmiting] = useState(false);

  const close = () => {
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status) => {
    if (status === 200) close();

    setSubmiting(false);
  };

  const updatePassword = useCallback((params) => dispatch(
    onUpdatePassword(params, callback),
  ), [dispatch]);

  const onFinish = (values) => {
    setSubmiting(true);
    updatePassword({
      user: {
        old_password: values.oldPassword,
        password: values.newPassword,
      },
    });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowModal(true);
    },
  }));

  return (
    <Modal
      closable={false}
      visible={isShow}
      footer={null}
      title='Thay đổi mật khẩu'
    >
      <Form
        layout='vertical'
        form={form}
        name='updatePassword'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={48}>
          <Col span={24}>
            <Form.Item
              name='oldPassword'
              label='Mật khẩu hiện tại'
              rules={[fieldValidate.required]}
            >
              <Input.Password placeholder='Mật khẩu hiện tại' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='newPassword'
              label='Mật khẩu mới'
              rules={[
                fieldValidate.required,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue('confirmNewPassword') && value !== getFieldValue('confirmNewPassword')) form.resetFields(['confirmNewPassword']);

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password placeholder='Mật khẩu mới' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='confirmNewPassword'
              label='Nhập lại mật khẩu mới'
              rules={[
                fieldValidate.required,
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value && value.length > 0 && getFieldValue('newPassword') !== value) {
                      return Promise.reject('Mật khẩu không trùng khớp.');
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password placeholder='Nhập lại mật khẩu mới' />
            </Form.Item>
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: 20 }}>
          <Button
            disabled={submiting}
            type='primary'
            size='large'
            htmlType='submit'
            style={{ width: 200, marginRight: 20 }}
          >
            Cập nhập mật khẩu
          </Button>
          <Button
            size='large'
            style={{ width: 150 }}
            onClick={close}
          >
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default Profile;
