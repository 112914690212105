import React, { useState, useImperativeHandle, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PreviewFile from './PreviewFile';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const UploadComponent = React.forwardRef(
  ({ onChange, defaultValue, disabled, canDelete = true, multiple }, ref) => {
    const [fileList, setFileList] = useState([]);
    const previewRef = useRef();

    useImperativeHandle(ref, () => ({
      reset: () => {
        setFileList([]);
      }
    }));

    const handleChange = ({ fileList: newFileList }) => {
      if (onChange) {
        onChange(
          newFileList.map((file) => (file.originFileObj ? new File([file.originFileObj], file.name) : file))
        );
      }
      setFileList(newFileList);
    };

    const handlePreview = (file) => {
      if (file.url) {
        previewRef.current.open(file);
      } else {
        getBase64(file.originFileObj, (url) => {
          previewRef.current.open({ id: file.uid, url });
        });
      }
    };

    const dummyRequest = ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    };

    const onDownload = async (file) => {
      if (file.url) {
        saveAs(file.url, file.name);
      }
    };

    useEffect(() => {
      if (defaultValue && defaultValue.length > 0) {
        const defaultImage = [];
        defaultValue.map((item, index) => {
          defaultImage.push({
            uid: index,
            name: item.name || `image-${index}.png`,
            status: 'done',
            url: item.url,
            id: item.id
          });
        });

        setFileList(defaultImage);
      }
    }, [defaultValue]);

    return (
      <div>
        <Upload
          multiple={multiple}
          onPreview={handlePreview}
          customRequest={dummyRequest}
          listType="picture"
          fileList={fileList}
          onChange={handleChange}
          onDownload={onDownload}
          showUploadList={{ showPreviewIcon: true, showRemoveIcon: canDelete, showDownloadIcon: true }}
        >
          {!disabled && <Button icon={<UploadOutlined />}>Tải lên</Button>}
        </Upload>

        <PreviewFile ref={previewRef} />
      </div>
    );
  }
);

export default UploadComponent;
