import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
  ClockCircleOutlined, CloseCircleOutlined, EyeOutlined,
} from '@ant-design/icons';
import {
  List, Typography, Tooltip, Space, Divider, Tag, Avatar, Checkbox,
} from 'antd';
import {
  onGetTasks, onEditTask,
} from '../../saga';
import {
  StyledList, Wrapper, Header, ProgressWrapper, Description, TimeWrapper, Time, TitleWrapper, Label, StatusWrapper, TextWrapper,
} from '../../styles';
import Preview from '../../Modal/Preview';

const { Title } = Typography;

const DeadlineTask = () => {
  const location = useLocation();
  const previewRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false, items: [], currentPage: 0, totalPages: 0,
  });

  const {
    items, loading,
  } = state;

  const callback = (status, response) => {
    if (status === 200) {
      setState({
        loading: false,
        items: response.items,
        totalPages: response.meta.totalPages,
        currentPage: response.meta.currentPage,
      });
    } else {
      setState((state) => ({
        ...state,
        loading: false,
      }));
    }
  };

  const showLoading = () => setState((state) => ({
    ...state,
    loading: true,
  }));

  const getData = useCallback((params) => dispatch(
    onGetTasks(params, callback),
  ), [dispatch]);

  const fetchData = () => {
    showLoading();
    getData({ task_type: '2', type: 'deadline' });
  };

  const actionGroup = (item) => [
    <Tooltip placement='bottom' title='Xem chi tiết'>
      <EyeOutlined style={{ cursor: 'pointer' }} onClick={() => previewRef.current.open(item)} />
    </Tooltip>,
  ];

  useEffect(() => {
    getData({ task_type: '2', type: 'deadline' });
  }, [location.search]);

  return (
    <Wrapper>
      <Preview ref={previewRef} fetchData={fetchData} />
      <Header>
        <Title level={4} style={{ color: '#fff', marginBottom: 0 }}>{`Trễ deadline (${items.length})`}</Title>
      </Header>
      <StyledList
        itemLayout='horizontal'
        dataSource={items}
        loading={loading}
        renderItem={(item) => {
          const doneQuantity = item.checklist ? item.checklist.filter((task) => task.checked === 'true').length : 0;
          const subProject = item.parent;
          const project = subProject.parent;

          return (
            <List.Item
              key={item.key}
              actions={actionGroup(item)}
            >
              <List.Item.Meta
                onClick={() => previewRef.current.open(item)}
                title={(
                  <TitleWrapper>
                    <StatusWrapper>
                      {project.color && <Label color={project.color} />}
                      {project.title}
                      {' > '}
                      {subProject.title}
                    </StatusWrapper>
                    <StatusWrapper style={{ margin: '5px 0' }}>
                      <Tag icon={<CloseCircleOutlined />} color='red'>
                        Trễ deadline
                      </Tag>
                      {item.checklist && item.checklist.length > 0 && (
                        <ProgressWrapper>
                          <Checkbox checked={true}>
                            {`${doneQuantity} / ${item.checklist.length}`}
                          </Checkbox>
                        </ProgressWrapper>
                      )}
                    </StatusWrapper>
                    <TextWrapper style={{ marginBottom: 10 }}>
                      {item.title}
                    </TextWrapper>
                  </TitleWrapper>
              )}
                description={(
                  <Description>
                    <Space split={<Divider type='vertical' />}>
                      <TimeWrapper>
                        <ClockCircleOutlined />
                        <Time>{item.datetime}</Time>
                      </TimeWrapper>
                      <Avatar.Group
                        style={{ marginLeft: 10 }}
                        maxCount={1}
                        maxStyle={{
                          color: '#f56a00', backgroundColor: '#fde3cf', width: 25, height: 25, display: 'flex', alignItems: 'center',
                        }}
                      >
                        {item.assigneeInfo.map((assignee) => (
                          <Tooltip key={assignee.name} title={assignee.name} placement='top'>
                            <Avatar src={assignee.avatarUrl} size={25} />
                          </Tooltip>
                        ))}
                      </Avatar.Group>
                    </Space>
                  </Description>
              )}
              />
            </List.Item>
          );
        }}
      />
    </Wrapper>
  );
};

export default DeadlineTask;
