import React from 'react';
import { Table, Row, Col, Typography } from 'antd';
import { columns } from './function';

const { Title } = Typography;

const Childrens = ({ data }) => {
  return (
    <Row style={{ padding: '40px 30px', background: '#f0f2f5' }}>
      <Col span={24} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
        <Title level={4}>Phiếu thu</Title>
      </Col>
      <Table columns={columns()} dataSource={data} pagination={false} />
    </Row>
  );
};

export default Childrens;
