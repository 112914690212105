import React from 'react';

import styled from 'styled-components';
import {
  MenuOutlined, DeleteOutlined, EditOutlined,
} from '@ant-design/icons';
import {
  Menu, Dropdown,
} from 'antd';

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete, dentists) => [
  {
    title: 'Tên ',
    dataIndex: 'name',
    key: 'name',
    sorter: {},
  },
  {
    title: 'Mô tả',
    dataIndex: 'shortDescription',
  },
  {
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item onClick={() => onEdit(item, 'children', dentists)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onDelete(item)}>
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
                {' '}
                Xóa
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    ),
  },
];

export default columns;
