import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call, takeEvery } from 'redux-saga/effects';
import Notification from '../../../../../components/Notification';
import { getErrorMessage } from '../../../../../shared/function';

const ON_GET_COURSE_CLASSES = 'ON_GET_COURSE_CLASSES';
const ON_GET_COURSE_CLASS = 'ON_GET_COURSE_CLASS';
const ON_NEW_COURSE_CLASS = 'ON_NEW_COURSE_CLASS';
const ON_EDIT_COURSE_CLASS = 'ON_EDIT_COURSE_CLASS';

const ON_GET_COURSE_CLASS_LESSONS = 'ON_GET_COURSE_CLASS_LESSONS';
const ON_NEW_COURSE_CLASS_LESSON = 'ON_NEW_COURSE_CLASS_LESSON';
const ON_EDIT_COURSE_CLASS_LESSON = 'ON_EDIT_COURSE_CLASS_LESSON';
const ON_REMOVE_COURSE_CLASS_LESSON = 'ON_REMOVE_COURSE_CLASS_LESSON';

const ON_REMOVE_COURSE_CLASS_TEACHER = 'ON_REMOVE_COURSE_CLASS_TEACHER';

const ON_GET_COURSE_CLASS_STUDENTS = 'ON_GET_COURSE_CLASS_STUDENTS';
const ON_REMOVE_COURSE_CLASS_STUDENT = 'ON_REMOVE_COURSE_CLASS_STUDENT';
const ON_UPDATE_COURSE_CLASS_STUDENT = 'ON_UPDATE_COURSE_CLASS_STUDENT';

function* getCourseClass({ params, setData }) {
  try {
    const response = yield call(get, `/course_classes/${params.id}`);
    setData(response);
  } catch (error) {
    console.log(error);
  }
}

function* getCourseClasses({ params, setData }) {
  try {
    const response = yield call(get, '/course_classes', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* newCourseClass({ params, callback }) {
  try {
    const response = yield call(post, '/course_classes', params);

    Notification.success({ message: 'Thêm lớp học thành công.' });
    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* editCourseClass({ params, callback }) {
  try {
    const response = yield call(axiosPut, `/course_classes/${params.id}`, params);

    Notification.success({ message: 'Chỉnh sửa thông tin lớp học thành công.' });
    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* getCourseClassLessons({ params, setData }) {
  try {
    const response = yield call(get, `/course_classes/${params.courseClassId}/lessons`);
    setData({
      loading: false,
      items: response
    });
  } catch (error) {
    console.log(error);
  }
}

function* newCourseClassLesson({ params, callback }) {
  try {
    yield call(post, `/course_classes/${params.courseClassId}/create_lesson`, params);

    Notification.success({ message: 'Thêm buổi học thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* editCourseClassLesson({ params, callback }) {
  try {
    yield call(axiosPut, `/course_classes/${params.courseClassId}/update_lesson`, params);

    Notification.success({ message: 'Chỉnh sửa thông tin buổi học thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* deleteCourseClassLesson({ params, callback }) {
  try {
    yield call(axiosDelete, `/course_classes/${params.courseClassId}/remove_lesson`, { params });
    Notification.success({ message: 'Xóa buổi học thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* removeCourseClassTeacher({ params, callback }) {
  try {
    const response = yield call(axiosDelete, `/course_classes/${params.courseClassId}/remove_teacher`, {
      params
    });
    Notification.success({ message: 'Xóa giảng viên thành công.' });
    callback(response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* getCourseClassStudents({ params, setData }) {
  try {
    const response = yield call(get, `/course_classes/${params.courseClassId}/students`, { params });
    setData({
      loading: false,
      items: response.data.items,
      totalPages: response.data.meta.totalPages,
      currentPage: response.data.meta.currentPage,
      currentStudents: response.currentStudents
    });
  } catch (error) {
    console.log(error);
  }
}

function* deleteCourseClassStudent({ params, callback }) {
  try {
    yield call(axiosDelete, `/course_classes/${params.courseClassId}/remove_student`, { params });
    Notification.success({ message: 'Xóa học viên khỏi lớp học thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

function* updateCourseClassStudent({ params, callback }) {
  try {
    const response = yield call(axiosPut, `/course_classes/${params.courseClassId}/update_student`, params);

    Notification.success({ message: 'Chỉnh sửa thông tin học viên thành công.' });
    callback(200, response);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

export const onEditCourseClassField = (params, callback) => ({
  type: ON_EDIT_COURSE_CLASS,
  params,
  callback
});

export const onNewCourseClass = (params, callback) => ({
  type: ON_NEW_COURSE_CLASS,
  params,
  callback
});

export const onGetCourseClasses = (params, setData) => ({
  type: ON_GET_COURSE_CLASSES,
  params,
  setData
});

export const onGetCourseClass = (params, setData) => ({
  type: ON_GET_COURSE_CLASS,
  params,
  setData
});

export const onEditCourseClassLesson = (params, callback) => ({
  type: ON_EDIT_COURSE_CLASS_LESSON,
  params,
  callback
});

export const onNewCourseClassLesson = (params, callback) => ({
  type: ON_NEW_COURSE_CLASS_LESSON,
  params,
  callback
});

export const onGetCourseClassLessons = (params, setData) => ({
  type: ON_GET_COURSE_CLASS_LESSONS,
  params,
  setData
});

export const onRemoveCourseClassLesson = (params, callback) => ({
  type: ON_REMOVE_COURSE_CLASS_LESSON,
  params,
  callback
});

export const onRemoveCourseClassTeacher = (params, callback) => ({
  type: ON_REMOVE_COURSE_CLASS_TEACHER,
  params,
  callback
});

export const onGetCourseClassStudents = (params, setData) => ({
  type: ON_GET_COURSE_CLASS_STUDENTS,
  params,
  setData
});

export const onRemoveCourseClassStudent = (params, callback) => ({
  type: ON_REMOVE_COURSE_CLASS_STUDENT,
  params,
  callback
});

export const onUpdateCourseClassStudent = (params, callback) => ({
  type: ON_UPDATE_COURSE_CLASS_STUDENT,
  params,
  callback
});

export default function* courseClassWatcher() {
  yield takeLatest(ON_GET_COURSE_CLASSES, getCourseClasses);
  yield takeEvery(ON_GET_COURSE_CLASS, getCourseClass);
  yield takeLatest(ON_NEW_COURSE_CLASS, newCourseClass);
  yield takeLatest(ON_EDIT_COURSE_CLASS, editCourseClass);

  yield takeLatest(ON_GET_COURSE_CLASS_LESSONS, getCourseClassLessons);
  yield takeLatest(ON_NEW_COURSE_CLASS_LESSON, newCourseClassLesson);
  yield takeLatest(ON_EDIT_COURSE_CLASS_LESSON, editCourseClassLesson);
  yield takeLatest(ON_REMOVE_COURSE_CLASS_LESSON, deleteCourseClassLesson);

  yield takeLatest(ON_REMOVE_COURSE_CLASS_TEACHER, removeCourseClassTeacher);

  yield takeLatest(ON_GET_COURSE_CLASS_STUDENTS, getCourseClassStudents);
  yield takeLatest(ON_REMOVE_COURSE_CLASS_STUDENT, deleteCourseClassStudent);
  yield takeLatest(ON_UPDATE_COURSE_CLASS_STUDENT, updateCourseClassStudent);
}
