import React, { useState, useEffect, useCallback } from 'react';
import { Menu, Spin, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';
import { onGetTopics } from '../saga';
import { StyledSider } from './styles';

const MainTopic = ({ currentFilter, changeLocation, createPostRef }) => {
  const dispatch = useDispatch();
  const [selectedKey, setSelectedKey] = useState(currentFilter.current.topic_id || '');
  const [state, setState] = useState({ loading: true, items: {} });
  const { items, loading } = state;

  const callback = (response) => {
    const filterdTopic = groupBy(response.items, (a) => (a.private ? 'private' : 'public'));
    setState({
      ...response,
      items: filterdTopic
    });
  };

  const getData = useCallback((params) => dispatch(onGetTopics(params, callback)), [dispatch]);

  const changeTopic = (topicId) => {
    currentFilter.current.order = 'name asc';
    currentFilter.current.topic_id = topicId;
    currentFilter.current.sub_topic_id = '';
    currentFilter.current.keyword = '';
    setSelectedKey(topicId.toString());
    changeLocation();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <StyledSider width={300} className="site-layout-background" theme="light">
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <Button
          style={{ width: '100%' }}
          onClick={() => createPostRef.current.open()}
          type="primary"
          size="large"
          icon={<PlusOutlined />}
        >
          Đăng bài
        </Button>
      </div>
      <Spin spinning={loading} tip="Đang tải dữ liệu...">
        <Menu mode="inline" selectedKeys={[selectedKey.toString()]} style={{ height: '100%' }}>
          <Menu.Item
            key={''}
            onClick={() => {
              currentFilter.current = {};
              changeLocation();
            }}
          >
            Tất cả bài viết
          </Menu.Item>
          <Menu.ItemGroup key="public" title="Chủ đề chung">
            {(items.public || []).map((topic, index) => (
              <Menu.Item key={topic.id.toString()} onClick={() => changeTopic(topic.id)}>{`${index + 1}. ${
                topic.name
              }`}</Menu.Item>
            ))}
          </Menu.ItemGroup>
          <Menu.ItemGroup key="public" title="Chủ đề chuyên ngành">
            {(items.private || []).map((topic, index) => (
              <Menu.Item key={topic.id.toString()} onClick={() => changeTopic(topic.id)}>{`${index + 1}. ${
                topic.name
              }`}</Menu.Item>
            ))}
          </Menu.ItemGroup>
        </Menu>
      </Spin>
    </StyledSider>
  );
};

export default MainTopic;
