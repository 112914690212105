import { get, put as axiosPut, post, delete as axiosDelete } from 'axios';
import { takeLatest, call, takeEvery } from 'redux-saga/effects';
import Notification from '../../../components/Notification';
import { getErrorMessage } from '../../../shared/function';

const ON_GET_COMPANY_CATEGORIES = 'ON_GET_COMPANY_CATEGORIES';
const ON_EDIT_COMPANY_CATEGORY = 'ON_EDIT_COMPANY_CATEGORY';
const ON_NEW_COMPANY_CATEGORY = 'ON_NEW_COMPANY_CATEGORY';
const ON_REMOVE_COMPANY_CATEGORY = 'ON_REMOVE_COMPANY_CATEGORY';

function* getData({ params, setData }) {
  try {
    const response = yield call(get, '/company_categories', { params });
    setData({
      loading: false,
      items: response.items,
      totalPages: response.meta.totalPages,
      currentPage: response.meta.currentPage
    });
  } catch (error) {
    console.log(error);
  }
}

function* editData({ params, callback }) {
  try {
    yield call(axiosPut, `/company_categories/${params.id}`, params);
    Notification.success({ message: 'Chỉnh sửa mã phiếu thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
  }
}

function* newData({ params, callback }) {
  try {
    yield call(post, '/company_categories', params);
    Notification.success({ message: 'Thêm mã phiếu thành công.' });
    callback(200);
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
    callback(400);
    console.log(error);
  }
}

function* removeData({ params, callback }) {
  try {
    yield call(axiosDelete, `/company_categories/${params.id}`);
    Notification.success({ message: 'Xóa mã phiếu thành công.' });
    callback();
  } catch (error) {
    Notification.error({ message: getErrorMessage(error) });
  }
}

export const onNewCompanyCategory = (params, callback) => ({
  type: ON_NEW_COMPANY_CATEGORY,
  params,
  callback
});

export const onEditCompanyCategory = (params, callback) => ({
  type: ON_EDIT_COMPANY_CATEGORY,
  params,
  callback
});

export const onGetCompanyCategories = (params, setData) => ({
  type: ON_GET_COMPANY_CATEGORIES,
  params,
  setData
});

export const onDeleteCompanyCategory = (params, callback) => ({
  type: ON_REMOVE_COMPANY_CATEGORY,
  params,
  callback
});

export default function* watcher() {
  yield takeEvery(ON_GET_COMPANY_CATEGORIES, getData);
  yield takeLatest(ON_EDIT_COMPANY_CATEGORY, editData);
  yield takeLatest(ON_NEW_COMPANY_CATEGORY, newData);
  yield takeLatest(ON_REMOVE_COMPANY_CATEGORY, removeData);
}
