import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Row, Col, Form, Select, Button, DatePicker } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import moment from 'moment';
import SelectSearch from '../../../components/SelectSearch';

const { RangePicker } = DatePicker;
const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

const Filter = ({ currentFilter, changeLocation, employees }) => {
  const location = useLocation();
  const [form] = Form.useForm();

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.resetFields();
    changeLocation();
  };

  useEffect(() => {
    const { user_id, time_gteq, time_lteq } = queryString.parse(location.search);

    form.setFieldsValue({
      user_id: user_id || '',
      time: time_gteq && time_lteq ? [moment(time_gteq, dateFormat), moment(time_lteq, dateFormat)] : []
    });
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={4} xxl={4}>
          <Form.Item name="user_id" label="Nhân viên:">
            <SelectSearch onChange={(e) => onFilter('user_id', e)}>
              [<Option value="">Tất cả </Option>,
              {employees.map((item) => (
                <Option value={item.id}>{item.name}</Option>
              ))}
              ]
            </SelectSearch>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={6}>
          <Form.Item name="time" label="Ngày lập phiếu:">
            <RangePicker
              format={dateFormat}
              style={{ width: '100%' }}
              placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
              onChange={(dates) => {
                currentFilter.current.time_gteq = dates ? dates[0].format(dateFormat) : null;
                currentFilter.current.time_lteq = dates ? dates[1].format(dateFormat) : null;
                changeLocation();
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={6}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
              Xóa bộ lọc
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
