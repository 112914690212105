import React, {
  useState, useImperativeHandle,
} from 'react';
import {
  Col, Row, Button, Modal, Typography, Avatar,
} from 'antd';

const { Text } = Typography;

const TeacherInfo = React.forwardRef(({}, ref) => {
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;

  const onClose = () => setState({ status: false, item: {} });

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ status: true, item });
    },
  }));

  return (
    <Modal
      onCancel={onClose}
      closable={true}
      visible={status}
      footer={null}
      title='Thông tin giảng viên'
      width={992}
    >
      <Col span={24}>
        <Row gutter={48} align='middle'>
          <Col span={5} style={{ marginBottom: 15 }}>
            <Avatar size={150} shape='square' src={item.avatarUrl} />
          </Col>
          <Col span={8}>
            <Row>
              <Col span={24} style={{ marginBottom: 5 }}>
                <Text strong>Tên: </Text>
                <Text>{`${item.firstName} ${item.lastName}`}</Text>
              </Col>
              <Col span={24} style={{ marginBottom: 5 }}>
                <Text strong>E-mail: </Text>
                <Text>{item.email}</Text>
              </Col>
              <Col span={24} style={{ marginBottom: 5 }}>
                <Text strong>Số điện thoại: </Text>
                <Text>{item.phone}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            { item.teacherInfo
                && (
                <Row>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    <Text strong>Chuyên môn: </Text>
                    <Text>{item.teacherInfo.specialty}</Text>
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    <Text strong>Tốt nghiệp trường: </Text>
                    <Text>{item.teacherInfo.school}</Text>
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    <Text strong>Năm tốt nghiệp: </Text>
                    <Text>{item.teacherInfo.graduation_year}</Text>
                  </Col>
                </Row>
                )}
          </Col>
        </Row>
      </Col>
      { item.teacherInfo
        && (
        <>
          <Col span={24} style={{ marginBottom: 15 }}>
            <Text strong>Giới thiệu:</Text>
            <div dangerouslySetInnerHTML={{ __html: item.teacherInfo.introduce }} />
          </Col>
          <Col span={24} style={{ marginBottom: 15 }}>
            <Text strong>Chuyên môn và bằng cấp:</Text>
            <div dangerouslySetInnerHTML={{ __html: item.teacherInfo.specialty_introduce }} />
          </Col>
        </>
        )}
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          size='large'
          style={{ width: 150 }}
          onClick={onClose}
        >
          Đóng
        </Button>
      </Row>
    </Modal>
  );
});

export default TeacherInfo;
