import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined, AppstoreOutlined, BarChartOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import AssigneeTask from './AssigneeTask';
import CreatedTask from './CreatedTask';
import { onGetEmployees } from '../../Employees/saga';

const { TabPane } = Tabs;

const MyTasks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({ loading: false, items: [] });
  const { items } = state;

  const getData = useCallback((params) => dispatch(
    onGetEmployees(params, setState),
  ), [dispatch]);

  const changeTab = (key) => {
    if (key === 'all') history.push('/admin/user-tasks');
    if (key === 'overview') history.push('/admin/user-tasks/overview');
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Tabs defaultActiveKey='myTask' onTabClick={changeTab}>
      <TabPane
        tab={(
          <span>
            <AppstoreOutlined />
            Tất cả
          </span>
        )}
        key='all'
      />
      <TabPane
        tab={(
          <span>
            <UserOutlined />
            Công việc của tôi
          </span>
        )}
        key='myTask'
      >
        <Row gutter={24}>
          <Col xs={24} md={24} lg={12}>
            <CreatedTask employees={items} />
          </Col>
          <Col xs={24} md={24} lg={12}>
            <AssigneeTask />
          </Col>
        </Row>
      </TabPane>
      <TabPane
        tab={(
          <span>
            <BarChartOutlined />
            Tổng quan
          </span>
        )}
        key='overview'
      />
    </Tabs>

  );
};

export default MyTasks;
