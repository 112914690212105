import React from 'react';
import { EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Image, Tag } from 'antd';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const columns = (onEdit, onDelete, type) => {
  let data = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: {}
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      key: 'image',
      width: 100,
      render: (image) => <Image src={image} height={100} style={{ objectFit: 'cover', width: '100%' }} />
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      key: 'title',
      sorter: {}
    }
  ];
  if (type === 'promotion') {
    data = data.concat([
      {
        title: 'Vị trí',
        dataIndex: 'position',
        key: 'position'
      },
      {
        title: 'Mô tả',
        dataIndex: 'shortDescription',
        key: 'shortDescription',
        sorter: {}
      }
    ]);
  } else {
    data = data.concat([
      {
        title: 'Loại',
        key: 'status',
        sorter: {},
        render: (item) => {
          switch (item.status) {
            case 'paid':
              return <Tag color="green">Trả phí</Tag>;
            case 'unpaid':
              return <Tag color="red">Không trả phí</Tag>;
            default:
              return <Tag color="green" />;
          }
        }
      },
      {
        title: 'Hashtag',
        render: (item) =>
          item.hashtags.map((item) => (
            <Tag color="gray" style={{ margin: '5px 5px 0 0' }}>
              {item.name}
            </Tag>
          ))
      },
      {
        title: 'Lượt xem',
        key: 'views',
        dataIndex: 'views'
      },
      {
        title: 'Người tạo',
        key: 'owner',
        dataIndex: 'owner'
      }
    ]);
  }

  data = data.concat([
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'created_at',
      sorter: {}
    },
    {
      title: '',
      width: 100,
      render: (item) => (
        <ActionWrapper>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => onEdit(item)}>
                  <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
                </Menu.Item>
                <Menu.Item onClick={() => onDelete(item)}>
                  <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Xóa
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
          </Dropdown>
        </ActionWrapper>
      )
    }
  ]);
  return data;
};
