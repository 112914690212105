import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import { Col, Input, Row, Form, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { fieldValidate } from '../../../../shared/function';
import Upload from '../../../../components/Upload';
import UploadList from '../../../../components/UploadList';
import { onEditCourseContent } from '../saga';
import { StyledModal } from './styles';
import Ckeditor from '../../../../shared/Ckeditor';

const { Item } = Form;

const Edit = React.forwardRef(({ setData, item }, ref) => {
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const uploadCalendarRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    if (uploadRef.current) uploadRef.current.reset();
    if (uploadCalendarRef.current) uploadCalendarRef.current.reset();
    form.resetFields();
    setShowModal(false);
  };

  const callback = (status, response) => {
    if (status === 200) {
      setData(response);
      close();
    }

    setSubmitting(false);
  };

  const editData = useCallback((params) => dispatch(onEditCourseContent(params, callback)), [dispatch]);

  const onFinish = (values) => {
    setSubmitting(true);
    editData({ ...values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      form.setFieldsValue({
        moduleName: item.moduleName,
        title: item.title,
        targetTitle: item.targetTitle || '',
        introduceTitle: item.introduceTitle || '',
        theoryTitle: item.theoryTitle || '',
        practiceTitle: item.practiceTitle || ''
      });
      setShowModal(true);
    }
  }));

  return (
    <StyledModal closable={false} visible={isShow} footer={null} title="Chỉnh sửa nội dung khóa học">
      <Form layout="vertical" form={form} name="editCourse" scrollToFirstError onFinish={onFinish}>
        <Row gutter={32}>
          <Col span={8}>
            <Item name="moduleName" rules={[fieldValidate.required]} label="Tên module">
              <Input placeholder="Tên module" />
            </Item>
          </Col>
          <Col span={8}>
            <Item name="title" rules={[fieldValidate.required]} label="Tiêu đề">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh" name="mainImage">
              <Upload
                defaultValue={item.mainImage}
                cropImage
                onFileChange={(file) => form.setFieldsValue({ mainImage: file })}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Item name="introduceTitle" label="Mục 1">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="introduce">
              <Ckeditor
                defaultValue={item.introduce || ''}
                onChange={(value) => form.setFieldsValue({ introduce: value })}
              />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="targetTitle" label="Mục 2">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="target">
              <Ckeditor
                defaultValue={item.target || ''}
                onChange={(value) => form.setFieldsValue({ target: value })}
              />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="theoryTitle" label="Mục 3">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="theory">
              <Ckeditor
                defaultValue={item.theory || ''}
                onChange={(value) => form.setFieldsValue({ theory: value })}
              />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="practiceTitle" label="Mục 4">
              <Input placeholder="Tiêu đề" />
            </Item>
          </Col>
          <Col span={24}>
            <Item name="practice">
              <Ckeditor
                defaultValue={item.practice}
                onChange={(value) => form.setFieldsValue({ practice: value })}
              />
            </Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh lịch học" name="calendar_images" />
            <UploadList
              noCrop={false}
              limitFile={10}
              aspect={1}
              ref={uploadCalendarRef}
              circle
              defaultValue={item?.calendarImages || []}
              onChange={(images) => form.setFieldsValue({ calendar_images: images })}
            />
          </Col>
          <Col span={24}>
            <Form.Item label="Hình ảnh" name="images" />
            <UploadList
              noCrop={false}
              limitFile={10}
              aspect={1.92}
              ref={uploadRef}
              circle
              defaultValue={item?.images || []}
              onChange={(images) => form.setFieldsValue({ images })}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </StyledModal>
  );
});

export default Edit;
