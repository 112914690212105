import React, { useState, useImperativeHandle, useEffect } from 'react';
import axios, { put, get, delete as axiosDelete } from 'axios';
import { Avatar, Drawer, Dropdown, Menu, Popconfirm, Modal, Tabs, Badge } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import slug from 'slug';
import { remove } from 'lodash';
import { StyledList, StyeledTab } from './styles';

const { Item, Badge: NotificationBadge, DelteIcon } = StyledList;
const { TabPane } = Tabs;

const NotificationItem = ({ item, showUnRead, onDeleteNotification, onNotificationClick }) => {
  return (
    <Item key={item.id}>
      <Item.Meta
        style={{ alignItems: 'center' }}
        avatar={<Avatar src={item.avatarUrl} />}
        title={
          <span>
            <Popconfirm
              placement="left"
              title="Bạn có muốn xóa thông báo này?"
              onConfirm={onDeleteNotification({ id: item.id })}
              okText="Yes"
              cancelText="No"
            >
              <DelteIcon />
            </Popconfirm>
            {showUnRead && <NotificationBadge status="processing" />}
            <div
              onClick={onNotificationClick(item)}
              dangerouslySetInnerHTML={{ __html: item.message }}
              style={{ fontWeight: 'normal' }}
            />
          </span>
        }
        description={item.createdAt}
      />
    </Item>
  );
};

const Notification = React.forwardRef(({ setNotificationCount, user }, ref) => {
  const [isShow, setShowNoti] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const getNotifications = () => {
    get('/notifications').then((response) => {
      setNotifications(response);
      setNotificationCount(
        response.filter((noti) =>
          noti.notificationKey === 'PublishForumPost' ? !noti.userReads.includes(user.id) : !noti.read
        ).length
      );
    });
  };

  const filteredNotifications = (type) => {
    let keys = [];
    if (type === 'forum')
      keys = [
        'ForumLike',
        'ForumComment',
        'PrivateForumPost',
        'PublishForumPost',
        'ForumResponseComment',
        'ForumCommentMention'
      ];
    if (type === 'class')
      keys = [
        'ClassPostComment',
        'NewClassForumPost',
        'ConfirmCourseRegistration',
        'ClassForumResponseComment',
        'ClassForumCommentMention'
      ];
    if (type === 'ycompany') keys = ['DoneTask', 'NewMemberRegistration', 'NewCourseRegistration'];

    return notifications.filter((a) => keys.includes(a.notificationKey));
  };

  const ycompanyNotiUnread = filteredNotifications('ycompany').filter((noti) => !noti.read).length;
  const classNotiUnread = filteredNotifications('class').filter((noti) => !noti.read).length;
  const forumNotiUnread = filteredNotifications('forum').filter((noti) =>
    noti.notificationKey === 'PublishForumPost' ? !noti.userReads.includes(user.id) : !noti.read
  ).length;

  useImperativeHandle(ref, () => ({
    open: () => {
      setShowNoti(true);
    }
  }));

  const onDeleteNotification = ({ id }) => () => {
    axiosDelete(`/notifications/${id}`).then(() => {
      const newItems = notifications.filter((a) => a.id !== id);
      setNotifications(newItems);
    });
  };

  const markAllRead = () => {
    axios.post('/notifications/read_all').then(getNotifications);
  };

  const deleteAll = () => {
    Modal.confirm({
      title: 'Bạn có muốn xóa tất cả thông báo không?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        axios.delete('/notifications/delete_all').then(getNotifications);
      }
    });
  };

  const redirectToDetail = ({ notificationKey, sourceId, sourceTitle, courseId }) => {
    if (notificationKey === 'DoneTask') {
      window.location = '/admin/user-tasks';
    }

    if (notificationKey === 'NewCourseRegistration') {
      if (user.roles.includes('admin')) return (window.location = '/admin/registration-infos');
      if (user.roles.includes('course_manager'))
        return (window.location = '/admin/my-management-registration-info');
      if (user.hasSupportCourse) return (window.location = '/admin/my-support-registration-info');
    }

    if (notificationKey === 'NewMemberRegistration') {
      window.location = '/admin/students';
    }

    if (notificationKey === 'NewClassForumPost') {
      window.location = `${process.env.WEBSITE_URL}/class-room?course_id=${courseId}&class_id=${sourceId}`;
    }

    if (
      ['ClassPostComment', 'ClassForumResponseComment', 'ClassForumCommentMention'].includes(notificationKey)
    ) {
      window.location = `${process.env.WEBSITE_URL}/class-forum/${sourceId}/${slug(sourceTitle)}`;
    }

    if (
      [
        'PublishForumPost',
        'PrivateForumPost',
        'ForumLike',
        'ForumComment',
        'ForumResponseComment',
        'ForumCommentMention'
      ].includes(notificationKey)
    ) {
      window.location = `${process.env.WEBSITE_URL}/forum/${sourceId}/${slug(sourceTitle)}`;
    }
  };

  const onNotificationClick = (item) => (e) => {
    put(`/notifications/${item.id}/read`).then(getNotifications);
    redirectToDetail(item);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Drawer
      bodyStyle={{ padding: '0' }}
      placement="right"
      onClose={() => setShowNoti(false)}
      visible={isShow}
      className="notification-drawer"
      closable={false}
      width={450}
    >
      <div className="ant-drawer-header">
        <div className="ant-drawer-title">Thông báo</div>
        <Dropdown
          arrow
          align={{
            offset: [8, -15]
          }}
          overlay={
            <Menu>
              <Menu.Item onClick={markAllRead}>Đánh dấu tất cả đã đọc</Menu.Item>
              <Menu.Item onClick={deleteAll}>Xóa tất cả</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <button type="button" className="ant-drawer-close" style={{ '--scroll-bar': 0, top: -4 }}>
            <EllipsisOutlined style={{ fontSize: 25 }} />
          </button>
        </Dropdown>
      </div>
      <StyeledTab>
        <TabPane
          tab={
            <Badge count={forumNotiUnread} offset={[20, 6]} size="small">
              Diễn đàn
            </Badge>
          }
          key="1"
        >
          <StyledList
            itemLayout="horizontal"
            split={false}
            dataSource={filteredNotifications('forum')}
            renderItem={(item) => {
              const showUnRead =
                item.notificationKey === 'PublishForumPost' ? !item.userReads.includes(user.id) : !item.read;

              return (
                <NotificationItem
                  item={item}
                  showUnRead={showUnRead}
                  onNotificationClick={onNotificationClick}
                  onDeleteNotification={onDeleteNotification}
                />
              );
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <Badge count={classNotiUnread} offset={[20, 6]} size="small">
              Lớp học
            </Badge>
          }
          key="2"
        >
          <StyledList
            itemLayout="horizontal"
            split={false}
            dataSource={filteredNotifications('class')}
            renderItem={(item) => {
              return (
                <NotificationItem
                  item={item}
                  onNotificationClick={onNotificationClick}
                  onDeleteNotification={onDeleteNotification}
                  showUnRead={!item.read}
                />
              );
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <Badge count={ycompanyNotiUnread} offset={[20, 6]} size="small">
              Ycompany
            </Badge>
          }
          key="3"
        >
          <StyledList
            itemLayout="horizontal"
            split={false}
            dataSource={filteredNotifications('ycompany')}
            renderItem={(item) => {
              return (
                <NotificationItem
                  item={item}
                  onNotificationClick={onNotificationClick}
                  onDeleteNotification={onDeleteNotification}
                  showUnRead={!item.read}
                />
              );
            }}
          />
        </TabPane>
      </StyeledTab>
    </Drawer>
  );
});

export default Notification;
