import React from 'react';
import {
  EditOutlined,
  CheckCircleOutlined,
  MenuOutlined,
  LoginOutlined,
  StopOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { Menu, Dropdown, Tag, Typography, Badge } from 'antd';
import styled from 'styled-components';

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const getRoleName = (role) => {
  switch (role) {
    case 'admin':
      return 'Quản trị viên';
    case 'course_manager':
      return 'Quản lí khóa học';
    case 'manager':
      return 'Quản lí nội dung';
    case 'teacher':
      return 'Giảng viên';
    case 'accountant':
      return 'Kế toán';
    case 'student_manager':
      return 'Quản lí học viên';
    case 'task_manager':
      return 'Quản lí Task';
    case 'sale_manager':
      return 'Quản lí Sale';
    case 'saler':
      return 'Nhân viên Sale';
    default:
      return '';
  }
};

export const columns = (onEdit, onLogin, onLock, onUnlock, onAddForumTopic, onStropWork) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: {}
  },
  {
    title: 'Nhân viên',
    key: 'name',
    sorter: {},
    render: ({ name, roles }) => (roles.includes('saler') ? <Badge dot>{name}</Badge> : name)
  },
  {
    title: 'Phân Quyền',
    key: 'roles',
    dataIndex: 'roles',
    render: (roles) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {roles.map(
          (role) => getRoleName(role) && <Typography.Text>{`- ${getRoleName(role)}`}</Typography.Text>
        )}
      </div>
    )
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: {}
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    key: 'phone',
    sorter: {}
  },
  {
    title: 'Giới tính / Tuổi',
    key: 'gender',
    render: ({ age, gender }) =>
      age ? `${gender === 'male' ? 'Nam' : 'Nữ'} / ${age} tuổi` : gender === 'male' ? 'Nam' : 'Nữ'
  },
  {
    title: 'Trạng thái',
    key: 'status',
    sorter: {},
    render: (item) => {
      switch (item.status) {
        case 'locked':
          return <Tag color="red">Cấm truy cập</Tag>;
        default:
          return <Tag color="green">Đang hoạt động</Tag>;
      }
    }
  },
  {
    width: 300,
    title: 'Chủ đề Forum',
    key: 'forumTopic',
    render: (item) =>
      item.forumTopics.map((topic) => (
        <Tag color="gray" style={{ margin: 5 }}>
          {topic.name}
        </Tag>
      ))
  },
  {
    key: 'action',
    title: '',
    width: 100,
    render: (item) => (
      <ActionWrapper>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => onAddForumTopic(item)}>
                <TagsOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Thêm chủ đề diễn đàn
              </Menu.Item>
              <Menu.Item onClick={() => onEdit(item)}>
                <EditOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Chỉnh sửa
              </Menu.Item>
              <Menu.Item onClick={() => onLogin(item)}>
                <LoginOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Đăng nhập
              </Menu.Item>
              {item.status === 'active' && (
                <Menu.Item onClick={() => onLock(item)} style={{ color: 'red' }}>
                  <StopOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Cấm truy cập
                </Menu.Item>
              )}
              {item.status === 'locked' && (
                <Menu.Item onClick={() => onUnlock(item)} style={{ color: 'green' }}>
                  <CheckCircleOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Cho phép truy cập
                </Menu.Item>
              )}

              <Menu.Item onClick={() => onStropWork(item)} style={{ color: 'red' }}>
                <StopOutlined style={{ cursor: 'pointer', fontSize: 17 }} /> Ngưng hoạt động
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <MenuOutlined style={{ cursor: 'pointer', fontSize: 17 }} />
        </Dropdown>
      </ActionWrapper>
    )
  }
];
