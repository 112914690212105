import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spin from './styles';
import { onAuthentication } from './redux';
import Layout from './Layout';
import { pathByRole } from './constant';

export default function PrivateRoute({ component: Component, redirect, ...rest }) {
  const authentication = useSelector((state) => state.privateRoute.authentication);
  const [status, setStatus] = useState(401);
  const dispatch = useDispatch();
  const history = useHistory();
  const clinicStatus = useRef('');

  const userAuthentication = useCallback(
    () =>
      dispatch(
        onAuthentication((response) => {
          if (rest.path !== '/') {
            const validPath = [];

            response.roles.map((role) => {
              validPath.push(pathByRole[role]);
            });

            if (!validPath.flat().includes(rest.path)) {
              return (window.location = '/error/403');
            }
          }

          setStatus(200);

          if (redirect) {
            return history.push('/admin/user-tasks');
          }
          return null;
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    if (!authentication) userAuthentication();
  }, []);

  if (status === 200) {
    return (
      <Layout>
        <Route
          {...rest}
          render={(props) => <Component match={props.match} clinicStatus={clinicStatus.current} />}
        />
      </Layout>
    );
  }

  return <Spin size="large" style={{ height: '100vh' }} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};
