import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  width: 1440px !important;

  @media(max-width: 1440px) {
    width: 1280px !important;
  }

  @media(max-width: 1280px) {
    width: 1140px !important;
  }
`;

export default StyledModal;
