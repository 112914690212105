import React from 'react';
import { Tag, Tooltip, Space } from 'antd';
import styled from 'styled-components';
import { DeleteOutlined, SnippetsOutlined, GiftOutlined } from '@ant-design/icons';

export const columns = (onView, onDelete, onUpdateReceiveGiftStatus) => [
  {
    title: 'Học viên',
    render: ({ user }) => user.name
  },
  {
    title: 'Email',
    render: ({ user }) => user.email
  },
  {
    title: 'Số điện thoại',
    render: ({ user }) => user.phone
  },
  {
    title: 'Giới tính / Tuổi',
    render: ({ user: { age, gender } }) =>
      age ? `${gender === 'male' ? 'Nam' : 'Nữ'} / ${age} tuổi` : gender === 'male' ? 'Nam' : 'Nữ'
  },
  {
    title: 'Nghề nghiệp',
    render: ({ user }) => user.job
  },
  {
    title: 'Trạng thái',
    sorter: {},

    render: ({ user }) => {
      switch (user.status) {
        case 'locked':
          return <Tag color="red">Cấm truy cập</Tag>;
        case 'not_confirm':
          return <Tag color="orange">Đang chờ xác nhận</Tag>;
        default:
          return <Tag color="green">Đang hoạt động</Tag>;
      }
    }
  },
  {
    title: 'Quà tặng',
    dataIndex: 'receiveGift',
    key: 'receive_gift',
    sorter: {},
    render: (receiveGift) =>
      receiveGift ? <Tag color="green">Đã nhận</Tag> : <Tag color="orange">Chưa nhận</Tag>
  },
  {
    title: '',
    width: 150,
    render: (item) => (
      <Space>
        <Tooltip
          title={item.receiveGift ? 'Xác nhận chưa nhận quà' : 'Xác nhận đã nhận quà'}
          color="orange"
          key="orange"
        >
          <GiftOutlined
            style={{ cursor: 'pointer', fontSize: 17 }}
            onClick={() => onUpdateReceiveGiftStatus(item)}
          />
        </Tooltip>
        <Tooltip title="Xem thông tin" color="geekblue" key="geekblue">
          <SnippetsOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onView(item)} />
        </Tooltip>
        <Tooltip title="Xóa" color="red" key="red">
          <DeleteOutlined style={{ cursor: 'pointer', fontSize: 17 }} onClick={() => onDelete(item)} />
        </Tooltip>
      </Space>
    )
  }
];

export default columns;
