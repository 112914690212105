import React, { useState, useEffect, useCallback, useImperativeHandle, useRef } from 'react';
import { Avatar, Typography, List, Breadcrumb, Col, Tag, Popconfirm, Input } from 'antd';
import {
  FolderOpenOutlined,
  EyeOutlined,
  CommentOutlined,
  DeleteOutlined,
  LikeOutlined,
  EditOutlined,
  EyeInvisibleOutlined
} from '@ant-design/icons';

import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { onGetForumPosts, onDeletePost, onEditForumPost } from '../../saga';
import EditPost from '../../Modal/EditPost';
import InfoModal from './InfoModal';

const { Text, Title } = Typography;

const PostItem = ({ defaultValue, editRef, removePost, editData }) => {
  const infoRef = useRef();
  const [item, setItem] = useState(defaultValue);
  const removeCommentData = (commentId) => {
    setItem((state) => {
      const filterdComments = state.comments.filter((item) => item.id !== parseInt(commentId, 10));

      return {
        ...state,
        comments: filterdComments
      };
    });
  };

  useEffect(() => {
    setItem(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <InfoModal ref={infoRef} item={item} removeCommentData={removeCommentData} />
      <List.Item style={{ display: 'flex', flexFlow: 'wrap row' }}>
        <List.Item.Meta
          avatar={<Avatar src={item.owner.avatarUrl} size={40} />}
          title={
            <Text style={{ cursor: 'pointer' }} strong onClick={() => infoRef.current.open()}>
              {item.title}
              {item.hidden && (
                <Tag color="red" style={{ marginLeft: 10 }}>
                  Đang Ẩn
                </Tag>
              )}
            </Text>
          }
          description={`${item.owner.name} - ${item.createdAt}`}
        />
        {item.hashtag.length > 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexFlow: 'wrap row',
              paddingLeft: 45
            }}
          >
            {item.hashtag.map((hashtag) => (
              <Tag style={{ marginTop: 5, marginRight: 5 }} color="#393939">
                {hashtag}
              </Tag>
            ))}
          </div>
        )}
        <div style={{ width: '100%', paddingLeft: 45, marginTop: 10 }}>
          <Text style={{ marginRight: 20 }}>
            <EyeOutlined style={{ marginRight: 5, fontSize: 15 }} />
            <Text>{`Lượt xem: ${item.views}`}</Text>
          </Text>
          <Text style={{ marginRight: 20 }}>
            <LikeOutlined style={{ marginRight: 5, fontSize: 15 }} />
            <Text>{`Lượt thích: ${item.likes || 0}`}</Text>
          </Text>
          <Text style={{ marginRight: 20 }}>
            <CommentOutlined style={{ marginRight: 5, fontSize: 15 }} />
            <Text>{`Bình luận: ${item.comments.length || 0}`}</Text>
          </Text>
          <Text style={{ marginRight: 20, cursor: 'pointer' }} onClick={() => editRef.current.open(item)}>
            <EditOutlined style={{ marginRight: 5, fontSize: 15 }} />
            <Text>Chỉnh sửa</Text>
          </Text>
          <Popconfirm
            placement="top"
            title="Bạn có muốn xóa bài viết này không?"
            onConfirm={() => removePost({ id: item.id })}
            okText="Có"
            cancelText="Không"
          >
            <Text style={{ color: 'red', cursor: 'pointer', marginRight: 20 }}>
              <DeleteOutlined style={{ marginRight: 5, fontSize: 15 }} />
              <Text style={{ color: 'red' }}>Xóa</Text>
            </Text>
          </Popconfirm>
          <Popconfirm
            placement="top"
            title={
              !item.hidden ? 'Bạn có muốn ẩn bài viết này không?' : 'Bạn có muốn bỏ ẩn bài viết này không?'
            }
            onConfirm={() => editData({ forum_post: { id: item.id, hidden: !item.hidden } })}
            okText="Có"
            cancelText="Không"
          >
            <Text style={{ color: 'red', cursor: 'pointer' }}>
              <EyeInvisibleOutlined style={{ marginRight: 5, fontSize: 15 }} />
              <Text style={{ color: 'red' }}>{item.hidden ? 'Bỏ Ẩn bài viết' : 'Ẩn bài viết'}</Text>
            </Text>
          </Popconfirm>
        </div>
      </List.Item>
    </>
  );
};

const Post = React.forwardRef(({ currentFilter, changeLocation }, ref) => {
  const dispatch = useDispatch();
  const editRef = useRef();
  const location = useLocation();
  let typing = null;
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1,
    parent: {},
    rootParent: {}
  });
  const { items, loading, currentPage, totalPages, parent, rootParent } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetForumPosts(params, setState)), [dispatch]);

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const removePost = useCallback((params) => dispatch(onDeletePost(params, fetchData)), [dispatch]);

  const editData = useCallback((params) => dispatch(onEditForumPost(params, fetchData)), [dispatch]);

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      showLoading();
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  useImperativeHandle(ref, () => ({
    fetchData: () => fetchData()
  }));

  useEffect(() => {
    if (currentFilter.current.sub_topic_id) getData(currentFilter.current);
  }, [location.search]);

  return (
    <>
      <EditPost fetchData={fetchData} ref={editRef} />
      <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title level={5} style={{ margin: '0 8px' }}>
          {rootParent.name || ''}
        </Title>
      </Col>
      <Col span={24}>
        {parent.id && (
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                currentFilter.current.sub_topic_id = '';
                currentFilter.current.page = 0;
                changeLocation();
              }}
            >
              <FolderOpenOutlined />
              <span>{parent.name}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Bài viết</Breadcrumb.Item>
          </Breadcrumb>
        )}
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
          <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
        </Col>
        <List
          itemLayout="horizontal"
          dataSource={items}
          loading={loading}
          {...(totalPages > 0 && {
            pagination: {
              onChange: (page) => {
                currentFilter.current.page = page;
                changeLocation();
              },
              total: 10 * totalPages,
              pageSize: 10,
              showLessItems: true,
              showSizeChanger: false,
              current: currentPage,
              defaultCurrent: 1
            }
          })}
          renderItem={(item) => (
            <PostItem defaultValue={item} removePost={removePost} editRef={editRef} editData={editData} />
          )}
        />
      </Col>
    </>
  );
});

export default Post;
