import React, { useEffect, useImperativeHandle, useState, useRef } from 'react';
import { Modal, Progress, message, Button } from 'antd';
import { useSelector } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import '../ckeditor5/build/ckeditor';

const Upload = React.forwardRef(({}, ref) => {
  const user = useSelector((state) => state.user.data);
  const xhrRef = useRef();
  const [isShow, setShowModal] = useState(false);
  const [progress, setShowProgress] = useState(0);
  const close = () => {
    setShowModal(false);
    setShowProgress(0);
  };

  const isAdmin = user.roles.includes('admin');
  const isManager = user.roles.includes('employee');

  const cancelRequest = () => {
    Modal.confirm({
      content: 'Bạn có muốn hủy tải video này',
      onOk() {
        xhrRef.current.abort();
        close();
      }
    });
  };

  useImperativeHandle(ref, () => ({
    open: (file, editor, type) => {
      if (file.size / 1024 / 1024 > 200 && !isAdmin && !isManager) {
        return message.error('Không thể tải lên video > 200MB');
      }

      setShowModal(true);

      let data = new FormData();
      data.append('upload', file);
      var request = new XMLHttpRequest();

      xhrRef.current = request;

      request.upload.addEventListener('progress', function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (percentCompleted !== 100) setShowProgress(percentCompleted);
      });

      request.upload.addEventListener('error', function (error) {
        console.log('error');
        console.log(error);
      });

      request.upload.addEventListener('abort', function (error) {
        console.log('abort');
        console.log(error);
      });

      request.onreadystatechange = function () {
        if (request.readyState === XMLHttpRequest.DONE) {
          const response = JSON.parse(request.response);
          if (type === 'video') {
            editor.execute('videoUpload', response.url);
          }

          if (type === 'file') {
            editor.execute('link', `${process.env.DOMAIN_URL}/editor-document/${response.id}`, null);
          }
          close();
        }
      };

      request.upload.addEventListener('error', function (error) {
        close();
        message.error('Xảy ra lỗi trong quá trình tải lên!');
      });

      request.open('post', `/direct_upload`, true);
      request.timeout = 100000000;

      request.send(data);
    }
  }));

  return (
    <Modal closable={false} visible={isShow} footer={null}>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        <Progress type="circle" percent={progress} />
        <span style={{ marginTop: 20 }}>Đang tải lên...</span>
        <Button type="primary" onClick={cancelRequest} style={{ marginTop: 20 }}>
          Hủy
        </Button>
      </div>
    </Modal>
  );
});

const Editor = ({ onChange, defaultValue }) => {
  const uploadRef = useRef();

  useEffect(() => {
    if (defaultValue) onChange(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Upload ref={uploadRef} />
      <CKEditor
        data={defaultValue || ''}
        config={{
          fileUpload: (file, editor) => {
            uploadRef.current.open(file[0], editor, 'file');
          },
          submitUpload: (file, editor) => {
            uploadRef.current.open(file[0], editor, 'video');
          },
          videoUpload: {
            extraProviders: [
              {
                name: 'zdy',
                url: [/(.*?)/],
                html: (match) => {
                  const src = match.input;
                  return (
                    '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;pointer-events: auto;">' +
                    '<video controlsList="nodownload" controls style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" src="' +
                    src +
                    '">' +
                    '</video>' +
                    '</div>'
                  );
                }
              }
            ]
          },
          ckfinder: {
            uploadUrl: '/direct_upload'
          },
          toolbar: {
            items: [
              '|',
              'bold',
              'italic',
              'heading',
              'indent',
              'outdent',
              'numberedList',
              'bulletedList',
              'blockQuote',
              'imageUpload',
              'videoUpload',
              'fileUpload',
              'alignment',
              'fontColor',
              'link',
              'code',
              'fileRepository',
              'fontBackgroundColor',
              'codeBlock',
              'fontFamily',
              'highlight',
              'fontSize',
              'undo',
              'redo'
            ]
          },
          image: {
            styles: ['alignLeft', 'alignCenter', 'alignRight'],
            toolbar: [
              'imageStyle:alignLeft',
              'imageStyle:alignCenter',
              'imageStyle:alignRight',
              '|',
              'imageResize',
              '|',
              'imageTextAlternative',
              '|',
              'linkImage'
            ]
          },
          table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
          },
          link: {
            addTargetToExternalLinks: true,
            decorators: [
              {
                mode: 'manual',
                label: 'Downloadable',
                attributes: {
                  download: 'download'
                }
              }
            ]
          }
        }}
        editor={ClassicEditor}
        onChange={(event, editor) => {
          const data = editor.getData();
          if (onChange) onChange(data);
        }}
      />
    </>
  );
};

export default Editor;
