import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Row, Col, Input, Form, Select, Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

const { Option } = Select;

const Filter = ({ currentFilter, changeLocation }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      changeLocation();
    }, 1000);
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.setFieldsValue({
      status: '',
      keyword: ''
    });
    changeLocation();
  };

  useEffect(() => {
    const { keyword, status } = queryString.parse(location.search);

    form.setFieldsValue({
      status: status || '',
      keyword: keyword || ''
    });
  }, []);

  return (
    <Form layout="vertical" form={form} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={24} md={8} lg={8} xl={6} xxl={6}>
          <Form.Item name="keyword" label="Từ khóa:">
            <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
          </Form.Item>
        </Col>
        <Col span={24} md={8} lg={8} xl={6} xxl={6}>
          <Form.Item name="status" label="Trạng thái:">
            <Select onChange={(e) => onFilter('status', e)}>
              <Option value="">Tất cả </Option>
              <Option value="active">Đang hoạt động</Option>
              <Option value="locked">Cấm truy cập</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={8} xxl={6}>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
              Xóa bộ lọc
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
