import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { Row, Col, Input, Form, Button, Select } from 'antd';
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const Filter = ({ currentFilter, changeLocation, createRef, fetchData }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  let typing = null;

  const onFilter = (field, value) => {
    currentFilter.current[field] = value;
    changeLocation();
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (typing) clearTimeout(typing);
    typing = setTimeout(() => {
      currentFilter.current.keyword = value;
      currentFilter.current.page = 1;
      changeLocation();
    }, 1000);
  };

  const clearFilter = () => {
    currentFilter.current = {};
    form.resetFields();
    changeLocation();
  };

  useEffect(() => {
    const { keyword, member_type } = queryString.parse(location.search);

    form.setFieldsValue({
      keyword: keyword || '',
      member_type: member_type || ''
    });
  }, []);

  return (
    <>
      <Form layout="vertical" form={form} style={{ width: '100%' }}>
        <Row gutter={24}>
          <Col span={24} md={8} lg={8} xl={6} xxl={6}>
            <Form.Item name="keyword" label="Từ khóa:">
              <Input placeholder="Nhập từ khóa..." onChange={onSearch} />
            </Form.Item>
          </Col>
          <Col span={24} md={8} lg={8} xl={6} xxl={6}>
            <Form.Item name="member_type" label="Loại:">
              <Select onChange={(e) => onFilter('member_type', e)}>
                <Option value="">Tất cả </Option>
                <Option value="0">Platinum</Option>
                <Option value="1">Gold</Option>
                <Option value="2">Silver</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={12} xl={8} xxl={6}>
            <Form.Item style={{ marginTop: 30 }}>
              <Button type="primary" onClick={clearFilter} icon={<FilterOutlined />}>
                Xóa bộ lọc
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify="end">
        <Button
          style={{ marginLeft: 10 }}
          onClick={() => createRef.current.open()}
          type="primary"
          icon={<PlusOutlined />}
        >
          Thêm phân hạng
        </Button>
      </Row>
    </>
  );
};

export default Filter;
