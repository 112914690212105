import React, { useState, useEffect, useCallback, useRef } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { columns } from './function';
import Table from '../../../shared/Table';
import { onGetMyManagementRegistrationInfos, onGetEmployees } from './saga';
import Preview from './Modal/Preview';
import Filter from './Filter';

const RegistrationInfo = ({ type }) => {
  const location = useLocation();
  const history = useHistory();
  const viewRef = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: true,
    items: [],
    currentPage: 0,
    totalPages: 1
  });

  const currentFilter = useRef(queryString.parse(location.search));
  const [employees, setEmployees] = useState([]);

  const { loading, items, currentPage, totalPages } = state;

  const showLoading = () => setState((state) => ({ ...state, loading: true }));

  const getData = useCallback((params) => dispatch(onGetMyManagementRegistrationInfos(params, setState)), [
    dispatch
  ]);

  const changeLocation = () => {
    history.push({
      pathname:
        type === 'support' ? '/admin/my-support-registration-info' : '/admin/my-management-registration-info',
      search: queryString.stringify(currentFilter.current)
    });
  };

  const fetchData = () => {
    showLoading();
    getData(currentFilter.current);
  };

  const onChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      currentFilter.current.sort = `${sorter.columnKey} ${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
    }

    if (pagination.current) currentFilter.current.page = pagination.current;
    changeLocation();
  };

  useEffect(() => {
    currentFilter.current = queryString.parse(location.search);
    currentFilter.current.type = type;
    fetchData();
  }, [location.search]);

  useEffect(() => {
    dispatch(onGetEmployees(setEmployees));
  }, []);

  const onView = (item) => viewRef.current.open(item.id);

  return (
    <>
      <Preview ref={viewRef} fetchData={fetchData} employees={employees} />
      <Table
        filter={<Filter currentFilter={currentFilter} changeLocation={changeLocation} />}
        title="Danh sách đăng kí khóa học"
        columns={columns(onView)}
        loading={loading}
        dataSource={items}
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={onChange}
      />
    </>
  );
};

export default RegistrationInfo;
