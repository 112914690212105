import React, {
  useState, useImperativeHandle, useRef,
} from 'react';
import {
  Col, Row, Typography, Button,
} from 'antd';
import { useReactToPrint } from 'react-to-print';
import {
  StyledModal, ClinicInfo, InforWrapper, InfoItem, Wrapper, ImageWrapper
} from './styles';
import { numberToCurrency } from '../../../../shared/function';

const { Title, Text } = Typography;

const Print = React.forwardRef((props, ref) => {
  const buttonPrintRef = useRef();
  const [state, setState] = useState({ status: false, item: {} });
  const { status, item } = state;

  const handlePrint = useReactToPrint({
    content: () => buttonPrintRef.current,
  });

  useImperativeHandle(ref, () => ({
    open: (item) => {
      setState({ status: true, item });
    },
  }));

  const onClose = () => {
    setState({ status: false, item: {} });
  };

  return (
    <StyledModal
      closable={false}
      visible={status}
      footer={null}
    >

      <Wrapper ref={buttonPrintRef}>
        <ClinicInfo>
          <ImageWrapper>
            <img alt='error' src='/images/dark-logo.png' width='100%' />
          </ImageWrapper>
        </ClinicInfo>
        <Title level={3} style={{ margin: 25, width: '100%', textAlign: 'center' }}>Phiếu Chi</Title>
        <InforWrapper>
          <Row>
            <Col span={24}>
              <InfoItem>
                <Text strong style={{ width: 110 }}>Mã phiếu:</Text>
                <Text>{`: ${item.code || ''}`}</Text>
              </InfoItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InfoItem>
                <Text strong style={{ width: 110 }}>Ngày lập phiếu</Text>
                <Text>{`: ${item.createdAt}`}</Text>
              </InfoItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <InfoItem>
                <Text strong style={{ width: 110 }}>Người nhận</Text>
                <Text>{`: ${item.expenseType === 'personal' ? item.name : item.receivePerson}`}</Text>
              </InfoItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <InfoItem>
                <Text strong style={{ width: 110 }}>Số tiền</Text>
                <Text>{`: ${numberToCurrency(item.amount)}`}</Text>
              </InfoItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InfoItem>
                <Text strong style={{ width: 110 }}>Nội dung chi</Text>
                <Text>{`: ${item.note || ''}`}</Text>
              </InfoItem>
            </Col>
          </Row>
        </InforWrapper>
      </Wrapper>
      <Row justify='center' style={{ marginTop: 20 }}>
        <Button
          type='primary'
          size='large'
          style={{ width: 150 }}
          onClick={handlePrint}
        >
          In phiếu
        </Button>
        <Button
          size='large'
          style={{ width: 150, marginLeft: 20 }}
          onClick={onClose}
        >
          Hủy
        </Button>
      </Row>
    </StyledModal>
  );
});

export default Print;
