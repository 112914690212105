import { get } from 'axios';
import { takeLatest, call, put } from 'redux-saga/effects';

import { ON_AUTHENTICATION, onUpdateAuthtication } from './redux';
import { onUpdateUserInfo } from '../../shared/CurrentUser/redux';

function* userAuthentication({ callback }) {
  try {
    const response = yield call(get, '/current_user/info');

    const redirect =
      (response.roles.length === 1 &&
        (response.roles.includes('student') || response.roles.includes('forum_member'))) ||
      (response.roles.length === 2 &&
        response.roles.includes('student') &&
        response.roles.includes('forum_member'));

    if (redirect) {
      window.location = `${process.env.WEBSITE_URL}/new-feeds`;
    } else {
      yield put(onUpdateUserInfo(response));
      yield put(onUpdateAuthtication(true));
      callback(response);
    }
  } catch (error) {
    callback(error.response.status);
  }
}

export default function* routeWatcher() {
  yield takeLatest(ON_AUTHENTICATION, userAuthentication);
}
