import React, { useState, useImperativeHandle, useCallback } from 'react';
import { Col, Input, Row, Form, Modal, Button, Select, InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import SelectSearch from '@components/SelectSearch';
import { fieldValidate } from '@shared/function';
import { onEditTopic } from '../saga';
import Upload from '@components/Upload';
const { Item } = Form;
const { Option } = Select;

const View = React.forwardRef(({ fetchData, users }, ref) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ item: {}, isShow: false });
  const { isShow, item } = state;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const close = () => {
    form.resetFields();
    setState({ item: {}, isShow: false });
  };

  const callback = (status) => {
    if (status === 200) {
      fetchData();
      close();
    }

    setSubmitting(false);
  };

  const updateData = useCallback((params) => dispatch(onEditTopic(params, callback)), [dispatch]);

  const studentForumTopicAttributes = (values) => {
    const selectUser = values.student_ids || [];

    const newUser = [];
    const removeUser = [];

    const students = item.userForumTopics.filter((a) => a.user_type === 'student');

    selectUser.map((id) => {
      const filteredCourse = students.filter((a) => a.user_id === parseInt(id, 10))[0];

      if (!filteredCourse) newUser.push({ user_id: id, user_type: 'student' });
    });

    students.map((userTopic) => {
      if (!selectUser.includes(userTopic.user_id)) {
        removeUser.push({
          _destroy: true,
          id: userTopic.id,
          user_id: userTopic.user_id
        });
      }
    });

    return newUser.concat(removeUser);
  };

  const employeeForumTopicAttributes = (values) => {
    const selectUser = values.employee_ids || [];

    const newUser = [];
    const removeUser = [];

    const employees = item.userForumTopics.filter((a) => a.user_type === 'employee');

    selectUser.map((id) => {
      const filteredCourse = employees.filter((a) => a.user_id === parseInt(id, 10))[0];

      if (!filteredCourse) newUser.push({ user_id: id, user_type: 'employee' });
    });

    employees.map((userTopic) => {
      if (!selectUser.includes(userTopic.user_id)) {
        removeUser.push({
          _destroy: true,
          id: userTopic.id,
          user_id: userTopic.user_id
        });
      }
    });

    return newUser.concat(removeUser);
  };

  const onFinish = (values) => {
    setSubmitting(true);

    const studentParams = studentForumTopicAttributes(values);
    const employeesParams = employeeForumTopicAttributes(values);
    values.user_forum_topics_attributes = employeesParams.concat(studentParams);
    values.position = values.position || 0;

    updateData({ ...values, id: item.id });
  };

  useImperativeHandle(ref, () => ({
    open: (item) => {
      form.setFieldsValue({
        position: item.position,
        name: item.name,
        private: item.private,
        student_ids: item.userForumTopics.filter((a) => a.user_type === 'student').map((a) => a.user_id),
        employee_ids: item.userForumTopics.filter((a) => a.user_type === 'employee').map((a) => a.user_id)
      });

      setState({ item, isShow: true });
    }
  }));

  return (
    <Modal closable={false} visible={isShow} footer={null} title="Chỉnh sửa danh mục" width={'70%'}>
      <Form layout="vertical" form={form} name="createTopic" scrollToFirstError onFinish={onFinish}>
        <Row gutter={48}>
          <Col span={12}>
            <Item name="position" label="Vị trí">
              <InputNumber placeholder="Vị trí" />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Hình ảnh" name="image">
              <Upload
                defaultValue={item.image}
                onFileChange={(file) => form.setFieldsValue({ image: file })}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="name" label="Tên" rules={[fieldValidate.required]}>
              <Input placeholder="Tên" />
            </Item>
          </Col>
          <Col span={12}>
            <Item name="private" label="Loại" rules={[fieldValidate.required]}>
              <Select placeholder="Loại">
                <Option value={true}>Chủ đề chuyên ngành</Option>
                <Option value={false}>Chủ đề chung</Option>
              </Select>
            </Item>
          </Col>
          <Col span={24}>
            <Item name="student_ids" label="Học viên">
              <SelectSearch placeholder="Học viên" mode="multiple">
                {users.students.map((user) => (
                  <Option value={user.id} key={user.id}>
                    {user.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
          <Col span={24}>
            <Item name="employee_ids" label="Nhân viên">
              <SelectSearch placeholder="Nhân viên" mode="multiple">
                {users.employees.map((user) => (
                  <Option value={user.id} key={user.id}>
                    {user.name}
                  </Option>
                ))}
              </SelectSearch>
            </Item>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            disabled={submitting}
            type="primary"
            size="large"
            htmlType="submit"
            style={{ width: 150, marginRight: 20 }}
          >
            Lưu
          </Button>
          <Button size="large" style={{ width: 150 }} onClick={close}>
            Đóng
          </Button>
        </Row>
      </Form>
    </Modal>
  );
});

export default View;
